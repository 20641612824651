import React from "react";
import Modal from "react-bootstrap/Modal";
import { useQueryClient as useCustomQueryClient } from "services/apiClient";
import "../add-transaction.scss";
import DotLoader from "components/Loader/DotLoader";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
// import config from "config/config";
// import ValidationUtil from "util/validation-util";
// import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

export const CheckDefaultStatusModal = ({ onHide }) => {
  const { refundTransaction, setRefundTransaction } =
    useChampionPaymentHistory();

  const [, setCheckForDefault] = React.useState(false);

  React.useEffect(() => {
    if (refundTransaction.showRefundVerificationModal)
      return setCheckForDefault(true);

    return setCheckForDefault(false);
  }, [refundTransaction.showRefundVerificationModal]);

  const url = "";
  useCustomQueryClient(url, {
    method: "get",
    enabled: false,
  });

  const closeModal = () => {
    setRefundTransaction((prevState) => {
      return {
        ...prevState,
        showUploadReceiptModal: !prevState.showUploadReceiptModal,
      };
    });
  };

  return (
    <Modal
      onHide={onHide}
      show={refundTransaction.showUploadReceiptModal}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="loader-wrapper">
        <DotLoader />
      </div>
      <>
        <Modal.Header>
          <h3 className={"title"}>Request Refund</h3>
          <CancelIcon onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <p className="body-txt">
            You are about to make this <br /> payment transaction void.
            <br /> Amount will be deducted from <br /> overall champions
            contract
          </p>

          <div className="confirm-button-wrapper">
            <button onClick={() => {}} disabled={""} className="confirm">
              Continue
            </button>
            <button disabled={""} onClick={closeModal} className="cancel">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};
