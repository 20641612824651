/**
 * For network calls
 */
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

//storage keys
export const AUTHENTICATION_TOKEN = 'lams-token';
export const USER_ROLE = 'usrRole';
export const FILTERED_STATE = 'filteredState';
export const AMORTIZATION_OBJ = 'amortizationObj';
export const PRICE_TEMPLATE_OBJ = 'priceTemplateObj';
export const IS_EDIT_FLAG = 'isEdit';

export const DurationInDaysMultiplier = {
  FIVE_DAYS_IN_A_WEEK: 5,
  SIX_DAYS_IN_A_WEEK: 6,
  ONCE_A_WEEK: 1,
  ONCE_A_MONTH: 12 / 52,
  ONCE_A_QUATER: 4 / 52,
  ONCE_A_YEAR: 1 / 52
};

export const UserRoleType = ['admin', 'super-admin', 'agent'];

export const HPStatusLang = {
  totalPaidAmountEqualHPValue:
    ' Total paid amount is equal to Total HP value of the contract, do you want to HP complete the contract?',
  totalPaidAmountGreaterThanHPValue:
    ' Total paid amount is greater than total HP value of the contract, do you want to HP complete the contract?',
  totalPaidAmountLessThanHPValueFirstPart: ' Total paid amount is less then HP value so an adjustment of ',
  totalPaidAmountLessThanHPValueLastPart: ' will be made in the contract. Do you agree to HP complete the contract?'
};
