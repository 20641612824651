import React from "react";
import Select from "react-select";
import styled from "styled-components";

const CustomSelect = ({
  options = [],
  onChange,
  placeholder = "Select an Option",
  name,
  isLoading = false,
  fullWidth = false,
  isDisabled = false,
  value,
  ...rest
}) => {
  return (
    <CustomSelect.Wrapper>
      <Select
        {...rest}
        options={options}
        value={value}
        className={`select-item ${fullWidth ? "full-width" : ""}`}
        classNamePrefix="react-select"
        isSearchable={true}
        isClearable={true}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#FFCC00",
            primary: "black",
            primary50: "#f1c40f",
          },
        })}
      />
    </CustomSelect.Wrapper>
  );
};
CustomSelect.Wrapper = styled.div`
//   font-size: 13px;
//   z-index: 12;
//   .select-item {
//     width: 200px;
//   }
//   .full-width {
//     width: 100%;
//   }
// `;
export default CustomSelect;
