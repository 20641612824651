import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Form } from 'semantic-ui-react';
import ValidationUtil from '../../util/validation-util';
import { Input, Icon } from 'semantic-ui-react';
import './ForgotPassword.scss';
import Axios from 'axios';
import config from '../../config/config';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import MaxSubHeader from '../../components/MaxSubHeader/MaxSubHeader';

const styles = {
  typography: { fontWeight: 600 },
  cardDescription: { marginTop: '0.75rem', color: '#808080' },
  cardBody: {
    padding: '2rem 0.7rem',
    width: 372,
    borderRadius: 5,
    border: '0.5px solid #EEEEEE',
    boxShadow: 'none'
  },
  btn: {
    marginTop: '1.4375rem',
    fontWeight: 600,
    background: '#FCDD18',
    color: '#3E3E3E',
    width: '100%'
  },
  btnLink: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem'
  }
};

const ForgotPassword = () => {
  const emilInputRef = useRef(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { value } = e.target;
    setEmailError(ValidationUtil.emailValidation(value));
    if (value === '') {
      clearInput();
    }

    if (!ValidationUtil.emailValidation(value)) {
      setEmail(value);
    }
  };

  const clearInput = () => {
    emilInputRef.current.inputRef.current.value = '';
    setEmail('');
    setEmailError('');
  };

  const submitPasswordReset = async (e) => {
    e.preventDefault();
    if (email.length > 0 && !emailError.length) {
      setLoader(true);
      const data = {
        username: email
      };
      try {
        const response = await Axios.post(`${config.base_url}/accounts/auth/forgot-password`, data);
        if (response.status === 200) {
          swal('Password has been sent to your inbox.', 'Please reset your password.', 'success').then(() => {
            localStorage.setItem('email', email);
            clearInput();
            history.push({
              pathname: '/reset-password',
              state: { detail: email }
            });
          });
        }
      } catch (err) {
        const errorResposnse = err?.response?.data;
        if (errorResposnse.code === 'E00' || errorResposnse.message === 'User does not exist') {
          setEmailError('This email address doesn’t exist. Please check and retry');
          return;
        }
        swal(errorResposnse?.message || 'Something went wrong!', 'Please try again', 'error');
      } finally {
        setLoader(false);
      }
    } else {
      setEmailError(ValidationUtil.emailValidation(email));
    }
  };

  return (
    <ForgotpasswordBottomOverlay>
      <MaxSubHeader />
      <Card
        className="reset-password__card"
        sx={styles.cardBody}>
        <CardContent>
          <Typography
            sx={styles.typography}
            variant="h6"
            component="h6">
            Reset Password
          </Typography>
          <Typography
            variant="body2"
            sx={styles.cardDescription}>
            Enter your email address and we will send you a temporary password to your email address
          </Typography>

          <Form
            className="reset-password-form"
            loading={loader}>
            <div className="reset-password-email-input">
              <label className="form-label">Email Address</label>
              <Input
                error={!!emailError}
                fluid={true}
                ref={emilInputRef}
                onChange={handleChange}
                icon={
                  emailError ? (
                    <Icon
                      onClick={clearInput}
                      name="times circle outline"
                      color="red"
                      link
                    />
                  ) : (
                    false
                  )
                }
              />
              {emailError && (
                <p className="password-reset-error text-danger text-left">
                  <Icon
                    name="exclamation triangle"
                    color="red"
                  />
                  {emailError}
                </p>
              )}
            </div>
            <Button
              onClick={submitPasswordReset}
              sx={styles.btn}>
              CONTINUE
            </Button>
            <div style={styles.btnLink}>
              <Button
                sx={{ color: '#E88E15', fontWeight: 600 }}
                size="small"
                onClick={() => {
                  history.push('/');
                }}>
                LOG INTO MY ACCOUNT
              </Button>
            </div>
          </Form>
        </CardContent>
      </Card>
    </ForgotpasswordBottomOverlay>
  );
};

export default ForgotPassword;

const ForgotpasswordBottomOverlay = (props) => {
  return (
    <div className="forgot-password-overlay-container">
      {props.children}
      <div className="bg-overlay" />
    </div>
  );
};
