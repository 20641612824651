import React, { createContext, useContext, useState } from 'react';
const ContractContext = createContext(null);

const ContractProvider = ({ children }) => {
  const [restructureData, setRestructureData] = useState({});
  const [showRestructureModal, toggleRestructureModal] = useState(false);
  const [showContractReviewModal, toggleContractReviewModal] = useState(false);

  return (
    <ContractContext.Provider
      value={{
        restructureData,
        setRestructureData,
        showRestructureModal,
        toggleRestructureModal,
        showContractReviewModal,
        toggleContractReviewModal
      }}>
      {children}
    </ContractContext.Provider>
  );
};

export default ContractProvider;
export const useContractProvider = () => {
  return useContext(ContractContext);
};
