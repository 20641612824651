import React, { useState, useContext, createContext } from "react";

const ChampionPaymentHistory = createContext(null);
const ChampionPaymentHistoryProvider = ({ children }) => {
  const [showPaymentHistoryModal, togglePaymentHistoryModal] = useState(false);
  const [foundMatchingPayment, setFoundMatchingPayment] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = React.useState({});
  const [userDataForAddTransaction, setUserDataForAddTransaction] =
    React.useState({});
  const [championInfo, setChampionInfo] = React.useState({});
  const [history, setChampionHistory] = React.useState([]);
  const [addTransactionSuccess, setAddTransactionSuccess] =
    React.useState(false);
  const [paymentChannel, setPaymentChannel] = React.useState("");
  const [collectionOfficerId, setCollectionOfficerId] = React.useState("");
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [voidTransactionObject, setVoidTransactionObject] = React.useState({
    showVoidModal: false,
    showConfirmVoidModal: false,
    transactionReference: "",
    championId: "",
    contractId: "",
    id: "",
  });

  const [transaferToChampion, setTransferTochampion] = React.useState({
    showChampionsModal: false,
    transferSuccessModal: false,
    showConfirmTransfer: false,
    newChampion: {},
    successModal: false,
  });

  const [refundTransaction, setRefundTransaction] = React.useState({
    showUploadReceiptModal: false,
    showRefundVerificationModal: false,
  });

  return (
    <ChampionPaymentHistory.Provider
      value={{
        showPaymentHistoryModal,
        togglePaymentHistoryModal,
        foundMatchingPayment,
        setFoundMatchingPayment,
        selectedTransaction,
        setSelectedTransaction,
        userDataForAddTransaction,
        setUserDataForAddTransaction,
        championInfo,
        setChampionInfo,
        history,
        setChampionHistory,
        addTransactionSuccess,
        setAddTransactionSuccess,
        paymentChannel,
        setPaymentChannel,
        collectionOfficerId,
        setCollectionOfficerId,
        voidTransactionObject,
        setVoidTransactionObject,
        transaferToChampion,
        setTransferTochampion,
        selectedRowId,
        setSelectedRowId,
        refundTransaction,
        setRefundTransaction,
      }}>
      {children}
    </ChampionPaymentHistory.Provider>
  );
};

export default ChampionPaymentHistoryProvider;

export const useChampionPaymentHistory = () => {
  return useContext(ChampionPaymentHistory);
};
