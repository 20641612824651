import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { Button } from "semantic-ui-react";

import format from "date-fns/format";

const dateFormat = "YYYY-MM-DD";

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.state = {
      selectedDay: null
    };
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day
    });
  }

  handleConfirmClick() {
    const { selectedDay } = this.state;
    this.props.onDayChange &&
      this.props.onDayChange(format(selectedDay, dateFormat));
  }

  render() {
    return (
      <div>
        <DayPicker
          selectedDays={this.state.selectedDay}
          onDayClick={this.handleDayClick}
        />
        <p>
          {this.state.selectedDay
            ? this.state.selectedDay.toLocaleDateString()
            : "Please select a day "}
          {/* 👻 */}
        </p>
        {this.state.selectedDay && (
          <Button positive onClick={this.handleConfirmClick}>
            confirm
          </Button>
        )}
      </div>
    );
  }
}
