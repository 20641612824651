import React from "react";
import Modal from "react-bootstrap/Modal";
// import { useQueryClient } from "services/apiClient";
import "../add-transaction.scss";
// import DotLoader from "components/Loader/DotLoader";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
// import config from "config/config";
// import ValidationUtil from "util/validation-util";
// import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as Asterisk } from "assets/img/asterisk.svg";

export const RequestRefundModal = ({ show, onHide }) => {
  const { refundTransaction, setRefundTransaction, selectedTransaction } =
    useChampionPaymentHistory();

  const { amount } = selectedTransaction;
  const [refundOption, setRefundOption] = React.useState("");
  const [amountToRefund,] = React.useState(0);

  const handleUpdateReferenceId = () => {};
  const handleSelectChange = (e) => {
    setRefundOption(e.target.value);
  };

  const closeModal = () => {
    setRefundTransaction((prevState) => {
      return {
        ...prevState,
        showUploadReceiptModal: !prevState.showUploadReceiptModal,
      };
    });
  };

  return (
    <Modal
      onHide={onHide}
      show={refundTransaction.showUploadReceiptModal}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {/* <div className="loader-wrapper">
        <DotLoader />
      </div> */}
      <>
        <Modal.Header>
          <h3 className={"title"}>Request Refund</h3>
          <CancelIcon onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="form request-refund-form">
            <label>Transaction Amount</label>
            <select value={refundOption} onChange={handleSelectChange}>
              <option value="">Select transaction amount</option>
              <option value="full">Full Amount</option>
              <option value="partial">Partial Amount</option>
            </select>

            <label>Amount to refund</label>
            <div className="refund-input-wrapper">
              <input
                disabled={refundOption === "full"}
                value={refundOption === "full" ? amount : amountToRefund}
                name="referenceId"
                onChange={handleUpdateReferenceId}
                placeholder="Enter the reference ID"
              />
              <span className="input-secondary-label"></span>
            </div>

            <p className="form-sub-text">
              <Asterisk />
              <div>
                You can either request to refund the full amount paid
                <br /> or a partial amount of the transaction.
              </div>
            </p>

            <button
              className="modal_form_btn"
              disabled={false}
              onClick={() => {}}>
              Refund Payment
            </button>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};
