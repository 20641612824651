import {
  AMORTIZATION_SCHEDULE_ENDPOINT,
  CALL_ENDPOINT,
  COLLECTION_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
  TRANCHE_INFO,
  CONTRACT_PDF_ENDPOINT
} from '../axios/endpoints';
import { axiosInstance, getUserToken } from '../axios/method';
import { GET } from '../util/constant';

const HEADERS = {
  'Content-Type': 'application/json'
};

// function to get collections
export const getCollections = (params) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: COLLECTION_ENDPOINT,
    headers: HEADERS,
    params: params
  });
};

// function to get a champion's contract
export const getChampionsCollection = (params, id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${COLLECTION_ENDPOINT}/champion/${id}`,
    headers: HEADERS,
    params: params
  });
};

export const getChampionsCallHistory = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CALL_ENDPOINT}`,
    headers: HEADERS
  });
};

// function to get a champion's amortization schedule
export const getChampionAmortization = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${AMORTIZATION_SCHEDULE_ENDPOINT}/champions/${id}`,
    headers: HEADERS
  });
};

// function to get a champion's repayment schedule
export const getChampionRepayment = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${REPAYMENT_SCHEDULE_ENDPOINT}/champions/${id}`,
    headers: HEADERS
  });
};

// function to get a champion's tranche info
export const getChampionTrancheInfo = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${TRANCHE_INFO}/${id}`,
    headers: HEADERS
  });
};

//function to get a champion's signed contract
export const getContractPDF = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_PDF_ENDPOINT}/${id}`,
    headers: HEADERS
  });
};

//function to get a champion summary PDF contract
export const getContractSummaryPDF = (id) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_PDF_ENDPOINT}/${id}/initiated`,
    headers: HEADERS
  });
};
