import React from 'react';

import './Alert.scss'
import { Icon } from 'semantic-ui-react';

const Alert = ({
    alertMessage,
    closeAlertBox
}) => {

    return (
        <>
            {alertMessage &&
                <div className="error__div">
                    <div className="error-message__div">
                        <Icon className="icon" name='ban' />
                        <p>{alertMessage}</p>
                    </div>

                    <div>
                        <Icon className="icon cursor" name='cancel' onClick={closeAlertBox}/> 
                    </div>
                </div>
            }
        </>
    )
}

export default Alert