import React from "react";
import Modal from "react-bootstrap/Modal";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
import { useMutationClient } from "services/apiClient";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

export const ConfirmVoidModal = () => {
  const queryClient = useQueryClient();
  const { voidTransactionObject, setVoidTransactionObject, setSelectedRowId } =
    useChampionPaymentHistory();
  const { showConfirmVoidModal, id } = voidTransactionObject;

  const closeModal = () => {
    setVoidTransactionObject((prevState) => {
      return {
        ...prevState,
        showConfirmVoidModal: !prevState.showConfirmVoidModal,
      };
    });
  };

  const voidPaymentEndpoint = `collection/v1/collections/void-collection/${id}`;
  const { mutate, isLoading } = useMutationClient(voidPaymentEndpoint, {
    method: "put",
    onSuccess: (data) => {
      const { data: { message = "" } = {} } = data;
      toast.success(message);
      queryClient.invalidateQueries(["collection_record"]);
      setSelectedRowId("");
      closeModal();
    },

    onError: (data) => {
      const { response: { data: resData = {} } = {} } = data;
      toast.error(resData.message);
      closeModal();
    },
  });

  return (
    <Modal
      onHide={closeModal}
      show={showConfirmVoidModal}
      dialogClassName="transaction-modal confirmation-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <p className="body-txt">
          You are about to make this <br /> payment transaction void.
          <br /> Amount will be deducted from <br /> overall champions contract
        </p>

        <div className="confirm-button-wrapper">
          <button onClick={mutate} disabled={isLoading} className="confirm">
            Continue
          </button>
          <button disabled={isLoading} onClick={closeModal} className="cancel">
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
