import React from 'react';
import { formatNumber } from 'pages/Overview/OverviewTable';
import { useContracts } from 'contexts/contracts';
import format from 'date-fns/format';
import Button from '@mui/material/Button';
import style from 'components/ContractToBeClose/style';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'services/apiClient';
import ValidationUtil from 'util/validation-util';

export const ContractDetails = () => {
  const api_prefix = 'collection/v1/';
  const history = useHistory();
  const { contractData, toggleOpenContract, setContractData } = useContracts();
  const { contract = {} } = contractData;
  const { hp_value, amortization_end_date, daily_remit, duration_in_days } = contract;
  const [contractID, setContractID] = React.useState('');

  const totalAmountRemittedURL = `repayments/total-amount-remitted/${contractID}`;
  const fetchTotalAmountRemitted = `${api_prefix}${totalAmountRemittedURL}`;
  const { data: totalAmountRemittedRes = {}, isLoading } = useQueryClient(fetchTotalAmountRemitted, {
    method: 'get',
    retry: false,
    enabled: !!contractID
  });

  const _total_amount_remitted = totalAmountRemittedRes && totalAmountRemittedRes?.data?.data;

  React.useEffect(() => {
    if (!ValidationUtil.isObjectEmpty(totalAmountRemittedRes)) {
      setContractData((prevState) => {
        return {
          ...prevState,
          totalAmountRemitted: totalAmountRemittedRes?.data?.data
        };
      });
    }
    // eslint-disable-next-line
  }, [totalAmountRemittedRes]);

  React.useEffect(() => {
    setContractID(contract.contract_id);
  }, [contract]);
  // invalidate "contracts_record"

  const contractDetails = [
    { title: 'HP Amount', amount: formatNumber(Number(hp_value)) },
    { title: 'Total Duration (Days)', amount: Number(duration_in_days) },
    { title: 'Daily Remitance Amount', amount: formatNumber(daily_remit) },
    {
      title: 'Amortisation End Date',
      amount: format(amortization_end_date, 'DD/MMM/YYYY')
    }
  ];

  return (
    <div className="contract-details-wrapper">
      <div className="contract-details-wrapper-child w-100 mr-5">
        <div className="dis-flex">
          {contractDetails.map((data, index) => (
            <div
              key={index}
              className="close-contract-div flex-fill">
              <p className="f-600 f-18">{data.amount}</p>
              <p>{data.title}</p>
            </div>
          ))}
        </div>
        <div className="close-contract-div down">
          <p className="f-600 f-18">{formatNumber(_total_amount_remitted)}</p>
          <p> {isLoading ? 'Fetching' : ''} Total Amount Remitted</p>
        </div>
      </div>
      <div>
        <Button
          sx={[style.btn, style.moreInfoBtn]}
          onClick={() => history.push(`/champion/${contract.champion_id}`)}>
          see more information
        </Button>
        <br />
        <Button
          sx={[style.btn, style.completeContractBtn]}
          onClick={toggleOpenContract}>
          complete contract
        </Button>
      </div>
    </div>
  );
};
