import React from 'react';

// css import
import './sideBar.scss';
import Logo from "../../components/Navbar/logo.svg";


const SideBar = ({ show, children, title, subTitle, tempHide, loading }) => {

    return (
        <div className={`${show ? "sidebar-overlay" : ""} ${tempHide ? "d-none" : ""}`}>
            <div id="mySidenav" className={`sidenav ${show ? "showSideBar" : ""}`}>
                <div className="closebtn">
                    {children}
                </div>

                {loading &&
                    <div className="logo-loader">
                        <img src={Logo} alt="Logo Loader" />
                        <h3 className="text-center text-secondary mt-3">Loading...</h3>
                    </div>
                }
            </div>
        </div>
    )
}

export default SideBar