import React from 'react';
import { Donut, DoughnutCircle, DoughnutFrame } from './styles';

const SIZE = 34;
const PREFIX = 'donut';

const halfSize = SIZE / 2;
const circleProps = {
  cx: halfSize,
  cy: halfSize,
  r: halfSize - 1
};

const getClassName = (p, c) => `${p}${c}`;
const renderProgress = (progress) => <strong>{progress}</strong>;

const CustomDoughnut = ({
  displayNumber,
  progress = 100,
  bgColor,
  fillColor,
  size = SIZE,
  onRender = renderProgress,
  prefix = PREFIX
}) => (
  <Donut
    size={size}
    className={`${progress < 0} ? " is--negative" : ""`}>
    <svg
      className={getClassName(prefix, '__canvas')}
      width={SIZE}
      height={SIZE}
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      xmlns="http://www.w3.org/2000/svg">
      <DoughnutFrame
        bgColor={bgColor}
        className={getClassName(prefix, '__frame')}
        {...circleProps}
      />

      <DoughnutCircle
        fillColor={fillColor}
        className={getClassName(prefix, '__circle')}
        strokeDasharray={`${Math.abs(progress)} 100`}
        {...circleProps}
      />
    </svg>

    {typeof onRender === 'function' && <div className={getClassName(prefix, '__text')}>{onRender(displayNumber)}</div>}
  </Donut>
);

export default CustomDoughnut;
