function setItem(key, value) {
  localStorage.setItem(key, value);
}

function getItem(key) {
  const val = localStorage.getItem(key);
  if (val !== null && val.length > 0) {
    return val;
  }
  return null;
}

function removeItem(key) {
  localStorage.removeItem(key);
}

export default {
  set: setItem,
  remove: removeItem,
  get: getItem,
};
