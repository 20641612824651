import React, { Component } from "react";
import axios from "axios";

import { Button, Modal, Form, Image, Label } from "semantic-ui-react";

import styled from "styled-components";

const ButtonStyle = styled(Button)`
  background-color: #ffdd00 !important;
  color: #000 !important;
  padding-left: 9.5rem !important;
  padding-right: 10rem !important;
  margin-top: 3rem !important;
  margin-left: 8.5rem !important;
`;

const ImageStyle = styled(Image)`
  margin: auto !important;
`;

const DueLabel = styled(Label)`
  background-color: #ffcccc !important;
  color: #e75454 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
`;

const ConfirmLabel = styled(Label)`
  background-color: #fafbbd !important;
  color: #696b0a !important;
  padding-left: 1.7rem !important;
  padding-right: 1.7rem !important;
`;

const OverdueLabel = styled(Label)`
  background-color: #f4e3d4 !important;
  color: orange !important;
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
`;

const MarkedLabel = styled(Label)`
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
`;

const ProcessButton = styled(Button)`
  background-color: #ffdd00 !important;
  color: #000 !important;
  padding-left: 8rem !important;
  padding-right: 8rem !important;
  margin-top: 2rem !important;

  :disabled {
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: rgba(0, 0, 0, 0.4) !important;
  }
`;

// check champion's status after update and remove form
class Status extends Component {
  state = {
    status: null,
    allStatus: [],
    pickStatus: "",
    error: null
  };

  componentDidMount = async () => {
    const { championId: id } = this.props;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/v1/champions/${id}/status`
      );

      const result2 = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/v1/statuses`
      );

      this.setState({ status: result.data.data, allStatus: result2.data.data });
    } catch (error) {
      console.error("Unable to fetch data");
      this.setState({ error: `An error occurred` });
    }
  };

  handleChange = e => this.setState({ pickStatus: e.target.value });

  confirm = async () => {
    this.setState({ modalOpen: true });
    const { championId: id } = this.props;
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/v1/champions/${id}/status`,
        { status_id: this.state.pickStatus }
      );

      this.setState({ status: { name: "Marked for retrieval" } });

      return result;
    } catch (error) {
      console.error("Unable to update status", error);
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    // alert(`You chose the ${this.state.pickStatus} pizza.`);
  }

  DisplayStatus() {
    if (this.state.status) {
      const { name } = this.state.status;
      // const isEnabled = this.state.pickStatus.checked;
      return (
        <>
          Retrieval Status :{" "}
          <div
            style={{ color: "#000", marginLeft: "8rem", marginTop: "-1.5rem" }}
          >
            {name === "Overdue" ? (
              <OverdueLabel>{name}</OverdueLabel>
            ) : name === "Due for retrieval" ? (
              <DueLabel>{name}</DueLabel>
            ) : name === "Marked for retrieval" ? (
              <MarkedLabel>{name}</MarkedLabel>
            ) : (
              <ConfirmLabel>{name}</ConfirmLabel>
            )}
          </div>
          {name === "Marked for retrieval" ? (
            ""
          ) : (
            <Form style={{ lineHeight: "3.5rem" }} onSubmit={this.handleSubmit}>
              <Form.Group grouped>
                {this.state.allStatus &&
                  this.state.allStatus.map(status => {
                    return (
                      <Form.Field
                        key={status.id}
                        id={status.id}
                        label={status.name}
                        control="input"
                        type="radio"
                        name="htmlRadios"
                        value={status.id}
                        onChange={this.handleChange}
                        style={{
                          marginRight: "10px"
                        }}
                      />
                    );
                  })}
                <ProcessButton
                  onClick={() => {
                    this.confirm();
                  }}
                  disabled={!this.state.pickStatus}
                >
                  Proceed
                </ProcessButton>
              </Form.Group>
            </Form>
          )}
          <Modal
            size="small"
            open={this.state.modalOpen}
            onClose={this.handleClose}
            style={{ width: "557px", height: "331px" }}
          >
            <Modal.Content style={{ marginTop: "2rem" }}>
              <ImageStyle
                width="83px"
                height="83px"
                src={`${process.env.PUBLIC_URL}/checked.png`}
              />
              <h2 style={{ marginLeft: "14.5rem" }}>Successful</h2>
              <ButtonStyle onClick={() => this.setState({ modalOpen: false })}>
                OK
              </ButtonStyle>
            </Modal.Content>
          </Modal>
        </>
      );
    } else {
      return this.state.error ? <p>{this.state.error} </p> : <p>Loading...</p>;
    }
  }
  render() {
    return <div>{this.DisplayStatus()}</div>;
  }
}

export default Status;
