import React, { useState, useRef, useEffect } from 'react';
// eslint-disable-next-line
import { Grid, Table, Icon, Button, Form, Popup, Dropdown, Menu } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';

import swal from 'sweetalert';

import styled from 'styled-components';
import './LockedVehicle.scss';

// images import
import bike from '../../assets/img/bike.svg';
import keke from '../../assets/img/tri.svg';
import car from '../../assets/img/car.svg';
import CancelImg from '../../assets/img/cancel.svg';
import csv_icon from '../../assets/img/csv.svg';
import warning_icon from '../../assets/img/warning.svg';
import Helmet from '../../assets/img/helmet-yellow.svg';
import IconFeatherSearch from '../../assets/img/Icon feather-search.svg';

// component import
import SideBar from '../../components/SideBar/SideBar';
import TableLoader from '../../components/Loader/TableLoader';
import PulseLoader from '../../components/Loader/PulseLoader';
import Modal from '../../components/Modal/Modal';

// config file
import config from '../../config/config';
import EditVehiclePricingInfo from './EditVehiclePricingInfo';
import Alert from '../../components/Alert/Alert';
import NumberFormatter from '../../util/numberFormatter';
import ValidationUtil from '../../util/validation-util';
import { getVehicleLocations, getVehicleTypes, unChurnChampion, updateHpv } from '../../services/vehicleService';
import {
  assignPackageService,
  getLocationService,
  getPackageByIdService,
  getPackages,
  reCalcHpAmountService
} from '../../services/packageService';
import { getUserRole } from '../../axios/method';
import CustomPagination from '../../components/CustomPagination/customPagination.component';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../../axios/handleAxiosError';
import { getContracts } from '../../services/contractService';
// eslint-disable-next-line
import CustomSelect from '../../components/CustomSelect';
import NoData from '../../components/NoData';
import { useQueryClient, useMutationClient } from 'services/apiClient';
import Papa from 'papaparse';
import { HPV_ENDPOINT } from 'axios/endpoints';
import { format } from 'date-fns';
// eslint-disable-next-line
import UtilFn from 'util/util-fn';

const HeaderCell = styled(Table.HeaderCell)`
  background-color: #ffdd00 !important;
`;

const LockedVehicles = ({ history, pageUrl, title, dateOrder }) => {
  const [showSideBar, SetSideBar] = useState(false);
  const [showEditingSideBar, setEditingSideBar] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loader, setLoader] = useState(false);
  const [, setStartingCount] = useState(1);
  const [vehicleCsvData, setVehicleCsvData] = useState(null);
  // eslint-disable-next-line
  const [loadingCSVFileUpload, setLoadingCSVFileUpload] = useState();
  const [successPackageAssignmentModal, setSuccessPackageAssignmentModal] = useState(false);
  const [showPricedVehicles, toggleShowPricedVehicle] = useState(true);
  const [searchParams, setSearchParams] = useState('');
  const [searchColunm, setSearchColunm] = useState('');

  const [showEditingModal, SetEditingModal] = useState(false);
  const [vehicleData, setVehicleData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(config.pageSize);
  const [assignModal, setAssignModal] = useState(false);
  const [cityArray, setCityArray] = useState('');

  // Editing Vehicle Information
  const [maxID, setMaxID] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [hpAmount, setHpAmount] = useState('');
  const [dailyRemittance, setDailyRemittance] = useState(0);
  const [standardDuration, setStandardDuration] = useState(0);
  const [insuranceDuration, setInsuranceDuration] = useState(0);
  const [workDays, setWorkDays] = useState('');
  const [packageId, setPackageId] = useState('');
  const [reCalculatedHpAmount, setReCalculatedHpAmount] = useState('');
  const [assignPackageError, setAssignPackageError] = useState('');
  const [successEditModal, setSuccessEditModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  // pagination
  const [hpDurationError, setHpDurationError] = useState('');

  const [pricedCheck, setPricedCheck] = useState('');

  const [pricedToggle, setPricedToggle] = useState('');

  const [vehicleDetails] = useState([]);

  const [packagesLoader, setPackagesLoader] = useState(false);

  const [packagesError, setPackagesError] = useState(false);

  const [, setLocationLoader] = useState(false);
  const [, setVehicleTypeLoader] = useState(false);
  const [, setListOfLocation] = useState([]);
  const [, setListOfVehicleTypes] = useState([]);
  const [vehicleNotPricedStatus, setVehicleNotPricedStatus] = useState(false);
  const [, setCollectionLocation] = useState([]);
  const [listOfContracts, setListOfContracts] = useState();
  const [selectedContract, setSelectedContract] = useState();
  const [selectedVehicleContract, setSelectedVehicleContract] = useState();
  const [assignLoader, setAssignLoader] = useState(false);

  const [searchInput, setSearchInput] = useState('');

  const onSearchFunction = () => {
    getContractsFn();
  };

  useEffect(() => {
    reCalcHpAmount();
    // eslint-disable-next-line
  }, [dailyRemittance]);

  const handleUpdateDailyRemit = (e) => {
    setDailyRemittance(e.target.value);
  };

  useEffect(() => {
    if (!searchInput) {
      setListOfContracts([]);
    }
  }, [searchInput]);

  // eslint-disable-next-line
  const handleColumnChange = (event) => {
    if (event) return setSearchColunm(event.value);
    setSearchColunm('');
  };

  const getContractsFn = async () => {
    const params = new URLSearchParams();

    if (searchInput) {
      params.append('search', searchInput.trim());
    }

    await getContracts(params)
      .then((res) => {
        setListOfContracts(res.data.data.contracts);
      })
      .catch((err) => {
        setListOfContracts([]);
      });
  };

  const HandleAssignmentToChampion = async () => {
    const payload = {
      reason: 'Mistakenly Unchurned',
      vehicle_plate_number: selectedVehicleContract.plate_number || 'N/A',
      max_vehicle_id: selectedVehicleContract.max_vehicle_id,
      max_champion_id: selectedContract.max_champion_id
    };

    setAssignLoader(true);
    await unChurnChampion(payload)
      .then((res) => {
        setAssignModal(false);
        toast.success('Vehicle Reassigned');
      })
      .catch((err) => {
        toast.error(handleAxiosError(err).message || 'Network Error');
      })
      .finally(() => {
        setAssignLoader(false);
      });
  };

  const [getLockedVehiclesURL, setGetVehicleURL] = React.useState('');
  React.useEffect(() => {
    // setQueryForVehicles
    let url;

    if (searchParams && searchColunm) {
      url = `${config.locked_vehicles_url}?intent=searchVehicles&searchKey=${searchColunm}&searchValue=${searchParams}`;
    } else {
      url = `${config.locked_vehicles_url}?intent=${pageUrl}&skip=${currentPage - 1}&limit=${pageSize}`;
    }

    setGetVehicleURL(url);
  }, [currentPage, pageSize, searchColunm, pageUrl, searchParams]);

  // handle upload of vehicle csv

  const inputRef = useRef(null);

  const { mutate } = useMutationClient(`${HPV_ENDPOINT}/reprice-vehicle`, {
    method: 'post',
    data: {
      csvData: vehicleCsvData
    },

    onSuccess: (res) => {
      const { data: { message = '' } = {} } = res;
      toast.success(message);
      setLoadingCSVFileUpload(false);
      setVehicleCsvData('');
      inputRef.current.value = null;
    },
    onError: (error = {}) => {
      const { response: { data = {} } = {} } = error;
      toast.error(data.message);
      setLoadingCSVFileUpload(false);
      setVehicleCsvData('');
      inputRef.current.value = null;
    }
  });

  React.useEffect(() => {
    if (vehicleCsvData) {
      mutate();
    }
    // eslint-disable-next-line
  }, [vehicleCsvData]);
  // handle upload of vehicle csv

  const { data: allVehicles = {}, isLoading: loadingAllVehicles } = useQueryClient(getLockedVehiclesURL, {
    method: 'get',
    enabled: !!getLockedVehiclesURL
  });
  console.log('allVehicles', allVehicles.data?.list);
  // eslint-disable-next-line
  const { data: { list: allVehiclesData = [], totalPages } = {} } = allVehicles;

  const getListOfPackages = async (manufacturer_id, model_id, trim_id, location) => {
    setPackagesLoader(true);
    const params = new URLSearchParams();
    params.append('pages', 1);
    params.append('limit', 6);
    params.append('manufacturer_id', manufacturer_id);
    params.append('model_id', model_id);
    params.append('trim_id', trim_id);
    params.append('location_config_id', location);

    await getPackages(params)
      .then((res) => {
        setPackages(res.data.data.data);
      })
      .catch((err) => {
        toast.error(handleAxiosError(err).message || 'Network Error');
      })
      .finally(() => {
        setPackagesLoader(false);
      });
  };

  const assignPackageEndpoint = async () => {
    const data = {
      vehicle_ids: [...selectedVehicles]
    };

    await assignPackageService(selectedPackage, data)
      .then((response) => {
        if (response.status === 200) {
          setSuccessPackageAssignmentModal(true);
          setSelectedVehicles([]);
          closeSideBar();
        } else {
          window.scrollTo({ top: 0 });
          setAssignPackageError('Something went wrong');
          setTimeout(() => {
            setAssignPackageError('');
          }, 3500);
        }
      })
      .catch((err) => {
        window.scrollTo({ top: 0 });
        console.error(err);
        setAssignPackageError('Something went wrong');
        setTimeout(() => {
          setAssignPackageError('');
        }, 3500);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const reCalcHpAmount = async (value) => {
    const params = new URLSearchParams();
    params.append('daily_remit', dailyRemittance);
    params.append('payoff_months', value || standardDuration);
    params.append('work_days', workDays);

    await reCalcHpAmountService(params)
      .then((res) => {
        setReCalculatedHpAmount(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getVehicleImages = (vehicleType) => {
    switch (vehicleType.toLowerCase()) {
      case 'keke':
        return keke;
      case 'car':
        return car;
      case 'emotorcycle':
        return bike;
      case 'etricycle':
        return keke;
      case 'tricycle':
        return keke;
      case 'motorcycle':
        return bike;
      case 'bike':
        return bike;
      default:
        return vehicleType;
    }
  };

  useEffect(() => {
    if (vehicleDetails.length > 1) {
      let vehicleTypeChecker = (obj) =>
        obj?.vehicle_type?.slug === vehicleDetails[0]?.vehicle_type?.slug &&
        obj?.manufacturer?.slug === vehicleDetails[0]?.manufacturer?.slug &&
        obj?.model?.slug === vehicleDetails[0]?.model?.slug &&
        obj?.trim?.slug === vehicleDetails[0]?.trim?.slug;

      let result = vehicleDetails?.every((obj) => vehicleTypeChecker(obj));

      if (result) {
        setPackagesError(false);
      } else {
        swal(
          'Vehicle info does not match',
          'Please confirm manufacturer, type, model, trim and location matches',
          'error'
        );
        setPackagesError(true);
      }
    } else {
      setPackagesError(false);
    }
  }, [vehicleDetails]);

  const getLocations = async () => {
    setLocationLoader(true);
    await getVehicleLocations()
      .then((res) => {
        let locationArray = [];
        let allCities = [];

        res.data.data.forEach((location) => locationArray.push({ label: location.name, value: location.id }));
        setListOfLocation(locationArray);

        res.data.data.forEach((location) =>
          allCities.push({
            id: location?.id,
            city: location?.city_id
          })
        );
        setCityArray(allCities);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLocationLoader(false);
      });
  };

  const getCityForLocation = (id) => {
    const place = cityArray.filter((item) => item.id === id);
    return place[0]?.city;
  };

  // eslint-disable-next-line
  const assignPackage = () => {
    if (packagesError) {
      swal(
        'Vehicle info does not match',
        'Please confirm manufacturer, type, model, trim and location matches',
        'error'
      );
    } else {
      SetSideBar(true);
      const packageLocation = getCityForLocation(vehicleDetails[0]?.location_id);

      getListOfPackages(
        vehicleDetails[0]?.manufacturer_id,
        vehicleDetails[0]?.model_id,
        vehicleDetails[0]?.trim_id,
        packageLocation
      );
    }
  };

  const closeSideBar = () => {
    SetSideBar(false);
    setActiveIndex(0);
    setPricedCheck('');
    setPricedToggle('');
    setSelectedPackage('');
    setLoader(false);
  };

  const submitAssignedPackage = () => {
    setLoader(true);
    assignPackageEndpoint();
  };

  const closeEditingSideBar = () => {
    setEditingSideBar(false);
  };

  const closeEditingModal = () => {
    SetEditingModal(false);
    setHpDurationError('');
  };

  // eslint-disable-next-line
  const editInformation = (data) => {
    setVehicleData(data);
    if (data.hpv) {
      SetEditingModal(true);
      setMaxID(data.max_vehicle_id);
      setVehicleType(data.vehicle_type ? data.vehicle_type.name : '');
      setHpAmount(data.hpv ? data.hpv.value : 0);
      setDailyRemittance(data.hpv ? data.hpv.daily_remit : 0);
      setStandardDuration(data.hpv ? data.hpv.standard_duration : 0);
      setInsuranceDuration(data.hpv ? data.hpv.insurance_duration : 0);
      setPackageId(data.hpv ? data.hpv.pricing_template_id : 0);
    } else {
      setVehicleNotPricedStatus(true);
    }
  };

  const closeAlertBox = () => {
    setAlertMessage('');
    setAssignPackageError('');
  };

  const closeSuccessPackageModal = () => {
    setStartingCount(1);
    setSuccessPackageAssignmentModal(false);
    setCurrentPage(1);
    // getListOfVehicles(1, pageSize);
  };

  // eslint-disable-next-line
  const isVehicleAssigned = (data) => {
    if (data.champion_id) {
      return 'isAssigned';
    }
  };

  const formatPlateNumber = (plateNumber) => {
    return plateNumber.toUpperCase().replace(/\s/g, '');
  };

  const getPackageByID = async (packageId) => {
    await getPackageByIdService(packageId)
      .then((res) => {
        setWorkDays(res.data.data.work_days);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  // eslint-disable-next-line
  const showPricedVehicle = () => {
    toggleShowPricedVehicle(!showPricedVehicles);
    setStartingCount(1);
  };

  const changeStandardDuration = ({ target }) => {
    let error = '';

    error = ValidationUtil.twoDecimalPointValidation(target.value);

    setStandardDuration(target.value);

    setHpDurationError(error);

    if (error === '') {
      if (target.value) {
        reCalcHpAmount(target.value);
      } else {
        setReCalculatedHpAmount('');
      }
    } else {
      setReCalculatedHpAmount('');
    }
  };

  const closeEditSuccessModal = () => {
    setSuccessEditModal(false);
    setCurrentPage(1);
  };

  const submitEditedVehicleInfo = async () => {
    setLoader(true);

    const data = {
      standard_duration: Number(standardDuration),
      daily_remit: Number(dailyRemittance)
    };

    await updateHpv(vehicleData.id, data)
      .then((res) => {
        if (res.status === 200) {
          SetEditingModal(false);
          setReCalculatedHpAmount('');
          setSuccessEditModal(true);
        }
      })
      .catch((err) => {
        toast(err.response?.data?.message);
        console.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const selectToggleCheck = (e, data, option) => {
    const { checked } = e.target;
    if (checked) {
      setPricedCheck(option);
      setPricedToggle(option);
      setActiveIndex(option);
      setSelectedPackage(data.id);
    } else {
      setPricedCheck('');
      setPricedToggle('');
      setActiveIndex(0);
      setSelectedPackage('');
    }
  };

  const selectToggleText = (option) => {
    if (pricedToggle === option) {
      if (pricedToggle === '') {
        setPricedToggle(option);
      } else {
        setPricedToggle('');
      }
    } else {
      setPricedToggle(option);
    }
  };

  const getCollectionsLocations = async () => {
    await getLocationService()
      .then((res) => {
        let locationArray = [];
        res.data.data.forEach((location) => locationArray.push(location));
        setCollectionLocation(locationArray);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getVehicleTypesFn = async () => {
    setVehicleTypeLoader(true);
    await getVehicleTypes()
      .then((res) => {
        let typesArray = [];
        res.data.data.forEach((types) => typesArray.push({ label: types.name, value: types.id }));
        setListOfVehicleTypes(typesArray);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setVehicleTypeLoader(false);
      });
  };

  const onPageChange = (e) => {
    const { selected } = e;
    setCurrentPage(selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  const handleChampionSearch = (e) => {
    const { value } = e.target;
    setSearchInput(value);
  };

  // eslint-disable-next-line
  const columnOptions = [
    { label: 'Plate Number', value: 'plateNumber' },
    { label: 'Device IMEI', value: 'device_IMEI' },
    { label: 'Max Vehicle ID', value: 'vehicle_id' }
  ];

  useEffect(() => {
    getLocations();
    getVehicleTypesFn();
    getCollectionsLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (packageId) {
      getPackageByID(packageId);
    }
    // eslint-disable-next-line
  }, [packageId]);

  useEffect(() => {
    setSelectedContract();
    if (assignModal === false) {
      setSelectedVehicleContract();
    }
  }, [assignModal]);

  return (
    <div className="">
      {/* editing vehicle pricing info */}
      <EditVehiclePricingInfo
        handleUpdateDailyRemit={handleUpdateDailyRemit}
        showEditingModal={showEditingModal}
        hpDurationError={hpDurationError}
        vehicleData={vehicleData}
        closeEditingModal={closeEditingModal}
        maxID={maxID}
        vehicleType={vehicleType}
        hpAmount={hpAmount}
        dailyRemittance={dailyRemittance}
        standardDuration={standardDuration}
        insuranceDuration={insuranceDuration}
        workDays={workDays}
        changeStandardDuration={changeStandardDuration}
        reCalculatedHpAmount={reCalculatedHpAmount}
        submitEditedVehicleInfo={submitEditedVehicleInfo}
        loader={loader}
        successEditModal={successEditModal}
        closeEditSuccessModal={closeEditSuccessModal}
      />

      {/* error modal for package assignment  */}
      <Modal show={vehicleNotPricedStatus}>
        <div
          style={{ padding: '100px' }}
          className="modal-body text-center">
          <img
            src={warning_icon}
            alt="csv icon"
            style={{ maxWidth: '60px' }}
          />

          <div className="mb-4 mt-3">
            <h2 className="mt-4">Vehicle not priced</h2>

            <p className="mb-3">
              Please assign a price template or create a price template for the selected vehicle{' '}
              <span className="font-weight-bold">
                {vehicleData.manufacturer && vehicleData.manufacturer.name}{' '}
                {vehicleData.model && vehicleData.model.name} {vehicleData.trim && vehicleData.trim.name}
              </span>
            </p>
          </div>

          <div>
            {getUserRole() === 'super-admin' && (
              <Button
                secondary
                type="button"
                className="py-3"
                style={{ fontSize: '12px' }}
                onClick={() => history.push('/pricetemplates')}>
                Create Price Template
              </Button>
            )}

            <Button
              type="button"
              className="py-3 ml-3 px-5"
              style={{ fontSize: '12px' }}
              onClick={() => setVehicleNotPricedStatus(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>

      {/* success modal for package assignment  */}
      <Modal show={successPackageAssignmentModal}>
        <div
          style={{ padding: '100px' }}
          className="modal-body text-center">
          <div className="d-inline-flex">
            <div
              style={{
                border: '1px solid #707070',
                height: '70px',
                width: '80px',
                marginRight: '35px'
              }}></div>
            <img
              src={csv_icon}
              alt="csv icon"
              style={{ maxWidth: '50px' }}
            />
          </div>

          <div className="mb-4 mt-4">
            <h3 className="mt-4">Price Template Assigned</h3>

            <p className="mb-4">You have successfully assigned the vehicles to a price template, please proceed.</p>
          </div>

          <Button
            secondary
            type="button"
            className="rounded"
            style={{ fontSize: '12px' }}
            onClick={closeSuccessPackageModal}>
            OKAY, THANKS
          </Button>
        </div>
      </Modal>

      {/* sidebar for assigning packages*/}
      <SideBar
        show={showSideBar}
        loading={packagesLoader}>
        <img
          src={CancelImg}
          alt="Cancel icon"
          onClick={closeSideBar}
          className="cursor"
        />

        <div className="mt-3">
          <Alert
            alertMessage={assignPackageError}
            closeAlertBox={closeAlertBox}
          />
        </div>

        {!packagesLoader && (
          <>
            <div className="sideBar-header">
              <h3>Select a Price Template</h3>
              <p>Select a price template to assign to vehicles</p>
            </div>

            {packages &&
              packages.length > 0 &&
              packages.map((data, index) => (
                <div key={data.manufacturer + data.location + index}>
                  <div className="pb-3 pt-2">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="d-flex align-items-center">
                        <input
                          className="styled-checkbox"
                          name="priceTemplateToggle"
                          id={index + 1}
                          checked={pricedCheck === index + 1}
                          type="checkbox"
                          onChange={(event) => selectToggleCheck(event, data, index + 1)}
                        />
                        <label htmlFor={index + 1}></label>

                        <p
                          className="cursor pl-1 m-0"
                          onClick={() => selectToggleText(index + 1)}>{`Price Template ${index + 1}`}</p>
                      </div>

                      <p
                        className="cursor px-3 m-0"
                        style={{ fontSize: '22px' }}
                        onClick={() => selectToggleText(index + 1)}>
                        {pricedToggle === index + 1 ? '-' : '+'}
                      </p>
                    </div>

                    <div
                      className={`mt-4 toggle-content text-break ${pricedToggle === index + 1 ? 'd-block' : 'd-none'}`}>
                      <p className="font-weight-bold">Price Template Details</p>
                      <p>Vehicle Type: {data.vehicle_type ? data.vehicle_type : 'N/A'}</p>
                      <p>Manufacturer: {data.manufacturer ? data.manufacturer : 'N/A'}</p>
                      <p>Location: {data.location}</p>
                      <p>HP Amount: N{formatNumber(data.hp_amount)}</p>
                      <p>Daily Remittance: N{formatNumber(data.daily_remit)}</p>
                      <p>Duration: {data.standard_duration ? `${data.standard_duration}` : 'N/A'} Months</p>
                      <p>Payment Days: {data.work_days ? `${data.work_days}` : 'N/A'} Days</p>
                    </div>
                  </div>

                  <hr />
                </div>
              ))}

            {packages && packages.length === 0 && (
              <div className="my-5 py-5 text-center">
                <h3 className="mt-4">Nothing to see here</h3>

                <Button
                  secondary
                  type="button"
                  className="px-5 mt-3"
                  onClick={closeSideBar}>
                  Close
                </Button>
              </div>
            )}

            {packages && packages.length > 0 && (
              <div className="text-right btn-div">
                <Button
                  className="black-link-btn"
                  type="button"
                  onClick={closeSideBar}>
                  Cancel
                </Button>
                <Button
                  secondary
                  type="button"
                  disabled={activeIndex === 0}
                  onClick={submitAssignedPackage}>
                  <div className="d-inline-flex">
                    ASSIGN PRICE TEMPLATE
                    {loader && (
                      <PulseLoader
                        width="15px"
                        height="15px"
                        bgColor="#777575"
                      />
                    )}
                  </div>
                </Button>
              </div>
            )}
          </>
        )}
      </SideBar>

      {/* sidebar for editing information*/}
      <SideBar show={showEditingSideBar}>
        <img
          src={CancelImg}
          alt="Cancel icon"
          onClick={closeEditingSideBar}
          className="cursor"
        />
        <div className="sideBar-header">
          <h3>Update information</h3>
          <p>Update vehicle information</p>
        </div>

        <div className="text-right btn-div">
          <Button
            className="black-link-btn"
            type="button"
            onClick={closeEditingSideBar}>
            Cancel
          </Button>
          <Button
            secondary
            type="button">
            <div className="d-inline-flex">
              UPDATE INFORMATION
              {loader && (
                <PulseLoader
                  width="15px"
                  height="15px"
                  bgColor="#777575"
                />
              )}
            </div>
          </Button>
        </div>
      </SideBar>

      <Alert
        alertMessage={alertMessage}
        closeAlertBox={closeAlertBox}
      />

      <div
        className="sticky-header px-0"
        style={{
          position: 'sticky',
          top: '0',
          zIndex: '9999',
          background: '#f8fbff',
          width: '100%',
          padding: '1.5rem'
        }}>
        <div className="col-md-12 px-4">
          <div className="row no-gutters">
            <div className="col-md-5 my-auto">
              <div className="text-left w-100 pagination-div">
                <Link to="/vehicles">
                  <p className="text-black">{title}</p>
                </Link>
              </div>
            </div>
            <div className="flex-grow-1 d-flex">
              <div className="text-right flex-grow-1 d-flex">
                <div className="col">
                  <CustomSelect
                    options={columnOptions}
                    className="vehicleSelect"
                    name="flows"
                    placeholder="Search search key"
                    onChange={handleColumnChange}
                  />
                </div>

                <div className="col h-100">
                  <Form className="h-100">
                    <div>
                      <Form.Field>
                        <div
                          className="search h-100"
                          style={{ width: '100%' }}>
                          <input
                            disabled={!searchColunm}
                            className=" h-100"
                            placeholder={'Search for by device imei'}
                            style={{ backgroundColor: '#fff' }}
                            name="search"
                            value={searchParams}
                            onChange={(e) => {
                              const {
                                target: { value }
                              } = e;
                              setSearchParams(value);
                            }}
                          />

                          <Icon name="search" />
                        </div>
                      </Form.Field>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="position-relative">
                <input
                  accept=".csv"
                  ref={inputRef}
                  onChange={(e) => {
                    setLoadingCSVFileUpload(true);
                    Papa.parse(e.target.files[0], {
                      header: false,
                      complete: (results) => {
                        if (results?.errors.length > 0) {
                          setLoadingCSVFileUpload(false);
                          toast.error('Please check csv file and try again');

                          setVehicleCsvData('');
                          return;
                        }
                        setVehicleCsvData(Papa.unparse(results?.data));
                      }
                    });
                  }}
                  className="position-absolute upload-csv-btn"
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table loader */}
      {loadingAllVehicles && <TableLoader />}

      {!loadingAllVehicles &&
        (allVehiclesData.length === 0 ? (
          <NoData text="No vehicle was found!" />
        ) : (
          <div className="locked-vehicle-table-wrapper">
            <Table className="vehicle-table locked-vehicle-table">
              <Table.Header>
                <Table.Row>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Vehicle Type</div>
                  </HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Vehicle Manufacturer</div>
                  </HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Vehicle Model</div>
                  </HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Vehicle Trim</div>
                  </HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Vehicle ID</div>
                  </HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Plate Number</div>
                  </HeaderCell>
                  <HeaderCell>Device IMEI</HeaderCell>
                  <HeaderCell>Date Updated</HeaderCell>
                  <HeaderCell>
                    <div className="d-inline-flex align-items-center">Location</div>
                  </HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {allVehiclesData.map((data, index) => (
                  <Table.Row
                    className="cursor"
                    onClick={() => history.push(`/champion/${data?.data?.champion_uuid_id?.measurement}`)}
                    key={index}>
                    <Table.Cell>
                      <div className="d-inline-flex">
                        {data.data.vehicleType.measurement ? (
                          <div className="d-inline-flex">
                            {data.data.vehicleType.measurement}{' '}
                            {data.data.vehicleType.measurement === '-' ? null : (
                              <img
                                className="vehicle-icon"
                                alt="vehicle icon"
                                src={getVehicleImages(data.data.vehicleType.measurement.toLowerCase())}
                                style={{ maxWidth: '28px' }}
                              />
                            )}
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {data.data.manufacturer?.measurement ? data.data.manufacturer?.measurement : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {data.data['Vehicle Model']?.measurement ? data.data['Vehicle Model']?.measurement : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {data.data['Vehicle Trim']?.measurement ? data.data['Vehicle Trim']?.measurement : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {data.data['vehicle_id']?.measurement ? data.data['vehicle_id']?.measurement : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {data.vehiclePlateNumber ? formatPlateNumber(data.vehiclePlateNumber) : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>{data.device_IMEI ? data.device_IMEI : 'N/A'}</Table.Cell>
                    <Table.Cell>{format(data?.reportedTime, 'DD/MM/YYYY hh:mm a')}</Table.Cell>
                    <Table.Cell>
                      {data.data.vehicleLocation.measurement ? data.data.vehicleLocation.measurement : 'N/A'}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <CustomPagination
              onPageChange={onPageChange}
              currentPage={currentPage}
              pageCount={totalPages}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
          </div>
        ))}
      <div className="championModal">
        <Modal show={assignModal}>
          <div className="position-relative">
            <div className="assign-champion__body">
              <div className="champion-modal__header">
                <h4>Assign to Champion</h4>

                <div
                  className="cancel-div"
                  onClick={() => setAssignModal(false)}>
                  x
                </div>
              </div>

              <div className="p-4">
                <p
                  className="small select-champion__text"
                  style={{ color: '#9e9e9e' }}>
                  Search Champion
                </p>
                <div className="row">
                  <div className="col-md-8 pr-0">
                    <div className="position-relative mb-5">
                      <img
                        src={IconFeatherSearch}
                        alt="search"
                        className="icon-search-assign"
                      />
                      <input
                        className="form-control pl-5 assign-champion__search"
                        placeholder="Enter champion name or ID"
                        onChange={(e) => handleChampionSearch(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Button
                      className="w-100"
                      onClick={() => onSearchFunction()}
                      disabled={!searchInput}
                      style={{
                        background: '#FCDD18',
                        color: '#3E3E3E',
                        height: '50px'
                      }}>
                      Search
                    </Button>
                  </div>
                </div>

                <p
                  className="small select-champion__text"
                  style={{ color: '#9e9e9e' }}>
                  Select Champion
                </p>
                <div className="contract-list__container">
                  {listOfContracts &&
                    listOfContracts.map((contract) => (
                      <div
                        className="champion-list__item"
                        key={contract.id}
                        onClick={() => setSelectedContract(contract)}>
                        <div className="d-flex">
                          <img
                            src={Helmet}
                            alt="Helmet"
                            className="mr-3"
                          />
                          <div>
                            <h4 className="p-0 m-0">{contract?.name}</h4>
                            <p
                              className="small m-0 p-0"
                              style={{ color: '#E88E15' }}>
                              {contract?.max_champion_id}
                            </p>
                          </div>
                        </div>

                        <div
                          className={`champion-check ${selectedContract?.id === contract?.id && 'selected-champion'}`}>
                          <div className="inner-champion-check"></div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="assign-champion__footer">
              <Button
                secondary
                type="button"
                className="w-100"
                style={{
                  fontSize: '12px',
                  height: '50px',
                  background: '#FCDD18',
                  color: '#000'
                }}
                disabled={!selectedContract || assignLoader}
                onClick={HandleAssignmentToChampion}>
                {assignLoader ? 'LOADING...' : 'ASSIGN TO CHAMPION'}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(LockedVehicles);
