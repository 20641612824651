import React, { useRef } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

const DropdownContent = ({ onClose, children }) => {
  const ref = useRef(null);

  const escapeListener = useCallback((e) => {
    if (e.key === "Escape") {
      onClose();
    }

    // eslint-disable-next-line
  }, []);

  const clickListener = useCallback(
    (e) => {
      if (!ref.current.contains(e.target)) {
        onClose();
      }
    },
    // eslint-disable-next-line
    [ref.current]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    document.addEventListener("keyup", escapeListener);
    return () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("keyup", escapeListener);
    };
    // eslint-disable-next-line
  }, []);

  return <div ref={ref}>{children}</div>;
};

export default DropdownContent;
