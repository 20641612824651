import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import config from "../../config/config";
import { getLocationService } from "../../services/packageService";
import { getRemittance } from "../../services/remittanceService";
import RemittanceNav from "./RemittanceNav";
import RemittanceTable from "./RemittanceTable";

const Remittance = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(config.pageSize);
  const [loading, setLoading] = useState(false);
  const [remittanceData, setRemittanceData] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [location, setLocation] = useState([]);
  const [day, setDay] = useState(false);
  const [search, setSearch] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [sortByTaoFlag, setSortByTaoFlag] = useState("");
  const [sortByTdoFlag, setSortByTdoFlag] = useState("");
  const [sortByChampionNameFlag, setSortByChampionNameFlag] = useState("");
  const [sortByLocationFlag, setSortByLocationFlag] = useState("");
  const [sortByDaysOfWeekFlag, setSortByDaysOfWeekFlag] = useState("");
  const [dayToSort, setDayToSort] = useState("");
  const [pagination, setPagination] = useState({});

  let history = useHistory();

  const fetchRemittanceData = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const params = new URLSearchParams();
    params.append("page", currentPage);
    params.append("limit", pageSize);

    if (day) {
      params.append("date", day);
    }

    if (search) {
      params.append("search", search);
    }

    if (selectedLocation) {
      params.append("location", selectedLocation);
    }

    if (sortByTaoFlag) {
      params.append("order_by", `total_amount_overdue:${sortByTaoFlag}`);
    }

    if (sortByTdoFlag) {
      params.append("order_by", `total_days_overdue:${sortByTdoFlag}`);
    }

    if (sortByChampionNameFlag) {
      params.append("order_by", `full_name:${sortByChampionNameFlag}`);
    }

    if (sortByLocationFlag) {
      params.append("order_by", `location:${sortByLocationFlag}`);
    }

    if (sortByDaysOfWeekFlag) {
      params.append("order_by", `${dayToSort}:${sortByDaysOfWeekFlag}`);
    }

    await getRemittance(params)
      .then((res) => {
        setRemittanceData(res.data.data.remittances);
        setDaysOfWeek(res.data.data.remittances[0].remittance);
        setPagination(res.data.data.pagination);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reFetchRemittanceData = async (page) => {
    let pageCurrent = page + 1;

    const params = new URLSearchParams();
    params.append("page", pageCurrent);

    params.append("limit", pageSize);

    if (day) {
      params.append("date", day);
    }

    if (search) {
      params.append("search", search);
    }

    if (selectedLocation) {
      params.append("location", selectedLocation);
    }

    if (sortByTaoFlag) {
      params.append("order_by", `total_amount_overdue:${sortByTaoFlag}`);
    }

    if (sortByTdoFlag) {
      params.append("order_by", `total_days_overdue:${sortByTdoFlag}`);
    }

    if (sortByChampionNameFlag) {
      params.append("order_by", `full_name:${sortByChampionNameFlag}`);
    }

    if (sortByLocationFlag) {
      params.append("order_by", `location:${sortByLocationFlag}`);
    }

    if (sortByDaysOfWeekFlag) {
      params.append("order_by", `${dayToSort}:${sortByDaysOfWeekFlag}`);
    }

    await getRemittance(params)
      .then((res) => {
        setRemittanceData(remittanceData.concat(res.data.data.remittances));
        setDaysOfWeek(res.data.data.remittances[0].remittance);
        setPagination(res.data.data.pagination);
        setCurrentPage(pageCurrent);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchLocation = async () => {
    await getLocationService()
      .then((res) => {
        setLocation(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setSelectedLocation(value);
  };

  const handleNameSearch = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setSearch(value);
  };

  const sortByTao = (sortingFlag) => {
    setCurrentPage(1);
    setSortByTaoFlag(sortingFlag);
    setSortByTdoFlag("");
    setSortByChampionNameFlag("");
    setSortByLocationFlag("");
    setSortByDaysOfWeekFlag("");
    setDayToSort("");
  };

  const sortByTdo = (sortingFlag) => {
    setCurrentPage(1);
    setSortByTaoFlag("");
    setSortByTdoFlag(sortingFlag);
    setSortByChampionNameFlag("");
    setSortByLocationFlag("");
    setSortByDaysOfWeekFlag("");
    setDayToSort("");
  };

  const sortByChampionName = (sortingFlag) => {
    setCurrentPage(1);
    setSortByTaoFlag("");
    setSortByTdoFlag("");
    setSortByChampionNameFlag(sortingFlag);
    setSortByLocationFlag("");
    setSortByDaysOfWeekFlag("");
    setDayToSort("");
  };

  const sortByLocation = (sortingFlag) => {
    setCurrentPage(1);
    setSortByTaoFlag("");
    setSortByTdoFlag("");
    setSortByChampionNameFlag("");
    setSortByLocationFlag(sortingFlag);
    setSortByDaysOfWeekFlag("");
    setDayToSort("");
  };

  const sortByDaysOfWeek = (dayOfWeek, sortingFlag) => {
    setCurrentPage(1);
    setSortByTaoFlag("");
    setSortByTdoFlag("");
    setSortByChampionNameFlag("");
    setSortByLocationFlag("");
    setSortByDaysOfWeekFlag(sortingFlag);
    setDayToSort(dayOfWeek);
  };

  const onDayChange = (day) => {
    setCurrentPage(1);
    setDay(day);
    setShowModal(false);
    setShowCancelButton(true);
  };

  const clear = async () => {
    setCurrentPage(1);
    setDay(false);
  };

  const navigateToChampionBio = (data) => {
    history.push(`/champion/${data}`);
  };

  const handleLocationRequest = (locationsList) => {
    return locationsList.map((location) => {
      const { name, slug } = location;
      location["label"] = `${name}`;
      location["value"] = `${slug}`;
      return location;
    });
  };

  const onPageChange = (e) => {
    const { selected } = e;
    setCurrentPage(selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  useEffect(() => {
    fetchRemittanceData();
    fetchLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchRemittanceData();
    // eslint-disable-next-line
  }, [day, search, selectedLocation]);

  useEffect(() => {
    fetchRemittanceData();
    // eslint-disable-next-line
  }, [
    sortByTaoFlag,
    sortByTdoFlag,
    sortByChampionNameFlag,
    sortByLocationFlag,
    sortByDaysOfWeekFlag,
    dayToSort,
    currentPage,
    pageSize,
  ]);

  return (
    <div className="custom-container">
      <RemittanceNav
        day={day}
        clear={clear}
        setShowModal={setShowModal}
        showCancelButton={showCancelButton}
        optionState={location}
        showModal={showModal}
        onDayChange={onDayChange}
        handleLocationChange={handleLocationChange}
        handleLocationRequest={handleLocationRequest}
        handleNameSearch={handleNameSearch}
      />

      <RemittanceTable
        remittanceData={remittanceData}
        loading={loading}
        daysOfWeek={daysOfWeek}
        sortByTao={sortByTao}
        sortByTdo={sortByTdo}
        sortByChampionName={sortByChampionName}
        sortByLocation={sortByLocation}
        sortByDaysOfWeek={sortByDaysOfWeek}
        reFetchRemittanceData={() => reFetchRemittanceData(currentPage)}
        pagination={pagination}
        navigateToChampionBio={navigateToChampionBio}
        currentPage={currentPage}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default Remittance;
