import React from "react";
import ReactPaginate from "react-paginate";
import style from "./basic-table.module.scss";

export const BasicTable = ({
  colunm = [],
  children,
  hasPagination,
  pagination,
}) => {
  const setPage = (e) => {
    const { selected } = e;
    pagination.setPage(selected + 1);
  };

  return (
    <>
      <table className={style.table}>
        <thead className={style.thead}>
          <tr className={style.thead_row}>
            {colunm.map((column, index) => (
              <th key={index} className={style.thead_th}>
                {column?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={style.tbody}>{children}</tbody>
      </table>
      {hasPagination && (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          activeClassName={"active"}
          containerClassName={"custom-pagination"}
          onPageChange={setPage}
          pageCount={pagination.page}
        />
      )}
    </>
  );
};
