import React, { useState, useEffect } from 'react';
import OverviewTable from './OverviewTable';
import CardComponent from 'components/Card/card.component';
import { SearchFilterWrapper } from 'components/SearchFilter';
import { useQueryContext } from 'contexts/query-context';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'services/apiClient';
import './ContractsToBeClosed.scss';
import { Form } from 'semantic-ui-react';
import style from 'components/SearchFilter/index.module.scss';
import DatePicker from 'react-datepicker';
import { RestructuringDetails } from 'components/ContractToBeClose/restructuring-details';
import { format } from 'date-fns';

const AwaitingCheckout = ({ eventKey }) => {
  let { searchQuery, pagination } = useQueryContext();

  const [baseQuery, setBaseQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterState, setFilterState] = useState({});
  // const api_prefix = 'collection/v1/';
  let history = useHistory();

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const navigateToChampionBio = (data) => {
    history.push(`/champion/${data.champion_id}`, {
      championObj: data
    });
  };

  const onPageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  useEffect(() => {
    setFilterState({
      ...filterState,
      searchQuery,
      currentPage
    });
    // eslint-disable-next-line
  }, [searchQuery, currentPage, searchQuery]);

  useEffect(() => {
    if (searchQuery === '') {
      setBaseQuery(
        `collection/v1/contracts/contract/restructuring?status=awaiting-checkout&page=${currentPage}&limit=${pageSize}${
          startDate ? `&start_date=${format(startDate, 'YYYY-MM-DD')}` : ''
        }${endDate ? `&end_date=${format(endDate, 'YYYY-MM-DD')}` : ''}`
      );
    } else {
      setBaseQuery(
        `collection/v1/contracts/contract/restructuring?status=awaiting-checkout&page=${currentPage}&limit=${pageSize}&search=${searchQuery}`
      );
    }
  }, [pageSize, currentPage, searchQuery, startDate, endDate, eventKey]);

  // endpoints
  const fetchContractsURL = `${baseQuery}`;
  const { data: contractsData, isLoading: contractsLoading } = useQueryClient(fetchContractsURL, {
    method: 'get',
    enabled: baseQuery !== '' && eventKey === 'awaiting-checkout',
    key: 'contracts_record'
  });

  // queries response
  const _contracts_data = contractsData && contractsData?.data?.data?.contracts;

  pagination = contractsData && contractsData?.data?.data?.pagination;

  // queries response

  return (
    <>
      <div className="w-100 mb-4 mt-2">
        <CardComponent>
          <SearchFilterWrapper
            wrapperClass={style['wrapper-nil-export']}
            title="Filter Table"
            filterTitle=" "
            filterBlock={
              <Form>
                <div className="select-wrapper"></div>

                <div className="select-wrapper h-100">
                  <DatePicker
                    className=" w-100 h-100"
                    isClearable
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={true}
                    placeholderText="Select A Date"
                  />
                </div>
              </Form>
            }
          />
          <div className="px-4">
            <OverviewTable
              contractDetails={<RestructuringDetails />}
              data={_contracts_data}
              loading={contractsLoading}
              navigateToChampionBio={navigateToChampionBio}
              pagination={pagination}
              pageCount={pagination?.pageCount || 0}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              contractsToBeClose={true}
            />
          </div>
        </CardComponent>
      </div>
    </>
  );
};

export default AwaitingCheckout;
