import React from "react";
import Modal from "react-bootstrap/Modal";
import "../add-transaction.scss";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import NumberFormatter from "util/numberFormatter";

export const TransferTransactionSuccess = () => {
  const adminData = JSON.parse(localStorage.getItem("lams-data"));
  const { first_name, last_name } = adminData;
  const { transaferToChampion, setTransferTochampion, selectedTransaction } =
    useChampionPaymentHistory();
  const { successModal, newChampion } = transaferToChampion;

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const { amount } = selectedTransaction;
  const { name: beneficiary, max_champion_id } = newChampion;

  const getBeneficiary = () => {
    return (
      <div className="d-flex flex-column">
        <div className="">{beneficiary}</div>
        <div className="txt_orange">{max_champion_id}</div>
      </div>
    );
  };
  const listContent = [
    { title: "Amount Transferred", value: `NGN ${formatNumber(amount)}` },
    { title: "Reference No", value: "" },
    { title: "Beneficiary", value: getBeneficiary() },
    { title: "Initiator", value: `${first_name} ${last_name}` },
  ];

  const closeModal = () => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        successModal: !prevState.successModal,
        newChampion: {},
      };
    });
  };
  return (
    <Modal
      onHide={closeModal}
      show={successModal}
      dialogClassName={"transaction-modal success-modal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <>
        <Modal.Header>
          <h3 className="title">Transfer to champion</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="outcome-header-wrapper success">
            <div className="outcome-icon-wrapper">
              <SyncAltIcon />
            </div>
            <p>Amount Transferred</p>
          </div>

          <div className="duo-col-list">
            <div className="header">Transfer Details</div>
            <div className="body">
              {listContent.map((item, idx) => (
                <div className="duo-col-item" key={idx}>
                  <span className="label">{item?.title}</span>
                  <span className="value">{item?.value}</span>
                </div>
              ))}
            </div>
          </div>

          <button className="modal_form_btn" onClick={closeModal}>
            Continue
          </button>
        </Modal.Body>
      </>
    </Modal>
  );
};
