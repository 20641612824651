import React from "react";
import Modal from "react-bootstrap/Modal";
import "../add-transaction.scss";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import format from "date-fns/format";
import NumberFormatter from "util/numberFormatter";
import { useMutationClient } from "services/apiClient";
import { ReactComponent as HelmetIcon } from "assets/img/helmet-yellow.svg";
import Spinner from "components/Spinner/Spinner";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

export const VerifyTransfer = () => {
  const queryClient = useQueryClient();
  const {
    transaferToChampion,
    setTransferTochampion,
    selectedTransaction,
    // championInfo,
  } = useChampionPaymentHistory();
  const { showConfirmTransfer, newChampion } = transaferToChampion;
  const { amount, id } = selectedTransaction;
  const {
    name,
    max_champion_id,
    last_amount_remitted,
    last_day_remitted,
    location_name,
    champion_id: recipient_champion_id,
  } = newChampion;
  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const transactionInfo = [
    {
      label: "Last Amount Remitted",
      value: `NGN ${formatNumber(last_amount_remitted)}`,
    },
    { label: "Time Remitted", value: format(last_day_remitted, "DD/MM/YYYY ") },
    { label: "City", value: location_name },
  ];

  const closeModal = () => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        showConfirmTransfer: !prevState.showConfirmTransfer,
      };
    });
  };

  const changeTransaction = () => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        showChampionsModal: !prevState.showChampionsModal,
      };
    });
    closeModal();
  };

  const transferURL = `collection/v1/collections/transfer-payment/${id}`;
  const { mutate, isLoading } = useMutationClient(transferURL, {
    method: "post",
    data: {
      recipient_champion_id,
    },

    onSuccess: (data = {}) => {
      setTransferTochampion((prevState) => {
        return {
          ...prevState,
          successModal: !prevState.successModal,
        };
      });
      queryClient.invalidateQueries(["collection_record"]);
      closeModal();
    },

    onError: (error = {}) => {
      closeModal();
      toast(error.response.data.message);
    },
  });

  return (
    <Modal
      onHide={closeModal}
      show={showConfirmTransfer}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <div className="floating_close_btn">
              <button>
                <HighlightOffIcon />
              </button>
            </div>
            <div className="select_champion_wrapper champion_detail">
              <div className="detail">
                <div className="icon_wrapper">
                  <HelmetIcon />
                </div>
                <div className="details_wrapper">
                  <span className="name">{name}</span>
                  <span className="max_champion_id">{max_champion_id}</span>
                </div>
              </div>
            </div>
            <div className="new-transfer-preview">
              <div className="list-item">
                <span className="label">Amount to transfer</span>
                <span className="value">{formatNumber(amount)} NGN</span>
              </div>
              <button className="btn_txt" onClick={changeTransaction}>
                CHANGE TRANSACTION
              </button>
            </div>
            <div className="transaction-modal-content">
              <div className=""></div>
              <div className="list-wrapper">
                {transactionInfo.map((item, index) => (
                  <div key={index} className="list-item">
                    <span className="title">{item.label}</span>
                    <span className="detail">{item.value}</span>
                  </div>
                ))}
              </div>

              <button onClick={mutate} className="modal_form_btn">
                TRANSFER PAYMENT
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
