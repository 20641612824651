import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import format from 'date-fns/format';
// import PulseLoader from '../../components/Loader/PulseLoader';
import Spinner from '../../components/Spinner/Spinner';
import './ChampionContract.scss';
import ActiveContract from '../../components/ActiveContract/ActiveContract';
import LeftArrow from '../../assets/img/left-arrow.svg';
import { SingleInactiveContractDetail, InactiveContract } from '../../components/InactiveContract/InactiveContract';
import AmortizationSchedule from '../../components/AmortizationSchedule/amortization-schedule.component';
import { handleAxiosError } from '../../axios/handleAxiosError';
import { getContractSummaryPDF } from '../../services/collectionService';
import PauseHpModal, { ConfirmPauseModal } from './PauseHpModal';
import SuccessModal from '../../components/Modal/SuccessModal';
import { unPauseContract, completeContract } from '../../services/HpService';
import swal from 'sweetalert';
import ValidationUtil from '../../util/validation-util';
import ConfirmationAlert from '../../components/Confirmation/ConfirmationAlert';
import { getUserRole } from '../../axios/method';
import { CONTRACT_ENDPOINT, TRANCHE_INFO, AMORTIZATION_SCHEDULE_ENDPOINT } from '../../axios/endpoints';
import PauseHpReasonsModal from './PauseHpReasonsModal';
import { useQueryClient } from '../../services/apiClient';
import { ContractsLog } from 'components/Champion/ui-champion/contracts-log';
import RestructureContract from 'components/Contracts/contracts-restucturing-modal';
import useCollectionOfficersAssignment from 'components/Collection/utility-hook';
import { ListOfAllCollectionOfficers } from 'components/Collection/collection-officers-modal';
import { RestructuredLog } from 'components/Champion/ui-champion/restructure-contracts-log';
import ContractReview from 'components/Contracts/contract-review-modal';
import { useContractProvider } from 'components/Contracts/contracts';
import RestructuredCard from 'components/Contracts/restructured-card-info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// eslint-disable-next-line
const ChampionContract = ({ championObj, championId, refreshChampionBioInfo, status, vehicleId, contractStatusId }) => {
  const [active, setActive] = useState({
    name: 'ACTIVE CONTRACT',
    status: true
  });

  const { assignedAgentData } = useCollectionOfficersAssignment({ id: championId });

  const getActiveButton = (name) => {
    if (active.name !== name) {
      setActive({ name, status: true });
      setInactiveDetailStatus(false);
      return;
    } else if (active.name === name) {
      setActive({ name, status: true });
      return;
    }
    active.status === false ? setActive({ name, status: true }) : setActive({ name, status: false });
  };

  const todaysDate = format(new Date(), 'YYYY-MM-DD');
  const [showCollectionAgentsList, setShowCollectionAgentsList] = useState(false);
  // eslint-disable-next-line
  const { showRestructureModal, toggleRestructureModal } = useContractProvider();

  const [inactiveDetailStatus, setInactiveDetailStatus] = useState(false);
  const [inactiveContractIndex, setInactiveContractIndex] = useState(0);
  const [inactiveContractStatus, setInactiveContractStatus] = useState('');

  const changeToInactiveDetailView = (index, status) => {
    if (inactiveDetailStatus === false) {
      setInactiveDetailStatus(true);
      setInactiveContractIndex(index);
      setInactiveContractStatus(status);
    } else {
      setInactiveDetailStatus(false);
      setInactiveContractIndex(0);
      setInactiveContractStatus('');
    }
  };

  const goBackToInactiveList = () => {
    setInactiveDetailStatus(false);
    setActive({ name: 'INACTIVE CONTRACT', status: true });
  };

  const navTextArray = [
    { title: 'ACTIVE CONTRACT', key: 'active-contract' },
    { title: 'INACTIVE CONTRACT', key: 'inactive-contract' },
    { title: 'CONTRACT LOGS', key: 'contract-log' }
  ];

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [pdfFile, setPdfFile] = useState(undefined);

  // const fetchContractPDF = async (id) => {
  //   try {
  //     setLoadingPDF(true);
  //     const pdfFile = await getContractPDF(id);
  //     setPdfFile(pdfFile?.data?.data?.url);
  //   } catch (error) {
  //     toast.error(handleAxiosError(error)?.message || 'Could not fetch Signed Contract PDF of this champion');
  //   } finally {
  //     setLoadingPDF(false);
  //   }
  // };

  const fetchContractSummaryPDF = async (id) => {
    try {
      setLoadingPDF(true);
      const pdfFile = await getContractSummaryPDF(id);
      setPdfFile(pdfFile?.data?.data?.url);
    } catch (error) {
      toast.error(handleAxiosError(error)?.message || 'Could not fetch Summary Contract PDF of this champion');
    } finally {
      setLoadingPDF(false);
    }
  };

  const [showAmortSchedule, setShowAmortSchedule] = useState(false);
  const [showConfirmPauseModal, setShowConfirmPauseModal] = useState(false);
  const [showHpPauseModal, setShowHpPauseModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [successPausedModal, setSuccessPausedHpModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState({});
  const [duration, setDuration] = useState('');
  const [, setStartDate] = useState(new Date());
  const [, setDurationMatchingError] = useState(false);
  const [pauseReason, setPauseReason] = useState('');
  const [, setOtherReasons] = useState('');
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [pauseHPAlert, setPauseHPAlert] = useState(false);
  const [pauseHpModal, setPauseHpModal] = useState(false);

  useEffect(() => {
    if (duration && pauseReason) {
      if (duration > pauseReason.max_duration) {
        setDurationMatchingError(true);
        swal(
          '',
          `The selected duration is more than the allocated duration for this reason. The duration shouldn't  be more than ${pauseReason.max_duration} days`,
          'error'
        );
      } else {
        setDurationMatchingError(false);
      }
    }
    // eslint-disable-next-line
  }, [pauseReason, duration]);

  const submitCompleted = async () => {
    try {
      let response = await completeContract(championId);
      if (response.status === 200) {
        setPauseHPAlert(false);
        toast.success('HP Completed');
      }
    } catch (error) {
      toast.error(handleAxiosError(error).message || 'Network Error');
    }
  };

  // const submitPause = async () => {
  //   setBtnLoader(true);

  //   const data = {
  //     pause_reason_id: pauseReason.id,
  //     duration: Number(duration),
  //     start_date: startDate,
  //     contract_id: championObj?.id
  //   };

  //   if (otherReasons !== '') {
  //     data.comment = otherReasons;
  //   }

  //   try {
  //     await pauseContract(championId, data).then((res) => {
  //       if (res.status === 200) {
  //         setSuccessModalMessage({
  //           title: 'HP Paused',
  //           subTitle: `You have successfully paused the champion's HP`
  //         });
  //         setShowHpPauseModal(false);
  //         setSuccessPausedHpModal(true);
  //       }
  //     });
  //   } catch (error) {
  //     toast.error(handleAxiosError(error).message || 'Something went wrong');
  //     console.error(error);
  //   } finally {
  //     setBtnLoader(false);
  //   }
  // };

  const closeHpPauseModal = () => {
    setShowHpPauseModal(false);
    setPauseReason('');
    setDuration('');
    setStartDate(new Date());
    setOtherReasons('');
  };

  const closeSuccessPausedHpModal = () => {
    setSuccessPausedHpModal(false);
    refreshChampionBioInfo();
  };

  const unPauseHP = async () => {
    setBtnLoader(true);

    try {
      await unPauseContract(championId, { last_update_time: todaysDate }).then((res) => {
        if (res.status === 200) {
          setSuccessModalMessage({
            title: 'HP UnPaused',
            subTitle: `You have successfully unpaused the champion's HP`
          });
          setSuccessPausedHpModal(true);
        }
        setBtnLoader(false);
      });
    } catch (error) {
      toast.error(handleAxiosError(error).message || 'Something went wrong');
    } finally {
      setBtnLoader(false);
      setShowConfirmPauseModal(false);
    }
  };

  const triggerPauseAlertModal = () => {
    setShowConfirmationAlert(false);
    if (status === 'Active') {
      setShowHpPauseModal(true);
    } else {
      unPauseHP();
    }
  };

  const disableHpCompleteBtn = (status) => {
    status = status?.toLowerCase();
    if (status === 'paused') {
      return true;
    }
  };

  // Contract info ID
  let contractInfoId;
  let contractRepaymentScheduleId;
  let contractStatus;
  let _pauseHpReasons = [];
  // Fetch champions contract
  const fetchSingleContractURL = `${CONTRACT_ENDPOINT}/champions/${championId}`;
  const { data: singleContractData, isLoading: contractIsLoading } = useQueryClient(fetchSingleContractURL, {
    key: 'contract_information',
    retry: 3,
    method: 'get',
    enabled: championId !== ''
  });
  const _singleContractData = singleContractData && singleContractData?.data?.data;
  contractInfoId = _singleContractData && _singleContractData?.id;
  contractRepaymentScheduleId = _singleContractData && _singleContractData?.overdue_repayment_schedule_id;
  contractStatus = _singleContractData && _singleContractData?.status;

  // Fetch all champion contract information (active and inactive contracts)
  const fetchContractInfoURL = `${CONTRACT_ENDPOINT}/champion/${championId}`;
  const {
    data: allChampionContractInfo
    // isLoading: allChampionContractIsLoading,
  } = useQueryClient(fetchContractInfoURL, {
    key: 'contract_info',
    retry: 3,
    method: 'get',
    enabled: championId !== ''
  });
  const _allChampionContractInfo = allChampionContractInfo && allChampionContractInfo?.data?.data;

  // filter active contact information
  const activeContractInfo =
    _allChampionContractInfo && _allChampionContractInfo.filter((contract) => contract?.status?.slug === 'active');

  // filter inactive contract information
  const inactiveContractInfo =
    _allChampionContractInfo && _allChampionContractInfo.filter((contract) => contract?.status?.slug !== 'active');

  const hpCompleteBtnCheck = () => {
    if (activeContractInfo) {
      if (Number(activeContractInfo[0]?.total_amount_remitted) < Number(activeContractInfo[0]?.hp_value)) {
        return true;
      }
    }
    return false;
  };

  // Fetch PauseHP Reasons
  const fetchPauseHpReasonsURL = `${CONTRACT_ENDPOINT}/pause/champions?contract_id=${contractInfoId}`;
  const { data: pauseHpReasons } = useQueryClient(fetchPauseHpReasonsURL, {
    key: 'pause_history',
    method: 'get',
    retry: 3,
    enabled: !!contractInfoId
  });
  _pauseHpReasons = pauseHpReasons && pauseHpReasons?.data?.data;

  // Fetch Tranche Information
  const fetchTrancheInfoURL = `${TRANCHE_INFO}/${contractInfoId}`;
  const { data: trancheInfo, isLoading: loadingTrancheInfo } = useQueryClient(fetchTrancheInfoURL, {
    key: 'tranche_info',
    retry: 3,
    method: 'get',
    enabled: !!contractInfoId
  });
  const _trancheInfo = trancheInfo && trancheInfo?.data?.data;

  // Fetch Amortization Schedule
  const fetchAmortizationScheduleURL = `${AMORTIZATION_SCHEDULE_ENDPOINT}/champions/${contractInfoId}`;
  const { data: amortizationScheduleInfo } = useQueryClient(fetchAmortizationScheduleURL, {
    method: 'get',
    retry: 3,
    enabled: !!contractInfoId
  });
  const _amortizationScheduleInfo = amortizationScheduleInfo && amortizationScheduleInfo?.data?.data;

  // fetch most recent restructure request
  const { data } = useQueryClient(`${CONTRACT_ENDPOINT}/overdue-repayment/most-recent/${contractInfoId}`, {
    key: 'overdue_repayment_most_recent',
    method: 'get',
    retry: 3,
    enabled: !!contractInfoId
  });
  const currentRestructuredData = data && data?.data?.data;

  return (
    <>
      <ConfirmPauseModal
        isLoading={btnLoader}
        show={showConfirmPauseModal}
        onHide={() => setShowConfirmPauseModal(!showConfirmPauseModal)}
        pauseContract={unPauseHP}
      />
      <PauseHpModal
        championId={championId}
        showHpPauseModal={showHpPauseModal}
        pauseReason={pauseReason}
        onHide={() => closeHpPauseModal()}
      />

      <PauseHpReasonsModal
        pauseReasons={_pauseHpReasons}
        showModal={pauseHpModal}
        setPauseHpModal={setPauseHpModal}
      />

      <ConfirmationAlert show={pauseHPAlert}>
        <h2 style={{ color: '#000000a6' }}>Are you sure?</h2>
        <p>Are you sure you want to compelete the selected HP</p>

        <div className="pt-4">
          <Button
            className="black-link-btn"
            type="button"
            onClick={() => setPauseHPAlert(false)}>
            Cancel
          </Button>
          <Button
            secondary
            type="button"
            className="px-5"
            onClick={submitCompleted}>
            Yes
          </Button>
        </div>
      </ConfirmationAlert>
      <ConfirmationAlert show={showConfirmationAlert}>
        <h2 style={{ color: '#000000a6' }}>Are you sure?</h2>

        <p>Are you sure you want to {status === 'Paused' ? 'Unpause' : 'Pause'} the selected contracts</p>

        <div className="pt-4">
          <Button
            className="black-link-btn"
            type="button"
            onClick={() => setShowConfirmationAlert(false)}>
            Cancel
          </Button>
          <Button
            onClick={triggerPauseAlertModal}
            secondary
            type="button"
            className="px-5">
            Yes
          </Button>
        </div>
      </ConfirmationAlert>

      <SuccessModal
        showSuccessModal={successPausedModal}
        title={successModalMessage.title}
        subTitle={successModalMessage.subTitle}
        submitSuccessModal={closeSuccessPausedHpModal}
        btnText="OKAY, THANKS"
      />
      <AmortizationSchedule
        show={showAmortSchedule}
        toAmortizationProfile={() => setShowAmortSchedule(false)}
        setAmortizationSchedule={setShowAmortSchedule}
        championsAmortizationSchedule={_amortizationScheduleInfo}
        championContractId={contractInfoId}
      />
      <div className="contract-container">
        <div className="contract-tab">
          <div className="contract-tab-inner">
            <div className="contract-tab-inner_status-wrapper">
              {navTextArray.map((navText, index) => (
                <div
                  className={`contract-button ${navText.key} ${
                    active.status && active.name === navText.title ? 'active' : ''
                  }`}
                  onClick={() => getActiveButton(navText.title)}
                  key={index}>
                  <p className="p-style">{navText.title}</p>
                </div>
              ))}
            </div>
            <div className="contract-tab-inner_contracts-wrapper">
              <div className="contract-tab-inner__button-section">
                {!pdfFile && (
                  <Button
                    className="contract-tab-inner__button-section__button"
                    loading={loadingPDF}
                    onClick={() => fetchContractSummaryPDF(vehicleId)}>
                    Contract Summary PDF
                  </Button>
                )}
                {pdfFile && (
                  <Button
                    className="contract-tab-inner__button-section__button-view"
                    target="_blank"
                    href={pdfFile}>
                    View Contract PDF
                  </Button>
                )}
              </div>
            </div>
          </div>

          {active.name === 'INACTIVE CONTRACT' && active.status === true && inactiveDetailStatus === true && (
            <div className="single-inactive-contract-bar">
              <div className="left-arrow-container">
                <img
                  className="left-arrow-icon"
                  src={LeftArrow}
                  alt="left arrow"
                  onClick={() => goBackToInactiveList()}
                />
              </div>
              <div className="right-inactive-container">
                <div className="inactive-contract-bar-header-container">
                  <p className="inactive-contract-title">
                    Contract {inactiveContractIndex < 10 ? '0' + inactiveContractIndex : inactiveContractIndex}
                  </p>
                </div>
                <div className="single-inactive-status-container">
                  <p className="inactive-contract-status">
                    <span className={`${inactiveContractStatus}`}>{inactiveContractStatus}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <>
          {contractIsLoading && (
            <div className="no-contract-container">
              <Spinner />
              <h4>Loading Contract Data...</h4>
            </div>
          )}
          {active.name === 'ACTIVE CONTRACT' &&
            active.status === true &&
            inactiveDetailStatus === false &&
            activeContractInfo &&
            activeContractInfo.length > 0 && (
              <ActiveContract
                contractIsLoading={contractIsLoading}
                contractInfo={_singleContractData}
                trancheInfo={_trancheInfo}
                loadingTrancheInfo={loadingTrancheInfo}
                setShowAmortSchedule={setShowAmortSchedule}
              />
            )}

          {contractRepaymentScheduleId === null && contractStatus.slug === 'paused' && (
            <div className="contract-info-container">
              <div className="action-button-container d-flex justify-content-between align-items-center px-4 py-2 ">
                <div className="d-flex flex-column pr-5">
                  <span className="font-weight-bold mb-1">Current Contract Action</span>
                </div>
                <div>
                  <Button
                    className="restructure-contract-btn"
                    onClick={() => toggleRestructureModal(!showRestructureModal)}>
                    Restructure Contract <ArrowForwardIcon />{' '}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {active.name === 'ACTIVE CONTRACT' &&
          active.status === true &&
          inactiveDetailStatus === false &&
          activeContractInfo &&
          activeContractInfo.length === 0 &&
          contractStatus?.slug === 'paused' &&
          contractRepaymentScheduleId !== null ? (
            <div className="no-contract-container">
              <RestructuredCard currentRestructuredData={currentRestructuredData} />
            </div>
          ) : active.name === 'ACTIVE CONTRACT' &&
            active.status === true &&
            inactiveDetailStatus === false &&
            activeContractInfo &&
            activeContractInfo.length === 0 &&
            contractStatus?.slug === 'paused' &&
            contractRepaymentScheduleId === null ? (
            <div className="no-contract-container">
              <p className="no-contract-text">No active contract found!</p>
            </div>
          ) : null}

          {active.name === 'INACTIVE CONTRACT' &&
            active.status === true &&
            inactiveDetailStatus === false &&
            inactiveContractInfo &&
            inactiveContractInfo.length === 0 && (
              <div className="no-contract-container">
                <p className="no-contract-text">No inactive contract found!</p>
              </div>
            )}

          {active.name === 'INACTIVE CONTRACT' &&
            active.status === true &&
            inactiveDetailStatus === false &&
            inactiveContractInfo &&
            inactiveContractInfo.length > 0 && (
              <InactiveContract
                onclickFunction={changeToInactiveDetailView}
                inactiveContractArray={inactiveContractInfo}
              />
            )}

          {active.name === 'INACTIVE CONTRACT' && active.status === true && inactiveDetailStatus === true && (
            <SingleInactiveContractDetail
              contractInfo={inactiveContractInfo[inactiveContractIndex - 1]}
              trancheInfo={_trancheInfo}
              loadingTrancheInfo={loadingTrancheInfo}
              disable={true}
            />
          )}

          {active.name === 'CONTRACT LOGS' && (
            <div className="contract-log-wrapper">
              <div className=" mb-4">
                <ContractsLog contractId={contractInfoId} />
              </div>

              <RestructuredLog contractId={contractInfoId} />
            </div>
          )}

          {status?.toLowerCase() !== 'completed' && (
            <div className="contract-info-container contract-actions">
              <div className="d-flex action-button-container">
                {/* {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
                  <>
                    {_singleContractData?.status?.slug === 'paused' ? (
                      <button
                        disabled={btnLoader}
                        className="contract-action-button resume-contract"
                        onClick={() => setShowConfirmPauseModal(true)}>
                        RESUME CONTRACT
                      </button>
                    ) : (
                      <button
                        className="contract-action-button pause-contract "
                        onClick={() => setShowHpPauseModal(true)}>
                        Pause Contract
                      </button>
                    )}
                  </>
                )} */}
                <button
                  className="contract-action-button see-pause-history mx-3"
                  onClick={() => setPauseHpModal(true)}>
                  See pause history
                </button>

                {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
                  <button
                    className="contract-action-button complete-contract"
                    disabled={disableHpCompleteBtn(status)}
                    onClick={() => !hpCompleteBtnCheck() && setPauseHPAlert(true)}>
                    complete contract
                  </button>
                )}
              </div>
            </div>
          )}

          {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
            <div className="contract-info-container collection-officer">
              <div className="action-button-container d-flex align-items-center px-4 py-2 ">
                <div className="d-flex flex-column pr-5">
                  <span className="font-weight-bold mb-1">Collection Officer</span>
                  <span className="text-secondary">
                    {ValidationUtil.isObjectEmpty(assignedAgentData) ? 'Unassigned' : assignedAgentData.full_name}
                  </span>
                </div>
                <div>
                  <Button
                    className={
                      ValidationUtil.isObjectEmpty(assignedAgentData) ? 'assign-champion' : 'reassign-champion'
                    }
                    onClick={() => setShowCollectionAgentsList(!showCollectionAgentsList)}>
                    {ValidationUtil.isObjectEmpty(assignedAgentData) ? 'Assign' : 'Reassign '}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <RestructureContract contractId={contractInfoId} />
      <ContractReview contractId={contractInfoId} />

      <ListOfAllCollectionOfficers
        championId={championId}
        assignedAgentData={assignedAgentData}
        show={showCollectionAgentsList}
        onHide={() => setShowCollectionAgentsList(false)}
      />
    </>
  );
};

export default ChampionContract;
