import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Icon } from 'semantic-ui-react';
import CustomSelect from '../../../components/CustomSelect';

import {
  getManufacturersService,
  getModelByManufacturerService,
  getTrimByModelService,
  getVehicleTypesByClass,
  getVehicleClassService
} from '../../../services/vehicleService';
import BikeIcon from '../../../assets/img/bike-icon.svg';
import KekeIcon from '../../../assets/img/keke-icon.svg';
import CarIcon from '../../../assets/img/car-icon.svg';

import './vehicle-info.styles.scss';
import { handleAxiosError } from '../../../axios/handleAxiosError';
import { getLocationService } from '../../../services/packageService';
import storageService from '../../../services/storageService';
import { AMORTIZATION_OBJ, IS_EDIT_FLAG } from '../../../util/constant';

const VehicleInfo = ({ setActiveStep, history, setAmortizationObj, amortizationObj }) => {
  const storedObj = JSON.parse(storageService.get(AMORTIZATION_OBJ));
  const [activeVehicleType, setActiveVehicleType] = useState(Number(storedObj?.asset_class) || '');
  const [listOfLocation, setListOfLocation] = useState([]);
  const [listOfManufacturers, setListOfManufacturers] = useState([]);
  const [grade] = useState([
    { value: '', label: 'Brand New' },
    { value: 'A+', label: 'Grade A+' },
    { value: 'A', label: 'Grade A' },
    { value: 'B', label: 'Grade B' },
    { value: 'C', label: 'Grade C' },
    { value: 'D', label: 'Grade D' },
    { value: 'E', label: 'Grade E' },
    { value: 'F', label: 'Grade F' },
    { value: 'new_vehicle_refurbished', label: 'New Vehicle Refurbished' }
  ]);
  const [listOfModels, setListOfModels] = useState([]);
  const [listOfVehicleTypes, setListOfVehicleTypes] = useState([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState(storedObj ? 1 : '');
  const [listOfTrims, setListOfTrims] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(storedObj?.location_id || '');
  const [selectedManufacturer, setSelectedManufacturer] = useState(storedObj?.manufacturer_id || '');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedModel, setSelectedModel] = useState(storedObj?.model_id || '');
  const [selectedTrim, setSelectedTrim] = useState(storedObj?.trim_id || '');

  const [vehicleClass, setVehicleClass] = useState([]);

  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [loadingTrim, setLoadingTrim] = useState(false);
  const [loadingVehicleType, setLoadingVehicleType] = useState(false);

  const getLocations = async () => {
    setLoadingLocation(true);

    await getLocationService()
      .then((res) => {
        let locationArray = [];
        res.data.data.forEach((location) =>
          locationArray.push({
            name: location.name,
            value: location.id,
            label: location.name
          })
        );
        setListOfLocation(locationArray.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingLocation(false);
      });
  };

  const getManufacturers = async () => {
    setLoadingManufacturer(true);

    await getManufacturersService()
      .then((res) => {
        let manufacturerArray = [];
        res.data.data.forEach((manufacturer) =>
          manufacturerArray.push({
            name: manufacturer.name,
            value: manufacturer.id,
            label: manufacturer.name
          })
        );
        setListOfManufacturers(manufacturerArray.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.error(`Failed to get manufacturers`, err);
      })
      .finally(() => {
        setLoadingManufacturer(false);
      });
  };

  const getModel = async (id) => {
    setLoadingModel(true);

    await getModelByManufacturerService(id)
      .then((res) => {
        let modelArray = [];
        res.data.data.forEach((model) =>
          modelArray.push({
            name: model.name,
            value: model.id,
            label: model.name
          })
        );
        setListOfModels(modelArray.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.error(`Failed to get models`, err);
      })
      .finally(() => {
        setLoadingModel(false);
      });
  };

  const getTrims = async (id) => {
    setLoadingTrim(true);

    await getTrimByModelService(id)
      .then((res) => {
        let trimArray = [];
        res.data.data.forEach((trim) =>
          trimArray.push({
            name: trim.name,
            value: trim.id,
            label: trim.name
          })
        );
        setListOfTrims(trimArray.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.error(handleAxiosError(err).message);
      })
      .finally(() => {
        setLoadingTrim(false);
      });
  };

  const getVehicleClass = async () => {
    try {
      let response = await getVehicleClassService();
      setVehicleClass(response.data.data);
    } catch (error) {
      console.error(handleAxiosError(error)?.message);
    }
  };

  const getVehicleTypes = async (id) => {
    const params = new URLSearchParams();
    params.append('asset_class_id', id);
    try {
      setLoadingVehicleType(true);

      const response = await getVehicleTypesByClass(params);
      let vehicleTypeArray = [];
      response.data.data.forEach((type) =>
        vehicleTypeArray.push({
          name: type.name,
          value: type.id,
          label: type.name
        })
      );
      setListOfVehicleTypes(vehicleTypeArray.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.error(handleAxiosError(error).message);
    } finally {
      setLoadingVehicleType(false);
    }
  };

  const next = () => {
    const obj = {
      location_id: selectedLocation,
      manufacturer_id: selectedManufacturer,
      model_id: selectedModel,
      trim_id: selectedTrim,
      asset_class: activeVehicleType,
      grade: selectedGrade === '' || selectedGrade == null ? 'new_vehicle' : selectedGrade
    };

    let ptObj;
    if (storageService.get(IS_EDIT_FLAG)) {
      ptObj = storedObj;
    } else {
      ptObj = amortizationObj;
    }

    setAmortizationObj({ ...ptObj, ...obj });
    setActiveStep('amortization');
  };

  const returnClassImage = (vehicle_class) => {
    switch (vehicle_class) {
      case '2_wheeler':
        return BikeIcon;
      case '3_wheeler':
        return KekeIcon;
      case '4_wheeler':
        return CarIcon;
      default:
        return BikeIcon;
    }
  };

  const clearFilter = (dropdownAffected) => {
    if (dropdownAffected === 'location') {
      setActiveVehicleType('');
      setSelectedVehicleTypes('');
      setSelectedManufacturer('');
      setSelectedModel('');
      setSelectedTrim('');
    } else if (dropdownAffected === 'asset class') {
      setActiveVehicleType('');
      setSelectedVehicleTypes('');
      setSelectedManufacturer('');
      setSelectedModel('');
      setSelectedTrim('');
    } else if (dropdownAffected === 'vehicle type') {
      setSelectedManufacturer('');
      setSelectedModel('');
      setSelectedTrim('');
    } else if (dropdownAffected === 'manufacturer') {
      setSelectedModel('');
      setSelectedTrim('');
    } else if (dropdownAffected === 'model') {
      setSelectedTrim('');
    }
  };

  useEffect(() => {
    if (selectedVehicleTypes) {
      getManufacturers();
    }
    if (activeVehicleType) {
      getVehicleTypes(activeVehicleType);
    }
  }, [activeVehicleType, selectedVehicleTypes]);

  useEffect(() => {
    if (selectedManufacturer) {
      setSelectedModel();
      getModel(selectedManufacturer);
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    if (selectedModel) {
      getTrims(selectedModel);
    }
  }, [selectedModel]);

  useEffect(() => {
    getLocations();
    getVehicleClass();
  }, []);

  return (
    <div className="vehicle-info">
      <div className="row">
        <div className="col-md-7">
          <CustomSelect
            className="pt-location"
            placeholder="- Select a Location -"
            isLoading={loadingLocation}
            options={listOfLocation}
            name="pt-location"
            onChange={(e) => {
              clearFilter('location');
              setSelectedLocation(e?.value);
            }}
          />

          <div className="my-4"></div>

          <div className="row">
            <div className="col-md-6">
              <p
                className="mb-2 mt-4"
                style={{ color: '#9F9F9F' }}>
                Select Asset Class
              </p>
            </div>

            <div className="col-md-6 text-right">
              {!selectedLocation && <p className="empty-field__blinker mb-2 mt-4">previous field needs to be filled</p>}
            </div>
          </div>

          <div className="row mb-4">
            {vehicleClass?.map((res) => (
              <div
                key={res.slug}
                className="col-md-4">
                <div
                  className={`vehicle-type__div ${activeVehicleType === res.id ? 'active-vehicle-type-div' : ''}`}
                  onClick={() => {
                    clearFilter('asset class');
                    selectedLocation && setActiveVehicleType(res.id);
                  }}>
                  {activeVehicleType === res.id && (
                    <div className="col-md-12">
                      <div className="checked-vehicle__div">
                        <Icon
                          className="icon"
                          name="check"
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="rounded-circle"
                    style={{
                      width: '34px',
                      height: '34px'
                    }}>
                    <img
                      src={returnClassImage(res?.id)}
                      style={{ maxWidth: '35px' }}
                      alt="Vehicle Class"
                    />
                  </div>

                  <p
                    className="mt-3 mb-1 font-weight-bold"
                    style={{ color: '#3E3E3E', fontSize: '13.5px' }}>
                    {res?.name}
                  </p>
                  <p className="small text-secondary mt-0 pt-0">({res.name})</p>
                </div>
              </div>
            ))}
          </div>

          <div className="my-4"></div>

          <div className="row">
            <div className="col-md-12">
              <CustomSelect
                className="pt-vehicle-type"
                isDisabled={!activeVehicleType}
                isLoading={loadingVehicleType}
                placeholder="- Select Vehicle Type -"
                options={listOfVehicleTypes}
                name="pt-vehicle-type"
                onChange={(e) => {
                  clearFilter('vehicle type');
                  setSelectedVehicleTypes(e?.value);
                }}
                value={listOfVehicleTypes?.filter((vtype) => vtype.value === selectedVehicleTypes)}
              />
              {!activeVehicleType && (
                <p className="empty-field__blinker mb-2 text-right mt-2">previous field needs to be filled</p>
              )}
            </div>
          </div>

          <div className="my-4"></div>
          <CustomSelect
            className="pt-manufacturer"
            placeholder="- Select Manufacturer -"
            isDisabled={!selectedVehicleTypes}
            isLoading={loadingManufacturer}
            options={listOfManufacturers}
            name="pt-manufacturer"
            onChange={(e) => {
              clearFilter('manufacturer');
              setSelectedManufacturer(e?.value);
            }}
            value={listOfManufacturers?.filter((manufacturer) => manufacturer.value === selectedManufacturer)}
          />
          {!selectedVehicleTypes && (
            <p className="empty-field__blinker mb-2 text-right mt-2">previous field needs to be filled</p>
          )}

          <div className="row mt-4">
            <div className="col-md-6">
              <CustomSelect
                className="pt-model"
                isDisabled={!selectedManufacturer}
                isLoading={loadingModel}
                placeholder="- Select Model -"
                options={listOfModels}
                name="pt-model"
                onChange={(e) => {
                  clearFilter('model');
                  setSelectedModel(e?.value);
                }}
                value={listOfModels?.filter((model) => model.value === selectedModel)}
              />
              {!selectedManufacturer && (
                <p className="empty-field__blinker mb-2 text-right mt-2">previous field needs to be filled</p>
              )}
            </div>

            <div className="col-md-6">
              <CustomSelect
                className="pt-trim"
                isDisabled={!selectedModel}
                isLoading={loadingTrim}
                placeholder="- Select Trim -"
                options={listOfTrims}
                name="pt-trim"
                onChange={(e) => setSelectedTrim(e?.value)}
                value={listOfTrims?.filter((trim) => trim.value === selectedTrim)}
              />
              {!selectedModel && (
                <p className="empty-field__blinker mb-2 text-right mt-2">previous field needs to be filled</p>
              )}
            </div>
          </div>

          <div className="my-4"></div>
          <CustomSelect
            className="pt-manufacturer"
            placeholder="- Select Grade -"
            isDisabled={!selectedManufacturer}
            isLoading={loadingManufacturer}
            options={grade}
            name="pt-manufacturer"
            onChange={(e) => {
              setSelectedGrade(e?.value);
            }}
            value={grade?.filter((group) => group.value === selectedGrade)}
          />
          {!selectedVehicleTypes && (
            <p className="empty-field__blinker mb-2 text-right mt-2">previous field needs to be filled</p>
          )}

          <div className="row mt-5">
            <div className="col-md-5">
              <Button
                onClick={next}
                disabled={
                  !selectedManufacturer ||
                  !selectedLocation ||
                  !selectedModel ||
                  !selectedTrim ||
                  !activeVehicleType ||
                  !selectedVehicleTypes
                }
                secondary
                className="continue w-100 text-uppercase py-3"
                style={{
                  background: '#FCDD18',
                  color: '#3E3E3E',
                  fontSize: '11.5px'
                }}>
                continue
              </Button>
            </div>

            <div className="col-md-5">
              <Button
                onClick={() => history.push('/pricetemplates')}
                secondary
                className="cancel w-100 text-uppercase py-3"
                style={{
                  background: '#E9E9E9',
                  color: '#3E3E3E',
                  fontSize: '11.5px'
                }}>
                cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VehicleInfo);
