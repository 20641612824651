export const getHpStatusColorCode = (status) => {
  switch (status?.slug?.toLowerCase()) {
    case 'active':
      return 'blue-div';
    case 'paused':
      return 'grey-div';
    case 'cancelled':
      return 'red-div';
    case 'lost':
      return 'red-div';
    case 'completed':
      return 'green-div';
    case 'marked-for-closure':
      return 'orange-div';
    case 'closed':
      return 'darker-grey-div';
    default:
      return status;
  }
};

export const getRetrivalStatusColorCode = (status) => {
  switch (status?.toLowerCase()) {
    case 'good standing':
      return 'green-div';
    case 'due for retrieval':
      return 'red-div';
    case 'retrieved':
      return 'grey-div';
    case 'overdue':
      return 'orange-div';
    case 'prepaid':
      return 'yellow-div';
    case 'marked for retrieval':
      return 'grey-div';
    default:
      return status;
  }
};

export function retrievalStatusColors(status) {
  switch (status?.toLowerCase()) {
    case 'good standing':
      return '#60d9ad';
    case 'due for retrieval':
      return '#d75353';
    case 'retrieved':
      return '#a8a7a7';
    case 'overdue':
      return '#e29c2c';
    case 'prepaid':
      return '#ab9715';
    case 'marked for retrieval':
      return '#a8a7a7';
    default:
      return status;
  }
}

export function retrievalStatusValue(status) {
  switch (status?.toLowerCase()) {
    case 'good standing':
      return '0';
    case 'due for retrieval':
      return '2';
    case 'retrieved':
      return '∞';
    case 'overdue':
      return '1';
    case 'prepaid':
      return '+1';
    case 'marked for retrieval':
      return '∞';
    default:
      return status;
  }
}
