import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import style from './style';
import CheckCircle from '../../assets/img/check-circle.svg';
import CheckCircleError from '../../assets/img/check-circle-cancel.svg';
import Typography from '@mui/material/Typography';
const SuccessErrorModal = ({ hpCompleteData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => setModalOpen(hpCompleteData.modalOpen), [hpCompleteData]);
  return (
    <Modal open={modalOpen}>
      <Box sx={[style.boxStyle, style.widthMid]}>
        <div style={style.successErrorModalWrapper}>
          <img
            width="85"
            height="28"
            alt="Success"
            src={hpCompleteData.status ? CheckCircle : CheckCircleError}
          />
          <Typography
            variant="h5"
            component="h5"
            className="mt-4 mb-4">
            {hpCompleteData.message}
          </Typography>
          <Button
            sx={[style.btn, style.completeContractBtn]}
            onClick={() => setModalOpen(false)}>
            DISMISS
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default SuccessErrorModal;
