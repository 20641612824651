import React, { useEffect, useState } from "react";
import { getChampionRepayment } from "../../services/collectionService";
import Modal from "../Modal/Modal";
import TableComponent from "../Table/table.component";
import format from "date-fns/format";
import "./repayment-schedule.styles.scss";
import NumberFormatter from "../../util/numberFormatter";
import { Table, Popup, Image } from "semantic-ui-react";
import config from "../../config/config";
import styled from "styled-components";

const RepaymentSchedule = ({
  setRepaymentModal,
  championObj,
  show,
  championTrancheInfo,
  trancheInfo,
}) => {
  const [repaymentSchedule, setRepaymentSchedule] = useState([]);

  const getSchedule = async (id) => {
    try {
      const response = await getChampionRepayment(id);
      setRepaymentSchedule(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HeaderCell = styled(Table.HeaderCell)`
    background-color: #fef5ba !important;
  `;

  useEffect(() => {
    if (championObj && championObj.id !== "") {
      getSchedule(championObj.id);
    }
  }, [championObj]);

  return (
    <div className="repayment-schedule">
      <Modal show={show}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <p style={{ fontSize: "16px" }} className="font-weight-bold mb-0">
                Repayment schedule
              </p>
            </div>
            <div className="col-md-4">
              <div
                className="cancel-icon ml-auto cursor"
                onClick={() => setRepaymentModal(false)}
              >
                <span className="icon-text">x</span>
              </div>
            </div>
          </div>

          <hr />

          <div className="card mb-4 mt-4 p-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <p className="small text-secondary mb-1">Amount Remitted</p>
                  <p className="font-weight-bold">
                    N
                    {NumberFormatter.formatNumber(
                      repaymentSchedule?.total_remitted
                    )}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="small text-secondary mb-1">
                    Amount Outstanding
                  </p>
                  <p className="font-weight-bold">
                    N
                    {NumberFormatter.formatNumber(
                      repaymentSchedule?.total_outstanding
                    )}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="small text-secondary mb-1">Days Remaining</p>
                  <p className="font-weight-bold">
                    {repaymentSchedule?.no_of_left_days || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {repaymentSchedule &&
            repaymentSchedule?.repayment_schedule?.length > 0 && (
              <div className="repayment-schedule">
                <TableComponent>
                  <Table.Header>
                    <HeaderCell>Period</HeaderCell>
                    <HeaderCell>Date</HeaderCell>
                    <HeaderCell>Repayment</HeaderCell>
                    <HeaderCell>
                      <div className="d-inline-flex align-items-center">
                        Principal BoP
                        <div className="d-flex flex-column ml-1">
                          <div className="d-inline-flex">
                            <Popup
                              basic
                              content="principal begining of period"
                              trigger={
                                <Image
                                  src={`${config.public_url}/info.svg`}
                                  style={{ width: "11px" }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </HeaderCell>
                    <HeaderCell>Amount Overdue</HeaderCell>
                  </Table.Header>

                  <tbody>
                    {repaymentSchedule &&
                      repaymentSchedule?.repayment_schedule?.length > 0 &&
                      repaymentSchedule?.repayment_schedule?.map((schedule) => (
                        <tr key={schedule.id}>
                          <td>{schedule.period}</td>
                          <td>
                            {format(new Date(schedule.date), "DD/MMM/YYYY")}
                          </td>
                          <td>
                            {NumberFormatter.formatNumber(schedule.repayment)}
                          </td>
                          <td>
                            {NumberFormatter.formatNumber(
                              schedule.principal_bop
                            )}
                          </td>
                          <td>
                            {NumberFormatter.formatNumber(
                              schedule.amount_overdue
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </TableComponent>
              </div>
            )}
        </div>
      </Modal>
    </div>
  );
};

export default RepaymentSchedule;
