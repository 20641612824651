import React from 'react';
import './css-loader.scss'

const PulseLoader = ({width, height, bgColor}) => {
    return (
        <div className="spinner" style={{width: width, height: height}}>
            <div className="double-bounce1" style={{backgroundColor: bgColor}}></div>
            <div className="double-bounce2" style={{backgroundColor: bgColor}}></div>
        </div>
    )
};

export default PulseLoader;