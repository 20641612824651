import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQueryClient as useCustomQueryClient } from 'services/apiClient';
import '../add-transaction.scss';
import DotLoader from 'components/Loader/DotLoader';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import config from 'config/config';
import ValidationUtil from 'util/validation-util';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import useAddPayment from './useAddPayment';
import { useQueryClient } from 'react-query';

export const AddPaymentModal = ({ championInfo, show, onHide }) => {
  const adminData = JSON.parse(localStorage.getItem('lams-data'));
  const { id: admin_id } = adminData;

  const queryClient = useQueryClient();
  const {
    userDataForAddTransaction,
    setUserDataForAddTransaction,
    foundMatchingPayment,
    setFoundMatchingPayment,
    paymentChannel,
    setPaymentChannel,
    setCollectionOfficerId
  } = useChampionPaymentHistory();
  const { bankPaymentAmount, referenceId } = userDataForAddTransaction;
  const {
    errorData,
    mutate,
    isLoading: addingPayment,
    isSuccess,
    toggleSuccess,
    isFailure,
    toggleFailure
  } = useAddPayment();

  useEffect(() => {
    return () => {
      toggleSuccess(false);
      toggleFailure(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Payment successfully added');
      queryClient.invalidateQueries(['collection_record']);
      setUserDataForAddTransaction({});
      setPaymentChannel('');
      toggleSuccess(false);
      onHide();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (isFailure) {
      toast.error(errorData?.message);
      queryClient.invalidateQueries(['collection_record']);
      setUserDataForAddTransaction({});
      setPaymentChannel('');
      toggleFailure(false);
      onHide();
    }
    // eslint-disable-next-line
  }, [isFailure]);

  const [checkForReference, toggleCheckForReference] = React.useState(false);
  const [collectionOfficerEmail, setCollectionOfficerEmail] = React.useState(false);

  const handleUpdateReferenceId = (e) => {
    const { value } = e.target;
    setUserDataForAddTransaction((prevData) => {
      return {
        ...prevData,
        referenceId: value
      };
    });
  };

  const handleUpdateBankPaymentAmount = (e) => {
    const { value } = e.target;
    setUserDataForAddTransaction((prevData) => {
      return {
        ...prevData,
        bankPaymentAmount: value
      };
    });
  };

  const handleSelectChange = (e) => {
    setPaymentChannel(e.target.value);
  };

  const closeModal = () => {
    onHide();
  };

  useEffect(() => {
    switch (paymentChannel) {
      case 'monnify':
        setCollectionOfficerEmail(config.monify_collection_officer_email);
        break;
      case 'woven':
        setCollectionOfficerEmail(config.woven_collection_officer_email);
        break;
      case 'paystack':
        setCollectionOfficerEmail(config.paystack_collection_officer_email);
        break;
      // TODO: Temporary implementation, until a payment channel is available in cameroon
      case 'bank':
        setCollectionOfficerEmail(admin_id);
        break;
      case 'cash':
        setCollectionOfficerEmail(admin_id);
        break;
      default:
        setCollectionOfficerEmail('');
    }
  }, [paymentChannel, admin_id]);

  const { isLoading, isError } = useCustomQueryClient(`thirdparty/v1/payment/${referenceId}`, {
    enabled: checkForReference,
    method: 'get',
    onSuccess: (data) => {
      const {
        data: { data: transactionData }
      } = data;

      if (ValidationUtil.isObjectEmpty(transactionData)) {
        toggleCheckForReference(false);
        setUserDataForAddTransaction((prevData) => {
          return {
            ...prevData,
            referenceId: ''
          };
        });
        toast('No record of this transaction was Found');
      }

      if (!ValidationUtil.isObjectEmpty(transactionData)) {
        setUserDataForAddTransaction({
          ...transactionData,
          referenceId: referenceId,
          _payment_channel: paymentChannel
        });
        setFoundMatchingPayment(!foundMatchingPayment);
        closeModal();
      }
    },
    onError: () => {
      toggleCheckForReference(false);
    }
  });

  useCustomQueryClient(`accounts/user/${collectionOfficerEmail}`, {
    enabled: !!collectionOfficerEmail,
    method: 'get',
    // key: collectionOfficerEmail,
    onSuccess: (data) => {
      const {
        data: { data: collectionOfficerData }
      } = data;
      setCollectionOfficerId(collectionOfficerData?.id);
    }
  });

  return (
    <Modal
      onHide={onHide}
      show={show}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {isLoading && (
        <div className="loader-wrapper">
          <DotLoader />
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <Modal.Header>
            <h3 className={'title'}>Add Payment Transaction</h3>
            <CancelIcon onClick={closeModal} />
          </Modal.Header>
          <Modal.Body>
            <div className="form add-reference-id-form">
              <label>Select payment channel</label>
              <select
                value={paymentChannel}
                onChange={handleSelectChange}>
                <option value="">Select payment channel</option>
                <option value="paystack">Paystack</option>
                <option value="woven">Woven</option>
                <option value="monnify">Monnify</option>
                <option value="bank">Bank</option>
                {championInfo?.country?.calling_code === '237' && <option value="cash">Cash</option>}
              </select>

              <label>Reference ID</label>
              <input
                value={referenceId}
                name="referenceId"
                onChange={handleUpdateReferenceId}
                placeholder="Enter the reference ID"
              />

              {paymentChannel === 'bank' || paymentChannel === 'cash' ? (
                <>
                  <label>Amount</label>
                  <input
                    type="number"
                    value={bankPaymentAmount}
                    name="bankPaymentAmount"
                    onChange={handleUpdateBankPaymentAmount}
                    placeholder="Enter payment amount"
                  />
                </>
              ) : null}

              <button
                className="modal_form_btn"
                disabled={addingPayment || !referenceId || !paymentChannel}
                onClick={() => {
                  if (paymentChannel === 'bank' || paymentChannel === 'cash') {
                    mutate();
                  } else {
                    toggleCheckForReference(true);
                  }
                }}>
                {addingPayment ? 'Adding Payment' : 'Add Payment'}
              </button>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
