import React from 'react';
import Modal from '../../components/Modal/Modal';
import './PausedDaysModal.scss';
import LeftArrow from '../../assets/img/left-arrow.svg';
import CancelIcon from '../../assets/img/icons8-cancel.svg';
import format from 'date-fns/format';
import { pauseReasons as pauseHPReasons } from '../../services/HpService';

const PausedDaysModal = ({ show, setShowPausedDaysModal, pausedHistory, pausedDaysTotal, expectedEndDate }) => {
  const [reasonArray, setReasonArray] = React.useState([]);

  const fetchReasons = async () => {
    const reasonsArray = await pauseHPReasons();
    setReasonArray(reasonsArray?.data?.data);
  };
  React.useEffect(() => {
    fetchReasons();
  }, []);

  const getReasonTitle = (id) => {
    const reasonFound = reasonArray?.find((reason) => reason.id === id);
    return reasonFound ? reasonFound?.title : 'reason does not exist';
  };

  return (
    <div className="paused-modal__container">
      <Modal show={show}>
        <div className="paused-modal__body">
          <nav className="paused-modal__nav">
            <img
              id="left-arrow"
              src={LeftArrow}
              alt="left arrow"
              onClick={() => setShowPausedDaysModal(false)}
            />
            <p className="paused-days-header">Paused Days</p>
            <img
              id="close-arrow"
              src={CancelIcon}
              alt="cancel icon"
              onClick={() => setShowPausedDaysModal(false)}
            />
          </nav>
          <main>
            <div className="main_left">
              {pausedHistory?.map((pausedItem, index) => (
                <div key={index}>
                  <div className="main_left__single_card">
                    <div className="single_card__left">
                      <p className="top">Check in Date</p>
                      <p className="bottom">
                        {pausedItem?.start_date ? format(pausedItem?.start_date, 'DD/MM/YYYY') : ''}
                      </p>
                    </div>
                    <div className="single_card__right">
                      <p className="top">Reason</p>
                      <p className="bottom">{pausedItem && getReasonTitle(pausedItem?.pause_reason_id)}</p>
                    </div>
                  </div>
                  <div className="main_left__single_card">
                    <div className="single_card__left">
                      <p className="top">Checkout Date</p>
                      <p className="bottom">{pausedItem?.end_date ? format(pausedItem?.end_date, 'DD/MM/YYYY') : ''}</p>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
            <div className="main_right">
              <div
                id="aside1"
                className="aside_container">
                <p className="top_word">{pausedDaysTotal}</p>
                <p className="bottom_word">Days Paused</p>
              </div>
              <div className="aside_container">
                <p className="top_word">+{pausedDaysTotal} Days</p>
                <p className="bottom_word">To expected HP end date</p>
              </div>
              <div className="last_aside_container">
                <p className="last_aside_top">New Expected HP end date</p>
                <p className="last_aside_bottom">{expectedEndDate}</p>
              </div>
            </div>
          </main>
        </div>
      </Modal>
    </div>
  );
};

export default PausedDaysModal;
