import React, { useState, useEffect } from 'react';
import OverviewTable from './OverviewTable';
import CardComponent from 'components/Card/card.component';
import { SearchFilterWrapper } from 'components/SearchFilter';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'services/apiClient';
import './ContractsToBeClosed.scss';
import { useQueryContext } from 'contexts/query-context';
import { Form } from 'semantic-ui-react';
import CustomSelect from 'components/CustomSelect';
import { CustomToggle } from 'components/Inputs/custom-toggle';
import { ContractDetails } from 'components/ContractToBeClose/contract-details';
import style from 'components/SearchFilter/index.module.scss';

const CompletedContracts = ({ eventKey }) => {
  const handleFlaggedChampionToggle = () => {
    toggleFlaggedChampions(!showFlaggedChampions);
  };

  let { searchQuery, setSearchQuery, pagination } = useQueryContext();

  const [baseQuery, setBaseQuery] = useState('');
  const [showFlaggedChampions, toggleFlaggedChampions] = useState(false);
  const [filterLocation, setFilterLocation] = useState('');
  const [hpStatus, setHPStatus] = useState('');
  const [retreivalStatus, setRetreivalStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterState, setFilterState] = useState({});
  const api_prefix = 'collection/v1/';
  let history = useHistory();

  const navigateToChampionBio = (data) => {
    history.push(`/champion/${data.champion_id}`, {
      championObj: data
    });
  };

  const onPageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line
  }, [eventKey]);

  useEffect(() => {
    setFilterState({
      ...filterState,
      searchQuery,
      currentPage,
      filterLocation,
      hpStatus,
      retreivalStatus
    });
    // eslint-disable-next-line
  }, [searchQuery, currentPage, filterLocation, hpStatus, retreivalStatus]);

  useEffect(() => {
    if (searchQuery === '') {
      setBaseQuery(
        `repayments/hp-complete?hp_complete_status=active&page=${currentPage}&limit=${pageSize}${
          filterLocation ? `&location_id=${filterLocation}` : ''
        }${hpStatus ? `&status_id=${hpStatus}` : ''}${retreivalStatus ? `&payment_status_id=${retreivalStatus}` : ''}`
      );
    } else {
      setBaseQuery(
        `repayments/hp-complete?hp_complete_status=active&page=${currentPage}&limit=${pageSize}&search=${searchQuery}`
      );
    }
    // eslint-disable-next-line
  }, [pageSize, currentPage, searchQuery, filterLocation, hpStatus, retreivalStatus, eventKey]);

  // endpoints
  const fetchContractsURL = `${api_prefix}${baseQuery}`;
  const fetchLocationURL = `${api_prefix}locations`;
  const fetchHPStatusURL = `${api_prefix}statuses/hp-statuses`;
  const fetchRetreivalStatusURL = `${api_prefix}statuses?type=all`;

  const { data: contractsData, isLoading: contractsLoading } = useQueryClient(fetchContractsURL, {
    method: 'get',
    enabled: baseQuery !== '' && eventKey === 'completed',
    key: 'contracts_record'
  });

  const { data: locationData } = useQueryClient(fetchLocationURL, {
    method: 'get',
    retry: false
  });

  const { data: hpStatusData } = useQueryClient(fetchHPStatusURL, {
    method: 'get',
    retry: false
  });

  const { data: retreivalStatusData } = useQueryClient(fetchRetreivalStatusURL, {
    method: 'get',
    retry: false
  });

  // queries response
  const _contracts_data = contractsData && contractsData?.data?.data?.contracts;

  pagination = contractsData && contractsData?.data?.data?.pagination;

  const _location_data =
    locationData &&
    locationData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));

  const _hp_status_data =
    hpStatusData &&
    hpStatusData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));

  const _retreival_status_data =
    retreivalStatusData &&
    retreivalStatusData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));

  // queries response

  return (
    <>
      <div className="w-100 mb-4 mt-2">
        <CardComponent>
          <SearchFilterWrapper
            wrapperClass={style['wrapper-nil-export']}
            title="Filter Table"
            filterTitle=" "
            // toggle section of the filter
            toggleBlock={
              <CustomToggle
                toggleState={showFlaggedChampions}
                handleChange={handleFlaggedChampionToggle}>
                {showFlaggedChampions ? 'Flagged' : 'ALL'}
              </CustomToggle>
            }
            // all filters
            filterBlock={
              <Form>
                <div className="select-wrapper">
                  <CustomSelect
                    placeholder="Location"
                    options={_location_data}
                    onChange={(e) => {
                      setFilterLocation(e?.value);
                    }}
                  />
                </div>

                <div className="select-wrapper">
                  <CustomSelect
                    placeholder="HP Status"
                    options={_hp_status_data}
                    onChange={(e) => {
                      setHPStatus(e?.value);
                    }}
                  />
                </div>

                {!showFlaggedChampions && (
                  <div className="select-wrapper">
                    <CustomSelect
                      placeholder="Retrieval Status"
                      options={_retreival_status_data}
                      onChange={(e) => {
                        setRetreivalStatus(e?.value);
                      }}
                    />
                  </div>
                )}
              </Form>
            }
          />
          <div className="px-4">
            <OverviewTable
              contractDetails={<ContractDetails />}
              data={_contracts_data}
              loading={contractsLoading}
              navigateToChampionBio={navigateToChampionBio}
              pagination={pagination}
              pageCount={pagination?.pageCount || 0}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              contractsToBeClose={true}
            />
          </div>
        </CardComponent>
      </div>
    </>
  );
};

export default CompletedContracts;
