import React, { useState, useContext, createContext } from 'react';

const QueryContext = createContext(null);
const QueryContextProvider = ({ children }) => {
  const [baseQuery, setBaseQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  let pagination;

  return (
    <QueryContext.Provider
      value={{
        setBaseQuery,
        baseQuery,
        searchQuery,
        setSearchQuery,
        pagination,
      }}>
      {children}
    </QueryContext.Provider>
  );
};

export default QueryContextProvider;

export const useQueryContext = () => {
  return useContext(QueryContext);
};
