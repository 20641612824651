import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import LockedVehicles from './LockedVehicles';

const RemoteLock = () => {
  const [currentEventKey, setCurrentEventKey] = useState('locked');

  return (
    <div className="custom-container">
      <div className="w-100">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={currentEventKey}>
          <div className="d-flex">
            <Nav
              variant="pills"
              className="w-100 bg-white d-flex tab-wrapper">
              <Nav.Item className="closed-contracts">
                <Nav.Link
                  onClick={() => setCurrentEventKey('locked')}
                  eventKey="locked">
                  Locked Vehicles
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="closed-contracts">
                <Nav.Link
                  onClick={() => setCurrentEventKey('unlocked')}
                  eventKey="unlocked">
                  Unlocked Vehicles
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey={'locked'}>
              <LockedVehicles
                dateOrder={'asc'}
                eventKey={'locked'}
                title="Locked Vehicles"
                pageUrl="getLockedVehicleList"
              />
            </Tab.Pane>

            <Tab.Pane eventKey={'unlocked'}>
              <LockedVehicles
                dateOrder={'desc'}
                eventKey={'unlocked'}
                title="Unlocked Vehicles"
                pageUrl="getUnlockedVehicleList"
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default RemoteLock;
