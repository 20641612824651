import React from "react";
import "./ActiveTrancheCard.scss";

const ActiveTrancheCard = ({ trancheInfo, setShowAmortSchedule, index, disable }) => {
  return (
    <div className="tranche-container">
      <div className="top-tranche">
        <div className="active-tranche-text-container">
          <p className="active-tranche-header">Tranche {index + 1}</p>
        </div>
        <div className="amortization-link-container">
          {index === 0 &&
            <p
            className="amortization-link"
            onClick={() => setShowAmortSchedule(true)}
            disable={disable}
          >
            VIEW AMORTIZATION SCHEDULE
          </p>}
        </div>
      </div>
      <div className="middle-tranche">
        <div className="middle-tranche-inner">
          <div className="middle-tranche-inner-left">
            <p className="mid-tranche-header-left">
              {trancheInfo?.tranche_name}
            </p>
            <p className="mid-tranche-text-left">Daily remittance</p>
          </div>
          <div className="middle-tranche-inner-right">
            <p className="mid-tranche-text-right">
              {trancheInfo?.daily_remittance}
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-tranche">
        <div className="bottom-tranche-container left">
          <p className="bottom-tranche-header">Duration(Days)</p>
          <p className="bottom-tranche-text">{trancheInfo?.duration_in_days}</p>
        </div>
        <div className="bottom-tranche-container middle">
          <p className="bottom-tranche-header"></p>
          <p className="bottom-tranche-text"></p>
        </div>
        <div className="bottom-tranche-container right">
          <p className="bottom-tranche-header">Duration(Months)</p>
          <p className="bottom-tranche-text">
            {trancheInfo?.duration_in_months}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActiveTrancheCard;
