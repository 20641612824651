import React, { Suspense, useEffect } from 'react';
import './styles/App.scss';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import QueryContextProvider from './contexts/query-context';
import ChampionPaymentHistoryProvider from 'contexts/champion/transaction';
import ContractsProvider from 'contexts/contracts';

import Navbar from './components/Navbar/Navbar';
import Overview from './pages/Overview/index';
import ChampionsBio from './pages/Overview/ChampionBio';
// import Collection from './pages/Collections/Collection';
import Remittance from './pages/Remittance/Remittance';
import Packages from './pages/Packages/Packages';
import Login from './pages/Login/Login';
import Vehicles from './pages/Vehicles/Vehicles';
import AllPackages from './pages/AllPackages/AllPackages';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import { getUserRole } from './axios/method';
import ProtectedRoute from './route/ProtectedRoute';
import CreatePackage from './pages/Create-packages/create-package.component';
import PackagesConfirmation from './pages/Packages/packages-confirmation/packages-confirmation.component';
import ContractsRestructuring from './pages/ContractsToBeClose/index';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from 'react-query';
import { UserRoleType } from 'util/constant';
import ContactCenter from 'pages/ContactCenter';
import AdvancePayment from 'pages/InitiatedContracts';
import Smartlook from 'smartlook-client';
import RemoteLock from 'pages/LockedVehicles';

// import { hotjar } from 'react-hotjar';

function App(props) {
  // hotjar.initialize('3543757', 6);
  const { loggedIn } = props;
  let history = useHistory();

  useEffect(() => {
    Smartlook.init('eb5983077d0ca8d3bcd678e1164e736ece6313a9');
  });

  if (!loggedIn) {
    if (history.location.pathname === '/forgot-password') {
      return <ForgotPassword />;
    } else if (history.location.pathname === '/reset-password') {
      return <ResetPassword />;
    } else {
      return (
        <Suspense>
          <Login />
        </Suspense>
      );
    }
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ChampionPaymentHistoryProvider>
        <ContractsProvider>
          <QueryContextProvider>
            <div className="App">
              {localStorage.getItem('lams-token') && <Navbar />}
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={Overview}
                />
                <Route
                  exact
                  path="/login"
                  component={Login}
                />
                <ProtectedRoute
                  exact
                  path="/remittance"
                  component={Remittance}
                />
                <Route
                  exact
                  path="/champion/:id"
                  component={ChampionsBio}
                />
                {/* <ProtectedRoute
                  exact
                  path="/collections"
                  component={Collection}
                /> */}
                {UserRoleType.includes(getUserRole()) ? (
                  <ProtectedRoute
                    exact
                    path="/pricetemplates"
                    component={Packages}
                  />
                ) : (
                  <Redirect to="/" />
                )}
                <ProtectedRoute
                  exact
                  path="/create-template"
                  component={CreatePackage}
                />
                <ProtectedRoute
                  exact
                  path="/vehicles"
                  component={Vehicles}
                />
                <ProtectedRoute
                  exact
                  path="/remote-lock"
                  component={RemoteLock}
                />
                <ProtectedRoute
                  exact
                  path="/view-pricetemplates"
                  component={AllPackages}
                />
                <ProtectedRoute
                  exact
                  path="/confirm-price-template"
                  component={PackagesConfirmation}
                />
                {UserRoleType.includes(getUserRole()) ? (
                  <ProtectedRoute
                    exact
                    path="/contracts"
                    component={ContractsRestructuring}
                  />
                ) : (
                  <Redirect to="/" />
                )}

                <ProtectedRoute
                  exact
                  path="/contact-center"
                  component={ContactCenter}
                />

                <ProtectedRoute
                  exact
                  path="/initiated-contract"
                  component={AdvancePayment}
                />
              </Switch>

              <ToastContainer
                position="bottom-left"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </QueryContextProvider>
        </ContractsProvider>
      </ChampionPaymentHistoryProvider>
    </QueryClientProvider>
  );
}

const mapStateToProps = ({ auth }) => ({ loggedIn: auth.isLoggedIn });

export default connect(mapStateToProps)(App);
