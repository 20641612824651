import React, { useEffect, useState } from "react";
import { handleAxiosError } from "../../../axios/handleAxiosError";
import CustomSelect from "../../../components/CustomSelect";
import { getLocationService } from "../../../services/packageService";
import {
  getManufacturersService,
  getVehicleTypes,
} from "../../../services/vehicleService";
import "./packages-filter-nav.styles.scss";

const PackagesFilterNav = ({
  setManufacturerFilter,
  setLocationFilter,
  setVehicleTypeFilter,
}) => {
  const [listOfLocation, setListOfLocation] = useState([]);
  const [listOfManufacturers, setListOfManufacturers] = useState([]);
  const [listOfVehicleTypes, setListOfVehicleTypes] = useState([]);

  const [locationLoader, setLocationLoader] = useState(false);
  const [manufacturerLoader, setManufacturerLoader] = useState(false);
  const [vehicleTypeLoader, setVehicleTypeLoader] = useState(false);

  const getLocations = async () => {
    setLocationLoader(true);
    try {
      let response = await getLocationService();
      let locationArray = [];
      response.data.data.forEach((location) =>
        locationArray.push({ label: location.name, value: location.id })
      );
      setListOfLocation(
        locationArray.sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (error) {
      console.error(handleAxiosError(error)?.message || "Something went wrong");
    } finally {
      setLocationLoader(false);
    }
  };

  const getManufacturers = async () => {
    setManufacturerLoader(true);
    try {
      let response = await getManufacturersService();
      let manufacturerArray = [];
      response.data.data.forEach((manufacturer) =>
        manufacturerArray.push({
          label: manufacturer.name,
          value: manufacturer.id,
        })
      );

      setListOfManufacturers(
        manufacturerArray.sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (error) {
      console.error(handleAxiosError(error)?.message || "Something went wrong");
    } finally {
      setManufacturerLoader(false);
    }
  };

  const getVehicleTypesFn = async () => {
    setVehicleTypeLoader(true);
    try {
      const response = await getVehicleTypes();
      let typeArray = [];
      response.data.data.forEach((type) =>
        typeArray.push({
          label: type.name,
          value: type.id,
        })
      );

      setListOfVehicleTypes(
        typeArray.sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (error) {
      console.error(handleAxiosError(error)?.message || "Network Error");
    } finally {
      setVehicleTypeLoader(false);
    }
  };

  useEffect(() => {
    getLocations();
    getManufacturers();
    getVehicleTypesFn();
  }, []);

  return (
    <div className='packages-filter__nav'>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-2'></div>

          <div className='col-md-12 px-0'>
            <div className='row d-flex justify-content-start'>
              <div className='col-auto w-25'>
                <CustomSelect
                  options={listOfLocation}
                  className='packages-select'
                  name='location'
                  isLoading={locationLoader}
                  placeholder='Select Location'
                  onChange={e => {
                    setLocationFilter(e?.value || '');
                  }}
                />
              </div>

              <div className='col-auto w-25'>
                <CustomSelect
                  options={listOfManufacturers}
                  className='packages-select'
                  name='manufacturer'
                  isLoading={manufacturerLoader}
                  placeholder='Select Manufacturer'
                  onChange={e => {
                    setManufacturerFilter(e?.value || '');
                  }}
                />
              </div>

              <div className='col-auto w-25'>
                <CustomSelect
                  options={listOfVehicleTypes}
                  className='packages-select'
                  name='vehicleTypes'
                  isLoading={vehicleTypeLoader}
                  placeholder='Select Vehicle Type'
                  onChange={e => {
                    setVehicleTypeFilter(e?.value || '');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesFilterNav;
