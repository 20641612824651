import React from 'react';
import { formatNumber } from 'pages/Overview/OverviewTable';
import format from 'date-fns/format';

const RestructuredCard = ({ currentRestructuredData }) => {
  return (
    <div className="tranche-container">
      <div className="top-tranche">
        <div className="active-tranche-text-container">
          <p className="active-tranche-header">Restructured Contract Tranche</p>
        </div>
      </div>
      <div className="middle-tranche">
        <div className="middle-tranche-inner">
          <div className="middle-tranche-inner-left">
            <p className="mid-tranche-header-left">{currentRestructuredData?.tranche_name}</p>
            <p className="mid-tranche-text-left">New tranche remittance</p>
          </div>
          <div className="middle-tranche-inner-right">
            <p className="mid-tranche-text-right">{`N ${formatNumber(currentRestructuredData?.daily_remit)}`}</p>
          </div>
        </div>
      </div>
      <div className="bottom-tranche">
        <div className="bottom-tranche-container ">
          <p className="bottom-tranche-header">Start Date</p>
          <p className="bottom-tranche-text">{format(currentRestructuredData?.start_date, 'DD/MM/YYYY')}</p>
        </div>
        <div className="bottom-tranche-container ">
          <p className="bottom-tranche-header">End Date</p>
          <p className="bottom-tranche-text">{format(currentRestructuredData?.end_date, 'DD/MM/YYYY')}</p>
        </div>

        <div className="bottom-tranche-container ">
          <p className="bottom-tranche-header">Status</p>
          <p className="bottom-tranche-text">Awaiting Checkout</p>
        </div>
      </div>
    </div>
  );
};

export default RestructuredCard;
