import React from "react";
import { Image, Grid, Popup, Table, Icon } from "semantic-ui-react";
import TableLoader from "../../components/Loader/TableLoader";

import NumberFormatter from "../../util/numberFormatter";

//config file
import config from "../../config/config";
import styled from "styled-components";

import EmptyState from "../../assets/img/empty-img.svg";
import CustomPagination from "../../components/CustomPagination/customPagination.component";
import UtilFn from "../../util/util-fn";

const RemittanceTable = ({
  remittanceData,
  loading,
  daysOfWeek,
  navigateToChampionBio,
  sortByChampionName,
  sortByTao,
  sortByTdo,
  pagination,
  sortByLocation,
  sortByDaysOfWeek,
  pageSize,
  onPageSizeChange,
  onPageChange,
  currentPage,
}) => {
  const HeaderCell = styled(Table.HeaderCell)`
    background-color: #ffdd00 !important;
  `;

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  return (
    <>
      {loading && <TableLoader />}

      <Grid className="w-100">
        <Grid.Row>
          <Grid.Column>
            {!loading && (
              <>
                <Table className="remittance-table">
                  <Table.Header>
                    <Table.Row>
                      <HeaderCell>#</HeaderCell>
                      <HeaderCell>
                        <div className="d-inline-flex align-items-center">
                          Champion
                          <div className="d-inline-flex">
                            <div>
                              <Popup
                                basic
                                content="Descending order"
                                trigger={
                                  <Icon
                                    name="caret up"
                                    onClick={() => sortByChampionName("desc")}
                                    className="d-flex justify-content-center cursor"
                                  />
                                }
                              />

                              <Popup
                                basic
                                content="Ascending order"
                                trigger={
                                  <Icon
                                    name="caret down"
                                    onClick={() => sortByChampionName("asc")}
                                    className="d-flex justify-content-center align-items-center cursor"
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </HeaderCell>
                      {daysOfWeek &&
                        daysOfWeek.map((data, index) => (
                          <HeaderCell
                            key={index + data.day}
                            className="text-capitalize"
                          >
                            <div className="d-inline-flex align-items-center">
                              {data.day}(₦)
                              <div className="d-inline-flex">
                                <div>
                                  <Popup
                                    basic
                                    content="Descending order"
                                    trigger={
                                      <Icon
                                        name="caret up"
                                        onClick={() =>
                                          sortByDaysOfWeek(data.day, "desc")
                                        }
                                        className="d-flex justify-content-center cursor"
                                      />
                                    }
                                  />

                                  <Popup
                                    basic
                                    content="Ascending order"
                                    trigger={
                                      <Icon
                                        name="caret down"
                                        onClick={() =>
                                          sortByDaysOfWeek(data.day, "asc")
                                        }
                                        className="d-flex justify-content-center align-items-center cursor"
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </HeaderCell>
                        ))}
                      <HeaderCell>
                        <div className="d-inline-flex align-items-center">
                          TAO(₦)
                          <div className="d-flex flex-column">
                            <div className="d-inline-flex">
                              <div>
                                <Popup
                                  basic
                                  content="Descending order"
                                  trigger={
                                    <Icon
                                      name="caret up"
                                      onClick={() => sortByTao("desc")}
                                      className="d-flex justify-content-center cursor"
                                    />
                                  }
                                />

                                <Popup
                                  basic
                                  content="Ascending order"
                                  trigger={
                                    <Icon
                                      name="caret down"
                                      onClick={() => sortByTao("asc")}
                                      className="d-flex justify-content-center align-items-center cursor"
                                    />
                                  }
                                />
                              </div>

                              <Popup
                                basic
                                content="Total Amount Overdue"
                                trigger={
                                  <Image
                                    src={`${config.public_url}/info.svg`}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </HeaderCell>
                      <HeaderCell>
                        <div className="d-inline-flex align-items-center">
                          TDO
                          <div className="d-flex flex-column">
                            <div className="d-inline-flex">
                              <div>
                                <Popup
                                  basic
                                  content="Descending order"
                                  trigger={
                                    <Icon
                                      name="caret up"
                                      onClick={() => sortByTdo("desc")}
                                      className="d-flex justify-content-center cursor"
                                    />
                                  }
                                />

                                <Popup
                                  basic
                                  content="Ascending order"
                                  trigger={
                                    <Icon
                                      name="caret down"
                                      onClick={() => sortByTdo("asc")}
                                      className="d-flex justify-content-center align-items-center cursor"
                                    />
                                  }
                                />
                              </div>

                              <Popup
                                basic
                                content="Total Days Overdue"
                                trigger={
                                  <Image
                                    src={`${config.public_url}/info.svg`}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </HeaderCell>
                      <HeaderCell>
                        <div className="d-inline-flex align-items-center">
                          Location
                          <div className="d-inline-flex">
                            <div>
                              <Popup
                                basic
                                content="Descending order"
                                trigger={
                                  <Icon
                                    name="caret up"
                                    onClick={() => sortByLocation("desc")}
                                    className="d-flex justify-content-center cursor"
                                  />
                                }
                              />

                              <Popup
                                basic
                                content="Ascending order"
                                trigger={
                                  <Icon
                                    name="caret down"
                                    onClick={() => sortByLocation("asc")}
                                    className="d-flex justify-content-center align-items-center cursor"
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {remittanceData &&
                      remittanceData.map((remittance, index) => (
                        <Table.Row
                          className="cursor"
                          onClick={() =>
                            navigateToChampionBio(remittance.champion_id)
                          }
                          key={index}
                        >
                          <Table.Cell>
                            {UtilFn.paginationNumbering(
                              pageSize,
                              pagination.offset,
                              index
                            )}
                          </Table.Cell>

                          <Table.Cell>{remittance.full_name}</Table.Cell>

                          {remittance.remittance.map((data, index) => (
                            <Table.Cell key={index}>
                              {formatNumber(data.value)}
                            </Table.Cell>
                          ))}

                          <Table.Cell>
                            {remittance.total_amount_overdue
                              ? formatNumber(remittance.total_amount_overdue)
                              : "N/A"}
                          </Table.Cell>

                          <Table.Cell>
                            {remittance.total_days_overdue
                              ? remittance.total_days_overdue
                              : "N/A"}
                          </Table.Cell>

                          <Table.Cell>
                            {remittance.location ? remittance.location : "N/A"}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>

                <CustomPagination
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  pageCount={pagination.pageCount}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </>
            )}

            {/* empty state */}
            {!loading && remittanceData.length === 0 && (
              <div
                className="text-center"
                style={{ marginTop: "5rem", marginBottom: "5rem" }}
              >
                <img src={EmptyState} alt="Empty state" />
                <h3>Nothing to see here</h3>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default RemittanceTable;
