import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";

import ProfileDefault from "../../assets/img/profile-default.png";

import "./amortization-profile.styles.scss";
import {
  getChampionAmortization,
  getChampionTrancheInfo,
} from "../../services/collectionService";
import { handleAxiosError } from "../../axios/handleAxiosError";
import { toast } from "react-toastify";
import NumberFormatter from "../../util/numberFormatter";

const AmortizationProfile = ({
  show,
  setAmortizationProfile,
  toAmortizationSchedule,
  championObj,
  navigateToChampionBio,
  setChampionsAmortizationSchedule,
  championsAmortizationSchedule,
  setChampionTrancheInfo,
}) => {
  const [trancheInfo, setTrancheInfo] = useState();
  const [championTranche, setChampionTranche] = useState();

  const getSchedule = async (id) => {
    try {
      const response = await getChampionAmortization(id);
      let schedule_data = response.data.data;
      if (schedule_data && schedule_data.amortization_schedule) {
        setChampionsAmortizationSchedule(schedule_data);
      }
      setTrancheInfo(schedule_data);
    } catch (error) {
      toast.error(handleAxiosError(error).message || "Something went wrong");
      console.error(error);
    }
  };

  const getTrancheInfo = async (id) => {
    try {
      const response = await getChampionTrancheInfo(id);
      setChampionTranche(response.data.data);
      setChampionTrancheInfo(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (championObj && championObj.id !== "") {
      getSchedule(championObj.id);
      getTrancheInfo(championObj.id);
    }

    // eslint-disable-next-line
  }, [championObj]);

  return (
    <div className="amortization-profile">
      <Modal show={show}>
        <div className="modal-body">
          <div>
            <div
              className="cancel-icon ml-auto cursor"
              onClick={() => setAmortizationProfile(false)}
            >
              <span className="icon-text">x</span>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <img
                src={ProfileDefault}
                style={{ maxWidth: "40px" }}
                alt="Profile"
              />
            </div>
            <div className="col-10">
              <p className="font-weight-bold mb-1">{championObj?.name}</p>
              <p
                className="small cursor"
                onClick={() => navigateToChampionBio(championObj)}
              >
                View champion profile
              </p>
            </div>
          </div>

          <div className="card mt-4 py-4">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <p className="small mb-1 text-secondary">No. of Tranches</p>
                  <p>
                    {trancheInfo && trancheInfo.num_tranches
                      ? trancheInfo.num_tranches
                      : "N/A"}
                  </p>
                </div>
                <div className="col-md-4 mb-3">
                  <p className="small mb-1 text-secondary">Total Remitted</p>
                  <p>
                    {trancheInfo && trancheInfo.total_remitted
                      ? NumberFormatter.formatNumber(
                          trancheInfo?.total_remitted
                        )
                      : "0"}
                  </p>
                </div>
                <div className="col-md-4 mb-3">
                  <p className="small mb-1 text-secondary">Total Outstanding</p>
                  <p>
                    {trancheInfo && trancheInfo?.total_outstanding
                      ? NumberFormatter.formatNumber(
                          trancheInfo?.total_outstanding
                        )
                      : "0"}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small mb-1 text-secondary">
                    Repayment Frequency
                  </p>
                  <p className="text-capitalize">
                    {trancheInfo && trancheInfo?.repayment_frequency
                      ? trancheInfo?.repayment_frequency
                      : "N/A"}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small mb-1 text-secondary">
                    No of Repayments (days)
                  </p>
                  <p>
                    {trancheInfo && trancheInfo?.total_no_of_repayments
                      ? trancheInfo?.total_no_of_repayments
                      : "0"}
                  </p>
                </div>

                <div className="col-md-8 mb-3">
                  <p className="small mb-1 text-secondary">
                    Repayments Overdue
                  </p>
                  <p>
                    {trancheInfo && trancheInfo?.no_of_overdue
                      ? trancheInfo?.no_of_overdue
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {championsAmortizationSchedule?.amortization_schedule?.length > 0 && (
            <div className="card mt-4 py-4">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <p className="mb-1 text-secondary">Active Tranche</p>
                  </div>
                  <div className="col-md-7">
                    <p
                      onClick={toAmortizationSchedule}
                      className="mb-1 font-weight-bold cursor"
                      style={{ color: "#E88E15" }}
                    >
                      View amortization schedule
                    </p>
                  </div>
                </div>
              </div>

              <hr />

              <div className="col-md-12">
                <div className="row mb-3">
                  <div className="col-md-5">
                    <p className="mb-1 font-weight-bold">
                      {championTranche?.active_tranche &&
                        championTranche?.active_tranche}
                    </p>

                    <p className="text-secondary">Daily remittance</p>
                  </div>
                  <div className="col-md-7 my-auto text-right">
                    <h2 className="font-weight-bold">
                      {trancheInfo?.active_tranche_remittance
                        ? `N${NumberFormatter.formatNumber(
                            trancheInfo?.active_tranche_remittance
                          )}`
                        : "N/A"}
                    </h2>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-4">
                    <p className="small mb-1 text-secondary">
                      Duration(Months)
                    </p>
                    <p>{trancheInfo?.duration_in_months}</p>
                  </div>
                  <div className="col-md-4">
                    <p className="small mb-1 text-secondary">Days Remaining</p>
                    <p>{trancheInfo?.days_remaining}</p>
                  </div>
                  <div className="col-md-4">
                    <p className="small mb-1 text-secondary">Days Elapsed</p>
                    <p>{trancheInfo?.days_elapsed}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AmortizationProfile;
