import React from "react";
import Modal from "./Modal";
import successIcon from "../../assets/img/success.png";
import { Button } from "semantic-ui-react";

const SuccessModal = ({
  showSuccessModal,
  title,
  subTitle,
  submitSuccessModal,
  btnText,
}) => {
  return (
    <Modal show={showSuccessModal}>
      <div style={{ padding: "100px" }} className="modal-body text-center">
        <div className="d-inline-flex">
          <img src={successIcon} alt="success" />
        </div>

        <div>
          <h3 className="mt-3">{title}</h3>

          <p className="mb-4">{subTitle}</p>
        </div>

        <Button
          secondary
          type="button"
          className="rounded"
          style={{ fontSize: "12px" }}
          onClick={submitSuccessModal}
        >
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
