import React from "react";
import { Redirect } from "react-router-dom";
import { expTimeFromToken } from "../reducers/auth";

const ProtectedRoute = (props) => {
  const { component } = props;
  const Component = component;

  const getTodaysDate = () => {
    let todaysDate = new Date();

    return Date.parse(todaysDate) / 1000;
  };

  const expTime = expTimeFromToken() ? expTimeFromToken() : getTodaysDate();

  const logoutFn = () => {
    localStorage.clear();

    window.location.reload(true);
    return "/login";
  };

  return getTodaysDate() < expTime ? (
    <Component />
  ) : (
    <Redirect to={logoutFn()} />
  );
};

export default ProtectedRoute;
