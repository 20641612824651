import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Form, Message, Image } from "semantic-ui-react";
import styled from "styled-components";

import { connect } from "react-redux";
import { login } from "../../reducers/auth";

// config file
import config from '../../config/config'

const LoginWrapper = styled.div`
  .left {
    background-color: white;
    width: 45%;
    height: 100vh;
    float: left;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .right {
    width: 55%;
    float: right;
    height: 100vh;
    opacity: 0.5;
    position: relative;
    top: 0;
    right: 0;
  }

  h1 {
    margin-left: 10rem;
    margin-top: 16px;
  }

  h3 {
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: 10rem;
    color: rgba(0, 0, 0, 0.7);
  }

  .logo {
    margin-left: 10rem;
    margin-top: 51px;
  }

  form {
    padding-left: 10rem;
    width: 36.5rem;
    margin-top: 3.5rem;
  }

  form input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .ui.button {
    margin-top: 4rem;
    padding-left: 12rem;
    padding-right: 12rem;
    background-color: #fd0;
    color: #000;
  }

  .message {
    width: 26.5rem;
    margin-left: 9.9rem !important;
    margin-bottom: 28px;
    background: #ffe2e2 !important;
    color: #dd2222;
  }

  .errorImage {
    position: absolute !important;
    top: 1.2rem !important;
  }

  p {
    color: #dd2222;
    margin-left: 2rem !important;
    margin-top: 0rem !important;
  }

  .dollarsImage {
    background-size: cover;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    background-color: #ffdd00 !important;
    opacity: 0.4;
  }

  .emailLabel {
    position: absolute;
    top: -1.5rem;
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.3);
  }

  #at {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  #padlock {
    position: absolute;
    right: 0.5rem;
    top: 7.3rem;
  }

  .passwordLabel {
    position: absolute;
    top: 5rem;
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 993px) {
    form {
      width: fit-content;
      margin-bottom: 35px;
    }
    h1 {
      width: fit-content;
      margin-left: 10rem;
      padding-bottom: 1rem;
    }
    h3 {
      width: fit-content;
      margin-left: 10rem;
      padding-bottom: 1rem;
    }
    .logo {
      margin: auto;
      margin: auto;
      padding-bottom: 1rem;
      padding-top: 2rem;
      margin-left: 10rem;
    }

    .message {
      width: 30.5rem;
    }

    .ui.button {
      width: 30.5rem;
    }
  }

  @media (max-width: 1199px) {
    .right {
      display: none;
    }
    .left {
      float: none;
      width: 100%;
      height: 35%;
    }
    form {
      margin: auto;
    }
    h1 {
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
    }
    h3 {
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .logo {
      margin: auto;
    }
    .message {
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 992px) {
    .small {
      margin: auto;
      padding-top: 1rem;
    }
    form {
      padding-left: 1rem;
      width: 32.5rem;
      padding-right: 1rem;
    }
    h1 {
      margin: auto;
      width: fit-content;
      padding-top: 1.5rem;
    }
    h3 {
      margin: auto;
      width: fit-content;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .logo {
      margin: auto;
    }
    .ui.button {
      width: -webkit-fill-available;
    }
    #at {
      right: 1.5rem;
    }

    #padlock {
      right: 1.5rem;
    }
  }

  @media (max-width: 425px) {
    .message {
      left: -9rem !important;
      bottom: 2rem;
      width: max-content;
    }
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    .message {
      margin: auto !important;
      bottom: 1.8rem !important;
    }
  }
`;

// move error message up
// change staging api endpoint
// check pagination
// format phone number

function Login({ login, loading, message }) {
  return (
    <LoginWrapper>
      <div className="left">
        <div className="small">
          <Image
            size="small"
            className="logo"
            src={`${config.public_url}/max_okada.svg`}
          />
          <h1>LAMS</h1>
          <h3>Log in to your account</h3>
          {message && (
            <Message className="message">
              <Image
                src={`${config.public_url}/errorInfo.svg`}
                className="errorImage"
              />
              <p>{message}</p>
            </Message>
          )}
          <LoginForm onSubmit={login} loading={loading} />
        </div>
      </div>
      <div className="right">
        <Image
          className="dollarsImage"
          size="small"
          src={`${config.public_url}/dollars-min.jpg`}
        />
        <Image className="overlay" />
      </div>
    </LoginWrapper>
  );
}

function LoginForm({ onSubmit, loading }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});

  const formSubmitted = e => {
    e.preventDefault();

    // Perform Validation;
    const error = {};

    if (!email) {
      error.email = true;
    }

    if (!password) {
      error.password = true;
    }

    if (error.email || error.password) {
      setError({ error });

      return;
    }

    if (typeof onSubmit === "function") {
      onSubmit({ email, password });
      setPassword({ password: "" });
    }
  };

  return (
    <>
      <Form loading={loading} onSubmit={formSubmitted}>
        <label className="emailLabel">Email Address</label>
        <Image id="at" src="at.svg" />
        <input
          fluid="true"
          id="email"
          type="Email"
          name="email"
          onChange={e => {
            setEmail(e.target.value);
          }}
          required
          value={email}
          error={error.email}
        />
        <div style={{ marginTop: "4rem" }}>
          <label className="passwordLabel">Enter Password</label>
          <Image id="padlock" src="padlock.svg" />
          <input
            fluid="true"
            id="password"
            name="password"
            type="password"
            onChange={e => {
              setPassword(e.target.value);
            }}
            required
            value={password}
            error={error.password}
          />
        </div>
        <input className="ui button" type="submit" value="Login" />
      </Form>

      <Link to="/forgot-password"><p className="text-center cursor" style={{fontSize: '15px', color: '#000'}}>Reset Password</p></Link>
    </>
  );
}

const mapStateToProps = ({ auth }) => ({
  loading: auth.authenticating,
  message: auth.message
});

export default connect(mapStateToProps, { login })(Login);
