import React from "react";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router";

import './packages-nav.styles.scss'
import { getUserRole } from "../../../axios/method";

const PackagesNav = ({ history }) => {
  return (
    <div className="packages-nav">
      <div className="nav-header">Pricing Templates</div>

      {(getUserRole() === 'super-admin') &&
        <Button
          onClick={() => history.push("/create-template")}
          secondary
          style={{ background: "#FCDD18", color: "#3E3E3E" }}
        >
          create new template
      </Button>
      }
    </div>
  );
};

export default withRouter(PackagesNav);
