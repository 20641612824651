import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import "./MaxSubHeader.scss";
import Logo from "../../assets/img/logo.svg";

const MaxSubHeader = ({ logo = Logo, name = 'LAMS' }) => {
  return (
    <>
      <div className="lams-subheader__container">
        <img width="85" height="28" src={logo} alt="{name}" />
        <Divider orientation="vertical" flexItem />
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: 16, fontWeight: "600" }}>
          {name}
        </Typography>
      </div>
    </>
  );
};

export default MaxSubHeader;