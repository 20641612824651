import React from 'react';
import { Button } from 'semantic-ui-react';
import Modal from '../../../components/Modal/Modal';
import './template-details.styles.scss';
import format from 'date-fns/format';

import BikeIcon from '../../../assets/img/bike-icon.svg';
import KekeIcon from '../../../assets/img/keke-icon.svg';
import CarIcon from '../../../assets/img/car-icon.svg';

import NumberFormatter from '../../../util/numberFormatter';
import { getUserRole } from '../../../axios/method';
import { AMORTIZATION_OBJ, IS_EDIT_FLAG } from '../../../util/constant';
import storageService from '../../../services/storageService';
import { useHistory } from 'react-router-dom';

const TemplateDetails = ({ isTemplateDetails, setIsTemplateDetails, templateDetails }) => {
  const history = useHistory();
  const getVehicleTypeImg = (type) => {
    switch (type) {
      case 'tricycle':
        return KekeIcon;
      case 'motorcycle':
        return BikeIcon;
      case 'car':
        return CarIcon;

      default:
        return BikeIcon;
    }
  };

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const handleEdit = () => {
    storageService.set(
      AMORTIZATION_OBJ,
      JSON.stringify({
        ...templateDetails,
        grade: templateDetails.grade == null ? 'new_vehicle' : templateDetails.grade
      })
    );
    storageService.set(IS_EDIT_FLAG, true);
    history.push('/create-template');
  };

  return (
    <div className="template-details">
      <Modal show={isTemplateDetails}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <div className="d-flex">
                <img
                  src={getVehicleTypeImg(
                    templateDetails.vehicle_type ? templateDetails.vehicle_type.toLowerCase() : 'N/A'
                  )}
                  alt="vehicle icon"
                />
                <div className="ml-3">
                  <p
                    className="font-weight-bold mb-0"
                    style={{ color: '#3E3E3E' }}>
                    {templateDetails.vehicle_type ? templateDetails.vehicle_type : 'N/A'} -{' '}
                    {templateDetails.manufacturer ? templateDetails.manufacturer : 'N/A'}
                  </p>

                  <p className="text-secondary small">
                    Created, {format(new Date(templateDetails.createdAt), 'DD/MM/YYYY')}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="cancel-icon ml-auto cursor"
                onClick={() => setIsTemplateDetails(false)}>
                <span className="icon-text">x</span>
              </div>
            </div>
          </div>

          <div className="card mt-4 py-2 w-100">
            <div className="px-3 pt-2 pb-1">
              <p
                style={{ fontSize: '13px' }}
                className="text-secondary">
                Vehicle Information
              </p>
            </div>

            <hr />

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Location</p>
                  <p>{templateDetails.location ? templateDetails.location : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Manufacturer</p>
                  <p>{templateDetails.manufacturer ? templateDetails.manufacturer : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Model</p>
                  <p>{templateDetails.model ? templateDetails.model : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Trim</p>
                  <p>{templateDetails.trim ? templateDetails.trim : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Asset Class</p>
                  <p>{templateDetails.vehicle_type ? templateDetails.vehicle_type : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Asset Grade</p>
                  <p>{templateDetails.grade === 'new_vehicle' ? 'New Vehicle' : templateDetails.grade || 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4 py-2 w-100">
            <div className="px-3 pt-2 pb-1">
              <p
                style={{ fontSize: '13px' }}
                className="text-secondary">
                Pricing Details
              </p>
            </div>

            <hr />

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Daily Remittance</p>
                  <p>{templateDetails.daily_remit ? formatNumber(templateDetails.daily_remit) : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Payment Frequency</p>
                  <p className="text-capitalize">{templateDetails.payment_frequency}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Payment Days</p>
                  <p className="text-capitalize">{templateDetails.work_days}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">HP Duration (Months)</p>
                  <p>{templateDetails.standard_duration ? templateDetails.standard_duration : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Insurance Duration (Months)</p>
                  <p>{templateDetails.insurance_duration ? templateDetails.insurance_duration : 'N/A'}</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">MAX LTV</p>
                  <p>{templateDetails?.tranches && templateDetails?.tranches[0]?.ltv}%</p>
                </div>

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">Total HP Amount (N)</p>
                  <p>{templateDetails.hp_amount ? formatNumber(templateDetails.hp_amount) : 'N/A'}</p>
                </div>

                {getUserRole() === 'super-admin' && (
                  <div className="col-md-6 mb-3">
                    <p className="small text-secondary mb-1">Total Onboarding Cost (N)</p>
                    <p>
                      {templateDetails?.tranches?.length > 0
                        ? formatNumber(templateDetails?.tranches[0]?.total_onboarding_cost)
                        : 'N/A'}
                    </p>
                  </div>
                )}

                <div className="col-md-6 mb-3">
                  <p className="small text-secondary mb-1">No of tranches</p>
                  <p>{templateDetails?.tranches ? templateDetails?.tranches?.length : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>

          {getUserRole() === 'super-admin' && (
            <Button
              secondary
              onClick={handleEdit}
              className="w-100 text-uppercase mt-4 mb-2 py-3"
              style={{
                background: '#FCDD18',
                color: '#3E3E3E',
                fontSize: '12px'
              }}>
              Edit pricing template
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TemplateDetails;
