import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Icon, Button, Form } from "semantic-ui-react";

// images import
import CancelImg from "../../assets/img/cancel.svg";

// component import
import SideBar from "../../components/SideBar/SideBar";
import TableLoader from "../../components/Loader/TableLoader";
import PulseLoader from "../../components/Loader/PulseLoader";

import "./allPackages.scss";

// config file
import config from "../../config/config";
import ValidationUtil from "../../util/validation-util";
import NumberFormatter from "../../util/numberFormatter";
import swal from "sweetalert";
import {
  getLocationService,
  getPackages,
  reCalcHpAmountService,
  updatePackageById,
} from "../../services/packageService";
import {
  getManufacturersService,
  getModelByManufacturerService,
  getTrimByModelService,
} from "../../services/vehicleService";
import { getUserRole } from "../../axios/method";
import CustomPagination from "../../components/CustomPagination/customPagination.component";
import UtilFn from "../../util/util-fn";
import CardComponent from "../../components/Card/card.component";
import TableComponent from "../../components/Table/table.component";

const AllPackages = () => {
  const [packages, setPackages] = useState([]);
  const [showSideBar, SetSideBar] = useState(false);

  //   const [asset, setAsset] = useState("");
  const [dailyRemit, setDailyRemit] = useState("");
  const [hpAmount, setHpAmount] = useState("");
  //   const [location, setLocation] = useState("");
  const [payoffMonth, setPayoffMonth] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [packageId, setPackageId] = useState("");
  const [isTableLoading, setTableLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [manufacturerId, setManufacturerId] = useState("");
  const [modelId, setModelId] = useState("");
  const [trimId, setTrimId] = useState("");
  const [insuranceDuration, setInsuranceDuration] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationDropdown, setLocationDropdown] = useState([]);
  //   const [models, setModels] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  //   const [trims, setTrims] = useState([]);
  const [vehicleType, setVehicleType] = useState("");

  // pagination
  //   const [totalPageCount, setTotalPageCount] = useState("");
  //   const [startingCount, setStartingCount] = useState(1);
  //   const [endingCount, setEndingCount] = useState(null);
  //   const [page, setPage] = useState(1);
  //   const [totalPackages, setTotalPackages] = useState([]);
  //   const [isNext, setNext] = useState(false);
  const [paginationData, setPaginationData] = useState({});

  //   const [dailyRemitErrors, setDailyRemitError] = useState("");
  //   const [standardDurationError, setStandardDurationError] = useState("");
  //   const [insuranceDurationError, setInsuranceDurationError] = useState("");
  //   const [paymentDaysError, setPaymentDaysError] = useState("");

  const [paymentDays, setPaymentDays] = useState("");
  const [editModelInfo, setEditModelInfo] = useState("");
  const [editTrimInfo, setEditTrimInfo] = useState("");

  const [dailyRemitEditErrors, setDailyRemitEditError] = useState("");
  const [standardDurationEditError, setStandardDurationEditError] =
    useState("");
  const [insuranceDurationEditError, setInsuranceDurationEditError] =
    useState("");
  const [paymentDaysEditError, setPaymentDaysEditError] = useState("");
  const [isConfirmEditing, setConfirmEditing] = useState(false);
  const [hpAmountSideBarLoader, setHpAmountSideBarLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(config.pageSize);

  useEffect(() => {
    getManufacturers();
    getListOfLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getListOfPackages();
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  const getListOfLocations = async () => {
    await getLocationService()
      .then((res) => {
        setLocationDropdown(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const getListOfPackages = async () => {
    window.scrollTo(0, 0);
    setTableLoading(true);
    const params = new URLSearchParams();
    params.append("page", currentPage);
    params.append("limit", pageSize);
    await getPackages(params)
      .then((res) => {
        let data = res.data.data.packages;
        let pagination = res.data.data.pagination;
        setPackages(data);
        setPaginationData(pagination);
        // setTotalPageCount(pagination.totalRows);

        // if (!isNext) {
        //   setEndingCount(data.length);
        // }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const editInformation = (data) => {
    setPackageId(data.id);
    // setAsset(data.asset);
    setEditModelInfo(data.model.name);
    setEditTrimInfo(data.trim.name);
    setDailyRemit(data.daily_remit);
    setHpAmount(data.hp_amount);
    // setLocation(data.location);
    // setLocation(data.location_id);
    setWorkDays(data.work_days);
    setPayoffMonth(data.standard_duration);
    setInsuranceDuration(data.insurance_duration);
    setManufacturerId(data.manufacturer_id);
    setModelId(data.model_id);
    getModel(true, data.model_id);
    setLocationId(data.location.id);
    setTrimId(data.trim_id);
    getTrims(true, data.trim_id);
    setPaymentDays(data.work_days);
    setVehicleType(data.vehicle_type ? data.vehicle_type.name : "");

    SetSideBar(true);
  };

  const onPageChange = (e) => {
    const { selected } = e;
    setCurrentPage(selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  const confirmUpdate = () => {
    setConfirmEditing(true);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to update this information?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        updatePackage();
      } else {
        setConfirmEditing(false);
      }
    });
  };

  const updatePackage = async () => {
    setConfirmEditing(false);
    setLoader(true);

    const data = {
      location_id: `${locationId}`,
      daily_remit: `${dailyRemit}`,
      standard_duration: `${payoffMonth}`,
      //   work_days: `${workDays}`,
      manufacturer_id: `${manufacturerId}`,
      model_id: `${modelId}`,
      trim_id: `${trimId}`,
      work_days: `${paymentDays}`,
      insurance_duration: insuranceDuration,
    };

    await updatePackageById(packageId, data)
      .then(() => {
        getListOfPackages();
        closeSideBar();
        setTableLoading(true);
        swal("Successful", {
          icon: "success",
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        closeSideBar();
        setLoader(false);
      });
  };

  // Method to get list of manufacturers
  const getManufacturers = async () => {
    await getManufacturersService()
      .then((res) => {
        setManufacturers(res.data.data);
      })
      .catch((err) => {
        console.error(`Failed to get manufacturers`, err);
      });
  };

  // Method to get list of models based on a manufacturer
  const getModel = async (retriggerFlag, values) => {
    await getModelByManufacturerService(values)
      .then((res) => {
        // setModels(res.data.data);
      })
      .catch((err) => {
        console.error(`Failed to get models`, err);
      });
  };

  const selectPaymentDays = (e) => {
    const { value } = e.target;
    setPaymentDaysEditError(ValidationUtil.sevenDaysValidation(value));
    setPaymentDays(value);
  };

  //method to get list of trims
  const getTrims = async (retriggerFlag, values) => {
    await getTrimByModelService(values)
      .then((res) => {
        // setTrims(res.data.data);
      })
      .catch((err) => {
        console.error(`Failed to get trims`, err);
      });
  };

  //   const selectAssets = (e, data) => {
  //     e.persist();
  //     setAsset(data.value);
  //   };

  //   const selectLocation = (e) => {
  //     setLocation(e.target.value);
  //   };

  const selectHpAmount = (e) => {
    setHpAmount(e.target.value);
  };

  const selectDailyRemit = (e) => {
    const { value } = e.target;
    setDailyRemitEditError(ValidationUtil.twoDecimalPointValidation(value));
    setDailyRemit(value);
    if (!ValidationUtil.twoDecimalPointValidation(value)) {
      reCalcHpAmount(value, payoffMonth, workDays);
    } else {
      setHpAmount("");
    }
  };

  const selectPayoffMonth = (e) => {
    const { value } = e.target;
    setStandardDurationEditError(
      ValidationUtil.twoDecimalPointValidation(value)
    );
    setPayoffMonth(value);
    if (!ValidationUtil.twoDecimalPointValidation(value)) {
      reCalcHpAmount(dailyRemit, value, workDays);
    } else {
      setHpAmount("");
    }
  };

  const reCalcHpAmount = async (
    dailyRemittance,
    standardDuration,
    workDays
  ) => {
    setHpAmountSideBarLoader(true);
    const params = new URLSearchParams();
    params.append("daily_remit", dailyRemittance);
    params.append("payoff_months", standardDuration);
    params.append("work_days", workDays);
    await reCalcHpAmountService(params)
      .then((res) => {
        setHpAmount(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setHpAmountSideBarLoader(false);
      });
  };

  //   const selectWorkDays = (e) => {
  //     setWorkDays(e.target.value);
  //   };

  const closeSideBar = () => {
    SetSideBar(false);
    setStandardDurationEditError("");
    setDailyRemitEditError("");
    setInsuranceDurationEditError("");
  };

  //   const selectLocationId = (e) => {
  //     setLocationId(e.target.value);
  //   };

  const selectManufacturer = (e) => {
    setManufacturerId(e.target.value);
    getModel(true, e.target.value);
  };

  const selectModel = (e) => {
    setModelId(e.target.value);
    getTrims(true, e.target.value);
  };

  const selectTrim = (e) => {
    setTrimId(e.target.value);
  };

  const selectInsuranceDuration = (e, data) => {
    e.persist();
    const { value } = e.target;
    setInsuranceDurationEditError(
      ValidationUtil.twoDecimalPointValidation(value)
    );
    setInsuranceDuration(value);
  };

  const disablePriceEdit = () => {
    let checkScore = 0;

    if (
      manufacturerId &&
      modelId &&
      locationId &&
      dailyRemit &&
      payoffMonth &&
      insuranceDuration &&
      paymentDays
    ) {
      checkScore = checkScore + 50;
    }

    if (
      !insuranceDurationEditError &&
      !standardDurationEditError &&
      !dailyRemitEditErrors &&
      !paymentDaysEditError
    ) {
      checkScore = checkScore + 50;
    }

    return checkScore;
  };

  const returnSelectedOption = (array, id) => {
    let filteredData = array.filter((obj) => obj.id === id);

    return filteredData[0] ? filteredData[0].name : "";
  };

  //   const nextPage = () => {
  //     setPage(page + 1);
  //     setStartingCount(startingCount + 20);
  //     getListOfPackages();
  //   };

  return (
    <div className="custom-container">
      {/* sidebar */}
      <SideBar tempHide={isConfirmEditing} show={showSideBar}>
        <img
          src={CancelImg}
          alt="Cancel icon"
          onClick={closeSideBar}
          className="cursor"
        />
        <div className="sideBar-header">
          <h3>Update Information</h3>
          <p>Update price template information.</p>
        </div>

        <Form onSubmit={confirmUpdate}>
          <Form.Field className="disabled">
            <label>Vehicle Type</label>
            <input
              placeholder="Vehicle Type"
              name="vehicle_type_id"
              value={vehicleType ? vehicleType : "N/A"}
              onChange={selectManufacturer}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Manufacturer</label>
            <input
              placeholder="Manufacturer"
              name="manufacturer_id"
              value={returnSelectedOption(manufacturers, manufacturerId)}
              onChange={selectManufacturer}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Model</label>
            <input
              placeholder="Model"
              name="model_id"
              value={editModelInfo}
              onChange={selectModel}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Trim</label>
            <input
              placeholder="Trim"
              name="trim_id"
              value={editTrimInfo}
              onChange={selectTrim}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Location</label>
            <input
              placeholder="Location"
              name="trim_id"
              value={returnSelectedOption(locationDropdown, locationId)}
              onChange={selectManufacturer}
            />
          </Form.Field>

          <Form.Field disabled>
            <div className="d-inline-flex">
              <label>HP Amount</label>
              {hpAmountSideBarLoader && (
                <PulseLoader width="14px" height="14px" bgColor="#777575" />
              )}
            </div>
            <input
              placeholder="Input HP Amount"
              name="hp_amount"
              value={new Intl.NumberFormat().format(hpAmount)}
              onChange={selectHpAmount}
            />
          </Form.Field>

          <Form.Field className={dailyRemitEditErrors ? "input-error" : ""}>
            <label>Daily Remittance</label>
            <input
              placeholder="Input Daily Remittance"
              name="daily_remit"
              value={dailyRemit}
              onChange={selectDailyRemit}
            />

            <p className="error-text">{dailyRemitEditErrors}</p>
          </Form.Field>

          <Form.Field
            className={standardDurationEditError ? "input-error" : ""}
          >
            <label>Standard Duration</label>
            <input
              placeholder="Input Standard Duration"
              name="standard_duration"
              value={payoffMonth}
              onChange={selectPayoffMonth}
            />

            <p className="error-text">{standardDurationEditError}</p>
          </Form.Field>

          <Form.Field
            className={insuranceDurationEditError ? "input-error" : ""}
          >
            <label>Insurance Duration</label>
            <input
              placeholder="Input Value"
              name="insurance_duration"
              value={insuranceDuration}
              onChange={selectInsuranceDuration}
            />
            <p className="error-text">{insuranceDurationEditError}</p>
          </Form.Field>

          <Form.Field className={paymentDaysEditError ? "input-error" : ""}>
            <label>Payment Days</label>
            <input
              placeholder="Payment Days"
              name="work_days"
              value={paymentDays}
              onChange={selectPaymentDays}
            />

            <p className="error-text">{paymentDaysEditError}</p>
          </Form.Field>

          <div className="text-right btn-div">
            <Button
              className="black-link-btn"
              type="button"
              onClick={closeSideBar}
            >
              Cancel
            </Button>
            <Button
              secondary
              type="submit"
              disabled={disablePriceEdit() !== 100}
            >
              <div className="d-inline-flex">
                UPDATE INFORMATION
                {loader && (
                  <PulseLoader width="15px" height="15px" bgColor="#777575" />
                )}
              </div>
            </Button>
          </div>
        </Form>
      </SideBar>

      <div className="w-100 mb-4 mt-5">
        <CardComponent>
          <div className="px-4">
            {/* table loader */}
            {isTableLoading && <TableLoader />}
          </div>

          {/* {!isTableLoading &&
                        <div className="search-div">
                            <div className="text-left w-100 pagination-div">
                                <Link to="/pricetemplates">

                                    <Icon name="chevron left" className="text-black" />
                                </Link>

                                <Link to="/pricetemplates">
                                    <p className="text-black">Price Templates</p>
                                </Link>

                                <div className="vertical-dividing-line"></div>

                                <p>{totalPageCount} Total</p>


                            </div>
                        </div>
                    } */}

          <div className="px-4 pt-4 pb-3">
            <div className="row">
              <div className="col-md-4 my-auto d-flex align-items-center">
                <Link to="/pricetemplates">
                  <Icon name="chevron left" className="text-black" />
                </Link>
                <h4 className="mb-0 pb-0 mt-0 pt-0 ml-2">Price Templates</h4>
              </div>
              <div className="col-md-8 my-auto text-right">
                <p
                  className="mb-0"
                  style={{ fontSize: "12px", color: "#6b6d6f" }}
                >
                  Showing page {currentPage} of {paginationData.pageCount}
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div className="px-4">
            {!isTableLoading && (
              <>
                <TableComponent>
                  <tr>
                    <th>#</th>
                    <th>Vehicle Type</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>Trim</th>
                    <th>Location</th>
                    <th>HP Amount (₦)</th>
                    <th>Daily Remittance (₦)</th>
                    <th>Payment Days</th>
                    <th>Duration (Months)</th>
                  </tr>

                  {packages.map((data, index) => (
                    <tr
                      className="body-row cursor"
                      key={index}
                      onClick={() =>
                        getUserRole() === "super-admin"
                          ? editInformation(data)
                          : ""
                      }
                    >
                      <td>
                        {UtilFn.paginationNumbering(
                          pageSize,
                          currentPage,
                          index
                        )}
                      </td>
                      <td>
                        {data.vehicle_type ? data.vehicle_type.name : "N/A"}
                      </td>
                      <td>
                        {data.manufacturer ? data.manufacturer.name : "N/A"}
                      </td>
                      <td>{data.model ? data.model.name : "N/A"}</td>
                      <td>{data.trim ? data.trim.name : "N/A"}</td>
                      <td>{data.location.name}</td>
                      <td className="text-green">
                        {formatNumber(data.hp_amount)}
                      </td>
                      <td className="text-green">
                        {formatNumber(data.daily_remit)}
                      </td>
                      <td>{data.work_days ? `${data.work_days}` : "N/A"}</td>
                      <td>
                        {data.standard_duration
                          ? `${data.standard_duration}`
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </TableComponent>
                <CustomPagination
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  pageCount={paginationData.pageCount}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </>
            )}
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default AllPackages;
