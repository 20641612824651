import React from 'react';
import format from 'date-fns/format';
import '../../components/Loader/skeleton.scss';
import PulseLoader from 'components/Loader/PulseLoader';

const ChampionBioInfo = ({ contract, championInfo, loading, vehicleInfo, listOfChampionAccount, loadingVNuban }) => {
  const championFullName = championInfo?.user?.first_name + ' ' + championInfo?.user?.last_name;
  const formattedDate = (date) => format(new Date(date), 'DD/MM/YYYY') ?? 'N/A';
  const championDataArray = [
    {
      title: 'Full Name',
      content: championFullName
    },
    { title: 'Phone Number', content: championInfo?.user?.phone },
    {
      title: 'Activation Date',
      content: formattedDate(championInfo?.date_engaged),
      type: 'date'
    },
    {
      title: 'Contract Activation Date',
      content: formattedDate(contract?.activated_at),
      type: 'date'
    },
    { title: 'Email Address', content: championInfo?.user?.email },
    { title: 'House Address', content: championInfo?.house_address },
    { title: 'Status', content: championInfo?.champion_status },
    {
      title: 'Champion Id',
      content: championInfo?.max_champion_id
    },
    {
      title: 'Vehicle ID',
      content: vehicleInfo?.max_vehicle_id
    },
    {
      title: 'Vehicle Manufacturer',
      content: vehicleInfo?.manufacturer?.name
    },
    {
      title: 'Vehicle Model',
      content: vehicleInfo?.model?.name
    },
    {
      title: 'Vehicle Trim',
      content: vehicleInfo?.trim?.name
    },
    {
      title: 'Vehicle Plate Number',
      content: vehicleInfo?.plate_number
    },
    {
      title: 'Vehicle Grade',
      content:
        contract?.pricing_template?.grade === 'new_vehicle' ? 'New Vehicle' : contract?.pricing_template?.grade || 'N/A'
    },
    {
      title: 'Champion Entry Channel',
      content: championInfo?.channel?.name
    },
    { title: 'Country', content: championInfo?.country?.name },
    { title: 'BVN', content: championInfo?.prospect?.bvn },
    { title: 'Next of Kin Name', content: championInfo?.next_of_kin_name },
    { title: 'Next of Kin Phone', content: championInfo?.next_of_kin_phone }
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <h4>Champion Info</h4>
            <hr />
          </div>
        </div>

        <div className="row mt-3">
          {championDataArray?.map((champion, idx) => {
            return (
              <div
                className="col-md-4 mb-4"
                key={idx}>
                {loading ? (
                  <>
                    <div
                      className="table-items table-item-loader mt-2"
                      style={{ width: '45%' }}></div>
                    <div
                      className="table-items table-item-loader mt-2"
                      style={{ width: '90%' }}></div>
                  </>
                ) : (
                  <>
                    <p className="text-secondary mb-2">{champion?.title}</p>
                    <p className="font-weight-bold">{champion?.content ?? 'N/A'}</p>
                  </>
                )}
              </div>
            );
          })}
        </div>

        {/* vNuban info */}
        <div className="row mt-5">
          <div className="col-md-12 d-flex align-items-center">
            <h4 className=" h-100 d-flex align-items-center">
              Bank Account Info
              {loadingVNuban && (
                <span>
                  <PulseLoader
                    width="12px"
                    height="12px"
                    bgColor="#777575"
                  />
                </span>
              )}
            </h4>
            <hr />
          </div>
        </div>

        <div className="row">
          {listOfChampionAccount &&
            listOfChampionAccount.length > 0 &&
            listOfChampionAccount.map((account, index) => (
              <div
                className="col-md-4"
                key={`${account.account_reference}${index}`}>
                <p className="text-secondary mb-2">
                  {account.bank_name === 'Sparkle Bank' ? 'Wema Bank' : account.bank_name}
                </p>
                <p className="font-weight-bold">{account.vnuban || 'N/A'}</p>
              </div>
            ))}
        </div>

        {/* guarantor's info */}
        <div className="row mt-5">
          <div className="col-md-12">
            <h4>Guarantor Info</h4>

            <hr />
          </div>
        </div>

        {championInfo?.guarantors && championInfo?.guarantors.length === 0 && <p>N/A</p>}

        {championInfo?.guarantors &&
          championInfo?.guarantors?.length > 0 &&
          championInfo?.guarantors?.map((data, index) => (
            <div key={data?.first_name + data?.address + index}>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <p className="text-secondary mb-2">Full Name </p>

                  {!loading ? (
                    <p>{`${data?.first_name} ${data?.last_name}` || 'N/A'} </p>
                  ) : (
                    <div
                      className="table-items table-item-loader mt-2"
                      style={{ width: '60%' }}></div>
                  )}
                </div>

                <div className="col-md-6 mb-4">
                  <p className="text-secondary mb-2">Phone Number </p>

                  {!loading ? (
                    <p>{data ? data?.phone : 'N/A'}</p>
                  ) : (
                    <div
                      className="table-items table-item-loader mt-2"
                      style={{ width: '60%' }}></div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-4">
                  <p className="text-secondary mb-2">Address</p>

                  {!loading ? (
                    <p>{data.address}</p>
                  ) : (
                    <div
                      className="table-items table-item-loader mt-2"
                      style={{ width: '60%' }}></div>
                  )}
                </div>

                <div className="col-md-6 mb-4"></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChampionBioInfo;
