import { of, from, concat } from "rxjs";
import { tap, catchError, ignoreElements, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import jwtDecode from "jwt-decode";

// config file
import config from "../config/config";
import { setUserRole } from "../axios/method";
import { USER_ROLE } from "../util/constant";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const AUTHENTICATING = "AUTHENTICATING";
const AUTHENTICATE = "AUTHENTICATE";
const AUTH_FAILED = "AUTH_FAILED";

export const login = (payload) => ({ type: LOGIN, payload });
export const logout = () => ({ type: LOGOUT });
export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

function authUser(payload) {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  return fetch(`${config.collection_base_url}/v1/login`, {
    method: "POST",
    headers: headers,
    credentials: "omit",
    body: JSON.stringify(payload),
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }

    if (res.status === 500) {
      return res.json().then((err) => {
        throw err;
      });
    }

    if (res.status === 400 || res.status === 401) {
      return res.json().then((err) => {
        throw err;
      });
    }

    // const error = new Error(
    //   "An unexpected error occured or the server was unreachable."
    // );

    // throw error;
  });
}

export function decodeToken(token) {
  const { user } = jwtDecode(token);
  if (user.app_roles) {
    setUserRole(user.app_roles["loan-asset-management"]);
    return user;
  } else {
    unsetToken();
  }
}

export function expTimeFromToken() {
  if (localStorage.getItem("lams-token")) {
    const { exp } = jwtDecode(localStorage.getItem("lams-token"));
    return exp;
  } else {
    let todaysDate = new Date();
    return Date.parse(todaysDate) / 1000;
  }
}

function setToken(payload) {
  localStorage.setItem("lams-token", payload.data.access_token);
  localStorage.setItem("lams-data", JSON.stringify(payload.data.user));

  // We want to refresh the page, so the user can start on a clean slate.
  window.location.reload(true);
}

function unsetToken() {
  try {
    localStorage.removeItem("lams-token");
    localStorage.removeItem(USER_ROLE);
  } catch (e) {
    // do nothing
  }
}

export const loginEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    mergeMap(({ payload }) =>
      concat(of({ type: AUTHENTICATING }), of({ type: AUTHENTICATE, payload }))
    )
  );

export const authenticateEpic = (action$) =>
  action$.pipe(
    ofType(AUTHENTICATE),
    mergeMap((action) => {
      return from(authUser(action.payload)).pipe(
        map((payload) => {
          const user = setToken(payload);
          return setCurrentUser(user);
        }),
        catchError((e) =>
          of({ type: AUTH_FAILED, error: true, payload: e.message })
        )
      );
    })
  );

export const logoutEpic = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    tap(unsetToken),
    tap(() => (window.location.href = "/")),
    ignoreElements()
  );

const initialState = {
  isLoggedIn: false,
  authenticating: false,
  user: {},
  message: "",
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATING:
      return { ...state, authenticating: true, message: "" };

    case AUTH_FAILED:
      return { ...state, authenticating: false, message: action.payload };

    case SET_CURRENT_USER:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: true,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default auth;
