const NumberFormatter = {
  addCommaToNumber(input) {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatNumber(number) {
    let formattedNumber = Number(number).toFixed(2);
    return this.addCommaToNumber(formattedNumber);
  },

  removeComma(string) {
    return parseFloat(string.replace(/,/g, ""));
  },

  removePercentageSign(string) {
    return parseFloat(string.replace(/%/gi, ""));
  },

  removeMinusSign(number) {
    // eslint-disable-next-line
    let formattedNumber = number.replace(/\-/g, "");
    return this.formatNumber(Number(formattedNumber));
  },

  makeNegative(number) {
    if (number > 0) {
      number = -number;

      return this.formatNumber(number);
    }
  },

  formatPhoneNumber(number) {
    if (number) {
      var str = number.split("");
      str.splice(0, 4, "0");
      str = str.join("");

      return str;
    }

    return "";
  },

  invertNumberSign(number) {
    if (number) {
      let stringifiedNumber = number.toString();
      if (stringifiedNumber.includes("-")) {
        return this.removeMinusSign(stringifiedNumber);
      }

      return this.makeNegative(number);
    }
    return number;
  },
};

export default NumberFormatter;
