import React from 'react';
import './champion-ui.scss';
import { useQueryClient } from 'services/apiClient';
import { formatNumber } from 'pages/Overview/OverviewTable';
import format from 'date-fns/format';
import DotLoader from 'components/Loader/DotLoader';
import ValidationUtil from 'util/validation-util';

export const ContractsLog = ({ contractId }) => {
  const api_prefix = 'collection/v1/';

  const contractsLogURL = `${api_prefix}repayments/hp-complete/${contractId}`;
  const { data: contractLogData = {}, isLoading } = useQueryClient(contractsLogURL, {
    method: 'get',
    retry: 1,
    enabled: !!contractId
  });

  const { data: { data: log = {} } = {} } = contractLogData;

  const contract_meta_data = [
    {
      label: 'Date Closed',
      value: log?.updatedAt ? format(new Date(log?.updatedAt), 'DD/MMM/YYYY') : 'N/A'
    },
    { label: 'Closed By', value: log?.completed_by_name },
    { label: 'HP Amount ', value: `N ${formatNumber(log?.total_hp_value)}` },
    { label: 'Outstanding ', value: `N ${formatNumber(log?.outstanding_amount)}` }
  ];

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <DotLoader />
        </div>
      )}

      {!isLoading && ValidationUtil.isObjectEmpty(log) && (
        <div className="w-100 d-flex justify-content-between">
          <p>No Contracts Logs Available</p>
        </div>
      )}

      {!isLoading && !ValidationUtil.isObjectEmpty(log) && (
        <div className="contracts-log__parent">
          <div className="contracts-log__top_detail">
            {contract_meta_data.map((item = {}) => (
              <div className="contracts-log__meta">
                <div>
                  <span className="label">{item.label}</span>
                  <span className="value">{item.value}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="contracts-log__bottom_detail">
            <span className="contracts-log__comment_title">Comments</span>
            <span className="contracts-log__comment_body">{log?.comment}</span>
          </div>
        </div>
      )}
    </>
  );
};
