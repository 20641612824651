/**
 * This module contains all endpoint related methods
 */

import axios from "axios";
import storageService from "../services/storageService";
import { AUTHENTICATION_TOKEN, USER_ROLE } from "../util/constant";

// axios instance creation
export const axiosInstance = axios.create();


// getting user token from local storage
export const getUserToken = () => {
    return storageService.get(AUTHENTICATION_TOKEN)
}

//setting user role
export const setUserRole = (value) => {
    return storageService.set(USER_ROLE, value)
}

//getting user role from local storage
export const getUserRole = () => {
    return storageService.get(USER_ROLE)
}

//getting user role from roles array
export const filterUserRole = (rolesArray) => {
    return rolesArray.filter(obj => obj.app === 'loan-asset-management')
}