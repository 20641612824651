import React, { useState, useEffect } from 'react';
import OverviewTable from './OverviewTable';
import CardComponent from 'components/Card/card.component';
import { SearchFilterWrapper } from 'components/SearchFilter';
import { useQueryContext } from 'contexts/query-context';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'services/apiClient';
import './ContractsToBeClosed.scss';
import { Form } from 'semantic-ui-react';
import style from 'components/SearchFilter/index.module.scss';
import { RestructuringDetails } from 'components/ContractToBeClose/restructuring-details';

const RestructuringContracts = ({ eventKey }) => {
  let { searchQuery, setSearchQuery, baseQuery, setBaseQuery, pagination } = useQueryContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterState, setFilterState] = useState({});

  let history = useHistory();

  const navigateToChampionBio = (data) => {
    history.push(`/champion/${data.champion_id}`, {
      championObj: data
    });
  };

  const onPageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line
  }, [eventKey]);

  useEffect(() => {
    setFilterState({
      ...filterState,
      searchQuery,
      currentPage
    });
    // eslint-disable-next-line
  }, [searchQuery, currentPage, eventKey]);

  useEffect(() => {
    if (searchQuery === '') {
      setBaseQuery(`collection/v1/contracts/restructuring/due?limit=${pageSize}&page=${currentPage}`);
    } else {
      setBaseQuery(
        `collection/v1/contracts/restructuring/due?page=${currentPage}&limit=${pageSize}&search=${searchQuery}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, searchQuery]);

  // endpoints
  const fetchContractsURL = `${baseQuery}`;
  const { data: contractsData, isLoading: contractsLoading } = useQueryClient(fetchContractsURL, {
    method: 'get',
    enabled: baseQuery !== '' && eventKey === 'restructuring',
    key: 'contracts_record'
  });
  const _contracts_data = contractsData && contractsData?.data?.data?.contracts;
  pagination = contractsData && contractsData?.data?.data?.pagination;

  return (
    <>
      <div className="w-100 mb-4 mt-2">
        <CardComponent>
          <SearchFilterWrapper
            wrapperClass={style['wrapper-nil-export']}
            title="Filter Table"
            filterTitle=" "
            filterBlock={
              <Form>
                <div className="select-wrapper"></div>
              </Form>
            }
          />
          <div className="px-4">
            <OverviewTable
              contractDetails={<RestructuringDetails hasRestructuringBtn={true} />}
              data={_contracts_data}
              loading={contractsLoading}
              navigateToChampionBio={navigateToChampionBio}
              pagination={pagination}
              pageCount={pagination?.pageCount || 0}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              contractsToBeClose={true}
            />
          </div>
        </CardComponent>
      </div>
    </>
  );
};

export default RestructuringContracts;
