import React from 'react';
import './packages-table.styles.scss';

import TableComponent from '../../../components/Table/table.component';
import NumberFormatter from '../../../util/numberFormatter';
import format from 'date-fns/format';
import { Dropdown } from 'react-bootstrap';
import BikeIcon from '../../../assets/img/bike-icon.svg';
import KekeIcon from '../../../assets/img/keke-icon.svg';
import CarIcon from '../../../assets/img/car-icon.svg';
import { Icon, Popup } from 'semantic-ui-react';
import { AMORTIZATION_OBJ, IS_EDIT_FLAG } from '../../../util/constant';
import storageService from '../../../services/storageService';
import { useHistory } from 'react-router-dom';
import { getUserRole } from '../../../axios/method';
import TableLoader from '../../../components/Loader/TableLoader';
import NoData from '../../../components/NoData';

const PackagesTable = ({ packages, handleTemplateDelete, viewTemplate, handleSorting, loading }) => {
  const history = useHistory();

  const returnClassImage = (type) => {
    switch (type) {
      case 'motorcycle':
        return BikeIcon;
      case 'tricycle':
        return KekeIcon;
      case 'car':
        return CarIcon;
      default:
        return BikeIcon;
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      <i className="ellipsis horizontal icon">{children}</i>
    </span>
  ));

  const handleEdit = (item) => {
    storageService.set(
      AMORTIZATION_OBJ,
      JSON.stringify({
        ...item,
        grade: item.grade == null ? 'new_vehicle' : item.grade
      })
    );
    storageService.set(IS_EDIT_FLAG, true);
    history.push('/create-template');
  };

  return (
    <div className="pt-table__container">
      {loading && <TableLoader />}

      {!loading && (
        <>
          {packages.length === 0 ? (
            <NoData text="No price template was found!" />
          ) : (
            <TableComponent>
              <thead>
                <tr>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Vehicle Type
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                onClick={() => handleSorting('desc', 'vehicle_type_id')}
                                name="caret up"
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                onClick={() => handleSorting('asc', 'vehicle_type_id')}
                                name="caret down"
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between position-relative align-items-center">
                Vehicle Type
                <CustomFilter
                 setFilterOrder={setFilterOrder}
                 filterOrder={filterOrder}
                 filterFlag={filterFlag}
                 setFilterFlag={setFilterFlag}
                 handleFilter={handleFilter}
                 clearFilter={clearFilter}
                 setFilterFlag={() => setFilterFlag("asset")}
                >
                  <p className="filter-label mb-3 mt-4">Filter By Asset</p>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ui checkbox">
                        <input type="checkbox" name="3 Wheeler" />
                        <label className="filter-checkbox__label">
                          2 Wheeler
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="ui checkbox">
                        <input type="checkbox" name="3 Wheeler" />
                        <label className="filter-checkbox__label">
                          3 Wheeler
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="ui checkbox">
                        <input type="checkbox" name="4 Wheeler" />
                        <label className="filter-checkbox__label">
                          4 Wheeler
                        </label>
                      </div>
                    </div>
                  </div>
                </CustomFilter>
              </div> */}
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Manufacturer
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                name="caret up"
                                onClick={() => handleSorting('desc', 'manufacturer_id')}
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('asc', 'manufacturer_id')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Model
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                onClick={() => handleSorting('desc', 'model_id')}
                                name="caret up"
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                onClick={() => handleSorting('asc', 'model_id')}
                                name="caret down"
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Trim
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                onClick={() => handleSorting('desc', 'trim_id')}
                                name="caret up"
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('asc', 'trim_id')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Location
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                name="caret up"
                                onClick={() => handleSorting('desc', 'location_id')}
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('asc', 'location_id')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Total HP Amount
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                name="caret up"
                                onClick={() => handleSorting('desc', 'hp_amount')}
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('asc', 'hp_amount')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Duration (Months)
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                name="caret up"
                                onClick={() => handleSorting('desc', 'standard_duration')}
                                className="d-flex justify-content-center cursor"
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('asc', 'standard_duration')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-inline-flex align-items-center">
                      Date Created
                      <div className="d-inline-flex ml-1">
                        <div>
                          <Popup
                            basic
                            content="Descending order"
                            trigger={
                              <Icon
                                name="caret up"
                                className="d-flex justify-content-center cursor"
                                onClick={() => handleSorting('desc', 'createdAt')}
                              />
                            }
                          />

                          <Popup
                            basic
                            content="Ascending order"
                            trigger={
                              <Icon
                                name="caret down"
                                onClick={() => handleSorting('desc', 'createdAt')}
                                className="d-flex justify-content-center align-items-center cursor"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {packages &&
                  packages.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={returnClassImage(item?.vehicle_type?.slug)}
                            alt="vehicle icon"
                            style={{ maxWidth: '25px' }}
                          />

                          <div className="ml-3">
                            <p>{item?.vehicle_type ? item?.vehicle_type : 'N/A'}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>{item.manufacturer ? item.manufacturer : 'N/A'}</p>
                      </td>
                      <td>
                        <p>{item.model ? item.model : 'N/A'}</p>
                      </td>
                      <td>
                        <p>{item.trim ? item.trim : 'N/A'}</p>
                      </td>
                      <td>
                        <p>{item.location ? item.location : 'N/A'}</p>
                      </td>
                      <td>
                        <p>
                          {item.hp_amount
                            ? NumberFormatter.addCommaToNumber(NumberFormatter.formatNumber(item.hp_amount))
                            : 'N/A'}
                        </p>
                      </td>
                      <td>
                        <p>{item.standard_duration ? NumberFormatter.formatNumber(item.standard_duration) : 'N/A'}</p>
                      </td>
                      <td>
                        <p>{item.createdAt ? format(new Date(item.createdAt), 'DD/MMM/YYYY') : 'N/A'}</p>
                      </td>
                      <td>
                        <div className="position-relative">
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components">
                              {/* Custom toggle */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => viewTemplate(item)}>View Template</Dropdown.Item>
                              {getUserRole() === 'super-admin' && (
                                <Dropdown.Item onClick={() => handleEdit(item)}>Edit Template</Dropdown.Item>
                              )}
                              <Dropdown.Item onClick={() => handleTemplateDelete(item.id)}>
                                <span className="text-danger">Delete Template</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableComponent>
          )}
        </>
      )}
    </div>
  );
};

export default PackagesTable;
