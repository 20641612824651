import { REMITTANCE_ENDPOINT } from "../axios/endpoints";
import { axiosInstance, getUserToken } from "../axios/method";
import { GET } from "../util/constant";

const HEADERS = {
  "Content-Type": "application/json",
};

export const getRemittance = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: REMITTANCE_ENDPOINT,
    headers: HEADERS,
    params: params,
  });
};
