import React from "react";

import { Table } from "semantic-ui-react";
import styled from "styled-components";
// import { getChampionsCallHistory } from "../../services/collectionService";
// import { handleAxiosError } from "../../axios/handleAxiosError";
// import { toast } from "react-toastify";

const HeaderCell = styled(Table.HeaderCell)`
  background-color: #ffdd00 !important;
`;

const CallHistory = ({ championId }) => {
  // const [loader, setLoader] = useState(false);

  // const getCallHistory = async () => {
  //   setLoader(true);
  //   try {
  //     // let response = await getChampionsCallHistory(championId)
  //   } catch (error) {
  //     toast.error(handleAxiosError(error).message || "Something went wrong");
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  // useEffect(() => {
  //   getCallHistory();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h4>Call History</h4>

          <hr />
        </div>
      </div>

      <Table className="history-table">
        <Table.Header>
          <Table.Row>
            <HeaderCell>Called By(Agent name)</HeaderCell>
            <HeaderCell>Called By(Agent ID)</HeaderCell>
            <HeaderCell>Call Date</HeaderCell>
            <HeaderCell>Time</HeaderCell>
            <HeaderCell>Comment</HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body></Table.Body>
      </Table>
    </div>
  );
};

export default CallHistory;
