import React, { useState } from 'react';
import style from './index.module.scss';
import Search from './search';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';
import { useQueryContext } from '../../contexts/query-context';

export const SearchFilterWrapper = ({
  title,
  toggleBlock,
  filterBlock,
  wrapperClass,
  // hasDateFilter,
  searchPlaceholder,
  filterTitle,
  exportBlock
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const { setSearchQuery } = useQueryContext();

  const handleSearchSubmit = (searchValue) => {
    setSearchQuery(searchValue);
  };
  return (
    <div className={`${style['wrapper']} ${wrapperClass}`}>
      <div className={style['title-wrapper']}>
        <span className={style['titles']}>{title}</span>
      </div>

      <div className={style['filter-wrapper']}>
        {toggleBlock && <div className={style['toggle-block']}>{toggleBlock}</div>}

        {showSearch && (
          <Search
            handleSubmit={handleSearchSubmit}
            handleCloseSearch={() => setShowSearch(!showSearch)}
            placeholder={searchPlaceholder || 'Search Table'}
            className
          />
        )}
        <span className={style['titles']}>{filterTitle || 'Filter Table'}</span>
        {filterBlock && <div className={style['filter-block']}>{filterBlock}</div>}

        <div className={style['search-icon-wrapper']}>
          <button onClick={() => setShowSearch(!showSearch)}>
            <SearchIcon />
          </button>
        </div>
      </div>

      {exportBlock && <div className={style['export-as-csv-wrapper']}>{exportBlock}</div>}
    </div>
  );
};
