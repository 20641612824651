import React, { useEffect } from 'react';
import { useQueryClient } from 'services/apiClient';
import { COLLECTION_ENDPOINT } from 'axios/endpoints';
import style from './history.module.scss';
import tableStyle from 'components/Table/basic-table.module.scss';
import TableLoader from 'components/Loader/TableLoader';
import { BasicTable } from 'components/Table/basic-table';
import NumberFormatter from 'util/numberFormatter';
import format from 'date-fns/format';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import { AddPaymentModal } from 'components/Champion/add-transaction-modals/modal-input-transaction-referenceid';
import { FoundMatchingPayment } from 'components/Champion/add-transaction-modals/modal-found-matching-payment';
import { VoidTransactionModal } from 'components/Champion/void-transaction/void-transaction';
import classNames from 'classnames';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
// import RefreshIcon from "@mui/icons-material/Refresh";
import { AddTransactionSuccess } from 'components/Champion/add-transaction-modals/add-transaction-success';
import { ConfirmVoidModal } from 'components/Champion/void-transaction/confirm-void-modal';
import { ListChampionsModal } from 'components/Champion/transfer/display-champions-modal';
import { VerifyTransfer } from 'components/Champion/transfer/verify-transfer';
import { TransferTransactionSuccess } from 'components/Champion/transfer/transfer-success';
import { toast } from 'react-toastify';
import { RequestRefundModal } from 'components/Champion/refund/request-refund';
import { CheckDefaultStatusModal } from 'components/Champion/refund/refund-verification-modal';

const History = ({ championInfo, championId, contractId, tableColunms }) => {
  const {
    selectedTransaction,
    setSelectedTransaction,
    showPaymentHistoryModal,
    togglePaymentHistoryModal,
    foundMatchingPayment,
    setFoundMatchingPayment,
    history,
    setChampionHistory,
    setVoidTransactionObject,
    setTransferTochampion,
    selectedRowId,
    setSelectedRowId,
    setRefundTransaction
  } = useChampionPaymentHistory();

  const [championsURL, setChampionURL] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({
    pageCount: 1
  });

  const updateSelectedRow = (rowId) => {
    setSelectedRowId((id) => {
      if (id === rowId) return '';
      return rowId;
    });
  };

  useEffect(() => {
    setChampionURL(`${COLLECTION_ENDPOINT}/champion/${championId}?contract_id=${contractId}&page=${page}`);
  }, [championId, contractId, page]);

  const { isLoading } = useQueryClient(championsURL, {
    key: 'collection_record',
    method: 'get',
    enabled: !!championId && !!contractId,
    onSuccess: (historyData) => {
      // destructure historyData
      const {
        data: {
          data: { collections = [], pagination = {} }
        }
      } = historyData;
      setChampionHistory(collections);
      setPagination(pagination);
    }
  });

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  // toggle void modal
  const toggleVoidModal = () => {
    setVoidTransactionObject((prevState) => {
      return {
        ...prevState,
        showVoidModal: !prevState.showVoidModal
      };
    });
  };

  const toggleRefundAction = () => {
    setRefundTransaction((prevState) => {
      return {
        ...prevState,
        showRefundVerificationModal: !prevState.showRefundVerificationModal
      };
    });
  };

  const toggleDisplayChampionsModal = () => {
    if (selectedTransaction.transaction_type === 'out-bound-transfer')
      return toast('You can not make a duplicate transfer');
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        showChampionsModal: !prevState.showChampionsModal
      };
    });
  };

  return (
    <>
      <CheckDefaultStatusModal onHide={toggleRefundAction} />
      <RequestRefundModal />
      <TransferTransactionSuccess />
      <VerifyTransfer />
      <ListChampionsModal />
      <ConfirmVoidModal />
      <AddTransactionSuccess />
      <VoidTransactionModal />
      <FoundMatchingPayment
        onHide={() => {
          setFoundMatchingPayment(!foundMatchingPayment);
        }}
        show={foundMatchingPayment}
      />
      <AddPaymentModal
        championInfo={championInfo}
        onHide={() => togglePaymentHistoryModal(!showPaymentHistoryModal)}
        show={showPaymentHistoryModal}
      />
      <div className={style.header_wrapper}>
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Payment Transaction</h4>
            <div className="d-grid">
              {!selectedRowId && (
                <button
                  onClick={() => {
                    togglePaymentHistoryModal(!showPaymentHistoryModal);
                  }}
                  className={classNames(style.txt_btn, 'd-flex align-items-center')}>
                  <AddCircleIcon />
                  ADD NEW TRANSACTION
                </button>
              )}

              {selectedRowId && (
                <div className={style.collection_history_actions}>
                  <button
                    // toggle display champions modal modal
                    onClick={toggleDisplayChampionsModal}
                    className={classNames(style.txt_btn)}>
                    <SyncAltIcon /> Transfer
                  </button>

                  {/* <button
                    onClick={toggleRefundAction}
                    className={classNames(style.refund_btn)}>
                    <RefreshIcon /> Refund
                  </button> */}

                  <button
                    // toggle void modal
                    onClick={toggleVoidModal}
                    className={classNames(style.void_btn)}>
                    <HighlightOffIcon /> Void
                  </button>
                </div>
              )}
            </div>
          </div>
          <hr />
        </div>
      </div>
      {isLoading && <TableLoader />}
      {history.length > 0 && (
        <BasicTable
          pagination={{ setPage: setPage, page: pagination.pageCount }}
          hasPagination={true}
          colunm={tableColunms}>
          {history.map((item, idx) => (
            <tr
              key={idx}
              className={selectedRowId === item?.id ? tableStyle.selected_row : ''}
              onClick={() => {
                updateSelectedRow(item?.id);
                setSelectedTransaction(item);
              }}>
              <td>
                {item?.agent?.first_name} {item?.agent?.last_name}
              </td>
              <td>{formatNumber(item?.amount)}</td>
              <td>{item?.payment_method_name}</td>
              <td>{item?.vehicle_plate_number || 'N/A'}</td>
              <td>{format(item?.createdAt, 'DD/MM/YYYY')}</td>
              <td>{format(item?.createdAt, 'hh:mm a')}</td>
              <td>
                <Labels transaction_type={item?.transaction_type} />
              </td>
            </tr>
          ))}
        </BasicTable>
      )}
      {history.length === 0 && <div>No collection History</div>}
    </>
  );
};

export default History;

History.defaultProps = {
  tableColunms: [
    { title: 'Collection Agent' },
    { title: 'Amount Paid (₦)' },
    { title: 'Transaction Type' },
    { title: 'Plate Number' },
    { title: 'Date' },
    { title: 'Time' },
    { title: '' }
  ]
};

export const Labels = ({ transaction_type }) => {
  switch (transaction_type) {
    case 'in-bound-transfer':
      return <div className={style.label_in_bound}>MAX TRF IN</div>;
    case 'out-bound-transfer':
      return <div className={style.label_out_bound}>MAX TRF OUT</div>;
    case 'void':
      return <div className={style.label_void}>VOID</div>;
    case 'refunded':
      return <div className={style.label_refund}>REFUNDED</div>;
    case 'transfer':
      return <div className={style.label_trf}>MAX TRF</div>;
    default:
      return null;
  }
};
