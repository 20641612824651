import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import style from 'components/ContractToBeClose/style';
import { useQueryClient } from 'services/apiClient';
import { Card } from 'react-bootstrap';
import config from 'config/config';
import NumberFormatter from 'util/numberFormatter';
import PulseLoader from 'components/Loader/PulseLoader';
import NoData from 'components/NoData';

const ChampionDetails = ({ contract, open, onClose }) => {
  const api_prefix = `${config.third_party_url}/v1/champion/vnuban`;
  const vehicle_api_prefix = `${config.vehicle_base_url}/v1/vehicle`;

  const contractsLogURL = `${api_prefix}/${contract?.champion_id}`;
  const { data: virtualAccounts = {}, isLoading } = useQueryClient(contractsLogURL, {
    method: 'get',
    retry: 1,
    enabled: !!contract?.champion_id
  });

  const { data: { data: log = [] } = {} } = virtualAccounts;

  const vehicleDetailsURL = `${vehicle_api_prefix}/${contract?.vehicle_id}`;
  const { data: vehicleData = {} } = useQueryClient(vehicleDetailsURL, {
    method: 'get',
    retry: 1,
    enabled: !!contract?.vehicle_id
  });

  const { data: { data: vehicle } = {} } = vehicleData;

  const getAmount = () => {
    if (vehicle?.vehicle_type_id === 1) {
      return `N ${NumberFormatter?.formatNumber(contract?.expected_amount)}`;
    } else if (vehicle?.vehicle_type_id === 2) {
      return `N ${NumberFormatter?.formatNumber(40000)}`;
    }
    return `N ${NumberFormatter?.formatNumber(0)}`;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={[style.boxStyle, style.restructuredBoxStyle]}>
          <div className="d-flex">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6">
              <span className="f-600">Champion Details</span>
            </Typography>
            <div
              className="cancel-icon ml-auto cursor"
              onClick={onClose}>
              <span className="icon-text">x</span>
            </div>
          </div>
          <hr />

          {isLoading && (
            <div className="w-100 h-75 d-flex align-items-center justify-content-center initiated-contract-loader-wrapper">
              <PulseLoader
                width="50px"
                height="50px"
                bgColor="#777575"
              />
            </div>
          )}

          {!isLoading && log.length > 0
            ? log.map((item = {}, index) => {
                const { account_name, bank_name, vnuban } = item;
                return (
                  <Card
                    key={index}
                    className="contract-log mb-4">
                    <Card.Header className="contract-log-header">
                      vNuban {index + 1} {bank_name}
                    </Card.Header>
                    <Card.Body className="contract-log-body">
                      <div className="row mb-3">
                        <div className="d-flex flex-column col">
                          <span>Account Name</span>
                          <span className="f-600">{account_name}</span>
                        </div>

                        <div className="d-flex flex-column col">
                          <span>Account Number</span>
                          <span className="f-600">{vnuban}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex flex-column col">
                          <span>Down Payment Amount</span>
                          <span className="f-600">{getAmount()}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })
            : !isLoading && <NoData text="No account number found" />}
        </Box>
      </Modal>
    </>
  );
};
export default ChampionDetails;
