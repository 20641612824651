import React from "react";
import Modal from "../../components/Modal/Modal";
import "./ChampionContract.scss";
import format from "date-fns/format";
import { pauseReasons as pauseHPReasons } from "../../services/HpService";

const PauseHpReasonsModal = ({ showModal, pauseReasons, setPauseHpModal }) => {
  const [reasonArray, setReasonArray] = React.useState([]);

  const fetchReasons = async () => {
    const reasonsArray = await pauseHPReasons();
    setReasonArray(reasonsArray?.data?.data);
  };
  React.useEffect(() => {
    fetchReasons();
  }, []);

  const getReasonTitle = (id) => {
    const reasonFound = reasonArray?.find((reason) => reason.id === id);
    return reasonFound ? reasonFound?.title : "reason does not exist";
  };

  return (
    <div className="hp-reason__modal">
      <Modal show={showModal}>
        <div className="hp-reason-modal__reason">
          <p>Pause History</p>

          <div className="cancel-div" onClick={() => setPauseHpModal(false)}>
            x
          </div>
        </div>

        <div className="p-4 hp-reason_inner">
          {pauseReasons?.length === 0 && (
            <h3 className="text-center">Nothing to see here</h3>
          )}
          {pauseReasons &&
            pauseReasons?.map((reason, index) => (
              <div key={index} className="hp-reason-modal__reason-items">
                <div>
                  <p style={{ marginBottom: "2px", color: "#707070" }}>Date</p>

                  <p style={{ color: "#3E3E3E", fontWeight: "600" }}>
                    {reason.start_date
                      ? format(reason.start_date, "DD/MM/YYYY")
                      : ""}
                  </p>
                </div>

                <div>
                  <p style={{ marginBottom: "2px", color: "#707070" }}>
                    Duration
                  </p>

                  <p style={{ color: "#3E3E3E", fontWeight: "600" }}>
                    {reason.duration} Day(s)
                  </p>
                </div>

                <div>
                  <p style={{ marginBottom: "2px", color: "#707070" }}>
                    Reason
                  </p>

                  <p style={{ color: "#3E3E3E", fontWeight: "600" }}>
                    {reason && getReasonTitle(reason?.pause_reason_id)}
                  </p>
                </div>
              </div>
            ))}
        </div>

        <div className="hp-reason-modal__reason-footer">
          <p>Number of times paused</p>

          <h3>{pauseReasons?.length}</h3>
        </div>
      </Modal>
    </div>
  );
};

export default PauseHpReasonsModal;
