import React, { useState, useEffect } from "react";

import { Button, Form } from "semantic-ui-react";

// images import
import CancelImg from "../../assets/img/cancel.svg";

// css import
import "./Packages.scss";

//components import
import Modal from "../../components/Modal/Modal";
import useForm from "../../util/useForm";
import SideBar from "../../components/SideBar/SideBar";
import PulseLoader from "../../components/Loader/PulseLoader";

// image import
import ValidationUtil from "../../util/validation-util";
import NumberFormatter from "../../util/numberFormatter";
import {
  createPackage,
  deletePriceTemplate,
  getPackages,
  reCalcHpAmountService,
  updatePackageById,
} from "../../services/packageService";
import {
  getManufacturersService,
  getModelByManufacturerService,
  getTrimByModelService,
  getVehicleLocations,
  getVehicleTypes,
} from "../../services/vehicleService";

import swal from "sweetalert";
import PriceTemplateEmpty from "./empty-state/empty-state.component";
import PackagesNav from "./packages-nav/packages-nav.component";
import PackagesTable from "./packages-table/packages-table.component";
import { handleAxiosError } from "../../axios/handleAxiosError";
import { toast } from "react-toastify";
import TemplateDetails from "./template-details/template-details.component";
// import config from "../../config/config";
import PackagesFilterNav from "./packages-filter-nav/packages-filter-nav.component";

const Packages = () => {
  const [packages, setPackages] = useState([]);

  const [showAddPackageModal, setAddPackageModal] = useState(false);

  const [showSideBar, SetSideBar] = useState(false);

  const [filterFlag, setFilterFlag] = useState();

  // const [asset, setAsset] = useState("");
  const [dailyRemit, setDailyRemit] = useState("");
  const [hpAmount, setHpAmount] = useState("");
  // const [location, setLocation] = useState("");
  const [payoffMonth, setPayoffMonth] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [modelId, setModelId] = useState("");
  const [trimId, setTrimId] = useState("");
  const [insuranceDuration, setInsuranceDuration] = useState("");
  const [paymentDays, setPaymentDays] = useState("");
  const [locationId, setLocationId] = useState("");
  const [vehicleType, setVehicleType] = useState("");

  const [deleteLoader, setDeleteLoader] = useState(false);

  const [packageId, setPackageId] = useState("");

  const [isTableLoading, setTableLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hpAmountLoader, setHpAmountLoader] = useState(false);
  const [calcHpAmount, setCalcHpAmount] = useState();

  const [locationDropdown, setLocationDropdown] = useState([]);

  const [manufacturers, setManufacturers] = useState([]);

  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);

  const [dailyRemitErrors, setDailyRemitError] = useState("");
  const [standardDurationError, setStandardDurationError] = useState("");
  const [insuranceDurationError, setInsuranceDurationError] = useState("");
  const [paymentDaysError, setPaymentDaysError] = useState("");

  const [dailyRemitEditErrors, setDailyRemitEditError] = useState("");
  const [standardDurationEditError, setStandardDurationEditError] =
    useState("");
  const [insuranceDurationEditError, setInsuranceDurationEditError] =
    useState("");
  const [paymentDaysEditError, setPaymentDaysEditError] = useState("");
  const [isConfirmEditing, setConfirmEditing] = useState(false);

  const [editModelInfo, setEditModelInfo] = useState("");
  const [editTrimInfo, setEditTrimInfo] = useState("");

  const [hpAmountSideBarLoader, setHpAmountSideBarLoader] = useState(false);

  //creating price template
  const [cptManufacturer, setCptManufacturer] = useState("");
  const [cptModel, setCptModel] = useState("");
  const [cptTrim, setCptTrim] = useState("");
  const [cptLocation, setCptLocation] = useState("");
  const [cptStandardDuration, setCptStandardDuration] = useState("");
  const [cptInsuranceDuration, setCptInsuranceDuration] = useState("");
  const [cptDailyRemittance, setCptDailyRemittance] = useState("");
  const [cptPaymentDays, setCptPaymentDays] = useState("");

  // const [listOfLocation, setListOfLocation] = useState([]);
  // const [listOfManufacturers, setListOfManufacturers] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState();
  // const [selectedManufacturer, setSelectedManufacturer] = useState();

  const [isTemplateDetails, setIsTemplateDetails] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({});

  // const [selectedVehicleTypes, setSelectedVehicleTypes] = useState();
  // const [listOfVehicleTypes, setListOfVehicleTypes] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  // const [pageSize, setPageSize] = useState(config.pageSize);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPtToDelete, setSelectedPtToDelete] = useState();

  const [locationFilter, setLocationFilter] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState("");
  const [vehicleTypeFilter, setVehicleTypeFilter] = useState("");

  const [sortingObj, setSortingObj] = useState();

  useEffect(() => {
    setTableLoading(true);
    getListOfLocations();
    getManufacturers();
    setCurrentPage(1);
  }, []);

  const getListOfLocations = async () => {
    await getVehicleLocations()
      .then((res) => {
        setLocationDropdown(res?.data?.data);

        let locationArray = [];
        res.data.data.forEach((location) =>
          locationArray.push({
            name: location.name,
            value: location.slug,
          })
        );
        // setListOfLocation(
        //   locationArray.sort((a, b) => a.name.localeCompare(b.name))
        // );
      })
      .catch((err) => {
        setLocationDropdown([]);
        console.error(err);
      });
  };

  const triggerDeleteConfirmation = (id) => {
    setSelectedPtToDelete(id);
    setShowDeleteModal(true);
  };

  const deleteTemplate = async (id) => {
    setDeleteLoader(true);
    try {
      let response = await deletePriceTemplate(id);
      toast.success(response.data.message);
      getListOfPackages();
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(handleAxiosError(error).message);
    } finally {
      setDeleteLoader(false);
    }
  };

  // const editPriceTemplate = (obj) => {
  //   storageService.set(PRICE_TEMPLATE_OBJ, JSON.stringify(obj));
  //   history.push("/create-template");
  // };

  const getTemplateDetails = (details) => {
    setTemplateDetails(details);
    setIsTemplateDetails(true);
  };

  // const onPageChange = (e) => {
  //   const { selected } = e;
  //   setCurrentPage(selected + 1);
  // };

  // const onPageSizeChange = (e) => {
  //   const { value } = e.target;
  //   setCurrentPage(1);
  //   setPageSize(value);
  // };

  // const applyFilter = () => {
  //   getListOfPackages(selectedLocation, selectedVehicleTypes);
  // };

  // const clearFilter = () => {
  //   setSelectedLocation();
  //   setSelectedVehicleTypes();
  // };

  const returnSelectedOption = (array, id) => {
    let filteredData = array?.filter((obj) => obj.id === id);

    return filteredData[0] ? filteredData[0].name : "";
  };

  const getListOfPackages = async (location, vehicleType) => {
    setTableLoading(true);
    const params = new URLSearchParams();
    params.append("page", currentPage);
    params.append("limit", 100);
    if (location) {
      params.append("location_id", location);
    }

    if (vehicleType) {
      params.append("asset_type", vehicleType);
    }

    params.append("manufacturer_id", manufacturerFilter);
    params.append("location_id", locationFilter);
    params.append("vehicle_type_id", vehicleTypeFilter);

    if (sortingObj) {
      params.append("sort", sortingObj.sortinFlag);
      params.append("order", sortingObj.key);
    }

    await getPackages(params)
      .then((res) => {
        let data = res.data.data.data;
        setPackages(data);
      })
      .catch((err) => {
        toast.error(handleAxiosError(err)?.message || "Network Error");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const submitCreatePackage = async (data) => {
    try {
      let response = await createPackage(data);

      if (response.status === 201) {
        closeModal();
        swal(
          "Success",
          "You have successfully created a price template",
          "success"
        );
      }
    } catch (e) {
      console.error(e.message);
      // let err = `${e}`

      // if (err.includes('400')) {
      //     swal("Duplicate Template", "A pricing template with the same specifications already exists", "error");
      // } else {
      //     console.error(e)
      // }
    } finally {
      setLoader(false);
    }
  };

  // const openCreatePackageModal = () => {
  //   clearValue();
  //   setLoader(false);
  //   setAddPackageModal(true);
  // };

  const closeModal = () => {
    setAddPackageModal(false);
    setCptManufacturer("");
    setCptTrim("");
    setCptModel("");
    setCptLocation("");
    setCptStandardDuration("");
    setStandardDurationError("");
    setCptInsuranceDuration("");
    setInsuranceDurationError("");
    setDailyRemitError("");
    setCptDailyRemittance("");
    setCptPaymentDays("");
    setPaymentDaysError("");
  };

  // const editInformation = (data) => {
  //   setEditModelInfo(data.model.name);
  //   setEditTrimInfo(data.trim.name);
  //   setLoader(false);
  //   setPackageId(data.id);
  //   setDailyRemit(data.daily_remit);
  //   setHpAmount(data.hp_amount);
  //   setLocation(data.location_id);
  //   setPayoffMonth(data.standard_duration);
  //   setInsuranceDuration(data.insurance_duration);
  //   setWorkDays(data.work_days);
  //   setManufacturerId(data.manufacturer_id);
  //   setModelId(data.model_id);
  //   getModel(true, data.model_id);
  //   setLocationId(data.location.id);
  //   setTrimId(data.trim_id);
  //   getTrims(true, data.trim_id);
  //   setPaymentDays(data.work_days);
  //   setVehicleType(data.vehicle_type ? data.vehicle_type.name : "");

  //   SetSideBar(true);
  // };

  const closeSideBar = () => {
    SetSideBar(false);
    setStandardDurationEditError("");
    setDailyRemitEditError("");
    setInsuranceDurationEditError("");
  };

  const submit = () => {
    setLoader(true);

    const data = {
      // name: `${asset} ${location}`,
      location_id: cptLocation,
      manufacturer_id: cptManufacturer,
      model_id: cptModel,
      trim_id: cptTrim,
      insurance_duration: cptInsuranceDuration,
      daily_remit: cptDailyRemittance,
      standard_duration: cptStandardDuration,
      work_days: cptPaymentDays,
    };

    submitCreatePackage(data);
  };

  const confirmUpdate = () => {
    setConfirmEditing(true);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to update this information?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        updatePackage();
      } else {
        setConfirmEditing(false);
      }
    });
  };

  const updatePackage = async () => {
    setConfirmEditing(false);
    setLoader(true);

    const data = {
      location_id: `${locationId}`,
      daily_remit: `${dailyRemit}`,
      standard_duration: `${payoffMonth}`,
      work_days: `${paymentDays}`,
      manufacturer_id: `${manufacturerId}`,
      model_id: `${modelId}`,
      trim_id: `${trimId}`,
      insurance_duration: insuranceDuration,
    };

    await updatePackageById(packageId, data)
      .then(() => {
        getListOfPackages();
        closeSideBar();
        setTableLoading(true);
        swal("Successful", {
          icon: "success",
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        closeSideBar();
        setLoader(false);
      });
  };

  // const selectAssets = (e, data) => {
  //   e.persist();
  //   setAsset(data.value);
  // };

  // const selectLocation = (e) => {
  //   setLocation(e.target.value);
  // };

  const selectHpAmount = (e) => {
    setHpAmount(e.target.value);
  };

  const selectDailyRemit = (e) => {
    const { value } = e.target;
    setDailyRemitEditError(ValidationUtil.twoDecimalPointValidation(value));
    setDailyRemit(value);
    if (!ValidationUtil.twoDecimalPointValidation(value)) {
      reCalcHpAmount(value, payoffMonth, workDays);
    } else {
      setHpAmount("");
    }
  };

  const selectPayoffMonth = (e) => {
    const { value } = e.target;
    setStandardDurationEditError(
      ValidationUtil.twoDecimalPointValidation(value)
    );
    setPayoffMonth(value);
    if (!ValidationUtil.twoDecimalPointValidation(value)) {
      reCalcHpAmount(dailyRemit, value, workDays);
    } else {
      setHpAmount("");
    }
  };

  // const selectWorkDays = (e) => {
  //   setWorkDays(e.target.value);
  // };

  // const selectLocationId = (e) => {
  //   setLocationId(e.target.value);
  // };

  const selectManufacturer = (e) => {
    setManufacturerId(e.target.value);
    getModel(true, e.target.value);
  };

  const selectModel = (e) => {
    setModelId(e.target.value);
    getTrims(true, e.target.value);
  };

  const selectTrim = (e) => {
    setTrimId(e.target.value);
  };

  const selectInsuranceDuration = (e, data) => {
    e.persist();
    const { value } = e.target;
    setInsuranceDurationEditError(
      ValidationUtil.twoDecimalPointValidation(value)
    );
    setInsuranceDuration(value);
  };

  const selectPaymentDays = (e) => {
    const { value } = e.target;
    setPaymentDaysEditError(ValidationUtil.sevenDaysValidation(value));
    setPaymentDays(value);
  };

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  // Method to get list of manufacturers
  const getManufacturers = async () => {
    await getManufacturersService()
      .then((res) => {
        setManufacturers(res.data.data);

        let manufacturerArray = [];
        res.data.data.forEach((manufacturer) =>
          manufacturerArray.push({
            name: manufacturer.name,
            value: manufacturer.id,
          })
        );
        // setListOfManufacturers(manufacturerArray);
      })
      .catch((err) => {
        console.error(`Failed to get manufacturers`, err);
      });
  };

  // Method to get list of models based on a manufacturer
  // where retriggerFlag is use to re-trigger the service call if there is an onChange event on select manufacturer
  const getModel = async (retriggerFlag, values) => {
    const { manufacturer_id } = inputs;

    if (!retriggerFlag) {
      if (manufacturer_id) {
        await getModelByManufacturerService(manufacturer_id)
          .then((res) => {
            setModels(res.data.data);
          })
          .catch((err) => {
            console.error(`Failed to get models`, err);
          });
      } else {
        setModels([]);
      }
    } else {
      await getModelByManufacturerService(values)
        .then((res) => {
          setModels(res.data.data);
        })
        .catch((err) => {
          console.error(`Failed to get models`, err);
        });
    }
  };

  //method to get list of trims
  // where retriggerFlag is use to re-trigger the service call if there is an onChange event on select model
  const getTrims = async (retriggerFlag, values) => {
    const { model_id } = inputs;

    if (!retriggerFlag) {
      if (model_id) {
        await getTrimByModelService(model_id)
          .then((res) => {
            setTrims(res.data.data);
          })
          .catch((err) => {
            console.error(`Failed to get trims`, err);
          });
      } else {
        setTrims([]);
      }
    } else {
      await getTrimByModelService(values)
        .then((res) => {
          setTrims(res.data.data);
        })
        .catch((err) => {
          console.error(`Failed to get trims`, err);
        });
    }
  };

  const getHpAmount = async () => {
    if (!standardDurationError && !dailyRemitErrors && !paymentDaysError) {
      if (cptDailyRemittance && cptStandardDuration && cptPaymentDays) {
        setHpAmountLoader(true);
        const params = new URLSearchParams();
        params.append("daily_remit", cptDailyRemittance);
        params.append("payoff_months", cptStandardDuration);
        params.append("work_days", cptPaymentDays);
        await reCalcHpAmountService(params)
          .then((res) => {
            setCalcHpAmount(res.data.data);
          })
          .catch((err) => {
            console.error(`Failed to get hp amount`, err);
          })
          .finally(() => {
            setHpAmountLoader(false);
          });
      } else {
        setCalcHpAmount();
      }
    } else {
      setCalcHpAmount();
    }
  };

  const disablePriceTempCreation = () => {
    let checkScore = 0;

    if (
      cptLocation &&
      cptManufacturer &&
      cptModel &&
      cptInsuranceDuration &&
      cptDailyRemittance &&
      cptStandardDuration &&
      cptPaymentDays
    ) {
      checkScore = checkScore + 50;
    }

    if (
      !dailyRemitErrors &&
      !standardDurationError &&
      !insuranceDurationError &&
      !paymentDaysError
    ) {
      checkScore = checkScore + 50;
    }

    return checkScore;
  };

  const disablePriceEdit = () => {
    let checkScore = 0;

    if (
      manufacturerId &&
      modelId &&
      locationId &&
      dailyRemit &&
      payoffMonth &&
      insuranceDuration &&
      paymentDays
    ) {
      checkScore = checkScore + 50;
    }

    if (
      !insuranceDurationEditError &&
      !standardDurationEditError &&
      !dailyRemitEditErrors &&
      !paymentDaysEditError
    ) {
      checkScore = checkScore + 50;
    }

    return checkScore;
  };

  const reCalcHpAmount = async (
    dailyRemittance,
    standardDuration,
    workDays
  ) => {
    setHpAmountSideBarLoader(true);
    const params = new URLSearchParams();
    params.append("daily_remit", dailyRemittance);
    params.append("payoff_months", standardDuration);
    params.append("work_days", workDays);
    await reCalcHpAmountService(params)
      .then((res) => {
        setHpAmount(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setHpAmountSideBarLoader(false);
      });
  };

  const cptSelectManufacturer = (e) => {
    const { value } = e.target;
    setCptManufacturer(value);
  };

  const getModelCpt = async () => {
    setCptTrim("");
    if (cptManufacturer) {
      await getModelByManufacturerService(cptManufacturer)
        .then((res) => {
          setModels(res.data.data);
        })
        .catch((err) => {
          console.error(`Failed to get models`, err);
        });
    } else {
      setModels([]);
    }
  };

  const getTrimsCpt = async () => {
    if (cptModel !== "") {
      await getTrimByModelService(cptModel)
        .then((res) => {
          setTrims(res.data.data);
        })
        .catch((err) => {
          console.error(`Failed to get trims`, err);
        });
    } else {
      setTrims([]);
    }
  };

  const cptSelectModel = (e) => {
    setCptTrim("");
    const { value } = e.target;
    setCptModel(value);
  };

  const cptSelectTrim = (e) => {
    const { value } = e.target;
    setCptTrim(value);
  };

  const cptSelectLocation = (e) => {
    const { value } = e.target;
    setCptLocation(value);
  };

  const cptChangeStandardDuration = (e) => {
    const { value } = e.target;
    setStandardDurationError(ValidationUtil.twoDecimalPointValidation(value));
    setCptStandardDuration(value);
  };

  const cptChangeInsuranceDuration = (e) => {
    const { value } = e.target;
    setInsuranceDurationError(ValidationUtil.twoDecimalPointValidation(value));
    setCptInsuranceDuration(value);
  };

  const cptChangeDailyRemittance = (e) => {
    const { value } = e.target;
    setCptDailyRemittance(value);
    setDailyRemitError(ValidationUtil.twoDecimalPointValidation(value));
  };

  const cptChangePaymentDays = (e) => {
    const { value } = e.target;
    setCptPaymentDays(value);
    setPaymentDaysError(ValidationUtil.sevenDaysValidation(value));
  };

  const getVehicleTypesFn = async () => {
    try {
      const response = await getVehicleTypes();
      let typeArray = [];
      response.data.data.forEach((type) =>
        typeArray.push({
          name: type.name,
          value: type.id,
        })
      );

      // setListOfVehicleTypes(
      //   typeArray.sort((a, b) => a.name.localeCompare(b.name))
      // );
    } catch (error) {
      toast.error(handleAxiosError(error)?.message || "Network Error");
    }
  };

  const handleSorting = (sortinFlag, key) => {
    setSortingObj({
      sortinFlag,
      key,
    });
  };

  const { inputs } = useForm(submit, getHpAmount, closeSideBar);

  const handleFilter = () => {};

  useEffect(() => {
    getVehicleTypesFn();
    setVehicleType("");
    setWorkDays("");
    setLocationId("");
    setPackageId("");
    setEditTrimInfo("");
    setEditModelInfo("");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getModelCpt();
    // eslint-disable-next-line
  }, [cptManufacturer]);

  useEffect(() => {
    getTrimsCpt();
    // eslint-disable-next-line
  }, [cptModel]);

  useEffect(() => {
    getHpAmount();
    // eslint-disable-next-line
  }, [cptDailyRemittance, cptPaymentDays, cptStandardDuration]);

  useEffect(() => {
    getListOfPackages();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    getHpAmount();
    // eslint-disable-next-line
  }, [inputs]);

  useEffect(() => {
    getModel();
    getTrims();
    // eslint-disable-next-line
  }, [inputs]);

  useEffect(() => {
    disablePriceTempCreation();
    // eslint-disable-next-line
  }, [
    inputs,
    dailyRemitErrors,
    standardDurationError,
    insuranceDurationError,
    paymentDaysError,
  ]);

  useEffect(() => {
    getListOfPackages();
    // eslint-disable-next-line
  }, [manufacturerFilter, locationFilter, vehicleTypeFilter]);

  useEffect(() => {
    if (sortingObj) {
      getListOfPackages();
    }
    // eslint-disable-next-line
  }, [sortingObj]);

  return (
    // <div className="price-template custom-container">
    <div className="price-template">
      {/* sidebar */}
      <SideBar tempHide={isConfirmEditing} show={showSideBar}>
        <img
          src={CancelImg}
          alt="Cancel icon"
          onClick={closeSideBar}
          className="cursor"
        />
        <div className="sideBar-header">
          <h3>Update Information</h3>
          <p>Update price template information.</p>
        </div>
        <Form onSubmit={confirmUpdate}>
          <Form.Field className="disabled">
            <label>Vehicle Type</label>
            <input
              placeholder="Vehicle Type"
              name="vehicle_type_id"
              value={vehicleType ? vehicleType : "N/A"}
              onChange={selectManufacturer}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Manufacturer</label>
            <input
              placeholder="Manufacturer"
              name="manufacturer_id"
              value={returnSelectedOption(manufacturers, manufacturerId)}
              onChange={selectManufacturer}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Model</label>
            <input
              placeholder="Model"
              name="model_id"
              value={editModelInfo}
              onChange={selectModel}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Trim</label>
            <input
              placeholder="Trim"
              name="trim_id"
              value={editTrimInfo}
              onChange={selectTrim}
            />
          </Form.Field>

          <Form.Field className="disabled">
            <label>Location</label>
            <input
              placeholder="Location"
              name="location_id"
              value={returnSelectedOption(locationDropdown, locationId)}
              onChange={selectTrim}
            />
          </Form.Field>

          <Form.Field disabled>
            <div className="d-inline-flex">
              <label>HP Amount</label>
              {hpAmountSideBarLoader && (
                <PulseLoader width="14px" height="14px" bgColor="#777575" />
              )}
            </div>
            <input
              placeholder="Input HP Amount"
              name="hp_amount"
              value={formatNumber(hpAmount)}
              onChange={selectHpAmount}
            />
          </Form.Field>

          <Form.Field className={dailyRemitEditErrors ? "input-error" : ""}>
            <label>Daily Remittance</label>
            <input
              placeholder="Input Daily Remittance"
              name="daily_remit"
              value={dailyRemit}
              onChange={selectDailyRemit}
            />
            <p className="error-text">{dailyRemitEditErrors}</p>
          </Form.Field>

          <Form.Field
            className={standardDurationEditError ? "input-error" : ""}
          >
            <label>Standard Duration</label>
            <input
              placeholder="Input Standard Duration"
              name="standard_duration"
              value={payoffMonth}
              onChange={selectPayoffMonth}
            />

            <p className="error-text">{standardDurationEditError}</p>
          </Form.Field>

          <Form.Field
            className={insuranceDurationEditError ? "input-error" : ""}
          >
            <label>Insurance Duration</label>
            <input
              placeholder="Input Value"
              name="insurance_duration"
              value={insuranceDuration}
              onChange={selectInsuranceDuration}
            />

            <p className="error-text">{insuranceDurationEditError}</p>
          </Form.Field>

          <Form.Field className={paymentDaysEditError ? "input-error" : ""}>
            <label>Payment Days</label>
            <input
              placeholder="Payment Days"
              name="work_days"
              value={paymentDays}
              onChange={selectPaymentDays}
            />

            <p className="error-text">{paymentDaysEditError}</p>
          </Form.Field>

          <div className="text-right btn-div">
            <Button
              className="black-link-btn"
              type="button"
              onClick={closeSideBar}
            >
              Cancel
            </Button>
            <Button
              secondary
              type="submit"
              disabled={disablePriceEdit() !== 100}
            >
              <div className="d-inline-flex">
                UDPDATE INFORMATION
                {loader && (
                  <PulseLoader width="15px" height="15px" bgColor="#777575" />
                )}
              </div>
            </Button>
          </div>
        </Form>
      </SideBar>

      <div className="delete-pt__modal">
        <Modal show={showDeleteModal}>
          <div className="modal-header">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3>Delete Price Template</h3>
              </div>
            </div>
          </div>

          <div className="modal-body">
            <h4>Are you sure you want to delete the Price Template</h4>

            <div className="text-right btn-div">
              <Button
                className="black-link-btn"
                type="button"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                secondary
                type="submit"
                onClick={() => deleteTemplate(selectedPtToDelete)}
              >
                <div className="d-inline-flex">
                  Delete
                  {deleteLoader && (
                    <PulseLoader width="15px" height="15px" bgColor="#777575" />
                  )}
                </div>
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      {/* modal */}
      <Modal show={showAddPackageModal}>
        <div className="modal-header">
          <div className="row">
            <div className="col-md-12 mb-3">
              <h3>Create Price Template</h3>
            </div>

            <div className="col-md-12">
              <p>Input details to create price template</p>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <Form onSubmit={submit}>
            <Form.Group widths="equal">
              <div className="field">
                <label>Manufacturer</label>
                <select
                  name="manufacturer_id"
                  value={cptManufacturer}
                  onChange={cptSelectManufacturer}
                >
                  <option value="">Select Manufacturer</option>
                  {manufacturers.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="field">
                <label>Model</label>
                <select
                  name="model_id"
                  value={cptModel}
                  onChange={cptSelectModel}
                >
                  <option value="">Select Model</option>
                  {models.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="field">
                <label>Trim</label>

                <select name="trim_id" value={cptTrim} onChange={cptSelectTrim}>
                  <option value="">Select Trim</option>
                  {trims.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </Form.Group>

            <Form.Group widths="equal">
              <div className="field">
                <label>Location</label>

                <select
                  name="location_id"
                  value={cptLocation}
                  onChange={cptSelectLocation}
                >
                  <option value="">Select Location</option>
                  {locationDropdown.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-100 mr-lg-2">
                <Form.Input
                  className={standardDurationError ? "input-error" : ""}
                  type="text"
                  fluid
                  label="Standard Duration"
                  placeholder="Number of months"
                  name="standard_duration"
                  value={cptStandardDuration}
                  onChange={cptChangeStandardDuration}
                />

                <p className="error-text">{standardDurationError}</p>
              </div>

              <div className="w-100">
                <Form.Input
                  className={insuranceDurationError ? "input-error" : ""}
                  type="text"
                  fluid
                  label="Insurance  Duration"
                  placeholder="Number of months"
                  name="insurance_duration"
                  value={cptInsuranceDuration}
                  onChange={cptChangeInsuranceDuration}
                />

                <p className="error-text">{insuranceDurationError}</p>
              </div>
            </Form.Group>

            <Form.Group widths="equal">
              <div className="w-100">
                <Form.Input
                  className={dailyRemitErrors ? "input-error" : ""}
                  fluid
                  label="Daily Remittance"
                  placeholder="Daily Remittance"
                  name="daily_remit"
                  value={cptDailyRemittance}
                  onChange={cptChangeDailyRemittance}
                />

                <p className="error-text">{dailyRemitErrors}</p>
              </div>

              <div className="w-100 ml-lg-2">
                <Form.Input
                  className={paymentDaysError ? "input-error" : ""}
                  fluid
                  label="Payment Days"
                  placeholder="Payment Days"
                  name="work_days"
                  value={cptPaymentDays}
                  onChange={cptChangePaymentDays}
                />

                <p className="error-text">{paymentDaysError}</p>
              </div>

              <Form.Input
                style={{ opacity: "1" }}
                fluid
                label="HP Amount"
                loading={hpAmountLoader}
                disabled
                placeholder={
                  calcHpAmount ? formatNumber(calcHpAmount) : "HP Amount"
                }
                name="hp_amount"
                value={calcHpAmount ? formatNumber(calcHpAmount) : ""}
              />
            </Form.Group>

            <div className="text-right btn-div">
              <Button
                className="black-link-btn"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                secondary
                type="submit"
                disabled={disablePriceTempCreation() !== 100}
              >
                <div className="d-inline-flex">
                  Create Price Template
                  {loader && (
                    <PulseLoader width="15px" height="15px" bgColor="#777575" />
                  )}
                </div>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* empty state */}
      <>
        <div className="d-none col-md-5">
          <div className="w-100">
            <PriceTemplateEmpty />
          </div>
        </div>
      </>

      {/* price templates */}
      <div className="row">
        <div className="col-md-12">
          <PackagesNav />
        </div>

        {/* price template filter nav */}
        <div className="col-md-12">
          <PackagesFilterNav
            setManufacturerFilter={setManufacturerFilter}
            setLocationFilter={setLocationFilter}
            setVehicleTypeFilter={setVehicleTypeFilter}
          />
        </div>

        {/* price template layout */}
        <div className="table-layout__div">
          <PackagesTable
            packages={packages}
            loading={isTableLoading}
            handleTemplateDelete={triggerDeleteConfirmation}
            viewTemplate={getTemplateDetails}
            filterFlag={filterFlag}
            setFilterFlag={setFilterFlag}
            handleFilter={handleFilter}
            handleSorting={handleSorting}
          />
        </div>

        {/* <div className="price-template__layout">
                    <div className="price-template-layout__side">
                        <PackagesFilter
                            listOfManufacturers={listOfManufacturers}
                            selectedManufacturer={selectedManufacturer}
                            setSelectedManufacturer={setSelectedManufacturer}
                            listOfLocations={listOfLocation}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            applyFilter={applyFilter}
                            clearFilter={clearFilter}
                            listOfVehicleTypes={listOfVehicleTypes}
                            selectedVehicleTypes={selectedVehicleTypes}
                            setSelectedVehicleTypes={setSelectedVehicleTypes}
                        />
                    </div>

                    <div className="price-template-layout__content">
                        <div className="col-md-12">
                            <div className="row">
                                {
                                    packages.map((data, index) => <div key={data.id} className="col-md-3 mb-4 pb-3">
                                        <PackagesList
                                            packageDetails={data}
                                            deleteTemplate={deleteTemplate}
                                            getTemplateDetails={getTemplateDetails}
                                            editTemplate={editPriceTemplate}
                                        />
                                    </div>)
                                }
                            </div>

                            <CustomPagination
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                                pageCount={pagination?.pageCount}
                                pagination={pagination}
                                pageSize={pageSize}
                                onPageSizeChange={onPageSizeChange}
                            />
                        </div>
                    </div>
                </div> */}
      </div>

      <TemplateDetails
        isTemplateDetails={isTemplateDetails}
        setIsTemplateDetails={setIsTemplateDetails}
        templateDetails={templateDetails}
      />
    </div>
  );
};

export default Packages;
