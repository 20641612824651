import React, { useState } from 'react';
import TableLoader from '../../components/Loader/TableLoader';
import EmptyState from '../../assets/img/empty-img.svg';
import format from 'date-fns/format';
import CustomPagination from '../../components/CustomPagination/customPagination.component';
import UtilFn from '../../util/util-fn';
import TableComponent from '../../components/Table/table.component';
import { Dropdown } from 'react-bootstrap';
import AmortizationProfile from '../../components/AmortizationProfile/amortization-profile.component';
import AmortizationSchedule from '../../components/AmortizationSchedule/amortization-schedule.component';
import RepaymentSchedule from '../../components/RepaymentSchedule/repayment-schedule.component';
import NoData from '../../components/NoData';
import NumberFormatter from 'util/numberFormatter';
import { getHpStatusColorCode, getRetrivalStatusColorCode } from 'util/ui-helper';

export const formatNumber = (number) => {
  if (!number) return Number('0').toFixed(2);
  let formattedNumber = Number(number).toFixed(2);
  return NumberFormatter.addCommaToNumber(formattedNumber);
};

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    <i className="ellipsis horizontal icon">{children}</i>
  </span>
));

const OverviewTable = ({
  data,
  loading,
  navigateToChampionBio,
  pageCount,
  currentPage,
  onPageSizeChange,
  onPageChange,
  pageSize
}) => {
  const [amortizationProfile, setAmortizationProfile] = useState(false);
  const [amortizationSchedule, setAmortizationSchedule] = useState(false);
  const [championObj, setChampionObj] = useState({});
  const [championsAmortizationSchedule, setChampionsAmortizationSchedule] = useState([]);
  const [showRepaymentSchedule, setShowRepaymentSchedule] = useState(false);
  const [championTrancheInfo, setChampionTrancheInfo] = useState();

  const toAmortizationSchedule = () => {
    setAmortizationProfile(false);
    setAmortizationSchedule(true);
  };

  const toAmortizationProfile = () => {
    setAmortizationProfile(true);
    setAmortizationSchedule(false);
  };

  const triggerProfileModal = (championObj) => {
    setAmortizationProfile(true);
    setChampionObj(championObj);
  };

  const triggerRepaymentModal = (championObj) => {
    setShowRepaymentSchedule(true);
    setChampionObj(championObj);
  };
  const idRetrieve = (obj) => {
    const { id } = obj;
    return id || '';
  };

  return (
    <div className="overview-table__div">
      {loading && <TableLoader />}
      {!loading && data && data?.length === 0 && <NoData text="No champion was found!" />}
      {!loading && data && data.length > 0 && (
        <div className="w-100">
          <TableComponent>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>ID</th>
                <th>Phone Number</th>
                <th>TAO (₦)</th>
                <th>TDO</th>
                <th>LAR (₦)</th>
                <th>LDR</th>
                <th>Retrieval Status</th>
                <th>HP Status</th>
                <th>Location</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((data, index) => {
                  const {
                    max_champion_id,
                    name,
                    phone,
                    amount_overdue,
                    days_overdue,
                    last_amount_remitted,
                    last_day_remitted,
                    payment_status,
                    hp_status,
                    location_name
                  } = data;
                  return (
                    <tr
                      className={'cursor'}
                      key={`${max_champion_id}_${index}`}>
                      <td>{UtilFn.paginationNumbering(pageSize, currentPage, index)}</td>
                      <td onClick={() => navigateToChampionBio(data)}>{name || 'N/A'}</td>
                      <td>{max_champion_id || 'N/A'}</td>
                      <td>{phone || 'N/A'}</td>
                      <td>{formatNumber(amount_overdue)}</td>
                      <td>{days_overdue}</td>
                      <td>{formatNumber(last_amount_remitted)}</td>
                      <td>{last_day_remitted ? format(new Date(last_day_remitted), 'DD/MMM/YYYY') : 'N/A'}</td>
                      <td>
                        <div className={`${getRetrivalStatusColorCode(payment_status)}`}>{payment_status || 'N/A'}</div>
                      </td>
                      <td>
                        <div className={`${getHpStatusColorCode(hp_status)}`}>{hp_status?.name || 'N/A'}</div>
                      </td>
                      <td>{location_name?.toUpperCase() || 'N/A'}</td>

                      <td>
                        <div className="position-relative">
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"></Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="view-champion-profile"
                                onClick={() => navigateToChampionBio(data)}>
                                View Champion Profile
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="see-amortization-schedule"
                                onClick={() => triggerProfileModal(data)}>
                                See Amortization Schedule
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="track-payments"
                                onClick={() => triggerRepaymentModal(data)}>
                                Track Payments
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </TableComponent>

          <CustomPagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            pageCount={pageCount}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />

          {/* empty state */}
          {!loading && data.length === 0 && (
            <div
              className="text-center"
              style={{ marginTop: '5rem', marginBottom: '5rem' }}>
              <img
                src={EmptyState}
                alt="Empty state"
              />
              <h3>Nothing to see here</h3>
            </div>
          )}
        </div>
      )}
      <AmortizationProfile
        show={amortizationProfile}
        setAmortizationProfile={setAmortizationProfile}
        toAmortizationSchedule={toAmortizationSchedule}
        championObj={Object.keys(championObj).length === 0 ? (championObj.id = '') : championObj}
        navigateToChampionBio={navigateToChampionBio}
        setChampionsAmortizationSchedule={setChampionsAmortizationSchedule}
        championsAmortizationSchedule={championsAmortizationSchedule}
        setChampionTrancheInfo={setChampionTrancheInfo}
      />

      <AmortizationSchedule
        show={amortizationSchedule}
        setAmortizationSchedule={setAmortizationSchedule}
        toAmortizationProfile={toAmortizationProfile}
        championsAmortizationSchedule={championsAmortizationSchedule}
        championContractId={idRetrieve(championObj)}
      />

      <RepaymentSchedule
        show={showRepaymentSchedule}
        championObj={championObj}
        trancheInfo={championTrancheInfo}
        setRepaymentModal={setShowRepaymentSchedule}
      />
    </div>
  );
};

export default OverviewTable;
