import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../add-transaction.scss';
import NumberFormatter from 'util/numberFormatter';
import { ReactComponent as Helmet } from 'assets/img/helmet-yellow.svg';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import useAddPayment from './useAddPayment';

export const FoundMatchingPayment = ({ show, onHide }) => {
  const queryClient = useQueryClient();
  const { userDataForAddTransaction, addTransactionSuccess, setAddTransactionSuccess, setPaymentChannel } =
    useChampionPaymentHistory();
  const { mutate, isLoading, isSuccess, toggleSuccess, toggleFailure, isFailure, errorData } = useAddPayment();
  const { account_name, amount } = userDataForAddTransaction;

  useEffect(() => {
    return () => {
      toggleSuccess(false);
      toggleFailure(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setAddTransactionSuccess(!addTransactionSuccess);
      setPaymentChannel('');
      queryClient.invalidateQueries(['collection_record']);
      onHide();
      toggleSuccess(false);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (isFailure) {
      const { status, message } = errorData;
      if (status === 500 && message === 'Champion do not have Championschedule Today') {
        queryClient.invalidateQueries(['collection_record']);
        setAddTransactionSuccess(!addTransactionSuccess);
        setPaymentChannel('');
        onHide();
        toggleFailure(false);
      } else {
        onHide();
        setPaymentChannel('');
        toast(message);
        toggleFailure(false);
      }
    }
    // eslint-disable-next-line
  }, [isFailure, errorData]);

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      dialogClassName={'transaction-modal found-matching-payments'}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <h3 className="title">Add Payment Transaction</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="payment-channel-wrapper champion-details">
          <h4 className="title">Found matching payment</h4>
          <div className="champion-card">
            <div className="helmet-wrapper">
              <Helmet />
            </div>

            <span className="name">{account_name}</span>
            <span className="amount">N{formatNumber(amount)}</span>
          </div>
        </div>

        <button
          className="modal_form_btn"
          disabled={isLoading}
          onClick={() => {
            mutate();
          }}>
          {isLoading ? 'Adding Payment' : 'Add Payment'}
        </button>
      </Modal.Body>
    </Modal>
  );
};
