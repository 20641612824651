import React from 'react';
import { useMutationClient } from 'services/apiClient';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import { useQueryClient } from 'react-query';

const useAddPayment = () => {
  const queryClient = useQueryClient();
  const adminData = JSON.parse(localStorage.getItem('lams-data'));
  const { id: admin_id } = adminData;

  const {
    userDataForAddTransaction,
    championInfo = {},
    history,
    collectionOfficerId,
    paymentChannel: transaction_source
  } = useChampionPaymentHistory();

  const [isSuccess, toggleSuccess] = React.useState(false);
  const [isFailure, toggleFailure] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const { amount, bankPaymentAmount, narration, referenceId: transaction_source_id } = userDataForAddTransaction;
  const addPaymentEndpoint = 'collection/v1/collections/payment';

  const { mutate, isLoading } = useMutationClient(addPaymentEndpoint, {
    method: 'post',
    data: {
      champion_id: championInfo.id,
      account_id: championInfo.account_id,
      payment_method_id: transaction_source === 'bank' ? 9 : 3, // 3 means transfer
      narration: narration || 'narration', //
      amount:
        transaction_source === 'bank' || transaction_source === 'cash'
          ? parseFloat(bankPaymentAmount)
          : parseFloat(amount),
      collection_officer_id:
        transaction_source === 'bank' || transaction_source === 'cash'
          ? admin_id
          : history[0]?.collection_officer_id
          ? history[0]?.collection_officer_id
          : collectionOfficerId,
      transaction_source_id,
      transaction_source
    },

    onSuccess: () => {
      toggleSuccess(true);
      queryClient.invalidateQueries(['collection_record']);
    },

    onError: (error = {}) => {
      const { data: errorData = {} } = error.response;
      queryClient.invalidateQueries(['collection_record']);

      if (errorData.code === 500 && errorData.message.toLowerCase() === 'successfully collected payment') {
        toggleSuccess(true);
      } else {
        setErrorData(errorData);
        toggleFailure(true);
      }
    }
  });
  return {
    mutate,
    isLoading,
    isSuccess,
    isFailure,
    toggleFailure,
    toggleSuccess,
    errorData
  };
};

export default useAddPayment;
