import CheckCircle from "../../assets/img/check-circle.svg";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const styles = {
  typography: { fontWeight: 600 },
  cardDescription: { marginTop: "0.75rem", color: "#808080" },
  successCard: {
    padding: "2rem 0.7rem",
    width: 318,
    marginTop: "3rem",
    display: "flex",
    textAlign: "center",
    borderRadius: 5,
    border: "0.5px solid #EEEEEE",
    boxShadow: "none",
  },
  btn: {
    background: "#EEEEEE",
    color: "#3E3E3E",
    marginTop: "1.4375rem",
    fontWeight: 600,
    fontSize: "12px",
    padding: "0.75rem 1.5rem",
  },
  icon: { width: "30px", height: "30px", marginBottom: "1rem" },
};

 const Confirmation= ({ btnClick, btnName, description,title }) => {
  return (
    <Card sx={styles.successCard}>
      <CardContent>
        <img style={styles.icon} alt="check-mark-icon" src={CheckCircle} />
        <Typography sx={styles.typography} variant="h6" component="h6">
          {title}
        </Typography>
        <Typography variant="body2" sx={styles.cardDescription}>
          {description}
        </Typography>
        <Button onClick={btnClick} sx={styles.btn}>
          {btnName}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Confirmation;

