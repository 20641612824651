const config = {
  base_url: process.env.REACT_APP_SERVER_URL,
  public_url: process.env.PUBLIC_URL,
  collection_base_url: `${process.env.REACT_APP_SERVER_URL}/collection`,
  vehicle_base_url: `${process.env.REACT_APP_SERVER_URL}/vehicles`,
  locked_vehicles_url: `${process.env.REACT_APP_LOCKED_UNLOCKED_VEHICLES}`,
  third_party_url: `${process.env.REACT_APP_SERVER_URL}/thirdparty`,
  pageSize: 25,
  paystack_collection_officer_email: process.env.REACT_APP_PAYSTACK_COLLECTION_ID_EMAIL,
  woven_collection_officer_email: process.env.REACT_APP_WOVEN_COLLECTION_ID_EMAIL,
  monify_collection_officer_email: process.env.REACT_APP_MONIFY_COLLECTION_ID_EMAIL,
  bank_collection_officer_email: process.env.REACT_APP_BANK_COLLECTION_ID_EMAIL,
  contact_center_source: process.env.REACT_APP_CONTACT_CENTER_SOURCE,
  advance_payment_source: process.env.REACT_APP_ADVANCE_PAYMENT_SOURCE,
  lamda_vnuban_base: process.env.REACT_APP_LAMDA_GET_VNUBAN_DETAILS,
  lamda_vnuban_auth: process.env.REACT_APP_LAMDA_AUTH_TOKEN,
  hotjar_site_id: process.env.REACT_APP_HOTJAR_SITE_ID
};

export default config;
