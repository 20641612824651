import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import CardComponent from "../../../components/Card/card.component";
import {
  createPackage,
  getLocationService,
  updatePackageById,
} from "../../../services/packageService";
import storageService from "../../../services/storageService";
import {
  getManufacturersService,
  getModelByManufacturerService,
  getTrimByModelService,
  getVehicleTypesByClass,
} from "../../../services/vehicleService";
import {
  AMORTIZATION_OBJ,
  IS_EDIT_FLAG,
  PRICE_TEMPLATE_OBJ,
} from "../../../util/constant";
import "./packages-confirmation.styles.scss";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import { handleAxiosError } from "../../../axios/handleAxiosError";
import { getUserRole } from "../../../axios/method";

import { toast } from "react-toastify";
import NumberFormatter from "../../../util/numberFormatter";
import { calculateDurationInDays } from "../../../util/durationToDays";

const PackagesConfirmation = ({ history }) => {
  const [isCreated, setIsCreated] = useState(false);
  const [listOfLocation, setListOfLocation] = useState([]);
  const [listOfManufacturers, setListOfManufacturers] = useState([]);
  const [listOfModels, setListOfModels] = useState([]);
  const [listOfTrims, setListOfTrims] = useState([]);

  const priceTemplateDetails = JSON.parse(storageService.get(AMORTIZATION_OBJ));

  const priceTemplateId = priceTemplateDetails.id;

  const [listOfVehicleClass, setListOfVehicleClass] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const submitCreatePackage = async () => {
    setIsLoading(true);
    try {
      let response = await createPackage(priceTemplateDetails);

      if (response.status === 201) {
        setIsCreated(true);
      }
    } catch (e) {
      toast.error(handleAxiosError(e).message);
    } finally {
      setIsLoading(false);
    }
  };

  const submitEditedPackage = async () => {
    delete priceTemplateDetails.id;
    delete priceTemplateDetails.hp_amount;
    if (priceTemplateDetails.payment_frequency === "daily") {
      priceTemplateDetails.work_days = Number(priceTemplateDetails.work_days);
    }
    // delete priceTemplateDetails.work_days;
    delete priceTemplateDetails.createdAt;
    delete priceTemplateDetails.updatedAt;
    delete priceTemplateDetails.vehicle_type_id;
    delete priceTemplateDetails.deletedAt;
    delete priceTemplateDetails.location;
    delete priceTemplateDetails.slug;
    delete priceTemplateDetails.manufacturer;
    delete priceTemplateDetails.vehicle_type;
    delete priceTemplateDetails.model;
    delete priceTemplateDetails.trim;
    delete priceTemplateDetails.location_id;
    priceTemplateDetails.location_id = priceTemplateDetails.location_uuid;
    delete priceTemplateDetails.location_uuid;
    delete priceTemplateDetails.driver_licence_fee;

    setIsLoading(true);
    try {
      let response = await updatePackageById(
        priceTemplateId,
        priceTemplateDetails
      );
      if (response.status === 200) {
        setIsCreated(true);
      }
    } catch (e) {
      toast.error(handleAxiosError(e).message);
    } finally {
      setIsLoading(false);
    }
  };

  const getLocations = async (id) => {
    await getLocationService()
      .then((res) => {
        let locationArray = [];
        res.data.data.forEach((location) =>
          locationArray.push({ name: location.name, value: location.id })
        );

        setListOfLocation(locationArray);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getManufacturers = async () => {
    await getManufacturersService()
      .then((res) => {
        let manufacturerArray = [];
        res.data.data.forEach((manufacturer) =>
          manufacturerArray.push({
            name: manufacturer.name,
            value: manufacturer.id,
          })
        );
        setListOfManufacturers(manufacturerArray);
      })
      .catch((err) => {
        console.error(`Failed to get manufacturers`, err);
      });
  };

  const getModel = async (id) => {
    await getModelByManufacturerService(id)
      .then((res) => {
        let modelArray = [];
        res.data.data.forEach((model) =>
          modelArray.push({
            name: model.name,
            value: model.id,
          })
        );
        setListOfModels(
          modelArray.sort((a, b) => a.name.localeCompare(b.name))
        );
      })
      .catch((err) => {
        console.error(`Failed to get models`, err);
      });
  };

  const getTrims = async (id) => {
    await getTrimByModelService(id)
      .then((res) => {
        let trimArray = [];
        res.data.data.forEach((trim) =>
          trimArray.push({
            name: trim.name,
            value: trim.id,
          })
        );
        setListOfTrims(trimArray.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.error(handleAxiosError(err).message);
      });
  };

  const onCancel = () => {
    storageService.remove(AMORTIZATION_OBJ);
    storageService.remove(PRICE_TEMPLATE_OBJ);
    storageService.remove("activePtStep");
    history.push("/pricetemplates");
  };

  const getLocationName = (id) => {
    let result = listOfLocation.filter((location) => location.value === id);
    return result[0]?.name;
  };

  const getManufacturerName = (id) => {
    let result = listOfManufacturers.filter(
      (manufacturer) => manufacturer.value === id
    );
    return result[0]?.name;
  };

  const getModelName = (id) => {
    let result = listOfModels.filter((model) => model.value === id);
    return result[0]?.name;
  };

  const getTrimName = (id) => {
    let result = listOfTrims.filter((trim) => trim.value === id);
    return result[0]?.name;
  };

  const getVehicleCLass = async () => {
    try {
      const response = await getVehicleTypesByClass();
      setListOfVehicleClass(response.data.data);
    } catch (error) {
      console.error(handleAxiosError(error).message);
    }
  };

  const returnVehicleClass = (obj) => {
    const result = listOfVehicleClass.filter(
      (res) => res.id === obj.asset_class
    );
    return result[0]?.name;
  };

  const getMultiply = (value) => {
    switch (value) {
      case "daily":
        if (priceTemplateDetails.days_of_the_week.length === 5) {
          return "FIVE_DAYS_IN_A_WEEK";
        } else {
          return "SIX_DAYS_IN_A_WEEK";
        }
      case "weekly":
        return "ONCE_A_WEEK";
      case "quarterly":
        return "ONCE_A_QUATER";
      case "yearly":
        return "ONCE_A_YEAR";
      default:
        return "FIVE_DAYS_IN_A_WEEK";
    }
  };

  useEffect(() => {
    getLocations();
    getManufacturers();
    getVehicleCLass();
    getModel(priceTemplateDetails.manufacturer_id);
    getTrims(priceTemplateDetails.model_id);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="packages-confirmation">
      {!isCreated && (
        <div className="row w-100  justify-content-center mt-5">
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-md-12">
                <p
                  style={{ color: "#E88E15" }}
                  className="cursor"
                  onClick={() => history.push("/create-template")}
                >
                  <i className="arrow left icon font-weight-bold "></i> Back
                </p>
              </div>
            </div>
            <CardComponent withBoxShadow>
              <div className="p-4">
                <div className="vehicle-details__div p-5">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="d-flex mb-2">
                        <h3 className="font-weight-bold">
                          {storageService.get(IS_EDIT_FLAG)
                            ? priceTemplateDetails.vehicle_type
                            : returnVehicleClass(priceTemplateDetails)}{" "}
                          -{" "}
                          {getManufacturerName(
                            priceTemplateDetails.manufacturer_id
                          )}
                        </h3>

                        {/* <div className="change__div ml-2 cursor" onClick={() => history.push('/create-template')}>change</div> */}
                      </div>

                      <div className="d-flex mt-2">
                        <p className="mb-0 mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">Model</span>{" "}
                          {getModelName(priceTemplateDetails.model_id)}
                        </p>
                        <p className="mb-0  mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">Trim</span>{" "}
                          {getTrimName(priceTemplateDetails.trim_id)}
                        </p>
                        <p
                          className="mb-0 text-capitalize"
                          style={{ fontSize: "13px" }}
                        >
                          <span className="text-secondary">Frequency</span>{" "}
                          {priceTemplateDetails.payment_frequency}
                        </p>
                      </div>

                      <div className="d-flex mt-2">
                        <p className="mb-0 mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">Location</span>{" "}
                          {getLocationName(priceTemplateDetails.location_id)}
                        </p>
                        {getUserRole() === "super-admin" && (
                          <p className="mb-0" style={{ fontSize: "13px" }}>
                            <span className="text-secondary">
                              Total Onboarding Cost
                            </span>{" "}
                            {NumberFormatter.formatNumber(
                              priceTemplateDetails.tranches[0]
                                .total_onboarding_cost
                            )}
                          </p>
                        )}
                      </div>

                      <div className="d-flex mt-1">
                        <p className="mb-0 mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">
                            Total number of repayments
                          </span>{" "}
                          {calculateDurationInDays(
                            priceTemplateDetails.standard_duration,
                            getMultiply(priceTemplateDetails.payment_frequency)
                          )}
                        </p>
                        <p className="mb-0 mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">
                            Total duration in Months
                          </span>{" "}
                          {NumberFormatter.formatNumber(
                            priceTemplateDetails.standard_duration
                          )}
                        </p>
                      </div>

                      <div className="d-flex mt-1">
                        <p className="mb-0 mr-3" style={{ fontSize: "13px" }}>
                          <span className="text-secondary">HMO Duration</span>{" "}
                          {NumberFormatter.formatNumber(
                            priceTemplateDetails.insurance_duration
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2 text-right my-auto">
                      <h2 className="font-weight-bold mb-2">
                        0{priceTemplateDetails.tranches.length}
                      </h2>
                      <p>Tranche(s)</p>
                    </div>
                  </div>
                </div>

                <div className="row mt-4 pt-2 tranche-overflow__div">
                  {priceTemplateDetails.tranches.map((tranche, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                      <CardComponent>
                        <div
                          className="px-3 py-2 d-flex align-items-center"
                          style={{ minHeight: "35px" }}
                        >
                          {index === 0 && (
                            <p className="text-secondary mb-0">
                              Active Tranche
                            </p>
                          )}
                        </div>
                        <hr style={{ margin: 0 }} />

                        <div className="px-3 pb-3 pt-3">
                          <div className="row pb-3">
                            <div className="col-md-7">
                              <p className="mb-0 font-weight-bold">
                                Tranche {index + 1}
                              </p>
                              <p className="mb-0 text-secondary">Remittance</p>
                            </div>
                            <div className="col-md-5 my-auto">
                              <h2 className="font-weight-bold">
                                {NumberFormatter.formatNumber(
                                  tranche?.daily_remit
                                )}
                              </h2>
                            </div>
                          </div>

                          <hr style={{ margin: 0 }} />

                          <div className="row">
                            <div className="col-md-12">
                              <p className="mb-0 pt-2">
                                <span className="text-secondary mr-3">
                                  Number of repayments
                                </span>{" "}
                                <span>
                                  {calculateDurationInDays(
                                    tranche.duration,
                                    getMultiply(
                                      priceTemplateDetails.payment_frequency
                                    )
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="col-md-12">
                              <p className="mb-0 pt-1">
                                <span className="text-secondary mr-3">
                                  Duration (Months)
                                </span>{" "}
                                <span>{tranche.duration}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardComponent>
                    </div>
                  ))}
                </div>
              </div>
            </CardComponent>

            <div className="mb-5">
              <div className="row mt-5 justify-content-center">
                <div className="col-md-4">
                  <Button
                    disabled={isLoading}
                    onClick={
                      storageService.get("isEdit")
                        ? submitEditedPackage
                        : submitCreatePackage
                    }
                    secondary
                    className="w-100 text-uppercase py-3"
                    style={{
                      background: "#FCDD18",
                      color: "#3E3E3E",
                      fontSize: "11.5px",
                    }}
                  >
                    {isLoading ? (
                      <div
                        style={{ width: "11px", height: "11px" }}
                        className="spinner-grow"
                        role="status"
                      ></div>
                    ) : storageService.get("isEdit") ? (
                      "Edit Template"
                    ) : (
                      "Save Template"
                    )}
                  </Button>
                </div>

                <div className="col-md-4">
                  <Button
                    onClick={onCancel}
                    secondary
                    className="w-100 text-uppercase py-3"
                    style={{
                      background: "#E9E9E9",
                      color: "#3E3E3E",
                      fontSize: "11.5px",
                    }}
                  >
                    cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isCreated && (
        <div className="row w-100 justify-content-center">
          <div className="col-md-3 mt-5 pt-3">
            <CardComponent withBoxShadow>
              <div className="p-5 text-center">
                <Icon
                  style={{ fontSize: "20px", color: "#FCDD18" }}
                  className="icon cursor"
                  name="check circle"
                />

                <p className="font-weight-bold mb-2 mt-4">
                  Template {storageService.get("isEdit") ? "edited" : "created"}{" "}
                  successfully
                </p>

                <p className="text-secondary">
                  Your template has been{" "}
                  {storageService.get("isEdit") ? "edited" : "created"} and is
                  now active
                </p>

                <p
                  onClick={() => history.push("/pricetemplates")}
                  className="cursor"
                  style={{ color: "#E88E15", textDecoration: "underline" }}
                >
                  See Templates
                </p>
              </div>
            </CardComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(PackagesConfirmation);
