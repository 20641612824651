import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./SubNav.scss";
const SubNav = (props) => {
    const [stickyClass, setStickyClass] = useState('relative');
    let history = useHistory();
    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => {
          window.removeEventListener('scroll', stickNavbar);
        };
      }, []);
      const stickNavbar = () => {
        if (window !== undefined) {
          let windowHeight = window.scrollY;
          setStickyClass(windowHeight > 65 ? 'fixed':'')
        }
      };
      const goBack = () =>{
        history.goBack()
      }
  return (
    <div className={`subnav-wrapper ${stickyClass}`}>
      <ArrowBackIcon onClick={goBack} role="button"/>
      <p className="subnav-title"> {props.title}</p>
    </div>
  );
};

export default SubNav;
