import React, { useState } from 'react';
import SUbNav from 'components/SubNav/SubNav';
import './ContractsToBeClosed.scss';
import { Tab, Nav } from 'react-bootstrap';
import CompletedContracts from './CompletedContracts';
import RestructuringContracts from './RestructuringContracts';
import AwaitingCheckout from './AwaitingCheckout';
import ContractProvider from 'components/Contracts/contracts';

const ContractsRestructuring = () => {
  const [currentEventKey, setCurrentEventKey] = useState('restructuring');

  return (
    <>
      <ContractProvider>
        <SUbNav title={'Contracts'} />
        <div className="custom-container">
          <div className="w-100">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={currentEventKey}>
              <div className="d-flex">
                <Nav
                  variant="pills"
                  className="w-100 bg-white d-flex tab-wrapper">
                  <Nav.Item className="closed-contracts">
                    <Nav.Link
                      onClick={() => setCurrentEventKey('restructuring')}
                      eventKey="restructuring">
                      Restructuring
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="closed-contracts">
                    <Nav.Link
                      onClick={() => setCurrentEventKey('completed')}
                      eventKey="completed">
                      Completed
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="closed-contracts">
                    <Nav.Link
                      onClick={() => setCurrentEventKey('awaiting-checkout')}
                      eventKey="awaiting-checkout">
                      Awaiting Checkout
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey={'restructuring'}>
                  <RestructuringContracts eventKey={'restructuring'} />
                </Tab.Pane>

                <Tab.Pane eventKey={'completed'}>
                  <CompletedContracts eventKey={'completed'} />
                </Tab.Pane>

                <Tab.Pane eventKey={'awaiting-checkout'}>
                  <AwaitingCheckout eventKey={'awaiting-checkout'} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </ContractProvider>
    </>
  );
};

export default ContractsRestructuring;
