import React, { useEffect, useState } from 'react';
import ActiveTrancheCard from '../../components/ActiveTrancheCard/ActiveTrancheCard';
import AddOnServiceCard from '../../components/AddOnServiceCard/AddOnServiceCard';
import ContractInfo from '../../components/ContractInfoCard/ContractInfo';
import { getChampionAddonServices } from '../../services/addonServices';
import { handleAxiosError } from '../../axios/handleAxiosError';
import './ActiveContract.scss';
import Spinner from 'components/Spinner/Spinner';

const ActiveContract = ({ contractInfo, trancheInfo, setShowAmortSchedule, contractIsLoading, loadingTrancheInfo }) => {
  const [addOns, setAddOns] = useState([]);
  const [loadingAddOns, setLoadingAddOns] = useState(false);
  const id = contractInfo?.champion_id;

  const fetchAddonServicies = async () => {
    setLoadingAddOns(true);
    try {
      const response = await getChampionAddonServices(id);
      setAddOns(response?.data?.data);
      setLoadingAddOns(false);
    } catch (error) {
      console.error(handleAxiosError(error)?.message || 'Something went wrong');
      setLoadingAddOns(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAddonServicies();
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="contract-info-container">
      {loadingTrancheInfo && (
        <div className="loading-states">
          <Spinner />
          <h3>Loading tranche Information</h3>
        </div>
      )}
      {!loadingTrancheInfo && (
        <div className="contract-info-top-inner-container">
          {trancheInfo &&
            trancheInfo.map((singleTranche, index) => (
              <div
                className="tranche-info"
                key={index}>
                <ActiveTrancheCard
                  trancheInfo={singleTranche}
                  setShowAmortSchedule={setShowAmortSchedule}
                  index={index}
                />
              </div>
            ))}
          {loadingAddOns && (
            <div className="loading-states">
              <Spinner />
              <h3>Loading Add Ons</h3>
            </div>
          )}
          {!loadingAddOns && (
            <div className="add-on-info">
              <AddOnServiceCard addOns={addOns} />
            </div>
          )}
        </div>
      )}
      {contractIsLoading && (
        <div className="loading-states">
          <Spinner />
          <h3>Loading Contract Information</h3>
        </div>
      )}
      {!contractIsLoading && (
        <div className="contract-info-bottom-inner-container">
          <ContractInfo contractInfo={contractInfo} />
        </div>
      )}
    </div>
  );
};

export default ActiveContract;
