import { ADDON_SERVICES_ENDPOINT } from "../axios/endpoints";
import { axiosInstance, getUserToken } from "../axios/method";
import { GET } from "../util/constant";

const HEADERS = {
    "Content-Type": "application/json",
  };

  // function to get a specific champion's contract
export const getChampionAddonServices = (championId) => {
    HEADERS["Authorization"] = "Bearer " + getUserToken();  
    return axiosInstance({
      method: GET,
      url: `${ADDON_SERVICES_ENDPOINT}/${championId}/addons`,   
      headers: HEADERS,
    });
  };