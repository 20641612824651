/**
 * This module contains all endpoints servicing the LAMS web
 */

import config from '../config/config';

export const VEHICLE_ENDPOINT = `${config.vehicle_base_url}/v1/vehicle`;
export const PACKAGE_ENPOINT = `${config.collection_base_url}/v1/packages`;
export const LOCATION_ENDPOINT = `${config.collection_base_url}/v1/locations`;
export const MANUFACTURER_ENPOINT = `${config.vehicle_base_url}/v1/manufacturer`;
export const MODEL_ENDPOINT = `${config.vehicle_base_url}/v1/model`;
export const TRIM_ENDPOINT = `${config.vehicle_base_url}/v1/trim`;
export const VEHICLE_LOCATION_ENDPOINT = `${config.vehicle_base_url}/v1/location`;
export const VEHICLE_TYPE_ENDPOINT = `${config.vehicle_base_url}/v1/vehicle-type`;
export const VEHICLE_CLASS_ENDPOINT = `${config.vehicle_base_url}/v1/asset-class`;

export const CALL_ENDPOINT = `${config.base_url}/calls/calls`;

export const REMITTANCE_ENDPOINT = `${config.collection_base_url}/v1/collections/remittance`;

export const CONTRACT_ENDPOINT = `${config.collection_base_url}/v1/contracts`;

export const ADDON_SERVICES_ENDPOINT = `${config.collection_base_url}/v1/champions`;

export const STATUSES_ENDPOINT = `${config.collection_base_url}/v1/statuses`;

export const COLLECTION_ENDPOINT = `${config.collection_base_url}/v1/collections`;

export const HPV_ENDPOINT = `${config.collection_base_url}/v1/vehicles`;

export const AMORTIZATION_SCHEDULE_ENDPOINT = `${config.base_url}/collection/v1/repayments/amortization-schedule`;
export const TRANCHE_INFO = `${config.base_url}/collection/v1/repayments/tranche-info/champions`;
export const REPAYMENT_SCHEDULE_ENDPOINT = `${config.base_url}/collection/v1/repayments/repayment-schedule`;

export const CONTRACT_PDF_ENDPOINT = `${config.base_url}/documents/v1/champion/generate-contract-pdf`;

export const CHAMPIONS_ENDPOINT = `${config.base_url}/champions/champion`;
export const CREDIT_SCORE_ENDPOINT = `${config.base_url}/creditscoring/api/credit_score/champion`;
export const GENERATE_VNUBAN_ENDPOINT = `${config.third_party_url}/v1/champion/virtual/account`;
export const FETCH_VNUBAN_ENDPOINT = `${config.third_party_url}/v1/champion/vnuban/details?phone=`;

export const GENERATE_WOVEN_ACCOUNT = `${config.base_url}/thirdparty/v1/champion/virtual/account`;
export const GENERATE_MONIFY_ACCOUNT = `${config.base_url}/thirdparty/v1/champion/monnify/virtual/account`;
export const GET_CHAMPIONS_ACCOUNT = `${config.lamda_vnuban_base}/vNuban/getBankDetails?champion_id=`;
