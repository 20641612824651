import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import { useQueryClient } from 'services/apiClient';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as HelmetIcon } from 'assets/img/helmet-yellow.svg';
import Spinner from 'components/Spinner/Spinner';
import ValidationUtil from 'util/validation-util';

export const ListChampionsModal = () => {
  const searchInput = React.useRef(null);
  const { transaferToChampion, setTransferTochampion } = useChampionPaymentHistory();
  const { showChampionsModal, newChampion = {} } = transaferToChampion;
  const [allContracts, setAllContracts] = React.useState([]);
  const [championsURL, setChampionsURL] = React.useState(``);

  const closeModal = () => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        showChampionsModal: !prevState.showChampionsModal
      };
    });
  };

  // const championsURL = `/collection/v1/contracts?page=1&limit=25`;
  const { isLoading } = useQueryClient(championsURL, {
    method: 'get',
    onSuccess: (data) => {
      const {
        data: {
          data: { contracts }
        }
      } = data;
      setAllContracts(contracts);
    }
  });

  const handleUpdateChampion = (newChampion) => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        newChampion
      };
    });
  };

  const verifyTransfer = () => {
    setTransferTochampion((prevState) => {
      return {
        ...prevState,
        showConfirmTransfer: !prevState.showConfirmTransfer
      };
    });
    closeModal();
  };

  function handleDebounce(e) {
    setTimeout(() => {
      setChampionsURL(`collection/v1/contracts?all=true&search=${searchInput.current.value}`);
    }, 1000);
  }

  return (
    <Modal
      onHide={closeModal}
      show={showChampionsModal}
      dialogClassName="transaction-modal confirmation-modal modal-relative"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <h3 className={'title'}>Transfer to champion</h3>
        <CancelIcon onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <form className="form">
          <label>Search Champion</label>
          <input
            name="referenceId"
            ref={searchInput}
            onChange={handleDebounce}
            placeholder="Enter champion name or ID"
          />
        </form>

        <p className="txt_divider">Select Champion</p>

        <div className="modal_champions_list">
          {isLoading && (
            <div className="spinner_wrapper">
              <Spinner />
            </div>
          )}
          {!isLoading &&
            allContracts.map((data, idx) => (
              <button
                onClick={() => handleUpdateChampion(data)}
                className="select_champion_btn"
                key={idx}>
                <SelectChampion
                  data={{
                    ...data,
                    championIsSelected: data.id === newChampion?.id
                  }}
                />
              </button>
            ))}
        </div>

        <div className="footer-button-wrapper">
          <button
            disabled={ValidationUtil.isObjectEmpty(newChampion)}
            onClick={verifyTransfer}
            className="modal_form_btn">
            {ValidationUtil.isObjectEmpty(newChampion) ? 'Continue' : 'Transfer To Champion'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const SelectChampion = ({ data }) => {
  const { name, max_champion_id, championIsSelected } = data;
  return (
    <div className="select_champion_wrapper">
      <div className="detail">
        <div className="icon_wrapper">
          <HelmetIcon />
        </div>
        <div className="details_wrapper">
          <span className="name">{name}</span>
          <span className="max_champion_id">{max_champion_id}</span>
        </div>
      </div>
      <div className="select_indicator">{championIsSelected && <div className="indicator">&nbsp;</div>}</div>
    </div>
  );
};
