import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { formatNumber } from 'pages/Overview/OverviewTable';
import TableLoader from 'components/Loader/TableLoader';
import { useQueryClient } from 'services/apiClient';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import 'components/Champion/add-transaction.scss';
import '../Contracts/style.scss';
import { useContracts } from 'contexts/contracts';
import format from 'date-fns/format';
import { COLLECTION_ENDPOINT } from 'axios/endpoints';
import { BasicTable } from 'components/Table/basic-table';

const TransactionsModal = ({ restructureDetails, tableColunms }) => {
  const { restructuringContractData = {}, contractData = {}, toggleViewTransactions } = useContracts();
  const { openTransactionDetailModal } = restructuringContractData;
  const { contract = {} } = contractData;
  const [page, setPage] = useState(1);
  const [fetchHistoryURL, setFetchHistoryURL] = React.useState('');
  const [history, setChampionHistory] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageCount: 1
  });

  const { amount_overdue = {} } = restructureDetails;

  useEffect(() => {
    setFetchHistoryURL(
      `${COLLECTION_ENDPOINT}/champion/${contract.champion_id}?contract_id=${
        contract.contract_id ?? contract.id
      }&page=${page}`
    );
  }, [contract.champion_id, contract.contract_id, contract.id, page]);

  const { isLoading } = useQueryClient(fetchHistoryURL, {
    key: 'collection_record',
    retry: true,
    method: 'get',
    enabled: !!contract.champion_id && (!!contract.contract_id || !!contract.id),
    onSuccess: (historyData) => {
      // destructure historyData
      const {
        data: {
          data: { collections = [], pagination = {} }
        }
      } = historyData;
      setChampionHistory(collections);
      setPagination(pagination);
    }
  });

  console.log('contract', contract);

  const detailArray = [
    {
      label: 'Amount Remitted',
      value: `N ${formatNumber(Number(amount_overdue?.amount_remitted))}`
    },
    {
      label: 'Amount Outstanding',
      value: `N ${formatNumber(Number(amount_overdue?.amount_outstanding))}`
    },
    {
      label: 'Days Remaining',
      value: amount_overdue?.days_remaining
    }
  ];

  return (
    <>
      <Modal
        toggleViewTransactions={toggleViewTransactions}
        show={openTransactionDetailModal}
        dialogClassName="transaction-modal transaction-list-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <h3 className={'title'}>Track Payments</h3>
          <CancelIcon
            color="#E2E2E2"
            onClick={toggleViewTransactions}
          />
        </Modal.Header>

        <Modal.Body className=" borde">
          <div className="">
            <div className="full-transactions-parent">
              <div className="transactions-overview">
                {detailArray.map((item, idx) => (
                  <div
                    key={idx}
                    className="d-flex flex-column align-items-center transactions-overview-inner">
                    <span className="label">{item.label}</span>
                    <span className="value">{item.value}</span>
                  </div>
                ))}
              </div>
              <div className="transactions-table-wrapper">
                {isLoading && <TableLoader />}
                {history.length > 0 && (
                  <BasicTable
                    pagination={{ setPage: setPage, page: pagination.pageCount }}
                    hasPagination={true}
                    colunm={tableColunms}>
                    {history.map((item, idx) => (
                      <tr
                        key={idx}
                        // className={selectedRowId === item?.id ? tableStyle.selected_row : ''}
                        onClick={() => {}}>
                        <td>{idx + 1}</td>
                        <td>{format(item?.createdAt, 'DD/MM/YYYY')}</td>
                        <td>{formatNumber(item?.amount)}</td>
                      </tr>
                    ))}
                  </BasicTable>
                )}
                {history.length === 0 && <div>No collection History</div>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionsModal;

TransactionsModal.defaultProps = {
  tableColunms: [{ title: 'Period' }, { title: 'Date' }, { title: 'Repayment' }]
};
