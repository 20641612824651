import React from "react";
import { withRouter } from "react-router";
import { Button } from "semantic-ui-react";
import CardComponent from "../../../components/Card/card.component";

import './empty-state.styles.scss'

const PriceTemplateEmpty = ({
    history
}) => {
  return (
    <div className="price-temp-empty__div">
      <CardComponent>
        <div className="p-5 text-center">
          <div
            style={{ height: "60px", width: "60px", background: "#E3E3E3" }}
            className="rounded-circle mx-auto"
          ></div>

          <p style={{color: '#3E3E3E'}} className="font-weight-bold text-center mt-3">You currently do not have any pricing template</p>

          <p className="text-center">Pricing templates you create will show up here. Currently you do not have any saved pricing template. Begin creating one</p>

          <Button onClick={() => history.push('/create-template')} secondary className="mt-4" style={{background: '#FCDD18', color: '#3E3E3E'}}>create new template</Button>
        </div>
      </CardComponent>
    </div>
  );
};

export default withRouter(PriceTemplateEmpty);
