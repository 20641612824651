import React from "react";
import {
  Grid,
  Icon,
  Input,
  Menu,
  Image,
  Button,
  Header,
  Form,
} from "semantic-ui-react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import SingleSelector from "../../components/SingleSelector";

const RemittanceNav = ({
  day,
  clear,
  setShowModal,
  showCancelButton,
  optionState,
  showModal,
  onDayChange,
  handleLocationChange,
  handleNameSearch,
}) => {
  const MenuCloseIcon = styled(Icon)`
    margin: 0 0 0 0.35em !important;
    font-size: 1.25em !important;
  `;

  function renderSubItems() {
    if (day) {
      return (
        <Menu compact>
          <Menu.Item color="black">
            <span>{day}</span>
            <MenuCloseIcon
              // name="cancel"
              // size="small"
              onClick={clear}
              // color="black"
            >
              <Image
                src={`${process.env.PUBLIC_URL}/closeBig.svg`}
                style={{
                  width: "0.6rem",
                  height: "1.3rem",
                  marginLeft: "0.4rem",
                }}
              />
            </MenuCloseIcon>
          </Menu.Item>
        </Menu>
      );
    }
  }
  return (
    <Grid className="w-100 overview-pagination-div">
      <Grid.Row style={{ backgroundColor: "#f8fbff" }}>
        <Grid.Column>
          <div
            style={
              {
                // margin: "1rem"
              }
            }
          >
            <Menu
              borderless
              // onStick={() => setSticked({ sticked: true })}
              // onUnstick={() => setSticked({ sticked: false })}
              style={{ border: "none", boxShadow: "none" }}
            >
              <Menu.Item position="right">
                <Input
                  placeholder="Search"
                  style={{
                    width: "26rem",
                  }}
                  onChange={(e) => {
                    handleNameSearch(e);
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Button
                  circular
                  icon="calendar"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                {showCancelButton && <>{renderSubItems()}</>}
              </Menu.Item>
              {/* <Menu.Item>
                                    <p>Location:</p>
                                </Menu.Item> */}
              <Menu.Item>
                <div>
                  <Form>
                    <div className="field no-margin__field">
                      <select
                        name="status"
                        onChange={(event) => handleLocationChange(event)}
                      >
                        <option value="">Filter By Location</option>
                        {optionState &&
                          optionState.map((value, index) => (
                            <option key={index} value={value.slug}>
                              {value.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Form>
                </div>
              </Menu.Item>
            </Menu>
          </div>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="md"
            centered
          >
            <Modal.Header
              className="d-flex align-items-center"
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
              closeButton
            >
              <Header
                icon="browser"
                content="Select range"
                style={{ marginBottom: "0" }}
              />
            </Modal.Header>
            <Modal.Body>
              <SingleSelector onDayChange={onDayChange} />
            </Modal.Body>
          </Modal>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RemittanceNav;
