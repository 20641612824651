import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import style from './style';
import { useContracts } from 'contexts/contracts';
import { formatNumber } from 'pages/Overview/OverviewTable';
import { useQueryClient } from 'services/apiClient';
import { Accordion, Card } from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import format from 'date-fns/format';

const RestructureLog = ({ contract }) => {
  const api_prefix = 'collection/v1/contracts/';
  const { toggleRestructuringLog, restructuringContractData } = useContracts();
  const { openViewLogModal } = restructuringContractData;

  const contractsLogURL = `${api_prefix}overdue-repayment-schedules/${contract.contract_id ?? contract?.id}`;
  const { data: contractLogData = {} } = useQueryClient(contractsLogURL, {
    method: 'get',
    retry: 1,
    enabled: !!contract?.contract_id || !!contract?.id
  });

  const { data: { data: log = [] } = {} } = contractLogData;

  return (
    <Modal
      open={openViewLogModal}
      onClose={toggleRestructuringLog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={[style.boxStyle, style.restructuredBoxStyle]}>
        <div className="d-flex">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6">
            <span className="f-600">Contract Restructure Log</span>
          </Typography>
          <div
            className="cancel-icon ml-auto cursor"
            onClick={toggleRestructuringLog}>
            <span className="icon-text">x</span>
          </div>
        </div>
        <hr />

        <Accordion className="contract-log-accordion">
          {log.map((item = {}, index) => {
            const { createdAt, start_date, daily_remit, contract = {}, tranche = {}, user = {} } = item;
            return (
              <Card
                key={index}
                className="contract-log mb-4">
                <Card.Header className="contract-log-header">Restructured {index + 1}</Card.Header>
                <Card.Body className="contract-log-body">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span>Date Restructured</span>
                      <span className="f-600">{format(createdAt, 'DD/MMM/YYYY')}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span>Start Date</span>
                      <span className="f-600">{format(start_date, 'DD/MMM/YYYY')}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span>New Remittance</span>
                      <span className="f-600">N{formatNumber(Number(daily_remit))}</span>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="contract-log-footer">
                  <Accordion.Toggle
                    variant="link"
                    eventKey={index.toString()}>
                    <div className="d-flex align-items-center">
                      <span>See more details</span>
                      <span className="ml-2 bg-white see-more-icon">
                        <KeyboardArrowDownIcon />
                      </span>{' '}
                    </div>
                  </Accordion.Toggle>
                </Card.Footer>

                <Accordion.Collapse
                  className="contract-log-accordion"
                  eventKey={index.toString()}>
                  <Card.Body className="contract-log-body">
                    <div className="bg-white restructure-log-container">
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Tranche Start Date</span>
                        <span className="f-600">{format(tranche.start_date, 'DD/MMM/YYYY')}</span>
                      </div>
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Tranche End Date</span>
                        <span className="f-600">{format(tranche.end_date, 'DD/MMM/YYYY')}</span>
                      </div>
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Vehicle Check-in Date</span>
                        <span className="f-600">{item.value || 'N/a'}</span>
                      </div>
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Vehicle Check-out Date</span>
                        <span className="f-600">{item.value || 'N/a'}</span>
                      </div>
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Old Remittance</span>
                        <span className="f-600">N{formatNumber(Number(contract.daily_remit))}</span>
                      </div>
                      <div
                        className="d-flex flex-column"
                        key={index}>
                        <span className="mb-1">Tranche Duration (Days)</span>
                        <span className="f-600">{tranche.duration || 'N/a'}</span>
                      </div>
                    </div>
                    <div className="restructure-log-container mt-3 bg-white d-flex">
                      <div></div>
                      <div className=" d-flex flex-column">
                        <span>Restructured By</span>
                        <span>
                          {user.last_name} {user.first_name}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </Box>
    </Modal>
  );
};
export default RestructureLog;
