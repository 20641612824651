import { CONTRACT_ENDPOINT } from '../axios/endpoints';
import { axiosInstance, getUserToken } from '../axios/method';
import { GET, PUT } from '../util/constant';

const HEADERS = {
  'Content-Type': 'application/json'
};

// function to pause contracts
export const pauseContract = (championId, data) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: PUT,
    url: `${CONTRACT_ENDPOINT}/champions/${championId}/pause`,
    headers: HEADERS,
    data
  });
};

// function to complete contracts
export const completeContract = (championId) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/champions/${championId}/completed`,
    headers: HEADERS
  });
};

// function to unpause contracts
export const unPauseContract = (championId, data) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    data,
    method: PUT,
    url: `${CONTRACT_ENDPOINT}/champions/${championId}/unpause`,
    headers: HEADERS
  });
};

// function to get pause reasons
export const pauseReasons = () => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/pause-reasons`,
    headers: HEADERS
  });
};

export const pauseMultipleContractService = (data, params) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: PUT,
    url: `${CONTRACT_ENDPOINT}/pause`,
    headers: HEADERS,
    data,
    params
  });
};

//function to unpause multiple contracts
export const unpauseMultipleContractService = (params) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: PUT,
    url: `${CONTRACT_ENDPOINT}/unpause`,
    headers: HEADERS,
    params
  });
};
