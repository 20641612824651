import NumberFormatter from "./numberFormatter";

// eslint-disable-next-line
export const UPPER_CASE_REGEX = /.*[A-Z].*/;
// eslint-disable-next-line
export const ALLOWED_SPCL_CHAR_REGEX = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
// eslint-disable-next-line
export const DIGIT = /.*\d.*/;

const ValidationUtil = {
  twoDecimalPointValidation(input) {
    if (!/^\d+(\.\d{1,2})?$/.test(input)) {
      return "Must be a positive number with maximum of two decimal places";
    }

    if (!/^[1-9][0-9]*(\.\d{1,2})?$/.test(input)) {
      return "Please start with a non zero value";
    }

    return "";
  },

  sevenDaysValidation(input) {
    if (!/^([0-1]|[1-7])$/.test(input)) {
      return "Must be a positive number between 1 and 7";
    }

    if (!/^[1-9][0-9]*$/.test(input)) {
      return "Must be a positive number between 1 and 7";
    }

    return "";
  },

  nonZeroValue(input) {
    if (!/^[1-9][0-9]*$/.test(input)) {
      return "Must be a positive number";
    }
    return "";
  },

  emailValidation(input) {
    // eslint-disable-next-line
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input)) {
      return "Enter a valid email address";
    }

    return "";
  },

  isObjectEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  },

  ltvValidation(input) {
    let formattedNumber = input.substring(0, input.length - 1);
    formattedNumber = Number(NumberFormatter.removeComma(formattedNumber));
    if (formattedNumber <= 0 || formattedNumber > 100) {
      return "Must be a positive number between 1 and 100";
    }

    return "";
  },
};

export default ValidationUtil;
