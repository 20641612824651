import React from "react";
import styled from "styled-components";
import NoDataImage from "../assets/img/empty-img.svg";

const NoDataContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    margin-top: 20px;
  }
`;

const NoData = ({ text }) => {
  return (
    <NoDataContainer>
      <img src={NoDataImage} alt="no-data-svg" />
      <h4>{text}</h4>
    </NoDataContainer>
  );
};

export default NoData;
