import React from "react";
import { Modal } from "react-bootstrap";
// css import
import "./ConfirmationAlert.scss";

const ConfirmationAlert = ({ show, handleClose, children }) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <div className="confirmation-alert">
          <div className="swal-icon swal-icon--warning">
            <span className="swal-icon--warning__body">
              <span className="swal-icon--warning__dot"></span>
            </span>
          </div>

          <div className="row pb-5">
            <div className="col-md-8 mx-auto text-center">{children}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationAlert;
