import React from 'react';
import TableComponent from '../Table/table.component';

// css import 
import './skeleton.scss'

const tableArray = [1, 2, 3, 4, 5, 6, 7]

const TableLoader = () => {
    return (
        <div className="w-100">
            <TableComponent>
                <thead>
                    <tr>
                        <th style={{ width: '20px' }}>#</th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                        <th><div className="table-items table-item-loader"></div></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableArray && tableArray.map((data, index) =>
                            <tr className="cursor" key={index}>
                                <td><div className="table-items table-item-loader" style={{ width: '20px' }}></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                                <td><div className="table-items table-item-loader"></div></td>
                            </tr>
                        )
                    }
                </tbody>
            </TableComponent>
        </div>
    )
};

export default TableLoader