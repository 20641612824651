const style = {
  boxStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 430,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 2
  },

  restructuredBoxStyle: {
    width: 448,
    height: 579,
    overflowY: 'auto'
  },

  btn: {
    width: '200px',
    padding: '1rem',
    borderRadius: '3px',
    margin: '1rem',
    fontWeight: '600',
    textTransform: 'capital',
    whitespace: 'no-wrap'
  },

  moreInfoBtn: {
    background: '#FCDD18',
    color: '#000',
    '&:hover': {
      background: '#3E3E3E',
      color: '#fff',
      transition: '0.5s'
    }
  },

  completeContractBtn: {
    background: '#EEEEEE',
    color: '#000',
    '&:hover': {
      background: '#EBF3F5',
      transition: '0.5s'
    }
  },

  textArea: {
    width: 400,
    borderRadius: '5px',
    border: '1px solid #E4E4E4',
    outline: 'none',
    fontSize: '12px',
    padding: '10px'
  },

  widthMid: {
    width: 286
  },

  successErrorModalWrapper: {
    textAlign: 'center',
    padding: '1rem',
    display: 'block'
  },

  tooltip: {
    color: '#BFBFBF'
  }
};
export default style;
