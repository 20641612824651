import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';

// import { ApolloClient } from 'apollo-client';
// import { ApolloProvider } from 'react-apollo';
// import { createHttpLink } from 'apollo-link-http';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';

// import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';

import { setCurrentUser, decodeToken } from './reducers/auth';
import { rootEpic, rootReducer } from './reducers';

import 'semantic-ui-css/semantic.min.css';

import 'react-toastify/dist/ReactToastify.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)));

epicMiddleware.run(rootEpic);

const token = localStorage.getItem('lams-token') || '';

if (token && token !== 'undefined') {
  const user = decodeToken(token);
  store.dispatch(setCurrentUser(user));
}

// const httpLink = createHttpLink({
//   uri: '/graphql'
// });

// const authLink = setContext((_, { headers }) => {
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : null
//     }
//   };
// });

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache()
// });

// let basename = '';
// if (process.env.NODE_ENV !== 'developmemnt') {
//   basename = '/beta';
// }

ReactDOM.render(
  <Provider store={store}>
    {/* <ApolloProvider client={client}> */}
      <Router>
        <Route component={App} />
      </Router>
    {/* </ApolloProvider> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
