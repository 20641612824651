import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Form } from 'semantic-ui-react';
import { Icon, Input } from 'semantic-ui-react';

import { ALLOWED_SPCL_CHAR_REGEX, DIGIT, UPPER_CASE_REGEX } from '../../util/validation-util';
import './ResetPassword.scss';
import CheckCircle from '../../assets/img/check-circle.svg';
import Axios from 'axios';
import config from '../../config/config';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import CirlceCheckmarkError from '../../assets/img/cirlce-checkmark-success.svg';
import MaxSubHeader from '../../components/MaxSubHeader/MaxSubHeader';
import Confirmation from '../Confirmation/Confirmation';

const styles = {
  typography: { fontWeight: 600, fontSize: 20 },
  card: {
    padding: '2rem 0.7rem',
    width: 372,
    borderRadius: 5,
    border: '0.5px solid #EEEEEE',
    boxShadow: 'none'
  },
  resetBtn: {
    marginTop: '1.4375rem',
    fontWeight: 600,
    background: '#FCDD18',
    color: '#3E3E3E',
    width: '100%'
  },
  label: { color: '#3E3E3E' },
  icon: { width: '15px', height: '15px' }
};

const ResetPassword = () => {
  const history = useHistory();
  const [tempPassword, setTempPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setLoader] = useState('');
  const [showConfirmation, setConfirmation] = useState(true);
  const location = useLocation();

  const [error, setError] = useState({
    tempPassword: '',
    password: '',
    confirmPassword: ''
  });

  const [passwordHintIndicator, setpasswordHintIndicator] = useState({
    hasCapitalLetter: false,
    hasSpecialCharacter: false,
    hasContainDigit: false,
    hasMaxLength: false
  });

  useEffect(
    () => () => {
      localStorage.clear('email');
    },
    []
  );
  useEffect(() => {
    setConfirmation(location.search === '?reset=success');
  }, [location]);

  const navigateToLoginPage = () => {
    history.push('/');
  };

  const validateInput = (e) => {
    const { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: '' };
      switch (name) {
        case 'tempPassword':
          if (!value) {
            stateObj[name] = 'Please enter Temporary password.';
          }
          break;

        case 'password':
          if (!value) {
            stateObj[name] = 'Please enter Password.';
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj['confirmPassword'] = 'Password and Confirm Password does not match.';
          } else {
            stateObj['confirmPassword'] = confirmPassword ? '' : error.confirmPassword;
          }
          break;
        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Please enter Confirm Password.';
          } else if (password && value !== password) {
            stateObj[name] = 'Password and Confirm Password does not match.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const passwordValueChange = (password) => {
    setPassword(password);
    validateHintMessage(password);
  };

  const validateHintMessage = (password) => {
    setpasswordHintIndicator((prev) => {
      if (password.length > 7) {
        prev.hasMaxLength = true;
      } else {
        prev.hasMaxLength = false;
      }
      if (ALLOWED_SPCL_CHAR_REGEX.test(password)) {
        prev.hasSpecialCharacter = true;
      } else {
        prev.hasSpecialCharacter = false;
      }
      if (DIGIT.test(password)) {
        prev.hasContainDigit = true;
      } else {
        prev.hasContainDigit = false;
      }
      if (UPPER_CASE_REGEX.test(password)) {
        prev.hasCapitalLetter = true;
      } else {
        prev.hasCapitalLetter = false;
      }
      return prev;
    });
  };

  const submitPasswordReset = async (e) => {
    if (Object.values(passwordHintIndicator).every(Boolean) && tempPassword.length) {
      setLoader(true);
      e.preventDefault();
      const data = {
        username: localStorage.getItem('email'),
        new_password: password,
        temporary_password: tempPassword
      };
      try {
        const response = await Axios.post(`${config.base_url}/accounts/auth/reset-password`, data);
        if (response.status === 200) {
          setLoader(false);
          history.push({
            pathname: 'reset-password',
            search: '?reset=success'
          });
        }
      } catch (err) {
        const errorResposnse = err?.response?.data;
        if (errorResposnse.message === 'Invalid Temporary Password') {
          setError((prev) => {
            return {
              ...prev,
              tempPassword: 'Temporary Password has been expired or Incorrect'
            };
          });
          return;
        }
        swal({
          title: 'Something went wrong!',
          text: err?.message ?? 'Please try again',
          icon: 'error'
        });
      } finally {
        setLoader(false);
      }
    } else {
      validateAllInput();
    }
  };
  const validateAllInput = () => {
    const allInput = [
      { name: 'tempPassword', value: tempPassword },
      { name: 'confirmPassword', value: confirmPassword },
      { name: 'password', value: password }
    ];
    allInput.forEach((input) => {
      validateInput({ target: input });
    });
  };

  return (
    <>
      {showConfirmation ? (
        <>
          <div className="reset-password-success-wrapper">
            <MaxSubHeader></MaxSubHeader>
            <Confirmation
              btnClick={navigateToLoginPage}
              title="Password reset was successful"
              description="You can now login into your account with your email and new password"
              btnName="LOGIN TO MY ACCOUNT"></Confirmation>
            <div className="bg-overlay" />
          </div>
        </>
      ) : (
        <>
          <div className="flex-center">
            <MaxSubHeader />
          </div>
          <div className="reset-password-container flex-center">
            <Card
              className="reset-password__card"
              sx={styles.card}>
              <CardContent>
                <Typography
                  sx={styles.typography}
                  variant="h6"
                  component="h6">
                  Setup your
                  <br />
                  account password
                </Typography>
                <Form
                  className="reset-password-form"
                  loading={isLoading}>
                  <div className="password-input">
                    <CustomPasswordWithVisiblityIcon
                      label="Temporary Password"
                      name="tempPassword"
                      validateInputonBlur={validateInput}
                      setInputValue={setTempPassword}
                    />
                    {error.tempPassword && (
                      <p className="text-danger text-left">
                        <Icon
                          name="exclamation triangle"
                          color="red"
                        />
                        {error.tempPassword}
                      </p>
                    )}
                  </div>
                  <div className="password-input">
                    <CustomPasswordWithVisiblityIcon
                      name="password"
                      label="New Password"
                      validateInputonBlur={validateInput}
                      setInputValue={passwordValueChange}
                    />
                    {error.password && (
                      <p className="text-danger text-left">
                        <Icon
                          name="exclamation triangle"
                          color="red"
                        />
                        {error.password}
                      </p>
                    )}
                  </div>
                  <div className="password-input">
                    <CustomPasswordWithVisiblityIcon
                      name="confirmPassword"
                      label="Retype New Password"
                      validateInputonBlur={validateInput}
                      setInputValue={setConfirmPassword}
                    />

                    {error.confirmPassword && (
                      <p className="text-danger text-left">
                        <Icon
                          name="exclamation triangle"
                          color="red"
                        />
                        {error.confirmPassword}
                      </p>
                    )}
                  </div>
                  <div>
                    <ul className="password-checklist-wrapper">
                      <PasswordCheckList
                        success={passwordHintIndicator.hasCapitalLetter}
                        errorHint="Must Contain One Capital Letter"></PasswordCheckList>
                      <PasswordCheckList
                        success={passwordHintIndicator.hasSpecialCharacter}
                        errorHint="Must Contain 1 Special Character."></PasswordCheckList>
                      <PasswordCheckList
                        success={passwordHintIndicator.hasContainDigit}
                        errorHint="Must Contain a number"></PasswordCheckList>
                      <PasswordCheckList
                        success={passwordHintIndicator.hasMaxLength}
                        errorHint="Must Be at least 8 characters in length"></PasswordCheckList>
                    </ul>
                  </div>
                  <Button
                    onClick={submitPasswordReset}
                    sx={styles.resetBtn}>
                    RESET PASSWORD
                  </Button>
                </Form>
              </CardContent>
            </Card>
          </div>
          <div className="reset-password-bg-overlay" />
        </>
      )}
    </>
  );
};

const CustomPasswordWithVisiblityIcon = ({ setInputValue, name, hasError, label, validateInputonBlur }) => {
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [value, setValue] = useState('');

  const onInputValueChange = (event) => {
    const { value } = event.target;
    setValue(value);
    setInputValue(value);
    validateInputonBlur(event);
  };
  const togglePasswordVisiblity = () => {
    setPasswordVisiblity((prev) => (prev = !prev));
  };

  return (
    <>
      <label
        className="form-label"
        style={styles.label}>
        {label}
      </label>
      <Input
        type={`${passwordVisiblity ? 'text' : 'password'}`}
        fluid={true}
        error={hasError}
        onChange={onInputValueChange}
        onBlur={validateInputonBlur}
        name={name}
        icon={
          value.length ? (
            <Icon
              onClick={togglePasswordVisiblity}
              name={`eye${passwordVisiblity ? '' : ' slash'}`}
              link
            />
          ) : (
            false
          )
        }
      />
    </>
  );
};

const PasswordCheckList = ({ success, errorHint }) => {
  return (
    <li className="password-checklist">
      {!success ? (
        <img
          style={styles.icon}
          alt="check-mark-icon"
          src={CheckCircle}
        />
      ) : (
        <img
          src={CirlceCheckmarkError}
          alt="check-mark-icon-sucess"
          style={styles.icon}
        />
      )}
      {errorHint}
    </li>
  );
};

export default ResetPassword;
