import React, { useState } from "react";
import storageService from "../../services/storageService";
import { AMORTIZATION_OBJ, IS_EDIT_FLAG } from "../../util/constant";
import Amortization from "../Packages/amortization/amortization.component";
import VehicleInfo from "../Packages/vehicle-info/vehicle-info.component";
import "./create-package.styles.scss";

const CreatePackage = () => {
  const activePtStep = localStorage.getItem("activePtStep");
  // const [noOfTranche, setNumberOfTranche] = useState();
  // const [tranches, setTranches] = useState([]);
  // const [selectedDayOfWeek, setSelectedDayOfWeek] = useState("");
  const [activeStep, setActiveStep] = useState(activePtStep || "vehicle");
  const [amortizationObj, setAmortizationObj] = useState({});

  // const fnToGenerateTranche = (value, length) => {
  //   let arry = [];

  //   for (let i = 0; i < length; i++) {
  //     arry.push(value);
  //   }

  //   setTranches(arry);
  // };

  // const generateTranche = () => {
  //   let trancheObj = {
  //     daily_remit: null,
  //     duration: null,
  //     total_onboarding_cost: null,
  //     ltv: null,
  //   };

  //   fnToGenerateTranche(trancheObj, noOfTranche);
  // };

  // const selectDayOfWeek = (day) => {
  //   setSelectedDayOfWeek(day);
  // };

  return (
    <div className="create-package">
      <div className="container">
        <div className="create-template__layout py-5">
          <div className="create-template-layout__side">
            <div
              className={`${
                activeStep === "vehicle" && !storageService.get(IS_EDIT_FLAG)
                  ? "price-template-active__step"
                  : ""
              } d-flex align-items-center px-3 py-3 cursor`}
            >
              <div className="number-div mr-2">1</div>
              Vehicle Information
            </div>

            <div
              className={`${
                activeStep !== "vehicle" || storageService.get(IS_EDIT_FLAG)
                  ? "price-template-active__step"
                  : ""
              } d-flex align-items-center px-3 py-3 cursor`}
            >
              <div className="number-div mr-2">2</div>
              Amortization Schedule
            </div>
          </div>

          <div className="create-template-layout__content">
            {activeStep === "vehicle" && !storageService.get(IS_EDIT_FLAG) ? (
              <VehicleInfo
                setActiveStep={setActiveStep}
                setAmortizationObj={setAmortizationObj}
                amortizationObj={amortizationObj}
              />
            ) : (
              <Amortization
                setActiveStep={setActiveStep}
                setAmortizationObj={setAmortizationObj}
                amortizationObj={
                  storageService.get(IS_EDIT_FLAG)
                    ? JSON.parse(storageService.get(AMORTIZATION_OBJ))
                    : amortizationObj
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePackage;
