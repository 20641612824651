import React, { useState } from 'react';
import TableLoader from 'components/Loader/TableLoader';
import EmptyState from 'assets/img/empty-img.svg';
import format from 'date-fns/format';
import CustomPagination from 'components/CustomPagination/customPagination.component';
import UtilFn from 'util/util-fn';
import TableComponent from 'components/Table/table.component';
// eslint-disable-next-line
import AmortizationProfile from 'components/AmortizationProfile/amortization-profile.component';
import AmortizationSchedule from 'components/AmortizationSchedule/amortization-schedule.component';
import RepaymentSchedule from 'components/RepaymentSchedule/repayment-schedule.component';
import NoData from 'components/NoData';
import CompleteContractModal from 'components/ContractToBeClose/complete-contract-modal';
import CloseContractReasonModal from 'components/ContractToBeClose/close-contract-reason-modal';
import {
  formatNumber,
  // eslint-disable-next-line
  CustomToggle
} from 'pages/Overview/OverviewTable';
import { ReactComponent as HelmetYellow } from 'assets/img/helmet-yellow.svg';
import { useContracts } from 'contexts/contracts';
import RestructureLog from 'components/ContractToBeClose/contract-restructure-log';
// import TransactionsModal from 'components/ContractToBeClose/full-transaction-details';
import RestructureContract from 'components/Contracts/contracts-restucturing-modal';
import ContractReview from 'components/Contracts/contract-review-modal';
import { getHpStatusColorCode, getRetrivalStatusColorCode } from 'util/ui-helper';

const OverviewTable = ({
  contractDetails,
  data,
  loading,
  navigateToChampionBio,
  pageCount,
  currentPage,
  onPageSizeChange,
  onPageChange,
  pageSize,
  tableHeader
}) => {
  const { setContractData, contractData } = useContracts();
  const { contract = {} } = contractData;
  const [amortizationProfile, setAmortizationProfile] = useState(false);
  const [amortizationSchedule, setAmortizationSchedule] = useState(false);
  const [championObj, setChampionObj] = useState({});
  const [championsAmortizationSchedule, setChampionsAmortizationSchedule] = useState([]);
  const [showRepaymentSchedule, setShowRepaymentSchedule] = useState(false);
  const [championTrancheInfo, setChampionTrancheInfo] = useState();

  const toAmortizationSchedule = () => {
    setAmortizationProfile(false);
    setAmortizationSchedule(false);
  };

  const toAmortizationProfile = () => {
    setAmortizationProfile(true);
    setAmortizationSchedule(false);
  };

  // eslint-disable-next-line
  const triggerProfileModal = (championObj) => {
    setAmortizationProfile(true);
    setChampionObj(championObj);
  };

  // eslint-disable-next-line
  const triggerRepaymentModal = (championObj) => {
    setShowRepaymentSchedule(true);
    setChampionObj(championObj);
  };
  const idRetrieve = (obj) => {
    const { id } = obj;
    return id || '';
  };

  const setSelectedContract = (data) => {
    setContractData((prevState) => {
      if (data.id === prevState.contract.id) return { ...prevState, contract: {} };
      return { ...prevState, contract: data };
    });
  };

  const toggleOpenContract = () => {
    setContractData((prevState) => {
      return {
        ...prevState,
        openCompleteContractModal: !prevState.openCompleteContractModal
      };
    });
  };

  return (
    <>
      <div className="overview-table__div">
        {loading && <TableLoader />}
        {!loading && data && data?.length === 0 && <NoData text="No contracts was found!" />}
        {!loading && data && data.length > 0 && (
          <div className="w-100">
            <TableComponent>
              <thead>
                <tr>
                  {tableHeader.map((el, index) => (
                    <th key={`${el}_${index}`}>{el}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((data, index) => {
                    const {
                      name,
                      max_champion_id,
                      phone,
                      amount_overdue,
                      days_overdue,
                      last_amount_remitted,
                      payment_status,
                      hp_status,
                      location_name
                    } = data;
                    return (
                      <React.Fragment key={`${data.id}_${index}`}>
                        <tr
                          className={`cursor ${contract.id === data.id ? 'close-contract-tr' : ''}`}
                          key={index}
                          onClick={() => {
                            setSelectedContract(data);
                          }}>
                          <td>{UtilFn.paginationNumbering(pageSize, currentPage, index)}</td>
                          <td>
                            <div className="d-flex align-items-center m-0">
                              <div className="mr-3">
                                <HelmetYellow />
                              </div>
                              <div className="d-flex flex-column">
                                <span>{name || 'N/A'}</span>
                                <span className="txt_orange">{max_champion_id || 'N/A'}</span>
                              </div>
                            </div>
                          </td>

                          <td>{phone || 'N/A'}</td>
                          <td>{formatNumber(amount_overdue)}</td>
                          <td>{days_overdue}</td>
                          <td>{formatNumber(last_amount_remitted)}</td>
                          <td>
                            {data.last_day_remitted ? format(new Date(data.last_day_remitted), 'DD/MMM/YYYY') : 'N/A'}
                          </td>
                          <td>
                            <div className={`${getRetrivalStatusColorCode(payment_status)}`}>
                              {payment_status || 'N/A'}
                            </div>
                          </td>
                          <td>
                            <div className={`${getHpStatusColorCode(hp_status)}`}>{hp_status?.name || 'N/A'}</div>
                          </td>
                          <td>{location_name?.toUpperCase() || 'N/A'}</td>
                        </tr>
                        {contract.id === data.id && (
                          <tr>
                            <td colSpan={12}>{contractDetails}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </TableComponent>

            <CustomPagination
              onPageChange={onPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />

            {/* empty state */}
            {!loading && data.length === 0 && (
              <div
                className="text-center"
                style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                <img
                  src={EmptyState}
                  alt="Empty state"
                />
                <h3>Nothing to see here</h3>
              </div>
            )}
          </div>
        )}
        <AmortizationProfile
          show={amortizationProfile}
          setAmortizationProfile={setAmortizationProfile}
          toAmortizationSchedule={toAmortizationSchedule}
          championObj={Object.keys(championObj).length === 0 ? (championObj.id = '') : championObj}
          navigateToChampionBio={navigateToChampionBio}
          setChampionsAmortizationSchedule={setChampionsAmortizationSchedule}
          championsAmortizationSchedule={championsAmortizationSchedule}
          setChampionTrancheInfo={setChampionTrancheInfo}
        />

        <AmortizationSchedule
          show={amortizationSchedule}
          setAmortizationSchedule={setAmortizationSchedule}
          toAmortizationProfile={toAmortizationProfile}
          championsAmortizationSchedule={championsAmortizationSchedule}
          championContractId={idRetrieve(championObj)}
        />

        <RepaymentSchedule
          show={showRepaymentSchedule}
          championObj={championObj}
          trancheInfo={championTrancheInfo}
          setRepaymentModal={setShowRepaymentSchedule}
        />
        <CompleteContractModal toggleOpenContract={toggleOpenContract} />
        <CloseContractReasonModal />
        <RestructureLog contract={contract} />
        <RestructureContract contractId={contract?.contract_id ?? contract?.id} />
        <ContractReview contractId={contract?.contract_id ?? contract?.id} />
      </div>
    </>
  );
};

export default OverviewTable;

OverviewTable.defaultProps = {
  tableHeader: [
    '#',
    'Champions',
    'Phone Number',
    'TAO (₦)',
    'TDO',
    'LAR (₦)',
    'LDR',
    'Retrieval Status',
    'HP Status',
    'Location'
  ]
};
