import React from 'react';
import ReactPaginate from 'react-paginate';
import { Form } from 'semantic-ui-react';

import './customPagination.styles.scss';

const CustomPagination = ({ currentPage, onPageChange, onPageSizeChange, pageSize, pageCount }) => {
  return (
    <div className="custom-pagination">
      <div className="col-md-12 px-0">
        <div className="row">
          <div className="col-md-2 my-auto">
            <Form>
              <div className="field no-margin__field">
                <select
                  style={{ maxWidth: '80px' }}
                  value={pageSize}
                  name="pageSize"
                  onChange={(event) => onPageSizeChange(event)}>
                  <option value="2">2</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
              </div>
            </Form>
          </div>
          <div className="col-md-10 text-md-right my-auto">
            <ReactPaginate
              previousLabel={'Prev'}
              nextLabel={'Next'}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              activeClassName="active-page"
              initialPage={currentPage - 1}
              onPageChange={onPageChange}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
