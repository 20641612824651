import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import style from './style';
import { useContracts } from 'contexts/contracts';
import { formatNumber } from 'pages/Overview/OverviewTable';
import { HPStatusLang } from 'util/constant';

const CompleteContractModal = () => {
  const { toggleOpenContract, contractData, setContractData } = useContracts();
  const { openCompleteContractModal, totalAmountRemitted, contract: { hp_value } = {} } = contractData;

  const handleClose = () => {
    toggleOpenContract();
    setContractData((prevState) => {
      return {
        ...prevState,
        openContractCommentModal: !prevState.openContractCommentModal
      };
    });
  };
  return (
    <Modal
      open={openCompleteContractModal}
      onClose={toggleOpenContract}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style.boxStyle}>
        <div className="d-flex">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6">
            <span className="f-600">Complete Contract</span>
          </Typography>
          <div
            className="cancel-icon ml-auto cursor"
            onClick={toggleOpenContract}>
            <span className="icon-text">x</span>
          </div>
        </div>
        <hr />
        <label className="complete-contract-label">Total Paid</label>
        <div className="complete-contract-div">N{formatNumber(totalAmountRemitted)}</div>
        <label className="complete-contract-label mt-4">Total HP Value</label>
        <div className="complete-contract-div">N{formatNumber(hp_value)}</div>
        <p className="d-flex mt-3">
          <span className="text-danger f-20">* </span>
          {Number(totalAmountRemitted) === Number(hp_value) && (
            <span className="ml-2 opacity-75">{HPStatusLang.totalPaidAmountEqualHPValue}</span>
          )}

          {Number(totalAmountRemitted) > Number(hp_value) && (
            <span className="ml-2 opacity-75">{HPStatusLang.totalPaidAmountGreaterThanHPValue}</span>
          )}

          {Number(totalAmountRemitted) < Number(hp_value) && (
            <span className="ml-2 opacity-75">
              {HPStatusLang.totalPaidAmountLessThanHPValueFirstPart}
              {formatNumber(hp_value - totalAmountRemitted)}
              {HPStatusLang.totalPaidAmountLessThanHPValueLastPart}
            </span>
          )}
        </p>
        <hr className="mt-4 mb-3" />
        <div className="d-flex">
          <Button
            sx={[style.btn, style.moreInfoBtn]}
            onClick={handleClose}>
            mark as completed
          </Button>
          <Button
            sx={[style.btn, style.completeContractBtn]}
            onClick={toggleOpenContract}>
            cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default CompleteContractModal;
