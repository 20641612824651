import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import DropdownContent from './dropdown-content.component';
import './dropdown.styles.scss'

const DropdownComponent = ({
    dropdownOption = [],
    placeHolderText = "Select an option",
    handleChange,
    selectedOption = "",
    label = "",
    disabled = false
}) => {
    const [open, setOpen] = useState(false);

    const generateNameFromValue = (array, value) => {
        const result = array.filter((e) => value === e.value);
        return result;
    };

    useEffect(() => {
        if(selectedOption) {
            setOpen(false)
        }
    }, [selectedOption])

    return (
        <div className="custom-dropdown">
            <div
                onClick={() => {
                    !disabled && setOpen(true)
                }}
                className="custom-select-wrapper"
            >
                {
                    label && <p className="mb-1" style={{ color: '#9F9F9F' }}>{label}</p>
                }


                <div className="custom-select__div">
                    <div className="custom-select__trigger">
                        <span>
                            {generateNameFromValue(dropdownOption, selectedOption)[0]?.name ||
                                placeHolderText}
                        </span>
                        <div className="arrow"></div>
                    </div>
                    {open && (
                        <DropdownContent  onClose={() => {
                            setOpen(false)
                        }}>
                            <div className="custom-dropdown__option">
                                {dropdownOption.map((option, index) => (
                                    <span
                                        key={index}
                                        onClick={() => handleChange(option.value)}
                                        className={`custom-option ${selectedOption.toString().toLowerCase() === option.name.toLowerCase()
                                            ? "selected"
                                            : ""
                                            }`}
                                    >
                                        {option.name}
                                    </span>
                                ))}
                            </div>
                        </DropdownContent>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DropdownComponent
