import React from "react";
import "./card.styles.scss";

const CardComponent = ({
    children,
    withBoxShadow
}) => {
  return (
    <div className="card-component">
      <div className={`custom-card ${withBoxShadow ? 'card-box__shadow' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default CardComponent;
