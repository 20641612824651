import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';
import DropdownComponent from '../../../components/DropdownComponent/dropdown.component';
import storageService from '../../../services/storageService';
import { AMORTIZATION_OBJ, IS_EDIT_FLAG } from '../../../util/constant';
import NumberFormatter from '../../../util/numberFormatter';
import ValidationUtil from '../../../util/validation-util';

import './amortization.styles.scss';

const Amortization = ({ history, setAmortizationObj, amortizationObj, setActiveStep }) => {
  const storedObj = JSON.parse(storageService.get(AMORTIZATION_OBJ));
  const [dailyRemit, setDailyRemit] = useState(storedObj?.daily_remit || '');
  const [insuranceDuration, setInsuranceDuration] = useState(storedObj?.insurance_duration || '');
  // const [pikRate, setPikRate] = useState();
  const [maxLtv, setMaxLtv] = useState(storedObj?.tranches[0]?.ltv || '');
  // const [facilityFee, setFacilityFee] = useState();
  const [onboardingCost, setOnboardingCost] = useState(storedObj?.tranches[0]?.total_onboarding_cost || '');
  // const [trancheCount, setTrancheCount] = useState(
  //   storedObj?.tranches?.length || 1
  // );

  const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(storedObj?.payment_frequency || '');
  const [selectedHpDuration, setSelectedHpDuration] = useState(storedObj?.standard_duration || '');
  const [selectedPaymentDay, setSelectedPaymentDay] = useState();

  // error blocks
  const [dailyRemittanceError, setDailyRemittanceError] = useState();
  const [selectedHpDurationError, setSelectedHpDurationError] = useState();
  const [selectedInsuranceDurationError, setSelectedInsuranceDurationError] = useState();
  const [onboardingCostError, setOnboardingCostError] = useState();
  const [maxLtvError, setMaxLtvError] = useState();

  const [trancheErrors, setTrancheErrors] = useState([]);

  // const [trancheFlag, setTrancheFlag] = useState("add");
  const [isEditFlag, setIsEditFlag] = useState(false);

  const [numberOfTranche, setNumberOfTranche] = useState();
  const [maximumNoOfTrancheError, setMaximumNoOfTrancheError] = useState(false);

  const [lastTrancheArrayDuration, setLastTrancheArrayDuration] = useState();

  const [isMaximumDuration, setIsMaximumDuration] = useState(false);

  const paymentDayArray = [
    {
      name: '5',
      value: '5'
    },
    {
      name: '6',
      value: '6'
    }
  ];

  const paymentFrequencyArray = [
    {
      name: 'Daily',
      value: 'daily'
    },
    {
      name: 'Weekly',
      value: 'weekly'
    },
    {
      name: 'Quartely',
      value: 'quarterly'
    },
    {
      name: 'Fortnightly',
      value: 'fortnightly'
    },
    {
      name: 'Biannually',
      value: 'biannually'
    },
    {
      name: 'Yearly',
      value: 'yearly'
    }
  ];

  const [trancheArray, setTrancheArray] = useState([]);

  const handleTrancheInput = (e, index) => {
    const { value, name } = e.target;
    setTrancheArray((currentValue) => {
      const updatedValues = [...currentValue];
      updatedValues[index][name] = NumberFormatter.removeComma(value);

      return updatedValues;
    });

    setTrancheErrors((currentValue) => {
      const updatedValues = [...currentValue];
      updatedValues[index][name] = ValidationUtil.twoDecimalPointValidation(NumberFormatter.removeComma(value));

      return updatedValues;
    });
  };

  const handleTrancheInputFunction = (e) => {
    const { value } = e.target;
    if (value) {
      setNumberOfTranche(value);
      if (Number(value) > 5) {
        setMaximumNoOfTrancheError(true);
      }
    } else {
      setTrancheArray([]);
      setNumberOfTranche();
      setMaximumNoOfTrancheError(false);
    }
  };

  const handleTrancheGeneration = () => {
    generateTrancheFlows(numberOfTranche);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    switch (name) {
      case 'daily_remit':
        setDailyRemittanceError(ValidationUtil.twoDecimalPointValidation(NumberFormatter.removeComma(value)));
        setDailyRemit(NumberFormatter.removeComma(value));
        break;
      case 'standard_duration':
        setSelectedHpDurationError(ValidationUtil.twoDecimalPointValidation(NumberFormatter.removeComma(value)));
        setSelectedHpDuration(NumberFormatter.removeComma(value));
        break;
      case 'insurance_duration':
        setSelectedInsuranceDurationError(ValidationUtil.twoDecimalPointValidation(NumberFormatter.removeComma(value)));
        setInsuranceDuration(NumberFormatter.removeComma(value));
        break;
      case 'maxLtv':
        setMaxLtvError(ValidationUtil.ltvValidation(value));
        setMaxLtv(NumberFormatter.removePercentageSign(value));
        break;
      case 'onboarding_cost':
        setOnboardingCostError(ValidationUtil.twoDecimalPointValidation(NumberFormatter.removeComma(value)));
        setOnboardingCost(NumberFormatter.removeComma(value));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    let modifiedTrancheArray = [];
    let arrayOfTranche = trancheArray;
    arrayOfTranche[arrayOfTranche.length - 1].duration = lastTrancheArrayDuration;
    arrayOfTranche.forEach((res) => {
      modifiedTrancheArray.push({
        ...res,
        ltv: maxLtv,
        total_onboarding_cost: onboardingCost
      });
    });

    const obj = {
      ...amortizationObj,
      daily_remit: dailyRemit,
      standard_duration: Number(selectedHpDuration),
      insurance_duration: Number(insuranceDuration),
      payment_frequency: selectedPaymentFrequency,
      tranches: [...modifiedTrancheArray]
    };
    if (selectedPaymentFrequency === 'daily') {
      obj.work_days = Number(selectedPaymentDay);
    } else {
      delete obj.work_days;
    }

    if (selectedPaymentDay) {
      let days_of_week;
      if (selectedPaymentDay === '5') {
        days_of_week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
      }

      if (selectedPaymentDay === '6') {
        days_of_week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      }

      obj.days_of_the_week = days_of_week;
    }

    storageService.set(AMORTIZATION_OBJ, JSON.stringify(obj));
    storageService.set('activePtStep', 'amortization');
    history.push('/confirm-price-template');
  };

  const handleEdit = () => {};

  const generateTrancheFlows = (n) => {
    let number = new Array(Number(n - 1));

    let arrayFromGenrate = [];
    let arrayErrorFromGenerate = [];

    for (var i = 1; i <= number.length; i++) {
      arrayFromGenrate.push({
        daily_remit: 0,
        duration: 0
      });

      arrayErrorFromGenerate.push({
        daily_remit: '',
        duration: ''
      });
    }
    setTrancheArray([...arrayFromGenrate, { daily_remit: dailyRemit, duration: '' }]);
    setTrancheErrors([...arrayErrorFromGenerate, { daily_remit: '', duration: '' }]);
  };

  // const generateTrancheArray = (N) => {
  //   var numberOfItems = new Array(N);

  //   for (var i = 1; i <= N; i++) {
  //     setTrancheArray([
  //       ...trancheArray,
  //       {
  //         daily_remit: 0,
  //         duration: 0,
  //       },
  //     ]);

  //     setTrancheErrors([
  //       ...trancheErrors,
  //       {
  //         daily_remit: "",
  //         duration: "",
  //       },
  //     ]);
  //   }
  // };

  useEffect(() => {
    let calculatedDuration = selectedHpDuration;
    trancheArray.forEach((res, index) => {
      calculatedDuration = calculatedDuration - Number(res.duration);
      setLastTrancheArrayDuration(calculatedDuration);
      if (calculatedDuration <= 0) {
        setIsMaximumDuration(true);
      } else {
        setIsMaximumDuration(false);
      }
    });
    // eslint-disable-next-line
  }, [trancheArray]);

  // const reduceTranceArray = () => {
  //   let reducedArray = trancheArray;
  //   reducedArray.pop();
  //   setTrancheArray([...reducedArray]);
  // };

  // const addTanche = () => {
  //   setTrancheFlag("add");
  //   if (trancheCount <= 4) {
  //     setTrancheCount(trancheCount + 1);
  //   }
  // };

  // const subtractTrache = () => {
  //   setTrancheFlag("subtract");
  //   if (trancheCount > 1) {
  //     setTrancheCount(trancheCount - 1);
  //   }
  // };

  // const returnLastTrancheDuration = (index) => {
  //   if (index + 1 > 1 && index + 1 === trancheArray.length) {
  //     return "";
  //   }

  //   return "";
  // };

  const ptCreationButtonCheck = () => {
    let btnCountCheck = 0;
    if (selectedPaymentFrequency) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (selectedPaymentFrequency === 'daily') {
      if (selectedPaymentDay) {
        btnCountCheck = btnCountCheck + 12.5;
      }
    }
    if (dailyRemit && !dailyRemittanceError) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (selectedHpDuration && !selectedHpDurationError) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (insuranceDuration && !selectedInsuranceDurationError) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (numberOfTranche && !isMaximumDuration) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (onboardingCost && !onboardingCostError) {
      btnCountCheck = btnCountCheck + 12.5;
    }
    if (maxLtv && !maxLtvError) {
      btnCountCheck = btnCountCheck + 12.5;
    }

    return btnCountCheck;
  };

  useEffect(() => {
    if (selectedPaymentFrequency !== 'daily') {
      setSelectedPaymentDay('');
    }
  }, [selectedPaymentFrequency]);

  useEffect(() => {
    // eslint-disable-next-line
    setIsEditFlag(false);
  }, []);

  return (
    <div className="amortization">
      <div className="row">
        <div className="col-md-8">
          <div className="row mb-4">
            {!storageService.get(IS_EDIT_FLAG) && (
              <div className="col-md-12">
                <p
                  onClick={() => setActiveStep('vehicle')}
                  className="small font-weight-bold cursor"
                  style={{ color: '#E88E15' }}>
                  <i className="arrow left icon"></i> BACK TO VEHICLE INFORMATION
                </p>
              </div>
            )}
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <DropdownComponent
                label="Select Payment Frequency"
                placeHolderText="- Select Frequency -"
                dropdownOption={paymentFrequencyArray}
                selectedOption={selectedPaymentFrequency}
                handleChange={(e) => setSelectedPaymentFrequency(e)}
              />
            </div>
            <div className="col-md-6">
              {selectedPaymentFrequency === 'daily' && (
                <DropdownComponent
                  label="Select Payment Day"
                  disabled={selectedPaymentFrequency !== 'daily'}
                  placeHolderText="- Select Payment day -"
                  dropdownOption={paymentDayArray}
                  selectedOption={selectedPaymentDay}
                  handleChange={(e) => setSelectedPaymentDay(e)}
                />
              )}
            </div>
          </div>

          <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>Repayment Amount (N)</p>
          <div className="ui fluid input">
            <NumberFormat
              allowNegative={false}
              decimalScale={2}
              disabled={!selectedPaymentFrequency}
              onChange={(e) => handleInput(e)}
              defaultValue={dailyRemit}
              name="daily_remit"
              thousandSeparator={true}
              inputMode="numeric"
            />
          </div>
          {dailyRemittanceError && <p style={{ color: 'red', fontSize: '11px' }}>{dailyRemittanceError}</p>}
          {!selectedPaymentFrequency && (
            <p className="empty-field__blinker mb-2 mt-1">previous field needs to be filled</p>
          )}

          <div className="row mt-4">
            <div className="col-md-6">
              <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>HP duration without HMO</p>
              <div className="ui fluid input">
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  disabled={!dailyRemit}
                  defaultValue={selectedHpDuration}
                  onChange={(e) => handleInput(e)}
                  name="standard_duration"
                  thousandSeparator={true}
                  inputMode="numeric"
                />
              </div>

              {selectedHpDurationError && <p style={{ color: 'red', fontSize: '11px' }}>{selectedHpDurationError}</p>}

              {!dailyRemit && <p className="empty-field__blinker mb-2 mt-1">previous field needs to be filled</p>}
            </div>

            <div className="col-md-6">
              <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>HMO Duration</p>
              <div className="ui fluid input">
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  disabled={!selectedHpDuration}
                  defaultValue={insuranceDuration}
                  onChange={(e) => handleInput(e)}
                  name="insurance_duration"
                  thousandSeparator={true}
                  inputMode="numeric"
                />
              </div>

              {selectedInsuranceDurationError && (
                <p style={{ color: 'red', fontSize: '11px' }}>{selectedInsuranceDurationError}</p>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>Number of Tranche</p>
              <div className="ui fluid input">
                <NumberFormat
                  allowNegative={false}
                  decimalScale={0}
                  disabled={!insuranceDuration}
                  onChange={(e) => handleTrancheInputFunction(e)}
                  name="tranche_input"
                  thousandSeparator={true}
                  inputMode="numeric"
                />
              </div>
              {maximumNoOfTrancheError && <p style={{ color: 'red', fontSize: '11px' }}>Not more than 5</p>}
            </div>

            <div className="col-md-4">
              <p
                style={{
                  color: '#a1a1a1',
                  marginBottom: '4px',
                  visibility: 'hidden'
                }}>
                HMO Duration
              </p>
              <Button
                disabled={!numberOfTranche || Number(numberOfTranche) === 0 || maximumNoOfTrancheError}
                style={{ height: '50px' }}
                className="w-100"
                onClick={handleTrancheGeneration}>
                Generate
              </Button>
            </div>
          </div>
          {trancheArray.length > 0 && (
            <div className="tranche-div mt-4">
              <div className="p-3">
                <div className="row">
                  <div className="col-md-5">
                    <p
                      className="mb-0"
                      style={{ color: '#3E3E3E' }}>
                      Number of Tranches
                    </p>
                  </div>
                  <div className="col-md-7">
                    {/* <div className="d-flex align-items-center justify-content-end">
                      <div
                        className="tranche-number__action cursor"
                        onClick={subtractTrache}
                      >
                        -
                      </div>
                      <div className="px-2">{trancheCount}</div>
                      <div
                        className="tranche-number__action cursor"
                        onClick={addTanche}
                      >
                        +
                      </div>
                    </div> */}
                  </div>
                </div>

                <hr />

                {trancheArray.map((tranche, index) => (
                  <div
                    key={index}
                    className="row mb-3">
                    <div className="col-md-6">
                      <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>Tranche Remittance</p>

                      {index + 1 >= 1 && index + 1 === trancheArray.length ? (
                        <div className="duration-input__div">{trancheArray[trancheArray.length - 1].daily_remit}</div>
                      ) : (
                        <div className="ui fluid input">
                          <NumberFormat
                            disabled={(index + 1 !== 1 && index + 1 === trancheArray.length) || !insuranceDuration}
                            allowNegative={false}
                            decimalScale={2}
                            onChange={(e) => handleTrancheInput(e, index)}
                            name="daily_remit"
                            thousandSeparator={true}
                            inputMode="numeric"
                          />
                        </div>
                      )}

                      {trancheErrors[index].daily_remit && (
                        <p style={{ color: 'red', fontSize: '11px' }}>{trancheErrors[index].daily_remit}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>Duration</p>

                      {index + 1 >= 1 && index + 1 === trancheArray.length ? (
                        <div className="duration-input__div">
                          {lastTrancheArrayDuration && lastTrancheArrayDuration}
                        </div>
                      ) : (
                        <div className="ui fluid input">
                          <NumberFormat
                            disabled={(index + 1 !== 1 && index + 1 === trancheArray.length) || !insuranceDuration}
                            allowNegative={false}
                            decimalScale={2}
                            onChange={(e) => handleTrancheInput(e, index)}
                            name="duration"
                            thousandSeparator={true}
                            inputMode="numeric"
                          />
                        </div>
                      )}

                      {trancheErrors[index].duration && (
                        <p style={{ color: 'red', fontSize: '11px' }}>{trancheErrors[index].duration}</p>
                      )}
                    </div>
                  </div>
                ))}

                {isMaximumDuration && (
                  <p className="small text-danger text-right">Input is greater than the last tranche. Fix it!</p>
                )}
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-md-6">
              <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>Total Onboarding Cost (N)</p>
              <div className="ui fluid input">
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  defaultValue={onboardingCost}
                  disabled={trancheArray.length === 0}
                  onChange={(e) => handleInput(e)}
                  name="onboarding_cost"
                  thousandSeparator={true}
                  inputMode="numeric"
                />
              </div>
              {onboardingCostError && <p style={{ color: 'red', fontSize: '11px' }}>{onboardingCostError}</p>}
              {trancheArray.length === 0 && (
                <p className="empty-field__blinker mb-2 mt-1">previous field needs to be filled</p>
              )}
            </div>

            <div className="col-md-6">
              <p style={{ color: '#a1a1a1', marginBottom: '4px' }}>MAX LTV</p>

              <div className="ui fluid input">
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  disabled={!onboardingCost}
                  label="MAX LTV"
                  name="maxLtv"
                  defaultValue={maxLtv}
                  onChange={(e) => handleInput(e)}
                  thousandSeparator={true}
                  suffix={'%'}
                  className="some"
                  inputMode="numeric"
                />
              </div>
              {maxLtvError && <p style={{ color: 'red', fontSize: '11px' }}>{maxLtvError}</p>}
              {!onboardingCost && <p className="empty-field__blinker mb-2 mt-1">previous field needs to be filled</p>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-5">
              <Button
                disabled={ptCreationButtonCheck() !== (selectedPaymentFrequency === 'daily' ? 100 : 87.5)}
                onClick={isEditFlag ? handleEdit : handleSubmit}
                secondary
                className="continue w-100 text-uppercase py-3"
                style={{
                  background: '#FCDD18',
                  color: '#3E3E3E',
                  fontSize: '11.5px'
                }}>
                {isEditFlag ? 'Edit' : 'Continue'}
              </Button>
            </div>

            <div className="col-md-5">
              <Button
                onClick={() => history.push('/pricetemplates')}
                secondary
                className="cancel w-100 text-uppercase py-3"
                style={{
                  background: '#E9E9E9',
                  color: '#3E3E3E',
                  fontSize: '11.5px'
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Amortization);
