import { CONTRACT_ENDPOINT, STATUSES_ENDPOINT } from "../axios/endpoints";
import { axiosInstance, getUserToken } from "../axios/method";
import { GET } from "../util/constant";

const HEADERS = {
  "Content-Type": "application/json",
};

// function to get contracts
export const getContracts = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}?order_by=date_engaged:desc`,
    headers: HEADERS,
    params: params,
  });
};

// function to get a specific champion's contract
export const getSelectedChampionContract = (params, id) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/champions/${id}`,
    headers: HEADERS,
    params: params,
  });
};

// function to get a specific champion's contract
export const getSelectedChampionContractList = (params, id) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/champion/${id}`,
    headers: HEADERS,
    params: params,
  });
};

// function to get contract stats
export const getContractStats = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/stats`,
    headers: HEADERS,
    params: params,
  });
};

// function to get retrival status options
export const getContractStatusesEndpoint = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: STATUSES_ENDPOINT,
    headers: HEADERS,
    params: params,
  });
};

//function to get HP status options
export const getHpStatusEndpoint = () => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${STATUSES_ENDPOINT}/hp-statuses`,
    headers: HEADERS,
  });
};

//function to get pause HP reasons
export const getPauseHPreasons = (id) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CONTRACT_ENDPOINT}/pause/champions?contract_id=${id}`,
    headers: HEADERS,
  });
};
