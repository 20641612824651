import React from 'react';
import './ContractInfo.scss';
import NumberFormatter from '../../util/numberFormatter';
import { getUserRole } from '../../axios/method';

const ContractInfo = ({ contractInfo }) => {
  return (
    <div className="new-contract-info-container">
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">{contractInfo?.duration_in_days || '0'}</p>
        <p className="contract-info-child-bottom-text">Contract duration (Days)</p>
      </div>
      {(getUserRole() === 'super-admin' || getUserRole() === 'admin' || getUserRole() === 'agent') && (
        <div className="contract-info-child">
          <p className="contract-info-child-top-text">
            {(contractInfo?.hp_value && NumberFormatter?.formatNumber(contractInfo?.hp_value)) || '0.00'}
          </p>
          <p className="contract-info-child-bottom-text">HP Amount</p>
        </div>
      )}
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">
          {(contractInfo?.daily_remit && NumberFormatter?.formatNumber(contractInfo?.daily_remit)) || '0.00'}
        </p>
        <p className="contract-info-child-bottom-text">Daily Remittance Amount</p>
      </div>
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">
          {(contractInfo?.amount_overdue && NumberFormatter?.formatNumber(contractInfo?.amount_overdue)) || '0.00'}
        </p>
        <p className="contract-info-child-bottom-text">Total amount overdue</p>
      </div>
      {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
        <div className="contract-info-child">
          <p className="contract-info-child-top-text">
            {(contractInfo?.total_amount_outstanding &&
              NumberFormatter?.formatNumber(contractInfo?.total_amount_outstanding)) ||
              '0.00'}
          </p>
          <p className="contract-info-child-bottom-text">Total amount outstanding</p>
        </div>
      )}
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">{contractInfo?.days_outstanding || '0'}</p>
        <p className="contract-info-child-bottom-text">Total days outstanding</p>
      </div>
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">
          {(contractInfo?.total_amount_remitted &&
            NumberFormatter?.formatNumber(contractInfo?.total_amount_remitted)) ||
            '0.00'}
        </p>
        <p className="contract-info-child-bottom-text">Total amount remitted</p>
      </div>
      <div className="contract-info-child">
        <p className="contract-info-child-top-text">
          {(contractInfo?.amount_due && NumberFormatter?.formatNumber(contractInfo?.amount_due)) || '0.00'}
        </p>
        <p className="contract-info-child-bottom-text">Amount Due</p>
      </div>
      <div className="contract-info-child">
        <p className="contract-info-child-top-text"></p>
        <p className="contract-info-child-bottom-text"></p>
      </div>
    </div>
  );
};

export default ContractInfo;
