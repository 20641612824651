import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { ReactComponent as Excel } from '../../assets/img/excel.svg';
import { useQueryClient } from '../../services/apiClient';
import OverviewTable from './OverviewTable';
import OverviewCard from './OverviewCard';
import CardComponent from '../../components/Card/card.component';
import { SearchFilterWrapper } from '../../components/SearchFilter';
import { CustomToggle } from '../../components/Inputs/custom-toggle';
import { useQueryContext } from '../../contexts/query-context';
import { Form } from 'semantic-ui-react';
import CustomSelect from '../../components/CustomSelect';
// import { CSVLink } from 'react-csv';
import storageService from '../../services/storageService';
import { FILTERED_STATE } from '../../util/constant';
import './Overview.scss';
import groupAttention from '../../assets/img/group-attention.svg';
import { getUserRole } from '../../axios/method';
import { UserRoleType } from 'util/constant';

const OverviewContainer = () => {
  // endpoint prefixes
  const api_prefix = 'collection/v1/';

  const handleFlaggedChampionToggle = () => {
    toggleFlaggedChampions(!showFlaggedChampions);
  };

  let { searchQuery, baseQuery, setBaseQuery, pagination } = useQueryContext();
  const [showFlaggedChampions, toggleFlaggedChampions] = useState(false);
  const [filterLocation, setFilterLocation] = useState('');
  const [hpStatus, setHPStatus] = useState('');
  const [retreivalStatus, setRetreivalStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterState, setFilterState] = useState({});
  let history = useHistory();

  const navigateToChampionBio = (data) => {
    storageService.set(FILTERED_STATE, JSON.stringify(filterState));
    history.push(`/champion/${data.champion_id}`, {
      championObj: data
    });
  };

  const onPageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  useEffect(() => {
    if (showFlaggedChampions) {
      setRetreivalStatus('2');
    } else {
      setRetreivalStatus('');
    }
  }, [showFlaggedChampions]);

  useEffect(() => {
    setFilterState({
      ...filterState,
      searchQuery,
      currentPage,
      filterLocation,
      hpStatus,
      retreivalStatus
    });
    // eslint-disable-next-line
  }, [searchQuery, currentPage, filterLocation, hpStatus, retreivalStatus]);

  useEffect(() => {
    if (searchQuery === '') {
      setBaseQuery(
        `contracts?page=${currentPage}&limit=${pageSize}${filterLocation ? `&location_id=${filterLocation}` : ''}${
          hpStatus ? `&status_id=${hpStatus}` : ''
        }${retreivalStatus ? `&payment_status_id=${retreivalStatus}` : ''}`
      );
    } else {
      setBaseQuery(`contracts?page=${currentPage}&limit=${pageSize}&all=true&search=${searchQuery}`);
    }
    // eslint-disable-next-line
  }, [pageSize, currentPage, searchQuery, filterLocation, hpStatus, retreivalStatus]);

  // endpoints
  const fetchContractsURL = `${api_prefix}${baseQuery}`;
  const fetchContractsStatsURL = `${api_prefix}contracts/stats`;
  const fetchLocationURL = `${api_prefix}locations`;
  const fetchHPStatusURL = `${api_prefix}statuses/hp-statuses`;
  const fetchRetreivalStatusURL = `${api_prefix}statuses?type=all`;

  const { data: markedForClosure } = useQueryClient(`${fetchContractsURL}&status_id=3`, {
    method: 'get',
    enabled: baseQuery !== ''
  });

  const { data: contractsData, isLoading: contractsLoading } = useQueryClient(fetchContractsURL, {
    method: 'get',
    enabled: baseQuery !== ''
  });

  const { data: contractsStats, isLoading: contractStatsLoading } = useQueryClient(fetchContractsStatsURL, {
    method: 'get'
  });

  const { data: locationData } = useQueryClient(fetchLocationURL, {
    method: 'get',
    retry: false
  });

  const { data: hpStatusData } = useQueryClient(fetchHPStatusURL, {
    method: 'get',
    retry: false
  });

  const { data: retreivalStatusData } = useQueryClient(fetchRetreivalStatusURL, {
    method: 'get',
    retry: false
  });

  // queries response
  const _contracts_data = contractsData && contractsData?.data?.data?.contracts;

  pagination = contractsData && contractsData?.data?.data?.pagination;

  const markedForClosurePagination = markedForClosure && markedForClosure?.data?.data?.pagination;

  const _contracts_stats = contractsStats && contractsStats?.data?.data;

  const _location_data =
    locationData &&
    locationData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));

  const _hp_status_data =
    hpStatusData &&
    hpStatusData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));

  const _retreival_status_data =
    retreivalStatusData &&
    retreivalStatusData?.data?.data?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id
    }));
  // queries response
  const navigateToContractsToBeClose = () => {
    history.push(`/contracts`);
  };

  return (
    <>
      {UserRoleType.includes(getUserRole()) && _contracts_stats?.total_hp_complete_due && (
        <div className="wrapper mt-4 align-center">
          <img
            alt="Attention"
            src={groupAttention}
            title="Contracts"
            role="button"
          />
          <div
            className="profile-underlier mt-4"
            id="slide">
            <div className="contract-wrapper">
              <span className="contract-title">Contracts</span>
              <br />
              <span className="contract-text">
                {_contracts_stats?.total_hp_complete_due} contract
                {_contracts_stats?.total_hp_complete_due === 1 ? '' : 's'} require attention
              </span>
            </div>
            <button
              className="attention-btn"
              onClick={navigateToContractsToBeClose}>
              VIEW
            </button>
          </div>
        </div>
      )}
      {markedForClosurePagination && (
        <div className="notification-div py-3 mt-4">
          <p className="text-center mb-0 pb-0">
            You have {markedForClosurePagination.totalRows} champions marked for closure
          </p>
        </div>
      )}
      <div className="custom-container">
        <OverviewCard
          contractStats={_contracts_stats}
          loading={contractStatsLoading}
        />

        <div className="w-100 mb-4 mt-5">
          <CardComponent>
            <SearchFilterWrapper
              title="Champion List"
              filterTitle="Filter Table By"
              // toggle section of the filter
              toggleBlock={
                <CustomToggle
                  toggleState={showFlaggedChampions}
                  handleChange={handleFlaggedChampionToggle}>
                  {showFlaggedChampions ? 'Flagged' : 'ALL'}
                </CustomToggle>
              }
              // all filters
              filterBlock={
                <Form>
                  <div className="select-wrapper">
                    <CustomSelect
                      placeholder="Location"
                      options={_location_data}
                      onChange={(e) => {
                        setFilterLocation(e?.value);
                      }}
                    />
                  </div>

                  <div className="select-wrapper">
                    <CustomSelect
                      placeholder="HP Status"
                      options={_hp_status_data}
                      onChange={(e) => {
                        setHPStatus(e?.value);
                      }}
                    />
                  </div>

                  {!showFlaggedChampions && (
                    <div className="select-wrapper">
                      <CustomSelect
                        placeholder="Retrieval Status"
                        options={_retreival_status_data}
                        onChange={(e) => {
                          setRetreivalStatus(e?.value);
                        }}
                      />
                    </div>
                  )}
                </Form>
              }
              // export
              // exportBlock={
              //   <button>
              //     <CSVLink
              //       filename="contract-list.csv"
              //       data={''}>
              //       <Excel style={{ width: '14px' }} /> EXPORT TO CSV
              //     </CSVLink>
              //   </button>
              // }
            />

            <div className="px-4">
              <OverviewTable
                data={_contracts_data}
                loading={contractsLoading}
                navigateToChampionBio={navigateToChampionBio}
                pagination={pagination}
                pageCount={pagination?.pageCount || 0}
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          </CardComponent>
        </div>
      </div>
    </>
  );
};

export default OverviewContainer;
