import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import style from './style';
import { useContracts } from 'contexts/contracts';
import { useMutationClient } from 'services/apiClient';
import { useQueryClient } from 'react-query';
import SuccessErrorAlert from './success-error-modal';
const CompleteContractModal = () => {
  const queryClient = useQueryClient();
  const adminData = JSON.parse(localStorage.getItem('lams-data'));
  const { id: admin_id, first_name, last_name } = adminData;
  const [comment, setComment] = useState('');
  const [hpCompleteData, setHpCompleteData] = useState({ modalOpen: false });
  const handleChange = (e) => {
    const { target: { value } = {} } = e;
    setComment(value);
  };

  const handleClose = () => {
    setContractData((prevState) => {
      return {
        ...prevState,
        openContractCommentModal: !prevState.openContractCommentModal
      };
    });
  };

  const { contractData, setContractData } = useContracts();
  const { openContractCommentModal, totalAmountRemitted, contract: { hp_value, contract_id, id } = {} } = contractData;

  const markContractAsComplete = `collection/v1/repayments/hp-complete`;
  const { mutate, isLoading } = useMutationClient(markContractAsComplete, {
    method: 'post',
    data: {
      id,
      contract_id,
      completed_by: admin_id,
      total_amount_paid: totalAmountRemitted,
      total_hp_value: hp_value,
      completed_by_name: first_name + ' ' + last_name,
      outstanding_amount: `${Number(hp_value) - Number(totalAmountRemitted)}`,
      comment
    },

    onSuccess: (data = {}) => {
      const { data: { message } = {} } = data;
      setHpCompleteData({
        ...hpCompleteData,
        message: message,
        status: true,
        modalOpen: true
      });
      handleClose();
      queryClient.invalidateQueries(['contracts_record']);
      setContractData((prevState) => {
        return {
          ...prevState,
          openContractCommentModal: false,
          contract: {}
        };
      });
    },

    onError: (error = {}) => {
      const { response: { data } = {} } = error;
      setHpCompleteData({
        ...hpCompleteData,
        message: data.message,
        status: false,
        modalOpen: true
      });
      handleClose();
    }
  });

  return (
    <>
      <Modal
        open={openContractCommentModal}
        onClose={handleClose}
        aria-labelledby="close-contract"
        aria-describedby="close-contract-reason-modal">
        <Box sx={style.boxStyle}>
          <div className="d-flex">
            <Typography
              id="comment_"
              variant="h6"
              component="h6">
              <span className="f-600">Enter Comments</span>
            </Typography>
            <div
              className="cancel-icon ml-auto cursor"
              onClick={handleClose}>
              <span className="icon-text">x</span>
            </div>
          </div>
          <hr />
          <div className="mt-3 mb-2">
            <span className="complete-contract-label">Reason for ending contract</span>
            <span className="text-danger"> *</span>
          </div>
          <TextareaAutosize
            value={comment}
            onChange={handleChange}
            aria-label="minimum height"
            minRows={8}
            placeholder="Enter the reason for ending this contract"
            style={style.textArea}
          />
          <div className="d-flex">
            <Button
              disabled={isLoading || comment.length < 6}
              onClick={mutate}
              sx={[style.btn, style.moreInfoBtn]}>
              {isLoading ? 'loading' : 'mark as completed'}
            </Button>
            <Button
              sx={[style.btn, style.completeContractBtn]}
              onClick={handleClose}>
              cancel
            </Button>
          </div>
        </Box>
      </Modal>
      {hpCompleteData.modalOpen && <SuccessErrorAlert hpCompleteData={hpCompleteData} />}
    </>
  );
};
export default CompleteContractModal;
