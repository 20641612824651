import React from "react";

const useForm = (callback) => {
  const [inputs, setInputs] = React.useState({});

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    e.target.reset();
    callback();

    setInputs({});
  };

  const handleChange = (e) => {
    e.persist();
    setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
  };

  const selectChangeHandle = (e, data) => {
    e.persist();
    setInputs((inputs) => ({ ...inputs, [data.name]: data.value }));
  };

  const clearValue = () => {
    setInputs({});
  };

  return { handleChange, handleSubmit, selectChangeHandle, clearValue, inputs };
};

export default useForm;
