import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import 'components/Champion/add-transaction.scss';
import { Modal } from 'react-bootstrap';
import { useMutationClient } from 'services/apiClient';
import { toast } from 'react-toastify';
import { CONTRACT_ENDPOINT } from 'axios/endpoints';
import 'react-datepicker/dist/react-datepicker.css';
import { useQueryClient } from 'react-query';
import { useQueryClient as query } from '../../services/apiClient';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import format from 'date-fns/format';

const PauseHpModal = ({ showHpPauseModal, onHide, championId }) => {
  const queryClient = useQueryClient();

  const [pauseReason, setPauseReason] = useState('');
  const [startDate, setStartDate] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { data: { data: { data: pauseReasons = [] } = {} } = {} } = query(`${CONTRACT_ENDPOINT}/pause-reasons`, {
    enabled: true,
    method: 'get'
  });

  const { mutate, isLoading } = useMutationClient(`${CONTRACT_ENDPOINT}/champions/${championId}/pause`, {
    method: 'put',
    data: {
      pause_reason: pauseReason,
      start_date: startDate
    },

    onSuccess: (data = {}) => {
      const { data: { message = '' } = {} } = data;
      toast.success(message);
      queryClient.invalidateQueries(['contract_information']);
      queryClient.invalidateQueries(['overdue_repayment_most_recent']);
      queryClient.invalidateQueries(['tranche_info']);
      queryClient.invalidateQueries(['contract_info']);
      queryClient.invalidateQueries(['pause_history']);

      onHide();
      setShowSuccessModal(true);
    },

    onError: (data = {}) => {
      const { response = {} } = data;
      const { response: { data: resData = {} } = {} } = data;

      if (response.message === 'Unknow Error' && response.code === 400) {
        toast.success('Contract paused successfully');
      } else toast.error(resData.message);

      queryClient.invalidateQueries(['contract_information']);
      queryClient.invalidateQueries(['overdue_repayment_most_recent']);
      queryClient.invalidateQueries(['tranche_info']);
      queryClient.invalidateQueries(['contract_info']);
      queryClient.invalidateQueries(['pause_history']);
      onHide();
    }
  });

  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <SuccessModal
        title="Contract Paused"
        subTitle="Successfully Paused Contract"
        showSuccessModal={showSuccessModal}
        hideSuccessModal={hideSuccessModal}
        btnText={'Done'}
      />

      <Modal
        dialogClassName="transaction-modal"
        aria-labelledby="contained-modal-title-vcenter"
        show={showHpPauseModal}>
        <Modal.Header>
          <h3 className={'title'}>Pause Champion HP</h3>
          <CancelIcon
            color="#E2E2E2"
            onClick={onHide}
          />
        </Modal.Header>

        <Modal.Body className=" borde">
          <div className="form add-reference-id-form">
            <label>
              <span className="mr-3"> Reason for pausing </span>
            </label>

            <select
              disabled={pauseReasons.length === 0}
              onChange={(e) => {
                setPauseReason(e.target.value);
              }}>
              <option value="">- Select a reason -</option>
              <option value="Law Enforcement">Law Enforcement</option>
              <option value="Severe Accident">Severe Accident</option>
            </select>

            <label>Start Date</label>
            <input
              max={format(new Date(), 'YYYY-MM-DD')}
              id="start-date-picker"
              type="date"
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />

            <hr />
            <div className=" d-flex">
              <button
                disabled={!pauseReason || !startDate || isLoading}
                onClick={mutate}
                className="modal_form_btn">
                {isLoading ? <>Pausing Contract</> : 'PAUSE CONTRACT'}
              </button>
              <div className="px-3"></div>
              <button
                onClick={onHide}
                className="modal_form_btn cancel">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PauseHpModal;

const SuccessModal = ({ hideSuccessModal, showSuccessModal }) => {
  return (
    <Modal
      onHide={hideSuccessModal}
      show={showSuccessModal}
      dialogClassName={'transaction-modal success-modal'}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <>
        <Modal.Header>
          <h3 className="title">Pause Contract</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="outcome-header-wrapper success">
            <div className="outcome-icon-wrapper">
              <CheckCircleIcon />
            </div>
            <p>Contract successfully paused</p>
          </div>
          <button
            className="modal_form_btn"
            onClick={hideSuccessModal}>
            Continue
          </button>
        </Modal.Body>
      </>
    </Modal>
  );
};

export const ConfirmPauseModal = ({ show, onHide, pauseContract, isLoading }) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
      dialogClassName="transaction-modal confirmation-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <p className="body-txt card-subtitle-1">
          You are about to unpause this champions contract
          <br /> Are you sure you want to continue? <br />
        </p>

        <div className="confirm-button-wrapper">
          <button
            onClick={pauseContract}
            disabled={isLoading}
            className="confirm">
            Continue
          </button>
          <button
            disabled={isLoading}
            onClick={onHide}
            className="cancel">
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
