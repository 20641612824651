import React from 'react';
import style from './custom-toggle.module.scss';
import classNames from 'classnames';

export const CustomToggle = ({ handleChange, toggleState, children }) => {
  const bubbleStyle = classNames({ [style['bubble-active']]: !toggleState });
  const wrapperStyle = classNames({
    [style['wrapper-inactive']]: toggleState,
    [style['wrapper-active']]: !toggleState,
  });
  const titleStyle = classNames({
    [style['title-inactive']]: toggleState,
    [style['title-active']]: !toggleState,
  });

  return (
    <button
      onClick={handleChange}
      className={classNames(style['wrapper'], wrapperStyle)}>
      <span className={classNames(style['title'], titleStyle)}>{children}</span>
      <div className={classNames(style['bubble'], bubbleStyle)}></div>
    </button>
  );
};
