import React from 'react';
import './champion-ui.scss';
import { useQueryClient } from 'services/apiClient';

import { formatNumber } from 'pages/Overview/OverviewTable';
import format from 'date-fns/format';
import DotLoader from 'components/Loader/DotLoader';
import ValidationUtil from 'util/validation-util';

export const RestructuredLog = ({ contractId }) => {
  const api_prefix = 'collection/v1/contracts/';

  // Fetch champions contract
  const contractsLogURL = `${api_prefix}overdue-repayment-schedules/${contractId}`;
  const { data: contractLogData = {}, isLoading } = useQueryClient(contractsLogURL, {
    method: 'get',
    retry: 1,
    enabled: !!contractId
  });

  const { data: { data: log = {} } = {} } = contractLogData;

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <DotLoader />
        </div>
      )}

      {!isLoading && log.length === 0 && (
        <div className="w-100 d-flex justify-content-between">
          <p>No Restructured Logs Available</p>
        </div>
      )}

      {!isLoading && !ValidationUtil.isObjectEmpty(log) && (
        <div>
          <div className="contracts-log__bottom_detail">
            <span className="contracts-log__comment_title">Contract Restructuring</span>
          </div>
          {log.map((log) => (
            <div className="contracts-log__parent mb-2">
              <div className="contracts-log__top_detail">
                <div className="contracts-log__meta">
                  <div>
                    <span className="label">Date Restructured</span>
                    <span className="value">
                      {log?.updatedAt ? format(new Date(log?.updatedAt), 'DD/MMM/YYYY') : 'N/A'}
                    </span>
                  </div>
                </div>
                <div className="contracts-log__meta">
                  <div>
                    <span className="label">Restructured By</span>
                    <span className="value">
                      {log.user.first_name} {log.user.last_name}
                    </span>
                  </div>
                </div>
                <div className="contracts-log__meta">
                  <div>
                    <span className="label">Daily Remittance</span>
                    <span className="value">N {formatNumber(log?.daily_remit)}</span>
                  </div>
                </div>
                <div className="contracts-log__meta">
                  <div>
                    <span className="label">Outstanding</span>
                    <span className="value">N {formatNumber(log?.amount_overdue)}</span>
                  </div>
                </div>
              </div>
              <div className="contracts-log__meta contracts-log__bottom_detail">
                <div>
                  <span className="label">Status</span>
                  <span className="value">{log?.status?.toUpperCase()}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
