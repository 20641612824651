import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import './Navbar.scss';

import Logo from './logo.svg';
import ActiveUser from '../../assets/img/active-user.svg';
import LogoutIcon from '../../assets/img/logout-icon.svg';

// import { Search } from '../Forms/Search';
import { connect } from 'react-redux';
import { logout } from '../../reducers/auth';
import { withRouter } from 'react-router-dom';
import { getUserRole } from '../../axios/method';
import storageService from '../../services/storageService';
import { AMORTIZATION_OBJ, PRICE_TEMPLATE_OBJ } from '../../util/constant';

class NavBar extends Component {
  state = { activeItem: 'home', active: true };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const path = this.props.history.location.pathname;

    if (!path.includes('create-template') && !path.includes('confirm-price-template')) {
      storageService.remove(AMORTIZATION_OBJ);
      storageService.remove(PRICE_TEMPLATE_OBJ);
      storageService.remove('activePtStep');
      storageService.remove('isEdit');
    }

    const returnUserRole = (role) => {
      switch (getUserRole()) {
        case 'admin':
          return 'Finance';
        case 'super-admin':
          return 'Admin';
        default:
          return getUserRole();
      }
    };

    return (
      <div className="navbar-section">
        <Navbar
          bg="white"
          expand="lg">
          <Link to="/">
            <Navbar.Brand>
              <img
                src={Logo}
                className="logo"
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link
                className="champion-overview"
                to="/">
                <p
                  className={`champion-overview nav-link pl-md-5 pr-md-3 ${
                    path === '/' || path.includes('champion') ? 'active-item' : ''
                  }`}>
                  Champions
                </p>
              </Link>
{/* 
              <Link
                className="collections-nav"
                to="/collections">
                <p className={`collections nav-link px-md-3 ${path === '/collections' ? 'active-item' : ''}`}>
                  Collections
                </p>
              </Link> */}

              {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
                <Link
                  className="pricetemplates"
                  to="/pricetemplates">
                  <p
                    className={`pricetemplates nav-link px-md-3 ${
                      path === '/pricetemplates' || path === '/view-pricetemplates'
                        ? 'active-item'
                        : '' || path === '/create-template'
                        ? 'active-item'
                        : '' || path === '/confirm-price-template'
                        ? 'active-item'
                        : ''
                    }`}>
                    Price Templates
                  </p>
                </Link>
              )}

              {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
                <Link
                  className="vehicles"
                  to="/vehicles">
                  <p className={`vehicles nav-link px-md-3 ${path === '/vehicles' ? 'active-item' : ''}`}>Vehicles</p>
                </Link>
              )}

              {(getUserRole() === 'super-admin' || getUserRole() === 'admin') && (
                <Link
                  className="remote-lock"
                  to="/remote-lock">
                  <p className={`vehicles nav-link px-md-3 ${path === '/remote-lock' ? 'active-item' : ''}`}>
                    Remote Lock
                  </p>
                </Link>
              )}

              <Link
                className="contact-center"
                to="/contact-center">
                <p className={`contact-center nav-link px-md-3 ${path === '/contact-center' ? 'active-item' : ''}`}>
                  Contact Center
                </p>
              </Link>

              <Link
                className="advance-payment"
                to="/initiated-contract">
                <p
                  className={`advance-payment nav-link px-md-3 ${path === '/initiated-contract' ? 'active-item' : ''}`}>
                  Initiated Contracts
                </p>
              </Link>
            </Nav>

            <div className="d-inline-flex">
              <div className="mr-3">
                <p className="font-weight-bold mb-1">{this.props.fullName}</p>
                <p className="mb-0 text-capitalize">{returnUserRole()}</p>
              </div>

              <div className="dropdown">
                <img
                  className="dropbtn"
                  src={ActiveUser}
                  alt="active user"
                />

                <div className="dropdown-content">
                  <p
                    className="mb-0 cursor"
                    onClick={() => this.props.logout()}>
                    {' '}
                    <img
                      className="mr-2"
                      src={LogoutIcon}
                      alt="logout icon"
                    />
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

// try including the user's role later
// role: auth.role ? `${auth.role}` : "Admin"
// <Dropdown text={`${this.props.fullName} - ${this.props.role}`} pointing className="link item"></Dropdown>

const mapStateToProps = ({ auth }) => {
  return {
    fullName: auth.user ? `${auth.user.first_name} ${auth.user.last_name}` : 'MAX Analyst',
    role: auth.user && auth.user.role ? auth.user.role : ''
  };
};

export default connect(mapStateToProps, { logout })(withRouter(NavBar));
