import axios from "axios";
import { useMutation, useQuery } from "react-query";
import config from "../config/config";
import { getUserToken } from "../axios/method";

export const instance = axios.create({
  baseURL: config.base_url,
  headers: {
    "Content-Type": "application/json",
  },
});

let USER_TOKEN;

const getToken = () => {
  USER_TOKEN = getUserToken() || "";
  instance.defaults.headers.common["Authorization"] = USER_TOKEN;
};

const checkMethod = (options) => {
  if (options.method === "get") {
    return instance.get(options.end_point, options.data || {});
  }

  if (options.method === "post") {
    return instance.post(options.end_point, options.data || {});
  }

  if (options.method === "delete") {
    return instance.delete(options.end_point, options.data || {});
  }

  if (options.method === "put") {
    return instance.put(options.end_point, options.data || {});
  }
};

const queryClient = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [key, url, options] = queryKey;

  let res = await checkMethod({
    end_point: url,
    data: options.data,
    method: options.method,
  });

  return res;
};

export const useQueryClient = (url, options) => {
  const { key = "" } = options;
  getToken();
  return useQuery([key, url, options], queryClient, {
    enabled: options.enabled ?? true,
    retry: options.retry ?? false,
    refetchOnWindowFocus: false,
    onError: (data) => {
      if (options.onError) return options.onError(data);
    },

    onSuccess: (data) => {
      if (options.onSuccess) return options.onSuccess(data);
    },
  });
};

export const useMutationClient = (url, options) => {
  const { key = "" } = options;
  getToken();
  const queryKey = [key, url, options];
  return useMutation(() => queryClient({ queryKey }), {
    onError: (data) => {
      if (options.onError) return options.onError(data);
    },

    onSuccess: (data) => {
      if (options.onSuccess) return options.onSuccess(data);
    },
  });
};
