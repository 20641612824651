import {
  GENERATE_VNUBAN_ENDPOINT,
  FETCH_VNUBAN_ENDPOINT,
  CHAMPIONS_ENDPOINT,
  GENERATE_WOVEN_ACCOUNT,
  GENERATE_MONIFY_ACCOUNT,
  GET_CHAMPIONS_ACCOUNT,
  CREDIT_SCORE_ENDPOINT
} from '../axios/endpoints';
import { axiosInstance, getUserToken } from '../axios/method';
import { GET, POST } from '../util/constant';
import config from '../config/config';

const HEADERS = {
  'Content-Type': 'application/json'
};

export const getVNuban = (championPhoneNumber) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    headers: HEADERS,
    url: `${FETCH_VNUBAN_ENDPOINT}${championPhoneNumber}`
  });
};

export const generateNewVNuban = (data) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: POST,
    headers: HEADERS,
    url: GENERATE_VNUBAN_ENDPOINT,
    data: data
  });
};

export const getChampionInfo = (championId) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CHAMPIONS_ENDPOINT}/${championId}?version=2&relations=user,contract,guarantors,prospect,channel,country`,
    headers: HEADERS
  });
};

export const getChampionCreditScore = (championId) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${CREDIT_SCORE_ENDPOINT}/${championId}`,
    headers: HEADERS
  });
};
// virtual accounts
//woven accounts
export const createWovenAccount = (data) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: POST,
    url: GENERATE_WOVEN_ACCOUNT,
    headers: HEADERS,
    data
  });
};

// monify accounts
export const createMonifyAccount = (data) => {
  HEADERS['Authorization'] = 'Bearer ' + getUserToken();
  return axiosInstance({
    method: POST,
    url: GENERATE_MONIFY_ACCOUNT,
    headers: HEADERS,
    data
  });
};

// get virtual accounts
export const getVirtualAccountsByChampionID = (id) => {
  HEADERS['Authorization'] = config.lamda_vnuban_auth;
  return axiosInstance({
    method: GET,
    url: `${GET_CHAMPIONS_ACCOUNT}${id}`,
    headers: HEADERS
  });
};
// virtual accounts
