import { DurationInDaysMultiplier } from "./constant";

export const calculateDurationInDays = (
  payoffMonths, // payment duration in months
  multiplier // Dependent on payment frequency
) => {
  // calculate number of weeks within the the payment duration
  // WEEKS_IN_A_YEAR: number of weeks in a year = 52
  // MONTHS_IN_A_YEAR: number of months in year = 12
  const WEEKS_IN_A_YEAR = 52;
  const MONTHS_IN_A_YEAR = 12;
  const weeks = (payoffMonths * WEEKS_IN_A_YEAR) / MONTHS_IN_A_YEAR;
  // round up to the nearest whole number
  return Math.ceil(weeks * DurationInDaysMultiplier[multiplier]);
};
