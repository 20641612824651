import {
  VEHICLE_CLASS_ENDPOINT,
  HPV_ENDPOINT,
  MANUFACTURER_ENPOINT,
  MODEL_ENDPOINT,
  TRIM_ENDPOINT,
  VEHICLE_ENDPOINT,
  VEHICLE_LOCATION_ENDPOINT,
  VEHICLE_TYPE_ENDPOINT
} from "../axios/endpoints";
import { axiosInstance, getUserToken } from "../axios/method";
import { GET, PUT } from "../util/constant";

const HEADERS = {
  "Content-Type": "application/json"
};

export const getVehicles = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: VEHICLE_ENDPOINT,
    headers: HEADERS,
    params: params
  });
};

export const updateHpv = (vehicleId, data) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: PUT,
    url: `${HPV_ENDPOINT}/${vehicleId}/hpv`,
    headers: HEADERS,
    data: data
  });
};

export const getManufacturersService = () => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: MANUFACTURER_ENPOINT,
    headers: HEADERS
  });
};

export const unChurnChampion = (data) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: PUT,
    url: `${HPV_ENDPOINT}/unchurn/champion`,
    headers: HEADERS,
    data
  });
};

export const getModelByManufacturerService = (manufacturerId) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${MODEL_ENDPOINT}/manufacturer/${manufacturerId}`,
    headers: HEADERS
  });
};

export const getTrimByModelService = (modelId) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: `${TRIM_ENDPOINT}/model/${modelId}`,
    headers: HEADERS
  });
};

export const getVehicleLocations = () => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: VEHICLE_LOCATION_ENDPOINT,
    headers: HEADERS
  });
};

export const getVehicleTypes = () => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: VEHICLE_TYPE_ENDPOINT,
    headers: HEADERS
  });
};

export const getVehicleTypesByClass = (params) => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: VEHICLE_TYPE_ENDPOINT,
    headers: HEADERS,
    params
  });
};

export const getVehicleClassService = () => {
  HEADERS["Authorization"] = "Bearer " + getUserToken();
  return axiosInstance({
    method: GET,
    url: VEHICLE_CLASS_ENDPOINT,
    headers: HEADERS
  });
};
