import styled, { keyframes } from "styled-components";

const DoughnutFill = keyframes`
    to {
        stroke-dasharray: 0 100;
    }
`;

const textSlideup = keyframes`
from {
    opacity: 0;
    transform: translateY(0%);
}
to {
    opacity: 1;
    transform: translateY(-50%);
}
`;

const Donut = styled.div`
  position: relative;
  width: ${(props) => props.size}px;

  .is--negative .donut__circle {
    transform: rotate(-90deg) scaleY(-1);
  }

  .donut__canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: 2;
  }

  .donut__circle[stroke-dasharray="0 100"] {
    stroke: none;
  }

  .donut__text {
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    position: absolute;
    color: #2b2d42;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 3;
    transform: translateY(-50%);
    animation: ${textSlideup} 1s linear;
  }

  .donut.is--negative .donut__circle {
    transform: rotate(-90deg) scaleY(-1);
  }

  .donut-complex text {
    font-size: 1rem;
    font-weight: bold;
    text-shadow: 0 0 0.5rem #fff;
    opacity: 0;
    transition: opacity 0.35s ease-in-out 1s;
  }

  .donut-complex circle {
    fill: none;
    transition: stroke-dashoffset 1s ease-in-out;
  }

  .donut-complex:not(.donut-complex--loaded) circle {
    stroke-dashoffset: 0;
  }

  .donut-complex--loaded text {
    opacity: 1;
  }
`;

const DoughnutFrame = styled.circle`
  stroke: ${(props) => props.bgColor};
  stroke-width: 0.125rem;
  fill: none;
`;

const DoughnutCircle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  animation: ${DoughnutFill} 2s reverse;

  stroke: ${(props) => props.fillColor};
  stroke-linecap: round;
  stroke-width: 0.125rem;
  fill: none;
`;

export { Donut, DoughnutFrame, DoughnutCircle };
