import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQueryClient as useCustomQueryClient, useMutationClient } from 'services/apiClient';
import DotLoader from 'components/Loader/DotLoader';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from 'semantic-ui-react';
import { useQueryClient } from 'react-query';

export const ListOfAllCollectionOfficers = ({ show, onHide, assignedAgentData, championId: champion_id }) => {
  const queryClient = useQueryClient();
  const [collectionOfficersList, setCollectionOfficersList] = useState([]);
  const [currentAgent, setCurrentAgent] = useState('');

  const closeModal = () => {
    setCurrentAgent('');
    onHide();
  };

  const url = 'collection/v1/collection-agent?add_account_details=true';
  const { isLoading } = useCustomQueryClient(url, {
    key: 'collection_record_officers_list',
    method: 'get',
    onSuccess: (res) => {
      const { data: { data: collectionOfficersList } = {} } = res;
      setCollectionOfficersList(collectionOfficersList);
    },
    onError: () => {
      toast.error('Failed to fetch collection Officers List');
    }
  });

  const { mutate, isLoading: reassigningAgent } = useMutationClient('collection/v1/agents', {
    method: 'put',
    data: {
      champion_id,
      agent_id: currentAgent
    },

    onSuccess: () => {
      toast.success('Successfully reassigned collection officer');
      queryClient.invalidateQueries(['currently_assigned_collection_officer']);
      closeModal();
    },

    onError: (error = {}) => {
      const { response: { data: { message = '' } } = {} } = error;
      queryClient.invalidateQueries(['currently_assigned_collection_officer']);
      toast.error(message);
      closeModal();
    }
  });
  useEffect(() => {
    if (currentAgent) mutate();
    // eslint-disable-next-line
  }, [currentAgent]);

  const handleReassignAgent = (newAgentId) => {
    setCurrentAgent(newAgentId);
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <>
        <Modal.Header>
          <h3 className={'title'}>Collection Officers</h3>
          <CancelIcon onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="collection-officers-list">
            {isLoading && (
              <div className="loader-wrapper d-flex flex-column justify-content-center">
                <DotLoader />
                <div>Fetching Collection Officers</div>
              </div>
            )}

            <div className="">
              {collectionOfficersList.map((el) => {
                const { account = {}, id} = el;
                return (
                  <div
                    key={id}
                    className="action-button-container d-flex align-items-center justify-content-between px-4 py-2 mb-2">
                    <div className="d-flex flex-column">
                      <span className="font-weight-bold mb-1">
                        {account.first_name} {account.last_name}
                      </span>
                      <span className="text-secondary">{account.email}</span>
                    </div>
                    <div>
                      <Button
                        onClick={() => handleReassignAgent(id)}
                        disabled={assignedAgentData.id === account.id}>
                        {reassigningAgent && currentAgent === account.id ? (
                          <DotLoader />
                        ) : assignedAgentData.id === account.id ? (
                          'Already Assigned'
                        ) : (
                          'Reassign Agent'
                        )}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};
