import { LOCATION_ENDPOINT, PACKAGE_ENPOINT } from "../axios/endpoints"
import { axiosInstance, getUserToken } from "../axios/method"
import { DELETE, GET, POST, PUT } from "../util/constant"

const HEADERS = {
    'Content-Type': 'application/json'
}

export const getPackages = (params) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: GET,
        url: PACKAGE_ENPOINT,
        headers: HEADERS,
        params: params
    })
}

export const createPackage = (data) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: POST,
        url: PACKAGE_ENPOINT,
        headers: HEADERS,
        data: data
    })
}

export const updatePackageById = (packageId, data) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: PUT,
        url: `${PACKAGE_ENPOINT}/${packageId}`,
        data: data,
        headers: HEADERS
    })
}

export const assignPackageService = (selectedPackage, data) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: POST,
        url: `${PACKAGE_ENPOINT}/${selectedPackage}/vehicles`,
        data: data,
        headers: HEADERS
    })
}

export const reCalcHpAmountService = (params) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: GET,
        url: `${PACKAGE_ENPOINT}/hp-amount`,
        params: params,
        headers: HEADERS
    })
}

export const getPackageByIdService = (packageId) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: GET,
        url: `${PACKAGE_ENPOINT}/${packageId}`,
        headers: HEADERS
    })
}

export const getLocationService = () => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: GET,
        url: LOCATION_ENDPOINT,
        headers: HEADERS
    })
}

export const deletePriceTemplate = (id) => {
    HEADERS['Authorization'] = 'Bearer ' + getUserToken()
    return axiosInstance({
        method: DELETE,
        url: `${PACKAGE_ENPOINT}/${id}`,
        headers: HEADERS
    })
}