import React, { useRef, useState } from 'react';
import DotLoader from '../../components/Loader/DotLoader';
import BlueWallet from '../../assets/img/blue-wallet.svg';
import PurpleMoney from '../../assets/img/purple-money.svg';
import GreenMoney from '../../assets/img/green-money.svg';
import YellowBike from '../../assets/img/yellow-bike.svg';
import NumberFormatter from '../../util/numberFormatter';
import CardComponent from '../../components/Card/card.component';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { useMutationClient } from 'services/apiClient';
import { COLLECTION_ENDPOINT } from 'axios/endpoints';
import PulseLoader from 'components/Loader/PulseLoader';

const OverviewCard = ({ loading, contractStats }) => {
  const adminData = JSON.parse(localStorage.getItem('lams-data'));
  const callingCode = adminData.phone.substring(0, 4);
  const inputRef = useRef(null);
  const [vehicleCsvData, setVehicleCsvData] = useState(null);
  const [loadingCSVFileUpload, setLoadingCSVFileUpload] = useState();

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const { mutate } = useMutationClient(
    `${COLLECTION_ENDPOINT}/upload-payments?location=${
      callingCode === '+237' ? 'cameroon' : callingCode === '+233' ? 'ghana' : 'nigeria'
    }`,
    {
      method: 'post',
      data: {
        csvData: vehicleCsvData
      },

      onSuccess: (res) => {
        const { data: { message = '' } = {} } = res;
        toast.success(message);
        setLoadingCSVFileUpload(false);
        setVehicleCsvData('');
        inputRef.current.value = null;
      },
      onError: (error = {}) => {
        const { response: { data = {} } = {} } = error;
        toast.error(data.message);
        setLoadingCSVFileUpload(false);
        setVehicleCsvData('');
        inputRef.current.value = null;
      }
    }
  );

  React.useEffect(() => {
    if (vehicleCsvData) {
      mutate();
    }
    // eslint-disable-next-line
  }, [vehicleCsvData]);
  // handle upload of vehicle csv

  return (
    <div className="overview-card__component w-100">
      <div className="row">
        <div className="col-md-3 mb-md-0 mb-3">
          <CardComponent>
            <div className="row p-4 py-5">
              <div className="col-md-9">
                <p className="text-uppercase card-title mb-0 pb-0">Collection count</p>
                {loading ? (
                  <DotLoader />
                ) : (
                  <h5 className="card-values mt-2">
                    {new Intl.NumberFormat().format(contractStats?.collection_count)}
                  </h5>
                )}
              </div>
              <div className="col-md-3 my-auto">
                <div className="blue-card_icon d-flex justify-content-center align-content-center">
                  <img
                    src={BlueWallet}
                    alt="Wallet Icon"
                    style={{ maxWidth: '20px' }}
                  />
                </div>
              </div>
            </div>
          </CardComponent>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <CardComponent>
            <div className="row p-4 py-5">
              <div className="col-md-9">
                <p className="text-uppercase card-title mb-0 pb-0">Total Remittance (₦)</p>
                {loading ? (
                  <DotLoader />
                ) : (
                  <h5 className="card-values mt-2">{`${formatNumber(contractStats?.total_remittance)}`}</h5>
                )}
              </div>
              <div className="col-md-3 my-auto">
                <button
                  disabled={loadingCSVFileUpload}
                  className="purple-card_icon d-flex justify-content-center align-items-center">
                  <input
                    accept=".csv"
                    ref={inputRef}
                    onChange={(e) => {
                      setLoadingCSVFileUpload(true);
                      Papa.parse(e.target.files[0], {
                        header: false,
                        complete: (results) => {
                          if (results?.errors.length > 0) {
                            setLoadingCSVFileUpload(false);
                            toast.error('Please check csv file and try again');

                            setVehicleCsvData('');
                            return;
                          }
                          setVehicleCsvData(Papa.unparse(results?.data));
                        }
                      });
                    }}
                    className="position-absolute upload-csv-btn"
                    type="file"
                  />

                  {loadingCSVFileUpload ? (
                    <PulseLoader
                      width="30px"
                      height="30px"
                      bgColor="#777575"
                    />
                  ) : (
                    <img
                      src={PurpleMoney}
                      alt="Money Icon"
                      style={{ maxWidth: '20px' }}
                    />
                  )}
                </button>
              </div>
            </div>
          </CardComponent>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <CardComponent>
            <div className="row p-4 py-5">
              <div className="col-md-9">
                <p className="text-uppercase card-title mb-0 pb-0">Total Amount Overdue (₦)</p>
                {loading ? (
                  <DotLoader />
                ) : (
                  <h5 className="card-values mt-2">{`${formatNumber(contractStats?.total_amount_overdue)}`}</h5>
                )}
              </div>
              <div className="col-md-3 my-auto">
                <div className="green-card_icon d-flex justify-content-center align-content-center">
                  <img
                    src={GreenMoney}
                    alt="Wallet Icon"
                    style={{ maxWidth: '20px' }}
                  />
                </div>
              </div>
            </div>
          </CardComponent>
        </div>

        <div className="col-md-3 mb-md-0 mb-3 pointer">
          <CardComponent>
            <div className="row p-4 py-5">
              <div className="col-md-9">
                <p className="text-uppercase card-title mb-0 pb-0">Total Assets Due For Retrival</p>
                {loading ? (
                  <DotLoader />
                ) : (
                  <h5 className="card-values mt-2">{`${new Intl.NumberFormat().format(
                    contractStats?.total_assets_due_for_retrieval
                  )}`}</h5>
                )}
              </div>
              <div className="col-md-3 my-auto">
                <div className="yellow-card_icon d-flex justify-content-center align-content-center">
                  <img
                    src={YellowBike}
                    alt="Wallet Icon"
                    style={{ maxWidth: '20px' }}
                  />
                </div>
              </div>
            </div>
          </CardComponent>
        </div>
      </div>
    </div>
  );
};

export default OverviewCard;
