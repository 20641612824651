import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { formatNumber } from 'pages/Overview/OverviewTable';
import { useQueryClient } from 'services/apiClient';
import { CONTRACT_ENDPOINT } from 'axios/endpoints';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import 'components/Champion/add-transaction.scss';
import './style.scss';
import { useContractProvider } from './contracts';
import { toast } from 'react-toastify';

const RestructureContract = ({ contractId: contract_id }) => {
  const {
    setRestructureData,
    restructureData = {},
    toggleContractReviewModal,
    showRestructureModal,
    toggleRestructureModal
  } = useContractProvider();

  const { startDate: start_date = '', previewData = {} } = restructureData;

  const onHide = () => {
    toggleRestructureModal(false);
  };

  useQueryClient(`${CONTRACT_ENDPOINT}/overdue-repayment-schedule/preview?contract_id=${contract_id}`, {
    key: 'collection_record',
    enabled: !!contract_id,
    method: 'get',
    onSuccess: (previewDataRes) => {
      const { data: { data } = {} } = previewDataRes;
      // destructure historyData
      setRestructureData((prevData) => {
        return {
          ...prevData,
          previewData: data
        };
      });
    },
    onError: (errorData = {}) => {
      toast(errorData.response?.data?.message);
      onHide();
    }
  });

  const { refetch } = useQueryClient(
    `${CONTRACT_ENDPOINT}/overdue-repayment-schedule/preview?contract_id=${contract_id}${start_date !== '' ? `&start_date=${start_date}` : ''}`,
    {
      key: 'collection_record',
      enabled: false,
      refetchOnWindowFocus: false,
      method: 'get',
      onSuccess: (previewDataRes) => {
        const { data: { data } = {} } = previewDataRes;
        // destructure historyData
        onHide();
        toggleContractReviewModal(true);
        setRestructureData((prevData) => {
          return {
            ...prevData,
            previewData: data
          };
        });
      },
      onError: (errorData = {}) => {
        toast(errorData.response?.data?.message);
        setRestructureData((prevData) => {
          return {
            ...prevData,
            lumpSum: 0
          };
        });
        setRestructureData((prevData) => {
          return {
            ...prevData,
            startDate: ''
          };
        });
        onHide();
      }
    }
  );

  const handleUpdateStartDate = (e) => {
    const { target = {} } = e;
    setRestructureData((prevData) => {
      return {
        ...prevData,
        startDate: target.value
      };
    });
  };

  return (
    <>
      <Modal
        onHide={onHide}
        show={showRestructureModal}
        dialogClassName="transaction-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <h3 className={'title'}>Add Payment Transaction</h3>
          <CancelIcon
            color="#E2E2E2"
            onClick={onHide}
          />
        </Modal.Header>

        <Modal.Body className="borde">
          <div className="form add-reference-id-form">
            <div>
              <label>Total Amount Overdue</label>
              <div className="outstanding">₦{formatNumber(previewData.amount_overdue)}</div>
            </div>

            <label>Start Date</label>
            <input
              id="start-date-picker"
              type="date"
              name="startDate"
              value={start_date}
              onChange={handleUpdateStartDate}
            />

            <hr />
            <div className=" d-flex">
              <button
                disabled={!start_date}
                onClick={() => {
                  refetch();
                }}
                className="modal_form_btn">
                Continue
              </button>
              <div className="px-3"></div>
              <button
                onClick={onHide}
                className="modal_form_btn cancel">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RestructureContract;
