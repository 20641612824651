import React, { useState } from 'react';
import { Icon, Form, Dropdown } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import TableComponent from 'components/Table/table.component';
import UtilFn from 'util/util-fn';
import format from 'date-fns/format';
import { ReactComponent as HelmetYellow } from 'assets/img/helmet-yellow.svg';
import { formatNumber } from 'pages/Overview/OverviewTable';
import './style.scss';
import TableLoader from '../../components/Loader/TableLoader';
import { getHpStatusColorCode, getRetrivalStatusColorCode } from 'util/ui-helper';
import { useHistory } from 'react-router-dom';

// config file
import config from '../../config/config';
import CustomPagination from '../../components/CustomPagination/customPagination.component';
import NoData from '../../components/NoData';
import { useQueryClient } from 'services/apiClient';
import ChampionDetails from './champion-details';

const AdvancePayment = ({ tableHeader }) => {
  const [searchParams, setSearchParams] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(config.pageSize);
  const [selectedContract, setSelectedContract] = useState({});
  const [showChampionDetails, toggleShowChampionDetails] = useState(false);

  let history = useHistory();

  const [getAdvancePaymentURL, setAdvancePaymentURL] = React.useState('');
  React.useEffect(() => {
    // setQueryForVehicles
    let url;
    if (searchParams === '') {
      url = `collection/v1/contracts?limit=${pageSize}&page=${currentPage}&status_id=7`;
    } else {
      url = `collection/v1/contracts?page=${currentPage}&limit=${pageSize}&search=${searchParams}&status_id=7`;
    }
    setAdvancePaymentURL(url);
  }, [searchParams, pageSize, currentPage]);

  const { data: contractsData = {}, isLoading: loadingAllVehicles } = useQueryClient(getAdvancePaymentURL, {
    method: 'get',
    enabled: !!getAdvancePaymentURL
  });
  let _contracts_data = [];
  _contracts_data = contractsData && contractsData?.data?.data?.contracts;
  let pagination = contractsData && contractsData?.data?.data?.pagination;

  const onPageChange = (e) => {
    const { selected } = e;
    setCurrentPage(selected + 1);
  };

  const onPageSizeChange = (e) => {
    const { value } = e.target;
    setCurrentPage(1);
    setPageSize(value);
  };

  return (
    <div className="custom-container">
      <div
        className="sticky-header px-0"
        style={{
          position: 'sticky',
          top: '0',
          zIndex: '999',
          background: '#f8fbff',
          width: '100%',
          padding: '1.5rem'
        }}>
        <div className="col-md-12 px-4">
          <div className="row no-gutters">
            <div className="col-md-5 my-auto">
              <div className="text-left w-100 pagination-div">
                <Link to="/vehicles">
                  <p className="text-black"></p>
                </Link>
              </div>
            </div>
            <div className="flex-grow-1 d-flex">
              <div className="text-right flex-grow-1 d-flex">
                <div className="col h-100">
                  <Form className="h-100">
                    <div>
                      <Form.Field>
                        <div
                          className="search h-100"
                          style={{ width: '70%' }}>
                          <input
                            className=" h-100"
                            placeholder={'Search Table'}
                            style={{ backgroundColor: '#fff' }}
                            name="search"
                            value={searchParams}
                            onChange={(e) => {
                              const {
                                target: { value }
                              } = e;
                              setSearchParams(value);
                            }}
                          />

                          <Icon name="search" />
                        </div>
                      </Form.Field>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table loader */}
      {loadingAllVehicles && <TableLoader />}

      {!loadingAllVehicles &&
        // (vehicles.length === 0 ? (
        (_contracts_data?.length === 0 ? (
          <NoData text="No vehicle was found!" />
        ) : (
          <div className="locked-vehicle-table-wrapper bg-white">
            <TableComponent>
              <thead>
                <tr>
                  {tableHeader.map((el, index) => (
                    <th key={`${el}_${index}`}>{el}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_contracts_data &&
                  _contracts_data.map((data, index) => {
                    const {
                      name,
                      max_champion_id,
                      phone,
                      amount_overdue,
                      days_overdue,
                      last_amount_remitted,
                      payment_status,
                      hp_status,
                      location_name
                    } = data;
                    return (
                      <React.Fragment key={`${data.id}_${index}`}>
                        <tr key={index}>
                          <td>{UtilFn.paginationNumbering(pageSize, currentPage, index)}</td>
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/champion/${data.champion_id}`, {
                                championObj: data
                              });
                            }}>
                            <div className="d-flex align-items-center m-0 pointer">
                              <div className="mr-3 pointer">
                                <HelmetYellow />
                              </div>
                              <div className="d-flex flex-column pointer">
                                <span>{name || 'N/A'}</span>
                                <span className="txt_orange">{max_champion_id || 'N/A'}</span>
                              </div>
                            </div>
                          </td>

                          <td>{phone || 'N/A'}</td>
                          <td>{formatNumber(amount_overdue)}</td>
                          <td>{days_overdue}</td>
                          <td>{formatNumber(last_amount_remitted)}</td>
                          <td>
                            {data.last_day_remitted ? format(new Date(data.last_day_remitted), 'DD/MMM/YYYY') : 'N/A'}
                          </td>
                          <td>
                            <div className={`${getRetrivalStatusColorCode(payment_status)}`}>
                              {payment_status || 'N/A'}
                            </div>
                          </td>
                          <td>
                            <div className={`${getHpStatusColorCode(hp_status)}`}>{hp_status?.name || 'N/A'}</div>
                          </td>
                          <td>{location_name?.toUpperCase() || 'N/A'}</td>
                          <td>
                            <Dropdown
                              direction="left"
                              icon="ellipsis vertical">
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  text="View Champion Info"
                                  onClick={() => {
                                    setSelectedContract(data);
                                    toggleShowChampionDetails(true);
                                  }}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </TableComponent>
            <ChampionDetails
              contract={selectedContract}
              open={showChampionDetails}
              onClose={() => {
                toggleShowChampionDetails(!showChampionDetails);
                setSelectedContract({});
              }}
            />
            <CustomPagination
              onPageChange={onPageChange}
              currentPage={currentPage}
              pageCount={pagination?.pageCount}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
          </div>
        ))}
    </div>
  );
};

export default withRouter(AdvancePayment);

AdvancePayment.defaultProps = {
  tableHeader: [
    '#',
    'Champions',
    'Phone Number',
    'TAO (₦)',
    'TDO',
    'LAR (₦)',
    'LDR',
    'Retrieval Status',
    'HP Status',
    'Location',
    ' '
  ]
};
