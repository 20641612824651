import React from "react";
import Modal from "react-bootstrap/Modal";
import "../add-transaction.scss";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NumberFormatter from "util/numberFormatter";
import format from "date-fns/format";

export const AddTransactionSuccess = () => {
  const adminData = JSON.parse(localStorage.getItem("lams-data"));
  const { first_name, last_name } = adminData;
  const {
    addTransactionSuccess,
    setAddTransactionSuccess,
    userDataForAddTransaction,
  } = useChampionPaymentHistory();

  const { _payment_channel, amount, created_at } = userDataForAddTransaction;

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  const listContent = [
    { title: "Payment Amount", value: `NGN ${formatNumber(amount)}` },
    { title: "Transaction Date", value: format(created_at, "DD/MM/YYYY") },
    { title: "Date Added", value: format(new Date(), "DD/MM/YYYY") },
    { title: "Payment Channel", value: _payment_channel },
    { title: "Creator", value: `${first_name} ${last_name}` },
  ];
  return (
    <Modal
      onHide={() => setAddTransactionSuccess(!addTransactionSuccess)}
      show={addTransactionSuccess}
      dialogClassName={"transaction-modal success-modal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <>
        <Modal.Header>
          <h3 className="title">Add Payment Transaction</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="outcome-header-wrapper success">
            <div className="outcome-icon-wrapper">
              <CheckCircleIcon />
            </div>
            <p>Payment successfully added</p>
          </div>

          <div className="duo-col-list">
            <div className="header">Payment Details</div>
            <div className="body">
              {listContent.map((item, idx) => (
                <div className="duo-col-item" key={idx}>
                  <span className="label">{item?.title}</span>
                  <span className="value">{item?.value}</span>
                </div>
              ))}
            </div>
          </div>

          <button
            className="modal_form_btn"
            onClick={() => setAddTransactionSuccess(!addTransactionSuccess)}>
            Continue
          </button>
        </Modal.Body>
      </>
    </Modal>
  );
};
