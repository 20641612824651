import React from 'react';
import config from 'config/config';
import { Loader } from 'semantic-ui-react';
import storageService from 'services/storageService';
import { AUTHENTICATION_TOKEN } from 'util/constant';

const ContactCenter = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const sendmsg = (e) => {
    setIsLoading(false);

    let token = storageService.get(AUTHENTICATION_TOKEN);

    e.target.contentWindow.postMessage(JSON.stringify({ token, req_origin: 'LAMS' }), '*');
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {isLoading && <Loader />}
        <div style={{ height: '100vh' }}>
          <iframe
            id="iframe_doms"
            title="contact center webview"
            allow="microphone;autoplay"
            onLoad={sendmsg}
            src={config.contact_center_source}
            frameBorder="0"
            width={'100%'}
            height={'100%'}></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactCenter;
