import React from "react";
import Modal from "react-bootstrap/Modal";
import "../add-transaction.scss";
import { useChampionPaymentHistory } from "contexts/champion/transaction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import format from "date-fns/format";
import NumberFormatter from "util/numberFormatter";
import { useQueryClient } from "services/apiClient";
import ValidationUtil from "util/validation-util";

export const VoidTransactionModal = () => {
  const {
    selectedTransaction,
    voidTransactionObject,
    setVoidTransactionObject,
  } = useChampionPaymentHistory();
  const { showVoidModal, transactionReference, contractId, championId } =
    voidTransactionObject;
  const [duplicates, setDuplicates] = React.useState([]);

  React.useEffect(() => {
    if (!ValidationUtil.isObjectEmpty(selectedTransaction)) {
      setVoidTransactionObject((prevState) => {
        return {
          ...prevState,
          championId: selectedTransaction.champion_id,
          contractId: selectedTransaction.contract_id,
          transactionReference: selectedTransaction.transaction_source_id,
          id: selectedTransaction.id,
        };
      });
    }
    // eslint-disable-next-line
  }, [selectedTransaction]);

  const url = `collection/v1/collections/champions/dups/${transactionReference}?contract_id=${contractId}&champion_id=${championId}`;
  useQueryClient(url, {
    method: "get",
    enabled: !!contractId,
    onSuccess: (data) => {
      const { data: { data: duplicates = [] } = {} } = data;
      setDuplicates(duplicates);
    },
  });

  const { amount, createdAt } = selectedTransaction;
  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  // const checkForDuplicates = () => {
  //   if (duplicates.length <= 1) return "No Duplicated Found";
  //   if (duplicates.length > 1) return duplicates.length - 1;
  // };

  const transactionInfo = [
    { label: "Transaction Amount", value: `NGN ${formatNumber(amount)}` },
    { label: "Transaction Date", value: format(createdAt, "DD/MM/YYYY") },
    { label: "Channel", value: "" },
    // { label: "No of Duplicates", value: checkForDuplicates() },
  ];

  const closeModal = () => {
    setVoidTransactionObject((prevState) => {
      return { ...prevState, showVoidModal: !prevState.showVoidModal };
    });
  };

  const voidPayment = () => {
    setVoidTransactionObject((prevState) => {
      return {
        ...prevState,
        showConfirmVoidModal: !prevState.showConfirmVoidModal,
      };
    });
    closeModal();
  };

  return (
    <Modal
      onHide={closeModal}
      show={showVoidModal}
      dialogClassName="transaction-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <h3 className={"title"}>Void Transaction</h3>
        <div className="close-btn-wrapper">
          <button className="unstyled-btn" onClick={() => closeModal()}>
            <HighlightOffIcon />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="transaction-modal-content">
          <div className="list-wrapper">
            {transactionInfo.map((item, index) => (
              <div key={index} className="list-item">
                <span className="title">{item.label}</span>
                <span className="detail">{item.value}</span>
              </div>
            ))}
          </div>

          <button
            disabled={duplicates.length <= 1}
            onClick={voidPayment}
            className="destructive modal_form_btn">
            VOID PAYMENT
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
