import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import TableComponent from '../Table/table.component';
import format from 'date-fns/format';

import './amortization-schedule.styles.scss';
import NumberFormatter from '../../util/numberFormatter';
import { Button, Table } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';

import GreyExcelIcon from '../../assets/img/grey-excel.svg';
import WhiteExcelIcon from '../../assets/img/white-excel.svg';
import UpRightArrow from '../../assets/img/arrow-up-right.svg';
import styled from 'styled-components';

import { getPauseHPreasons } from '../../services/contractService';
import PausedDaysModal from '../PausedDaysModal/PausedDaysModal';

const AmortizationSchedule = ({
  show,
  setAmortizationSchedule,
  toAmortizationProfile,
  championsAmortizationSchedule,
  formatedCsvData,
  championContractId
}) => {
  const sortedArray = championsAmortizationSchedule?.amortization_schedule?.sort(function (a, b) {
    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
  });

  let exportData = [];
  let formattedData = [];
  if (championsAmortizationSchedule?.amortization_schedule) {
    exportData = [...championsAmortizationSchedule?.amortization_schedule];
    exportData.forEach((data) => {
      let obj = {
        period_closing: data.period,
        date: data.date,
        repayment: data.repayment ? NumberFormatter.invertNumberSign(data?.repayment) : 'N/A',
        principal_bop: data.principal_bop ? NumberFormatter.formatNumber(data?.principal_bop) : 'N/A',
        interest_paid: data.interest_paid ? NumberFormatter.invertNumberSign(data?.interest_paid) : 'N/A',
        principal_repaid: data.principal_repaid ? NumberFormatter.invertNumberSign(data?.principal_repaid) : 'N/A',
        principal_eop: data.principal_eop >= 0 ? NumberFormatter.formatNumber(data?.principal_eop) : 'N/A'
      };
      formattedData.push(obj);
    });
  }

  const HeaderCell = styled(Table.HeaderCell)`
    background-color: #fef5ba !important;
  `;

  const [showPausedDaysModal, setShowPausedDaysModal] = useState(false);
  const openPausedDaysModal = (show) => {
    setShowPausedDaysModal(show);
  };

  const [pausalHistory, setPausalHistory] = useState([]);
  const [pausedDaysTotal, setPausedDaysTotal] = useState(0);

  const getPausedHistoryData = async (contractId) => {
    try {
      const pausedHistoryData = await getPauseHPreasons(contractId);
      const pausedHistory = pausedHistoryData?.data?.data;
      const pausedDaysAll = pausedHistory.reduce((element, current) => {
        const pausedDays = parseInt(element) + parseInt(current?.duration);
        return pausedDays;
      }, 0);
      setPausalHistory(pausedHistory);
      setPausedDaysTotal(pausedDaysAll);
    } catch (error) {
      setPausalHistory([]);
      setPausedDaysTotal(0);
    }
  };

  useEffect(() => {
    if (championContractId) {
      getPausedHistoryData(championContractId);
    }
  }, [championContractId]);

  return (
    <div>
      <PausedDaysModal
        show={showPausedDaysModal}
        setShowPausedDaysModal={setShowPausedDaysModal}
        pausedHistory={pausalHistory}
        pausedDaysTotal={pausedDaysTotal}
        expectedEndDate={
          sortedArray?.length > 0 ? format(new Date(sortedArray[sortedArray.length - 1].date), 'DD/MMM/YYYY') : 'N/A'
        }
      />
      <div className="amortization-schedule">
        <Modal show={show}>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex">
                  <i
                    className="arrow left icon mr-3 cursor"
                    onClick={toAmortizationProfile}></i>

                  <div>
                    <p
                      style={{ fontSize: '16px' }}
                      className="font-weight-bold mb-0">
                      Amortization schedule
                    </p>

                    <p className="text-secondary">Schedule for Payment</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="cancel-icon ml-auto cursor"
                  onClick={() => setAmortizationSchedule(false)}>
                  <span className="icon-text">x</span>
                </div>
              </div>
            </div>

            <hr />
            <div className="amortization-table">
              <div className="col-md-12 text-right mb-4">
                <CSVLink
                  filename="amortization-schedule.csv"
                  data={formattedData}>
                  <Button
                    style={{ padding: '7px 20px' }}
                    className="btn-csv">
                    <img
                      src={GreyExcelIcon}
                      className="mr-2 grey-excel__icon"
                      style={{ maxWidth: '23px' }}
                      alt="excel"
                    />
                    <img
                      src={WhiteExcelIcon}
                      className="mr-2 white-excel__icon"
                      style={{ maxWidth: '23px' }}
                      alt="excel"
                    />
                    Export as Excel
                  </Button>
                </CSVLink>
              </div>
              <TableComponent>
                <Table.Header>
                  <Table.Row>
                    <HeaderCell>Period Closing</HeaderCell>
                    <HeaderCell>Date</HeaderCell>
                    <HeaderCell>Repayment</HeaderCell>
                    <HeaderCell>Amount Paid</HeaderCell>
                    <HeaderCell>Payment Date</HeaderCell>
                    <HeaderCell>Culmulative Expected</HeaderCell>
                    <HeaderCell>Culmulative Amount Paid</HeaderCell>
                    <HeaderCell>Daily Update Status</HeaderCell>
                  </Table.Row>
                </Table.Header>

                <tbody>
                  {sortedArray &&
                    sortedArray.length > 0 &&
                    sortedArray.map((schedule, index) => (
                      <tr
                        className="cursor"
                        key={index}>
                        <td>{index + 1}</td>
                        {/* <td>{schedule?.period}</td> */}
                        <td>{format(new Date(schedule.date), 'DD/MMM/YYYY')}</td>
                        <td>{schedule.repayment ? NumberFormatter.invertNumberSign(schedule?.repayment) : 'N/A'}</td>
                        <td>{schedule.amount_paid ? NumberFormatter.formatNumber(schedule?.amount_paid) : '0'}</td>
                        <td>
                          {schedule.payment_date ? format(new Date(schedule.payment_date), 'DD/MMM/YYYY') : 'N/A'}
                        </td>
                        <td>
                          {schedule.cumulative_expected
                            ? NumberFormatter.formatNumber(schedule?.cumulative_expected)
                            : '0'}
                        </td>
                        <td>
                          {schedule.cumulative_amount_paid
                            ? NumberFormatter.formatNumber(schedule?.cumulative_amount_paid)
                            : '0'}
                        </td>

                        <td>{schedule.daily_update_status ? <div className={'green-div'}>{'SUCCESS'}</div> : 'N/A'}</td>
                      </tr>
                    ))}
                </tbody>
              </TableComponent>
            </div>

            <div className="row">
              <div className="col">
                <div className="row mt-5 mb-3 pt-3">
                  <div className="col-md-5">
                    <p className="text-secondary">Total duration (days)</p>
                  </div>
                  <div className="col-md-5">
                    <p className="font-weight-bold">{championsAmortizationSchedule?.duration_in_days}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <p className="text-secondary">Expected HP end date</p>
                  </div>
                  <div className="col-md-5">
                    <p className="font-weight-bold">
                      {/* {format(
                        new Date(
                          championsAmortizationSchedule?.expected_hp_end_date
                        ),
                        "DD/MMM/YYYY"
                      )} */}

                      {sortedArray?.length > 0
                        ? format(new Date(sortedArray[sortedArray.length - 1].date), 'DD/MMM/YYYY')
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col pt-5">
                <div className="container__with-border">
                  <div className="container__left">
                    <div className="left__top">
                      <div className="top_first">
                        <p className="bolder-text">{pausedDaysTotal}</p>
                      </div>
                      <div className="top_second">
                        <div>
                          <p className="v-days-text">VIEW DAYS</p>
                        </div>
                        <div className="up-arrow-container">
                          {pausalHistory?.length > 0 ? (
                            <img
                              alt="top arrow"
                              src={UpRightArrow}
                              onClick={() => {
                                openPausedDaysModal(true);
                              }}
                            />
                          ) : (
                            <img
                              alt="top arrow"
                              src={UpRightArrow}
                              onClick={() => openPausedDaysModal(false)}
                              disabled
                              className="disabled"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="left__bottom">
                      <p className="bottom-text">Days Paused</p>
                    </div>
                  </div>
                  <div className="container__right">
                    <div className="right__top">
                      <p className="bolder-text">+{pausedDaysTotal} Days</p>
                    </div>
                    <div className="right__bottom">
                      <p className="bottom-text">To expected HP end date</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AmortizationSchedule;
