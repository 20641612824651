import React from 'react';

// css import
import './modal.scss'


const Modal = ({children, show}) => {
    return (
        <div id="customModal" className={`modal ${show ? "d-block" : "d-none"}`}>
            <div className="modal-content">
                {children}
            </div>

        </div>
    )
}

export default Modal