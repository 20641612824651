import { useQueryClient } from 'services/apiClient';

export default function useCollectionOfficersAssignment({ id }) {
  const api_prefix = 'collection/v1/';

  const contractsLogURL = `${api_prefix}agents/${id}`;
  const { data: assignedAgentResponse = {} } = useQueryClient(contractsLogURL, {
    key: "currently_assigned_collection_officer",
    method: 'get',
    retry: 1,
    enabled: !!id
  });

  const { data: { data: assignedAgentData = {} } = {} } = assignedAgentResponse; // && !ValidationUtil.isObjectEmpty(selectedTransaction);
  return { assignedAgentData };
}
