import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { formatNumber } from 'pages/Overview/OverviewTable';
import PulseLoader from 'components/Loader/PulseLoader';
import { useMutationClient } from 'services/apiClient';
import { CONTRACT_ENDPOINT } from 'axios/endpoints';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import 'components/Champion/add-transaction.scss';
import './style.scss';
import { useContractProvider } from './contracts';
import format from 'date-fns/format';
import { useQueryClient } from 'react-query';

const ContractReview = ({ contractId: contract_id }) => {
  const queryClient = useQueryClient();
  const {
    setRestructureData,
    showContractReviewModal,
    toggleContractReviewModal,
    restructureData = {},
    toggleRestructureModal
  } = useContractProvider();

  const { previewData = {} } = restructureData;
  const date = new Date();

  const { mutate, isLoading } = useMutationClient(`${CONTRACT_ENDPOINT}/create-overdue-repayment-schedule`, {
    method: 'post',
    data: {
      contract_id,
      start_date: date.toISOString()
    },

    onSuccess: (data = {}) => {
      const { data: { message = '' } = {} } = data;
      toast.success(message);
      queryClient.invalidateQueries(['contracts_record']);
      queryClient.invalidateQueries(['restructuring_details']);
      queryClient.invalidateQueries(['contract_information']);
      queryClient.invalidateQueries(['overdue_repayment_most_recent']);
      clearRestructureData();
      onHide();
    },

    onError: (data = {}) => {
      const { response: { data: resData = {} } = {} } = data;
      toast.error(resData.message);
      queryClient.invalidateQueries(['contracts_record']);
      queryClient.invalidateQueries(['restructuring_details']);
      queryClient.invalidateQueries(['contract_information']);
      queryClient.invalidateQueries(['overdue_repayment_most_recent']);
      clearRestructureData();
      onHide();
    }
  });

  const clearRestructureData = () => {
    setRestructureData((prevData) => {
      return {
        ...prevData,
        previewData: {}
      };
    });
    setRestructureData((prevData) => {
      return {
        ...prevData,
        lumpSum: 0
      };
    });
    setRestructureData((prevData) => {
      return {
        ...prevData,
        startDate: ''
      };
    });
  };
  const onHide = () => {
    toggleContractReviewModal(false);
  };

  const detailArray = [
    { label: 'Duration (Days)', value: previewData.no_of_days_for_repayment },
    { label: 'Tranche Start Date', value: format(previewData.tentative_start_date, 'DD/MM/YYYY') ?? 'N/A' },
    { label: 'Tranche End Date', value: format(previewData.tentative_end_date, 'DD/MM/YYYY') ?? 'N/A' },
    { label: 'Total HP Value', value: `₦ ${formatNumber(previewData.total_hp)}` },
    { label: 'Old Daily Remit', value: `₦ ${formatNumber(previewData.old_daily_remit)}` },
    { label: 'Contract End Date', value: format(previewData.contract_end_date, 'DD/MM/YYYY') ?? 'N/A' }
  ];

  return (
    <>
      <Modal
        onHide={onHide}
        show={showContractReviewModal}
        dialogClassName="transaction-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <h3 className={'title'}>Contract Review</h3>
          <CancelIcon
            color="#E2E2E2"
            onClick={onHide}
          />
        </Modal.Header>

        <Modal.Body className=" borde">
          <div className="form add-reference-id-form preview-contract">
            <div className="preview-contract_header">New Tranche</div>
            <div className="preview-contract_tranche-detail">
              <div className="d-flex flex-column">
                <span className="preview-contract_tranche-detail-label">New Contract Tranche</span>
                <span className="preview-contract_tranche-detail-sub-label">Daily remittance </span>
              </div>
              <div className="preview-contract_tranche-detail-value">₦{formatNumber(previewData.new_daily_remit)}</div>
            </div>

            <div className="preview-contract_tranche-list-wrapper">
              {detailArray.map((item, index) => {
                const { label, value } = item;
                return (
                  <div
                    key={index}
                    className="preview-contract_tranche-list">
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="d-flex preview-contract_header-footer">
            <button
              onClick={mutate}
              className="modal_form_btn save-new-changes">
              SAVE NEW CHANGES{' '}
              {isLoading && (
                <PulseLoader
                  width="15px"
                  height="15px"
                  bgColor="#777575"
                />
              )}
            </button>
            <div className="px-3"></div>
            <button
              onClick={() => {
                onHide();
                toggleRestructureModal(true);
              }}
              className="modal_form_btn cancel">
              GOBACK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractReview;
