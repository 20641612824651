import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CONTRACT_ENDPOINT } from '../../axios/endpoints';
import config from '../../config/config';
import { getChampionInfo, createWovenAccount, createMonifyAccount } from '../../services/championService';
import NumberFormatter from '../../util/numberFormatter';
import { handleAxiosError } from '../../axios/handleAxiosError';
import LockIcon from '@mui/icons-material/Lock';
import { useMutationClient } from 'services/apiClient';
import Status from './Status';
import ChampionBioInfo from './ChampionBioInfo';
import ChampionContract from './ChampionContract';
import History from './History';
import '../../components/Loader/skeleton.scss';
import DotLoader from '../../components/Loader/DotLoader';
import CallHistory from './CallHistory';
import CustomDoughnut from '../../components/CustomDoughnut';
import Modal from '../../components/Modal/Modal';

import profileDefault from '../../assets/img/profile-default.png';
import helmetIcon from '../../assets/img/helmet.svg';
import contractIcon from '../../assets/img/contract.svg';
import historyIcon from '../../assets/img/history.svg';
import activeHelmetIcon from '../../assets/img/active-helmet.svg';
import activeContractIcon from '../../assets/img/active-contract.svg';
import activeHistoryIcon from '../../assets/img/active-history.svg';
import { useChampionPaymentHistory } from 'contexts/champion/transaction';
import { useQueryClient } from '../../services/apiClient';
import { useQueryClient as useReactQueryClient } from 'react-query';
import { getHpStatusColorCode, retrievalStatusColors, getRetrivalStatusColorCode } from 'util/ui-helper';

import './ChampionBio.scss';
import ContractProvider from 'components/Contracts/contracts';
import swal from 'sweetalert';

const ChampionBio = ({ match }) => {
  const queryClient = useReactQueryClient();
  const { id: championId } = match.params;
  const history = useHistory();
  const { championInfo, setChampionInfo } = useChampionPaymentHistory();
  const [activeTab, setActiveTab] = useState('bio');
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [vNubanInfo] = useState([]);

  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(false);
  const [vehicleloading, setVehicleLoading] = useState(false);
  const selectTab = (activeTab) => {
    setActiveTab(activeTab);
  };
  const [accountCreationLoader, setAccountCreationLoader] = useState(false);
  const [selectedAccountProvider, setSelectedAccountProvider] = useState('');
  const [accountProviderModal, setAccountProviderModal] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  const getChampionBioInfo = async (params) => {
    setLoading(true);
    setVehicleLoading(true);
    const { id } = params;
    let response;
    try {
      response = await getChampionInfo(id);
      setChampionInfo(response.data && response.data.data);
      // start the process for getting vnuban info
      // getFormattedPhoneNumber(response);
    } catch (err) {
      toast.error(handleAxiosError(err)?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const getVehicleInfo = async (vehicleId) => {
    setVehicleLoading(true);
    let response;
    let token = localStorage.getItem('lams-token');
    try {
      response = await Axios.get(`${config.vehicle_base_url}/v1/vehicle/${vehicleId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setVehicleInfo(response.data && response.data.data);
    } catch (err) {
      toast.error(handleAxiosError(err).message || 'Something went wrong');
    } finally {
      setVehicleLoading(false);
    }
  };

  const fetchSingleContractURL = `${CONTRACT_ENDPOINT}/champions/${championId}`;
  const { isLoading: contractLoader, refetch: refetchContractInfo } = useQueryClient(fetchSingleContractURL, {
    key: 'contract_information',
    retry: 3,
    method: 'get',
    enabled: championId !== '',
    onSuccess: (res) => {
      setContract(res?.data?.data);
      getVehicleInfo(res?.data?.data?.vehicle_id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(handleAxiosError(error)?.message || 'Something went wrong while fetching contract data');
      setVehicleLoading(false);
    }
  });

  // Vehicle Lock Status

  const unlockURL = `collection/v1/retrieval/vehicle/${vehicleInfo.id}/unlock`;
  const { mutate, isLoading: unlockingVehicle } = useMutationClient(unlockURL, {
    method: 'post',

    onSuccess: (data = {}) => {
      queryClient.invalidateQueries(['contract_information']);
      getVehicleLockStatus(vehicleInfo.device_imei);
    },

    onError: (error = {}) => {
      toast(error.response.data.message);
    }
  });

  const confirmUnlock = () => {
    swal({
      title: 'Are you sure?',
      text: "Are you sure you want to unlock this champion's vehicle?",
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        mutate();
      } else {
      }
    });
  };

  // vehicleInfo.device_imei

  useEffect(() => {
    if (vehicleInfo.device_imei) getVehicleLockStatus(vehicleInfo.device_imei);
  }, [vehicleInfo.device_imei]);

  const [vehicleStatus, setVehicleStatus] = useState(false);
  const getVehicleLockStatus = async (device_imei) => {
    const url = `${config.locked_vehicles_url}?intent=getCommandStatus&device_IMEI=${device_imei}`;
    setVehicleLoading(true);
    let response;
    try {
      response = await Axios.get(url, {
        headers: {
          Authorization: ``
        }
      });
      if (response.data[0].dataFusion.shutOffCommandResult.toLowerCase() === 'immobilized') {
        setVehicleStatus('immobilized');
      } else if (response.data[0].dataFusion.shutOffCommandResult.toLowerCase() === 'in process') {
        setVehicleStatus('in process');
      } else if (response.data[0].dataFusion.shutOffCommandResult.toLowerCase() === 'mobilized') {
        setVehicleStatus('mobilized');
      }
    } catch (err) {}
  };

  // Vehicle Lock Status

  const contractsLogURL = `${config.third_party_url}/v1/champion/vnuban/${contract?.champion_id}`;
  const { data: virtualAccounts = {}, isLoading } = useQueryClient(contractsLogURL, {
    method: 'get',
    retry: 1,
    enabled: !!contract?.champion_id
  });

  const { data: { data: log = [] } = {} } = virtualAccounts;

  const onAccountCreation = async () => {
    setAccountCreationLoader(true);
    if (selectedAccountProvider === 'wema') {
      const payload = {
        customer_reference: match.params.id,
        name: `${championInfo?.user?.first_name} ${championInfo?.user?.last_name}`,
        email: `${championInfo?.user?.email}`,
        mobile_number: `${NumberFormatter.formatPhoneNumber(championInfo?.user?.phone)}`
      };

      await createWovenAccount(payload)
        .then((res) => {
          toast.success(res.data.message);
          setAccountProviderModal(false);
          setAccountCreationLoader(false);
        })
        .catch((err) => {
          setAccountCreationLoader(false);
          setAccountProviderModal(false);
          toast.error(handleAxiosError(err).message || 'Something went wrong');
        });
    }

    if (selectedAccountProvider === 'sterling') {
      const payload = {
        accountReference: match.params.id,
        customerEmail: `${championInfo?.user?.email}`,
        customerName: `${championInfo?.user?.first_name} ${championInfo?.user?.last_name}`,
        preferredBanks: ['232']
      };

      await createMonifyAccount(payload)
        .then((res) => {
          toast.success(res.data.message);
          setAccountProviderModal(false);
          setAccountCreationLoader(false);
        })
        .catch((err) => {
          setAccountProviderModal(false);
          setAccountCreationLoader(false);
          toast.error(handleAxiosError(err).message || 'Something went wrong');
        });
    }
  };

  const refreshChampionBioInfo = () => {
    getChampionBioInfo(match.params);
    refetchContractInfo();
    selectTab('bio');
  };

  useEffect(() => {
    getChampionBioInfo(match.params);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedAccountProvider('');
  }, [accountProviderModal]);

  return (
    <div className="champion-bio__container">
      <div className="mx-3 my-4">
        <div className="custom-container">
          <div className="row w-100">
            <div className="col-md-12 pb-4 text-left">
              <p
                style={{ width: 'fit-content' }}
                className="cursor pr-4"
                onClick={goBack}>
                GO BACK
              </p>
            </div>
          </div>

          <div className="row w-100">
            <div className="col-md-3">
              <div className="card py-4 px-4 overview-card profile-details__Card">
                <img
                  src={profileDefault}
                  alt="Default profile"
                  style={{ maxWidth: '100px' }}
                  className="mx-auto mt-3"
                />

                {!loading ? (
                  <div className="d-flex flex-column">
                    <p className="font-weight-bold text-center pt-4 mb-2 d-flex justify-content-center align-items-center">
                      {`${championInfo?.user?.first_name || 'N/A'}  ${championInfo?.user?.last_name || 'N/A'}`}{' '}
                    </p>

                    {vehicleStatus === 'immobilized' ? (
                      <div className="ml-2 border rounded-lg p-1">
                        <LockIcon />
                      </div>
                    ) : null}

                    {vehicleStatus === 'in process' ? (
                      <div className="ml-2 mb-2 text-center border rounded-lg p-1">In Process</div>
                    ) : null}

                    {vehicleStatus === 'mobilized' ? (
                      <div className="ml-2 mb-2 text-center border rounded-lg p-1">Mobilized</div>
                    ) : null}

                    {vehicleStatus === 'immobilized' ? (
                      <Button
                        negative={unlockingVehicle}
                        positive={!unlockingVehicle}
                        disabled={unlockingVehicle}
                        className={`apply-filter font-12 w-75 mx-auto mb-2`}
                        onClick={() => confirmUnlock()}>
                        {unlockingVehicle ? 'Unlocking ' : 'Unlock '}Vehicle
                      </Button>
                    ) : null}
                  </div>
                ) : (
                  <div
                    className="table-items table-item-loader mt-2 mx-auto"
                    style={{ width: '60%' }}></div>
                )}

                {!loading ? (
                  <p className="text-secondary text-center">{championInfo?.city}</p>
                ) : (
                  <div
                    className="table-items table-item-loader mt-2 mx-auto"
                    style={{ width: '35%' }}></div>
                )}

                {!contractLoader ? (
                  <div
                    className={`green-div text-center mx-auto px-3 mb-3 ${
                      contract.status ? getHpStatusColorCode(contract?.status?.name) : ''
                    }`}>
                    {contract?.status?.name}
                  </div>
                ) : (
                  <div className="text-center">
                    <DotLoader />
                  </div>
                )}

                <div
                  className="mb-3"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomDoughnut
                    size={100}
                    displayNumber={
                      contract?.payment_status?.name.toLowerCase() === 'prepaid'
                        ? contract?.prepaid_days
                        : contract?.days_overdue
                    }
                    bgColor="#f5f6fa"
                    fillColor={retrievalStatusColors(contract?.payment_status?.name)}
                  />
                </div>

                {!contractLoader ? (
                  <div
                    className={`green-div text-center mx-auto px-3 mb-3 ${
                      contract?.payment_status?.name ? getRetrivalStatusColorCode(contract?.payment_status?.name) : ''
                    }`}>
                    {contract?.payment_status?.name}
                  </div>
                ) : (
                  <div className="text-center">
                    <DotLoader />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="card position-relative h-100">
                <div className="wrapper h-100">
                  {/* sidebar */}
                  <div className="sidebar">
                    <div
                      className={`text-center py-4 cursor ${activeTab === 'bio' ? 'active' : ''}`}
                      onClick={() => selectTab('bio')}>
                      <div className="py-2 d-flex flex-column align-items-center bio">
                        <img
                          src={activeTab === 'bio' ? activeHelmetIcon : helmetIcon}
                          alt="Helmet Icon"
                          style={{ maxWidth: '18px' }}
                        />
                        <button className="text-secondary pt-2">Bio</button>
                      </div>
                    </div>

                    <div
                      className={`text-center py-4 cursor ${activeTab === 'contract' ? 'active' : ''}`}
                      onClick={() => selectTab('contract')}>
                      <div className="py-2 d-flex flex-column align-items-center contract">
                        <img
                          src={activeTab === 'contract' ? activeContractIcon : contractIcon}
                          alt="Helmet Icon"
                          style={{ maxWidth: '20px' }}
                        />
                        <button className="text-secondary pt-2">Contract</button>
                      </div>
                    </div>

                    <div
                      className={`text-center py-4 cursor ${activeTab === 'history' ? 'active' : ''}`}
                      onClick={() => selectTab('history')}>
                      <div className="py-2 d-flex flex-column align-items-center history">
                        <img
                          src={activeTab === 'history' ? activeHistoryIcon : historyIcon}
                          alt="Helmet Icon"
                          style={{ maxWidth: '20px' }}
                        />
                        <button className="text-secondary pt-2">History</button>
                      </div>
                    </div>

                    <div
                      className={`text-center py-4 cursor ${activeTab === 'call' ? 'active' : ''}`}
                      onClick={() => selectTab('call')}>
                      <div className="py-2 d-flex flex-column align-items-center call-history">
                        {/* <img src={activeTab === 'history' ? activeHistoryIcon : historyIcon} alt="Helmet Icon" style={{ maxWidth: '20px' }} /> */}
                        <i
                          className={`${
                            activeTab === 'call' ? 'call-history_icon_active' : ''
                          } phone volume icon call-history_icon`}></i>
                        <button className="text-secondary pt-2">Call History</button>
                      </div>
                    </div>
                  </div>
                  <ContractProvider>
                    <div className="content-wrapper d-flex flex-column">
                      <div className="content p-5 overflow-auto">
                        {activeTab === 'bio' && (
                          <ChampionBioInfo
                            contract={contract}
                            championInfo={championInfo}
                            loading={loading}
                            vehicleInfo={vehicleInfo}
                            vehicleloading={vehicleloading}
                            vNubanInfo={vNubanInfo}
                            listOfChampionAccount={log}
                            loadingVNuban={isLoading}
                          />
                        )}

                        {activeTab === 'contract' && (
                          <ChampionContract
                            championName={`${championInfo?.user?.first_name}_${championInfo?.user?.last_name}_${championInfo?.max_champion_id}`}
                            championObj={history?.location?.state?.championObj}
                            championId={match.params.id}
                            refreshChampionBioInfo={refreshChampionBioInfo}
                            status={contract?.status?.name}
                            contractStatusId={contract?.status?.id}
                            vehicleId={vehicleInfo?.id}
                          />
                        )}

                        {activeTab === 'retrieval' && <Status championId={match.params.id} />}

                        {activeTab === 'history' && (
                          <History
                            championInfo={championInfo}
                            contractId={contract?.id}
                            championId={match.params.id}
                          />
                        )}

                        {activeTab === 'call' && <CallHistory championId={match.params.id} />}
                      </div>
                    </div>
                  </ContractProvider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={accountProviderModal}>
          <div className="modal-body">
            <h3 className="text-center">Select an option and proceed</h3>

            <div className="d-flex justify-content-center my-5">
              <div>
                <div
                  className={`vnuban-elements ${selectedAccountProvider === 'wema' && 'active-elecement'}`}
                  onClick={() => setSelectedAccountProvider('wema')}>
                  {selectedAccountProvider === 'wema' && (
                    <div className="active-selected__vnunban">
                      <div></div>
                    </div>
                  )}
                </div>

                <h5 className="text-center mt-3">WEMA</h5>
              </div>
              <div className="mx-3"></div>
              <div>
                <div
                  className={`vnuban-elements ${selectedAccountProvider === 'sterling' && 'active-elecement'}`}
                  onClick={() => setSelectedAccountProvider('sterling')}>
                  {selectedAccountProvider === 'sterling' && (
                    <div className="active-selected__vnunban">
                      <div></div>
                    </div>
                  )}
                </div>
                <h5 className="text-center mt-3">Sterling</h5>
              </div>
            </div>

            <div className="text-center">
              <Button
                disabled={!selectedAccountProvider || accountCreationLoader}
                secondary
                style={{ height: '45px' }}
                className="px-5"
                onClick={onAccountCreation}>
                Proceed
              </Button>

              <Button
                style={{ height: '45px' }}
                className="px-5 ml-3"
                onClick={() => setAccountProviderModal(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChampionBio;
