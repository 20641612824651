import React from "react";
import "./AddOnServiceCard.scss";
import CheckedIcon from "../../assets/img/checked.svg";


const AddOnServiceCard = ({addOns}) => {
   
    return (   
        <div className="add-on-service-container">
            <div className="first-section">
                <div className="first-section-header"><p className="add-on-header">Add-on Services</p></div>
                {/* <div className="first-section-text"><p className="add-on-card-link">SEE ALL ADDONS</p></div> */}
            </div>
           
            {addOns && addOns.length > 0 ? (
               addOns.map((addOn, index) => 
                <div className="add-on-section" key={index}>
                    <div className="add-on-text-container">
                        <p className="add-on-text">{addOn}</p>
                    </div>
                    <div className="checked-icon-container">
                        <img className="checked-icon" src={CheckedIcon} alt="checked icon"/>
                    </div>
                </div>
            )    
            ):(
                <div className="no-contract-container">
                <p className="no-contract-text">Champion did not select any addOn service!</p>    
              </div>
            )}

        </div>
    )
};

export default AddOnServiceCard;
