import React from 'react';
import './table.styles.scss';

const TableComponent = ({ children }) => {
  return (
    <div className="table-component w-100 table-responsive">
      <table className="w-100">{children}</table>
    </div>
  );
};

export default TableComponent;
