import React from "react";
import "./InactiveContract.scss";
import ActiveContract from "../ActiveContract/ActiveContract";
import RightArrow from "../../assets/img/right-arrow.svg";

export const SingleInactiveContractDetail = ({ contractInfo, disable }) => {
  return <ActiveContract contractInfo={contractInfo} disable={disable} />;
};

export const InactiveContract = ({
  onclickFunction,
  inactiveContractArray,
}) => {
  return (
    <div className="inactive-contract-container">
      {inactiveContractArray &&
        inactiveContractArray.map((contract, index) => (
          <div
            className="single-inactive-contract-container"
            onClick={() => onclickFunction(index + 1, contract?.status?.name)}
            key={index}
          >
            <div className="single-inactive-contract-content-container">
              <div className="inactive-contract-top">
                <p className="inactive-contract-title">
                  {`Contract ${index + 1 < 10 ? "0" + (index + 1) : index}` ||
                    "N/A"}
                </p>
                <p className="inactive-contract-status">
                  <span className={`${contract?.status?.name}`}>
                    {contract?.status?.name || "N/A"}
                  </span>
                </p>
              </div>
              <div className="inactive-contract-bottom">
                <p className="inactive-contract-duration">Duration</p>
                <p className="inactive-contract-date">
                  {`${contract?.activated_at?.substring(
                    0,
                    10
                  )} - ${contract?.updatedAt?.substring(0, 10)}` || "N/A"}
                </p>
              </div>
            </div>
            <div className="next-arrow-container">
              <img
                className="next-arrow-icon"
                alt="next arrow"
                src={RightArrow}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
