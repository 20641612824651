import React from "react";

import "./DotLoader.scss";

const DotLoader = ({ loadingText }) => {
  return (
    <p className="loading">
      {loadingText}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </p>
  );
};

export default DotLoader;
