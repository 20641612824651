import React, { useState, useContext, createContext } from 'react';

const ContractContext = createContext(null);
const ContractsProvider = ({ children }) => {
  const [contractData, setContractData] = useState({
    contract: {},
    openCompleteContractModal: false,
    openContractCommentModal: false
  });

  const [restructuringContractData, setRestructuringContractData] = useState({
    restructuringContractData: {},
    openViewLogModal: false,
    openTransactionDetailModal: false,
    openRestructureContract: false
  });

  const toggleRestructureContract = () => {
    setRestructuringContractData((prevState) => {
      return {
        ...prevState,
        openRestructureContract: !prevState.openRestructureContract
      };
    });
  };

  const toggleRestructuringLog = () => {
    setRestructuringContractData((prevState) => {
      return {
        ...prevState,
        openViewLogModal: !prevState.openViewLogModal
      };
    });
  };

  const toggleViewTransactions = () => {
    setRestructuringContractData((prevState) => {
      return {
        ...prevState,
        openTransactionDetailModal: !prevState.openTransactionDetailModal
      };
    });
  };

  const toggleOpenContract = () => {
    setContractData((prevState) => {
      return {
        ...prevState,
        openCompleteContractModal: !prevState.openCompleteContractModal
      };
    });
  };

  return (
    <ContractContext.Provider
      value={{
        toggleRestructuringLog,
        toggleViewTransactions,
        toggleRestructureContract,
        restructuringContractData,
        setRestructuringContractData,
        contractData,
        toggleOpenContract,
        setContractData
      }}>
      {children}
    </ContractContext.Provider>
  );
};

export default ContractsProvider;

export const useContracts = () => {
  return useContext(ContractContext);
};
