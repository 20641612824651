import React from 'react';
import Modal from '../../components/Modal/Modal';
import { Form, Button } from 'semantic-ui-react';

// images import
import bike from '../../assets/img/bike.svg';
import keke from '../../assets/img/tri.svg';
import successIcon from '../../assets/img/success.png';
import NumberFormatter from '../../util/numberFormatter';

const EditVehiclePricingInfo = ({
  showEditingModal,
  closeEditingModal,
  maxID,
  hpAmount,
  vehicleType,
  dailyRemittance,
  standardDuration,
  insuranceDuration,
  changeStandardDuration,
  workDays,
  successEditModal,
  closeEditSuccessModal,
  handleUpdateDailyRemit,
  hpDurationError
}) => {
  const getVehicleImages = (vehicleType) => {
    switch (vehicleType.toLowerCase()) {
      case 'keke':
        return keke;
      case 'tricycle':
        return keke;
      case 'motorcycle':
        return bike;
      case 'bike':
        return bike;
      default:
        return vehicleType;
    }
  };

  const formatNumber = (number) => {
    let formattedNumber = Number(number).toFixed(2);
    return NumberFormatter.addCommaToNumber(formattedNumber);
  };

  return (
    <>
      {/* success modal for edit vehicle info  */}
      <Modal show={successEditModal}>
        <div
          style={{ padding: '100px' }}
          className="modal-body text-center">
          <div className="d-inline-flex">
            <img
              src={successIcon}
              alt="Success"
            />
          </div>

          <div className="mb-4 mt-4">
            <h3 className="mt-4">HP Duration updated</h3>

            <p className="mb-4">You have successfully edited the HP Duration</p>
          </div>

          <Button
            secondary
            type="button"
            className="rounded"
            style={{ fontSize: '12px' }}
            onClick={closeEditSuccessModal}>
            OKAY, THANKS
          </Button>
        </div>
      </Modal>

      <Modal show={showEditingModal}>
        <div className="modal-header">
          <div className="row">
            <div className="col-md-12">
              <h3>Vehicle Pricing Information</h3>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <Form>
            <Form.Group widths="equal">
              <div className="field disabled-div-input">
                <p
                  style={{
                    color: 'rgba(0,0,0,.87)',
                    fontWeight: 'bold',
                    fontSize: '.92857143em',
                    marginBottom: '5px'
                  }}>
                  Vehicle Type
                </p>
                <div
                  id="vehicleType"
                  className="div-input-field d-flex">
                  {vehicleType}{' '}
                  <img
                    src={getVehicleImages(vehicleType)}
                    alt="vehicle Icon"
                    style={{ maxWidth: '30px', marginLeft: '5px' }}
                  />
                </div>
              </div>

              <Form.Input
                fluid
                label="MAX ID"
                placeholder="002448"
                name="max_id"
                id="max_id"
                value={maxID}
                disabled
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="HP Amount"
                placeholder="5,000,000"
                name="hp_amount"
                id="hp_amount"
                value={formatNumber(hpAmount)}
                disabled
              />

              <Form.Input
                fluid
                label="Daily Remittance"
                placeholder="Input Daily Remittance"
                name="daily_remit"
                id="daily_remit"
                type="number"
                value={dailyRemittance}
                onChange={handleUpdateDailyRemit}
                disabled
              />
            </Form.Group>

            <Form.Group widths="equal">
              <div className="field">
                <Form.Input
                  fluid
                  className={hpDurationError ? 'input-error' : ''}
                  label="HP Duration"
                  placeholder="5"
                  name="hp_duration"
                  id="hp_duration"
                  value={standardDuration + insuranceDuration}
                  disabled
                  onChange={changeStandardDuration}
                />
                <p className="error-text">{hpDurationError}</p>
              </div>

              <Form.Input
                fluid
                label="Payment Days"
                placeholder="Mondays"
                name="payment_days"
                id="payment_days"
                value={workDays ? workDays : 0}
                disabled
              />
            </Form.Group>

            <div className="text-right btn-div">
              <Button
                className="black-link-btn"
                type="button"
                onClick={closeEditingModal}>
                Close
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditVehiclePricingInfo;
