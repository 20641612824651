import React from 'react';
import { formatNumber } from 'pages/Overview/OverviewTable';
import { useContracts } from 'contexts/contracts';
import Button from '@mui/material/Button';
import style from 'components/ContractToBeClose/style';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'services/apiClient';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useContractProvider } from 'components/Contracts/contracts';
import { toast } from 'react-toastify';
import TransactionsModal from 'components/ContractToBeClose/full-transaction-details';

export const RestructuringDetails = ({ hasRestructuringBtn }) => {
  const api_prefix = 'collection/v1/';
  const history = useHistory();
  // eslint-disable-next-line
  const { contractData, toggleRestructuringLog, toggleViewTransactions, toggleRestructureContract } = useContracts();
  const { showRestructureModal, toggleRestructureModal } = useContractProvider();
  const { contract = {} } = contractData;
  const [contractID, setContractID] = React.useState('');
  const details = `contracts/getContractRestructureDetails/${contractID}`;
  const fetchTotalAmountRemitted = `${api_prefix}${details}`;

  // eslint-disable-next-line
  const { data: restructureDetailsRes = {}, isLoading } = useQueryClient(fetchTotalAmountRemitted, {
    key: 'restructuring_details',
    method: 'get',
    retry: false,
    enabled: !!contractID,
    onError: (errorData = {}) => {
      toast.error(errorData.response?.data?.message);
    }
  });

  const restructureDetails = restructureDetailsRes.data?.data ? restructureDetailsRes?.data?.data : {};
  const {
    min_amount_to_be_paid,
    no_of_times_restrcutured,
    amount_overdue = {},
    hp_amount,
    days_missed
  } = restructureDetails;

  React.useEffect(() => {
    setContractID(contract.contract_id ?? contract.id);
  }, [contract]);
  // invalidate "contracts_record"

  const contractDetails = [
    { title: 'HP Amount', amount: formatNumber(Number(hp_amount)) },
    {
      title: 'Amount Overdue (At Retreival)',
      amount:
        amount_overdue.amount_owed_before_retrieval == null
          ? 'N/a'
          : formatNumber(Number(amount_overdue.amount_owed_before_retrieval)),
      tooltip: 'This is what the champion owes in total'
    },
    {
      title: `Amount to be Paid — ${amount_overdue.percentage}%`,
      amount: min_amount_to_be_paid == null ? 'N/a' : formatNumber(Number(min_amount_to_be_paid)),
      tooltip: 'This is what the champion needs to pay before new contract takes effect'
    },
    {
      title: 'Amount Paid',
      amount:
        amount_overdue.amount_paid_after_retrieval == null
          ? 'N/a'
          : formatNumber(Number(amount_overdue.amount_paid_after_retrieval)),
      tooltip: 'This is the total the champion has paid from what he is meant to pay'
    },
    {
      title: 'No. Of Times Restructured',
      amount: no_of_times_restrcutured,
      subFunc: () => {
        toggleRestructuringLog();
      },
      subFuncText: 'VIEW LOG'
    },
    { title: 'No. of Missed Payments (Days)', amount: days_missed == null ? 'N/a' : formatNumber(Number(days_missed)) },
    {
      icon: <ArrowOutwardIcon />,
      mainFunc: () => toggleViewTransactions(),
      mainFuncText: 'SEE FULL TRANSACTION DETAILS'
    }
  ];

  return (
    <div className="contract-details-wrapper">
      <div className="contract-details-wrapper-child w-100 mr-5">
        <div className="dis-grid">
          {contractDetails.map((data, index) =>
            data.mainFunc ? (
              <div
                key={index}
                className="d-flex align-items-center">
                <button
                  disabled={no_of_times_restrcutured == null}
                  style={{ color: '#E88E15' }}
                  className="w-100 d-flex text-nowrap align-items-center justify-content-center f-600"
                  onClick={data.mainFunc}>
                  {data.mainFuncText} <span>{data.icon}</span>{' '}
                </button>
              </div>
            ) : (
              <div
                key={index}
                className="close-contract-div position-relative">
                <>
                  <>
                    <div className="d-flex align-items-center ">
                      <p className="f-600 f-18 mb-2">{data.amount}</p>
                      {data.subFunc && (
                        <button
                          disabled={no_of_times_restrcutured == null || no_of_times_restrcutured === 0}
                          onClick={data.subFunc}
                          className="subfunc-btn f-600">
                          {data.subFuncText}
                        </button>
                      )}
                    </div>
                    <p>{data.title}</p>
                  </>
                  {data.tooltip ? (
                    <div className="tool-tip">
                      <MyToolTip title={data.tooltip} />
                    </div>
                  ) : null}
                </>
              </div>
            )
          )}
        </div>
      </div>
      <div>
        <Button
          sx={[style.btn, style.moreInfoBtn]}
          onClick={() => history.push(`/champion/${contract.champion_id}`)}>
          see more information
        </Button>
        <br />
        {hasRestructuringBtn && (
          <Button
            disabled={amount_overdue.amount_paid_after_retrieval < min_amount_to_be_paid || min_amount_to_be_paid === 0}
            sx={[style.btn, style.completeContractBtn]}
            onClick={() => {
              toggleRestructureModal(!showRestructureModal);
            }}>
            restructure contract
          </Button>
        )}
      </div>
      <TransactionsModal
        restructureDetails={restructureDetails}
        contractInfo={contractData}
      />
    </div>
  );
};

const MyToolTip = ({ title }) => {
  return (
    <Tooltip
      sx={style.tooltip}
      title={title}>
      <IconButton>
        <HelpIcon></HelpIcon>
      </IconButton>
    </Tooltip>
  );
};
